import React from "react";
import { withRouter } from "react-router-dom";
import { useStyles } from '../../assets/stylesMUI/sidebarStyles';
import urls from "config/urls";
import { COMPANY_LOGO_SRC, COMPANY_NAME, COMPANY_LOGO_MAX_WIDTH, COMPANY_LOGO_MAX_HEIGHT } from "config/envVariables";


function BrandLogoText({ history, showCompanyName = true, logoMaxWidth, logoMaxHeight, showText, props }) {
    logoMaxWidth = logoMaxWidth || COMPANY_LOGO_MAX_WIDTH;
    logoMaxHeight = logoMaxHeight || COMPANY_LOGO_MAX_HEIGHT;
    const classes = useStyles(props);

    const handleTextDisplay = () => {
        if (showText === false) {
            return false;
        } else {
            return true;
        }
    }

    return (
        <div className={classes.logoContainer}
            onClick={(e) => {
                e.preventDefault();
                history.push(urls.DOMAIN)
            }}
        >
            <img className={classes.logoContainerImg}
                alt="Company logo"
                src={COMPANY_LOGO_SRC}
            />
            {handleTextDisplay() && <p className={classes.logoContainerText}>
                {COMPANY_NAME}
            </p>}
        </div>
    );
}

export default withRouter(BrandLogoText);
