import React from "react";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import get from "lodash/get";
import { Box, Grid, Button } from "@material-ui/core";
import ajax from "remotes/ajax";
import UtilAlert from "util/toastAlerts";
import { getServerErrorMessage } from "util/Errors";
import TypographyLarge from "components/form/TypographyLarge";
import { withLocalToasts } from "components/hoc";
import CustomInput from "components/form/InputWithValidation";
import WrappedPulseLoader from "components/Loader/WrappedPulseLoader";


class ChangeExistingPasswordForm extends React.Component {
    state = {
        // new customer data
        new_password1: "",
        new_password2: "",
        // other
        validationErrors: {},
        loading: false
    };

    FORM_ID = "change-existing-password-form";

    componentDidMount() {
    }

    componentWillUnmount() {
        this.props.dismissLocalToasts();
    }

    render() {
        return (

            <div className="change-existing-password__wrapper">
                <Box pb={2}>
                    <TypographyLarge> 
                        {this.props.t("user.changeExistingPassword.title", "Change password")}
                    </TypographyLarge>
                </Box>
                {this.renderContent()}
            </div>
        );
    }

    renderContent() {
        return this.renderForm();
    }

    renderForm() {
        const { validationErrors, loading } = this.state;
        return (
            <form
                onSubmit={this.onSubmit}
                id={this.FORM_ID}
                className="change-existing-password__form text-left"
            >
                <Grid container>
                    <Grid item xs={12} m={12}>
                        <CustomInput
                            value={this.state.new_password1}
                            error={validationErrors.new_password1}
                            onChange={(value) =>
                                this.setState({ new_password1: value })
                            }
                            type="password"
                            labelText={this.props.t("user.changeExistingPassword.newPasswordLabel", "New password")}
                            placeholder={this.props.t("user.changeExistingPassword.newPasswordPlaceholder", "New password")}
                        />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} sm={12}>
                        <CustomInput
                            value={this.state.new_password2}
                            error={validationErrors.new_password2}
                            onChange={(value) =>
                                this.setState({ new_password2: value })
                            }
                            type="password"
                            labelText={this.props.t("user.changeExistingPassword.repeatNewPasswordLabel", "Repeat new password")}
                            placeholder={this.props.t("user.changeExistingPassword.repeatNewPasswordPlaceholder", "Repeat new password")}
                        />
                    </Grid>
                </Grid>
                <Box mt={4}>
                    <Grid container>
                        <Button
                            variant="contained"
                            size="large"
                            className="submitButton"
                            form={this.FORM_ID}
                            disabled={loading}
                            type="submit"
                        >
                            {loading ? <WrappedPulseLoader /> : this.props.t("user.changeExistingPassword.submitButton", "Change password")}
                        </Button>
                    </Grid>
                </Box>
            </form>
        );
    }

    onValidate = () => {
        return true; // leaving validations to server-side atm.
    }

    onSubmit = (e) => {
        e.preventDefault();
        UtilAlert.dismissAlerts(this.state.localAlertIds);
        this.setState({
            validationErrors: {}
        });
        if (!this.onValidate()) {
            const alertId = UtilAlert.createValidationAlert(this.props.t("user.changeExistingPassword.validationErrorMessage", "Please check the fields for invalid data."));
            this.props.addLocalToast(alertId);
            return;
        }

        this.changeExistingPassword();
    };

    changeExistingPassword = () => {
        const { new_password1, new_password2 } = this.state;
        this.setState({ loading: true });
        ajax.doChangeExistingPassword(new_password1, new_password2)
            .then(() => {
                this.setState({
                    new_password1: "",
                    new_password2: "",
                })
                const successMessage = this.props.t("user.changeExistingPassword.successMessage", "Password has been changed successfully.");
                const alertId = UtilAlert.createAlert(successMessage, {
                    type: UtilAlert.ALERT_TYPE.SUCCESS,
                    autoClose: true
                });
                this.props.addLocalToast(alertId);
            })
            .catch(error => {
                const data = get(error, "response.data", null);
                if (typeof data === "object" && data !== null) {
                    this.setState({ validationErrors: data });
                } else {
                    const errorMessage = getServerErrorMessage(error);
                    const errorTitle = this.props.t("user.changeExistingPassword.errorMessage", "Error occured during password change.");
                    const alertId = UtilAlert.createAlert(errorMessage, {
                        type: UtilAlert.ALERT_TYPE.ERROR,
                        title: errorTitle,
                        autoClose: false
                    });
                    this.props.addLocalToast(alertId);
                }
            })
            .finally(() => this.setState({ loading: false }))

    }
}

export default withRouter(withTranslation()(withLocalToasts(ChangeExistingPasswordForm)));
