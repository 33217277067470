import React, { Component } from "react";
import { withTranslation, useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Button, Row, Col, ListGroup, Card, CardHeader, CardBody, CardFooter, CardTitle } from "reactstrap";
import _ from "lodash";

import Loading from "components/notifications/Loading";
import CloseButton from "components/modal/CloseButton";
import EscClosable from "components/modal/EscClosable";

function ImportantChange(props) {
    const { t } = useTranslation();
    return (
        <Row>
            <Col
                xs="3"
                className="text-right pr-0 font-weight-bold text-capitalize"
            >
                {props.label}
            </Col>
            <Col>
                <code className="text-danger">
                    {props.oldValue}
                </code>
                &nbsp;
                {t("confirmChangesModal.toWord", "to")}
                &nbsp;
                <code className="text-success">
                    {props.newValue}
                </code>
            </Col>
        </Row>
    );
}

class ConfirmChangesModal extends Component {
    static propTypes = {
        onToggle: PropTypes.func.isRequired,
        isOpen: PropTypes.bool.isRequired,
        isError: PropTypes.bool,
        isLoading: PropTypes.bool,
        onCancel: PropTypes.func.isRequired,
        cancelButtonLabel: PropTypes.string,
        onConfirm: PropTypes.func.isRequired,
        confirmButtonLabel: PropTypes.string,
        headerTitle: PropTypes.string,
        changesMade: PropTypes.array,

    };

    toggle = () => {
        this.props.onToggle();
    }

    render() {
        const { t } = this.props;
        if (!this.props.isOpen) {
            return false;
        }

        const changes = this.props.changesMade.map((change, idx) =>
        (<ImportantChange
            key={idx}
            label={change.label}
            oldValue={change.oldVal}
            newValue={change.newVal}
        />)
        );

        return (
            <EscClosable onCloseModal={this.props.isLoading ? _.noop : this.props.onCancel}>
                <div className="overlay-card-wrapper confirm-changes-wrapper">
                    <Card className="overlay-card">
                        <CardHeader className="justify-content-center">
                            <CloseButton onClick={this.props.isLoading ? _.noop : this.props.onCancel} />
                            <CardTitle
                                tag="h4"
                                className="text-center pt-2"
                            >
                                {this.props.headerTitle || t("confirmChangesModal.defaultTitle", "Confirm changes")}
                            </CardTitle>
                        </CardHeader>
                        <CardBody>
                            <ListGroup>
                                {changes}
                            </ListGroup>
                        </CardBody>
                        <div className={classNames("text-center", { invisible: !this.props.isError })} >
                            <span className="error-msg is-invalid-custom-message-color">
                                {t("confirmChangesModal.unknownError", "Unknown error, try again.")}
                            </span>
                        </div>
                        <CardFooter className="">
                            <Button
                                color="primary"
                                className="mr-2 btn-wd"
                                onClick={this.props.onConfirm}
                                disabled={this.props.isLoading}
                            >
                                {this.props.isLoading ?
                                    (<Loading />) :
                                    (this.props.confirmButtonLabel || t("confirmChangesModal.defaultConfirmButton", "Confirm"))
                                }
                            </Button>
                            <Button
                                color="secondary"
                                className="btn-wd float-right"
                                onClick={this.props.onCancel}
                                disabled={this.props.isLoading}
                            >
                                {this.props.cancelButtonLabel || t("confirmChangesModal.defaultCancelButton", "Cancel")}
                            </Button>
                        </CardFooter>
                    </Card>
                </div>
            </EscClosable>
        );
    }
}

export default withTranslation()(ConfirmChangesModal);
