import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Button, Link, Box, Dialog, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import styled from "styled-components";
import _ from "lodash";
import Loading from "components/notifications/Loading";
import EscClosable from "components/modal/EscClosable";

const ButtonGroup = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

class ConfirmationModal extends Component {
    static propTypes = {
        isOpen: PropTypes.bool.isRequired,
        isError: PropTypes.bool,
        isLoading: PropTypes.bool,
        onCancel: PropTypes.func.isRequired,
        cancelButtonLabel: PropTypes.string,
        onConfirm: PropTypes.func.isRequired,
        confirmButtonLabel: PropTypes.string,
        headerTitle: PropTypes.string,

    };

    render() {
        const {
            isOpen,
            isError,
            isLoading,
            onCancel,
            cancelButtonLabel,
            onConfirm,
            confirmButtonLabel,
            headerTitle,
            t,
            children
        } = this.props;
        if (!isOpen) {
            return false;
        }

        return (
            <EscClosable onCloseModal={isLoading ? _.noop : onCancel}>
                <Dialog open={isOpen} onClose={onCancel}>
                    <Box p={2}>
                        <CloseIcon
                            className="modalClose"
                            onClick={onCancel} />
                        <Box p={2}>
                            <Typography
                                variant="h5"
                                align="center"
                            >
                                {headerTitle || t("confirmationModal.defaultTitle", "Confirm action")}
                            </Typography>
                        </Box>
                        <Box p={2}>
                            {children}
                        </Box>
                        <div className={classNames("text-center", { invisible: !isError })} >
                            <span className="error-msg is-invalid-custom-message-color">{t("confirmationModal.unknownError", "Unknown error, try again.")}</span>
                        </div>
                        <ButtonGroup>
                            <div>
                                <Button
                                    className="submitButton"
                                    onClick={onConfirm}
                                    disabled={isLoading}
                                >
                                    {this.props.isLoading ?
                                        (<Loading />) :
                                        (confirmButtonLabel || t("confirmationModal.defaultConfirmButton", "Confirm"))
                                    }
                                </Button>
                            </div>
                            <Box p={2}>
                                <Link
                                    className="linkStyleButton"
                                    onClick={onCancel}
                                    disabled={isLoading}
                                    style={{ display: 'block', textAlign: 'center', cursor: 'pointer' }}
                                >
                                    {cancelButtonLabel || t("confirmationModal.defaultCancelButton", "Cancel")}
                                </Link>
                            </Box>
                        </ButtonGroup>
                    </Box>
                </Dialog>
            </EscClosable>
        );
    }
}

export default withTranslation()(ConfirmationModal);
