import React from "react";
import { Trans } from "react-i18next";

export const predictionStates = {
    FRESH: "Fresh",
    QUEUED: "Queued",
    IN_PROGRESS: "In progress",
    OUTDATED: "Outdated",
    FAILED: "Failed",
};

export const accuracies = {
    NONE: "None",
    CORRECT: "Correct",
    UNCLEAR: "Unclear",
    WRONG: "Wrong",
};

export const accuracyFilter = {
    NONE: "None",
    CORRECT: "Matches",
    UNCLEAR: "Uncertain",
    WRONG: "Mismatch",
};

export const accuracyButton = {
    NONE: "None",
    CORRECT: "Match",
    UNCLEAR: "Uncertain",
    WRONG: "Mistake",
};

export const frequency = {
    ONE_TIME: "Single",
    WEEKLY: "Weekly",
    MONTHLY: "Monthly",
    COMPARATIVE: "Comparative",
};

export const trainingPeriods = {
    6: "6 Months",
    12: "12 Months",
    24: "24 Months",
};

export const transportType = {
    ROAD: "ROAD",
    RAIL: "RAIL",
    SEA: "SEA",
    AIR: "AIR",
};

export const transportTypeIcon = {
    ROAD: "localShipping",
    RAIL: "directionsRailway",
    SEA: "directionsBoat",
    AIR: "flightTakeoff"
};

export const transportTypeTranslations = {
    ROAD: <Trans i18nKey="form.transportType.road">ROAD</Trans>,
    RAIL: <Trans i18nKey="form.transportType.rail">RAIL</Trans>,
    SEA: <Trans i18nKey="form.transportType.sea">SEA</Trans>,
    AIR: <Trans i18nKey="form.transportType.air">AIR</Trans>,
};

export const loadType = {
    LTL: "LTL", // Less Than Truckload
    FTL: "FTL", // Full Truckload
    LCL: "LCL", // Less Than Container Load
    FCL: "FCL", // Full Container Load
};

export const transportTypeLoadTypes = {
    ROAD: [loadType.LTL, loadType.FTL],
    RAIL: [loadType.LCL, loadType.FCL],
    SEA: [loadType.LCL, loadType.FCL],
    AIR: [],
};

export const quoteStatus = {
    SUBMITTED: "Requested",
    OFFER_MADE: "Offer made",
    OFFER_OPENED: "Opened",
    OFFER_REJECTED: "Rejected",
    BOOKING_STARTED: "Accepted",
    WON: "Booked",
    ARCHIVED: "Archived",
};

export const quouteEventType = {
    QUOTE_SUBMITTED: "Quote request submitted",
    OFFER_MADE: "Offer made",
    EMAIL_SENT: "E-mail sent",
    OFFER_OPENED: "Offer opened",
    OFFER_REJECTED: "Offer rejected",
    BOOKING_STARTED: "Booking accepted",
    BOOKING_COMPLETED: "Booking completed",
    QUOTE_ARCHIVED: "Quote archived",
    QUOTE_UNARCHIVED: "Quote unarchived",
};

export const saleStatus = {
    offer_made_amount: "Offers made",
    offer_opened_amount: "Offers opened",
    booking_started_amount: "Bookings started",
    won_amount: "Booked",
};
