import React from "react";
import { toast } from "react-toastify";
import defaults from "lodash/defaults";
import { Trans } from "react-i18next";

import { getServerErrorMessage } from "util/Errors";


export const ALERT_TYPE = {
    DEFAULT: toast.TYPE.DEFAULT,
    SUCCESS: toast.TYPE.SUCCESS,
    INFO: toast.TYPE.INFO,
    WARNING: toast.TYPE.WARNING,
    ERROR: toast.TYPE.ERROR,
};

export const ALERT_TYPE_CONFIGS = [
    {
        value: ALERT_TYPE.DEFAULT,
        method: toast
    },
    {
        value: ALERT_TYPE.SUCCESS,
        method: toast.success
    },
    {
        value: ALERT_TYPE.INFO,
        method: toast.info
    },
    {
        value: ALERT_TYPE.WARNING,
        method: toast.warn
    },
    {
        value: ALERT_TYPE.ERROR,
        method: toast.error
    },
]

const _checkAlertTypeOrDefault = type => Object.values(ALERT_TYPE).includes(type) ? type : ALERT_TYPE.DEFAULT;
const _getAlertTypeConfigOrDefault = type => ALERT_TYPE_CONFIGS.find(item => item.value === _checkAlertTypeOrDefault(type));

const createTitleAndBodyContent = (title, text) => (
    <div>
        <div>{title}</div>
        <div>{text}</div>
    </div>
)

export function createAlert(text, options) {
    options = defaults(options, {
        title: undefined,
        type: ALERT_TYPE.DEFAULT
    });
    const { type, title } = options;

    const alertConfig = _getAlertTypeConfigOrDefault(type);

    if (!title) {
        return alertConfig.method(text, options);
    } else {
        return alertConfig.method(createTitleAndBodyContent(title, text), options);
    }
}

export function createValidationAlert(text, options) {
    options = defaults(options, {
        title: <Trans i18nKey="alerts.validationAlertDefaultTitle">Validation error</Trans>,
        type: ALERT_TYPE.WARNING
    });
    return createAlert(text, options);
}

export function createErrorAlert(text, options) {
    options = defaults(options, {
        type: ALERT_TYPE.ERROR,
        autoClose: false,
        parseError: true
    });
    if (options.parseError) {
        text = getServerErrorMessage(text);
    }

    return createAlert(text, options);
}

export function dismissAlerts(ids) {
    if (Array.isArray(ids)) {
        for (const id of ids) {
            toast.dismiss(id);
        }
    } else {
        toast.dismiss(ids);
    }
}

export default {
    ALERT_TYPE, createAlert, createValidationAlert, dismissAlerts
}
