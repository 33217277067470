import React from "react";
import { withTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { Container, Paper, Box, Link } from '@material-ui/core';
import urls from "config/urls"
import CreateCustomerForm from "components/user/CreateCustomerForm";
import TypographyLargest from "components/form/TypographyLargest";

class CreateCustomerPage extends React.Component {
    render() {
        const { t } = this.props;
        return (
            <Container>
                <Box mt={6}>
                    <Paper
                        elevation={3}
                        style={{ maxWidth: '70rem', margin: 'auto' }} >
                        <Box p={8}>
                            <TypographyLargest>
                                {t("form.booking.formFields.heading.signup", "Sign up")}
                            </TypographyLargest>
                            <CreateCustomerForm />
                            <Link
                                component="button"
                                variant="body2"
                                underline="none"
                                className="linkStyleButton"
                                style={{ display: 'block', margin: 'auto' }}
                            >
                                <NavLink
                                    to={urls.LOGIN}>
                                    {this.props.t("buttons.login", "Login")}
                                </NavLink>
                            </Link>
                        </Box>
                    </Paper>
                </Box>
            </Container >

        );
    }
}

export default withTranslation()(CreateCustomerPage);
