import React, { Component } from "react";
import PropTypes from "prop-types";

export default class ValidationMessage extends Component {
    static propTypes = {
        isVisible: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
        message: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    };

    render() {
        const { message, isVisible } = this.props;
        if (!isVisible || !message) {
            return false;
        }

        let validationMessage = message;
        if (Array.isArray(message)) {
            validationMessage = message.join(" ");
        }

        return <div style={{ color: 'red' }}>{validationMessage}</div>;
    }
}
