import commonRoutes from "./common";
import publicRoutes, { publicSpecificRoutes } from "./public";
import companyRoutes, { companySpecificRoutes } from "./company";
import customerRoutes, { customerSpecificRoutes } from "./customer";


export { publicRoutes, companyRoutes, customerRoutes };

export default [
    ...commonRoutes,
    ...publicSpecificRoutes,
    ...companySpecificRoutes,
    ...customerSpecificRoutes,
];
