import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { getBookingUrl } from "config/urls";
import Loading from "components/notifications/Loading";
import connect from "react-redux/es/connect/connect";
import { fetchBookings } from "remotes/fetching";
import { onBookingsReceived } from "redux/actions/bookings";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import ToolkitProvider from "react-bootstrap-table2-toolkit";

import { transportType, transportTypeIcon } from "maps/classifiers";
import { formatRouteFrom, formatRouteTo } from "util/formatRoute";
import SearchIcon from '@material-ui/icons/Search';

import moment from "moment";
import { dateFormat } from "util/dateTime";
import { Container, Paper, Box, Grid, Button } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import TypographyLarge from "components/form/TypographyLarge";
import Tooltip from "components/form/Tooltip";

class MySearch extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.searchValue !== prevProps.searchValue ||
            this.props.refreshOnChange !== prevProps.refreshOnChange
        ) {
            this.props.onSearch(this.props.searchValue);
        }
    }

    render() {
        return <></>;
    }
}

export class BookingsDatatable extends Component {
    static propTypes = {
        bookings: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
        chosenLocationFrom: PropTypes.string,
        chosenLocationTo: PropTypes.string,
        chosenClient: PropTypes.string,
        chosenPickupStart: PropTypes.object,
        chosenPickupEnd: PropTypes.object,
        chosenGenericSearch: PropTypes.string,
        onBookingsReceived: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            refreshSearch: false,
            bookingsLoading: false
        };

        this.filterLocationFrom = null;
        this.filterLocationTo = null;
        this.filterClient = null;
        //        this.filterPickupDateStart;
        //        this.filterPickupDateEnd;
    }

    async componentDidMount() {
        this._fetchBookings();
    }

    componentDidUpdate(prevProps, prevState) {
        /* Its important that bookings are initialized with some items, otherwise datatable won't be rendered and
            filters won't be mounted. React doesn't like when we change state of unmounted components.
            */
        const bookings = this.props.bookings;
        if (Array.isArray(bookings) && bookings.length) {
            this.filterLocationFrom(this.props.chosenLocationFrom);
            this.filterLocationTo(this.props.chosenLocationTo);
            this.filterClient(this.props.chosenClient);
            if (prevState.refreshSearch === this.state.refreshSearch) {
                this.refreshSearchFilter();
            }
        }
    }

    render() {
        const { t } = this.props;
        return (
            <Container disableGutters maxWidth={false}>
                <Paper elevation={3}>
                    <Box p={4}>
                        <Grid container>
                            <TypographyLarge>
                                {t("bookingList.heading", "Booking list")}
                            </TypographyLarge>
                            <div style={{ marginLeft: 'auto' }}>
                                <Button
                                    type="submit"
                                    className="submitButton"
                                    variant="contained"
                                    size="small"
                                    onClick={this.refreshBookings}
                                    style={{ minWidth: '26px' }}
                                >
                                    <RefreshIcon />
                                </Button>
                            </div>
                        </Grid>
                        {this.renderTable()}
                    </Box>
                </Paper >
            </Container >
        );
    }

    renderTable() {
        const { t } = this.props;
        const bookingsOrDefault = this.props.bookings || [];
        const filteredBookings = this.filterBookings(bookingsOrDefault);

        let NoDataIndication;

        if (!this.props.bookings || this.state.bookingsLoading) {
            NoDataIndication = <Loading />;
        }
        else if (!this.props.bookings.length) {
            NoDataIndication = <div className="card-content text-center">
                {t("bookingList.noDataText", "No bookings to show.")}
            </div>;
        }
        else {
            NoDataIndication = <div className="card-content text-center">
                {t("bookingList.noDataMatchingFiltersText", "None of the bookings matched active filters.")}
            </div>;
        }

        const columns = [
            {
                dataField: "id",
                text: "ID",
                sort: true,
                classes: "booking-col-id pr-0",
                headerClasses: "booking-col-header-id pr-0",
            },
            {
                dataField: "transport_type",
                text: t("common.tableHeaders.type", "Type"),
                sort: true,
                formatter: this.typeColumnFormatter,
                classes: "text-center booking-col-transport-type",
                headerClasses: "text-center booking-col-header-transport-type",
                searchable: false,
            },
            {
                dataField: "pickup_date",
                text: t("common.tableHeaders.pickupDate", "Pickup date"),
                sort: true,
                formatter: (cell) => cell && moment(cell).format(dateFormat),
                classes: "booking-col-pickup-date",
                headerClasses: "booking-col-header-pickup-date",
            },
            {
                dataField: "from_country_code",
                text: t("common.tableHeaders.from", "From"),
                sort: true,
                sortValue: this.fromColumnFormatter,
                filterValue: this.fromColumnFormatter,
                formatter: this.fromColumnFormatter,
                classes: "booking-col-route-from",
                headerClasses: "booking-col-header-route-from",
                filter: textFilter({
                    className: "d-none",
                    getFilter: (filter) => {
                        this.filterLocationFrom = filter;
                    },
                }),
            },
            {
                dataField: "to_country_code",
                text: t("common.tableHeaders.to", "To"),
                sort: true,
                sortValue: this.toColumnFormatter,
                filterValue: this.toColumnFormatter,
                formatter: this.toColumnFormatter,
                classes: "booking-col-route-to",
                headerClasses: "booking-col-header-route-to",
                filter: textFilter({
                    className: "d-none",
                    getFilter: (filter) => {
                        this.filterLocationTo = filter;
                    },
                }),
            },
            {
                dataField: "total_weight",
                text: t("common.tableHeaders.weight", "Weight (kg)"),
                sort: true,
                classes: "booking-col-weight",
                headerClasses: "booking-col-header-weight",
            },
            {
                dataField: "total_ldm",
                text: t("common.tableHeaders.ldm", "LDM"),
                sort: true,
                classes: "booking-col-ldm",
                headerClasses: "booking-col-header-ldm",
            },
            {
                dataField: "company_name",
                text: t("common.tableHeaders.client", "Client"),
                sort: true,
                classes: "booking-col-client",
                headerClasses: "booking-col-header-client",
                filter: textFilter({
                    className: "d-none",
                    getFilter: (filter) => {
                        this.filterClient = filter;
                    },
                }),
            },
            {
                dataField: "created_on",
                text: t("common.tableHeaders.created", "Created"),
                formatter: (cell) => { return new moment(cell).format(dateFormat); },
                sort: true,
                hidden: true,
                searchable: false,
            },
            {
                dataField: "",
                text: t("common.tableHeaders.actions", "Action"),
                classes: "td-actions text-right booking-col-actions",
                formatter: this.actionColumnFormatter.bind(this),
                headerClasses: "text-right booking-col-header-actions",
                searchable: false,
            },
        ];

        const defaultSorted = [{
            dataField: "created_on",
            order: "desc",
        }];

        const paginationOptions = {
            hidePageListOnlyOnePage: true,
            paginationSize: 50,
            hideSizePerPage: filteredBookings.length < 10,
        };

        const rowEvents = {
            onClick: (e, row) => {
                this.onClickView(row);
            },
        };

        return (
            <Box pt={2}>
                <ToolkitProvider
                    keyField="id"
                    data={filteredBookings}
                    columns={columns}
                    bootstrap4={true}
                    search={{
                        // searchFormatted: true,
                    }}
                >
                    {
                        props => (
                            <div>

                                <MySearch
                                    {...props.searchProps}
                                    searchValue={this.props.chosenGenericSearch}
                                    refreshOnChange={this.state.refreshSearch}
                                />
                                {/* <ToggleList { ...props.columnToggleProps } />*/}
                                <BootstrapTable
                                    {...props.baseProps}
                                    defaultSorted={defaultSorted}
                                    bordered={false}
                                    wrapperClasses="table-responsive overflow-auto"
                                    classes=""
                                    rowClasses="booking-item pointer"
                                    pagination={paginationFactory(paginationOptions)}
                                    rowEvents={rowEvents}
                                    hover
                                    filter={filterFactory()}
                                    noDataIndication={NoDataIndication}
                                />
                            </div>
                        )
                    }
                </ToolkitProvider>
            </Box>
        );
    }

    typeColumnFormatter(cell, row) {
        return (
            <i
                className={transportTypeIcon[row.transport_type]}
                title={transportType[row.transport_type]}
            />
        );
    }

    fromColumnFormatter(cell, row) {
        return formatRouteFrom(row);
    }

    toColumnFormatter(cell, row) {
        return formatRouteTo(row);
    }

    actionColumnFormatter(cell, row) {
        return (
            <>
                <Tooltip title="Details">
                    <button
                        type="button"
                        className="btn btn-link btn-xs linkStyleButton"
                        onClick={() => this.onClickView(row)}
                    >
                        <SearchIcon />
                    </button>
                </Tooltip>
            </>
        );
    }

    async onClickView(booking) {
        const bookingRoute = getBookingUrl(booking.id);
        this.props.history.push(bookingRoute);
    }

    filterBookings(bookings) {
        let filteredBookings = bookings;
        //        filteredBookings = this.filterByLocationFrom(filteredBookings);
        //        filteredBookings = this.filterByLocationTo(filteredBookings);
        //        filteredBookings = this.filterByClient(filteredBookings);
        filteredBookings = this.filterByPickupStart(filteredBookings);
        filteredBookings = this.filterByPickupEnd(filteredBookings);
        return filteredBookings;
    }

    filterByLocationFrom(data) {
        if (this.props.chosenLocationFrom) {
            return data.filter(q => formatRouteFrom(q).toLowerCase().includes(this.props.chosenLocationFrom.trim().toLowerCase()));
        }
        return data;
    }

    filterByLocationTo(data) {
        if (this.props.chosenLocationTo) {
            return data.filter(q => formatRouteTo(q).toLowerCase().includes(this.props.chosenLocationTo.trim().toLowerCase()));
        }
        return data;
    }

    filterByClient(data) {
        if (this.props.chosenClient) {
            return data.filter(q => q.company_name.toLowerCase().includes(this.props.chosenClient.trim().toLowerCase()));
        }
        return data;
    }

    filterByPickupStart(data) {
        if (this.props.chosenPickupStart) {
            const pickupStartDate = this.props.chosenPickupStart ? new moment(this.props.chosenPickupStart) : null;
            return data.filter(q => new moment(q.pickup_date) >= pickupStartDate);
        }
        return data;
    }

    filterByPickupEnd(data) {
        if (this.props.chosenPickupEnd) {
            const pickupEndDate = this.props.chosenPickupEnd ? new moment(this.props.chosenPickupEnd) : null;
            return data.filter(q => new moment(q.pickup_date) <= pickupEndDate);
        }
        return data;
    }

    refreshBookings = async () => {
        this.props.onBookingsReceived(null);
        this._fetchBookings();
    }

    refreshSearchFilter = () => {
        this.setState({
            refreshSearch: !this.state.refreshSearch,
        });
    }

    _fetchBookings() {
        this.setState({ bookingsLoading: true });
        fetchBookings().finally(() => {
            this.setState({ bookingsLoading: false });
        })
    }
}

function mapStateToProps(state) {
    return {
        bookings: state.bookings,
        chosenClient: state.bookingSearch.chosenClient,
        chosenLocationFrom: state.bookingSearch.chosenLocationFrom,
        chosenLocationTo: state.bookingSearch.chosenLocationTo,
        chosenPickupStart: state.bookingSearch.chosenPickupStart,
        chosenPickupEnd: state.bookingSearch.chosenPickupEnd,
        chosenGenericSearch: state.bookingSearch.chosenGenericSearch,
    };
}

export default withRouter(connect(mapStateToProps, { onBookingsReceived })(withTranslation()(BookingsDatatable)));
