
const initialState = {
};

export const general = (state = initialState, action) => {
    switch (action.type) {

        default:
            return state;
    }
};
