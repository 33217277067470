import React from "react";
import BookingForm from "components/page/bookings/BookingForm";

export default function BookingFormPage(props) {
    const isStartExpanded = props.isStartExpanded === undefined ? false : props.isStartExpanded;
    return (
        <div className="content">
            <BookingForm isModalMode={!!props.isModalMode} isCreateMode={true} isStartExpanded={isStartExpanded} {...props} />
        </div>
    );
}
