// Everything about react router (subroutes and so on... ) https://reacttraining.com/react-router/web/example/route-config
import React from "react";
import { Translation } from "react-i18next";

import urls from "config/urls";
import commonRoutes from "./common";
import { isCompanyMode, isCompanySalesMode } from "util/Access";

import RouteWithSubRoutes from "components/Routing/RouteWithSubRoutes";
import QuotePage from "components/page/quotes/QuotePage";
import QuoteForm from "components/page/quotes/QuoteForm";
import QuoteHistory from "components/page/quotes/QuoteHistory";
import BookingPage from "components/page/bookings/BookingPage";
import BookingForm from "components/page/bookings/BookingForm";
import EmployeeProfile from "components/page/user/EmployeeProfile";
import CustomerListPage from "components/page/user/CustomerListPage";
import CustomerDetailsPage from "components/page/user/CustomerDetailsPage";

export const companySpecificRoutes = [
    {
        path: urls.DOMAIN,
        component: RouteWithSubRoutes,
        label: "",
        disabled: () => !isCompanyMode(),
        routes: [
            {
                path: urls.COMPANY_QUOTES,
                component: RouteWithSubRoutes,
                label: "",
                routes: [
                    {
                        path: urls.COMPANY_QUOTES,
                        exact: false,
                        component: QuotePage,
                        labelComponent: () => <Translation>{t => t("routes.company.quotes", "Quotes")}</Translation>,
                        showSidebar: () => isCompanySalesMode(),
                        linkLabel: () => <Translation>{t => t("sidebar.company.quotes", "Quotes")}</Translation>,
                        icon: "assignment",
                    },
                    {
                        path: urls.COMPANY_NEW_OFFER,
                        exact: true,
                        component: () => <QuoteForm isModalMode={true} isOfferMode={true}></QuoteForm>,
                        labelComponent: () => <Translation>{t => t("routes.company.newOffer", "New offer")}</Translation>,
                        showSidebar: () => isCompanySalesMode(),
                        linkLabel: () => <Translation>{t => t("sidebar.company.newOffer", "New Offer")}</Translation>,
                        icon: "newOffer",
                    },
                    {
                        path: [urls.COMPANY_QUOTE_DETAILS, urls.COMPANY_QUOTE_DETAILS_ALTERNATIVE],
                        exact: true,
                        component: () => <QuoteForm isModalMode={true} isOfferMode={true}></QuoteForm>,
                        labelComponent: () => <Translation>{t => t("routes.company.quoteDetails", "Quote details")}</Translation>,
                    },
                    {
                        path: urls.COMPANY_QUOTE_HISTORY,
                        exact: true,
                        labelComponent: () => <Translation>{t => t("routes.company.quoteHistory", "Quote history")}</Translation>,
                        component: QuoteHistory
                    },
                ]
            },
            {
                path: urls.COMPANY_BOOKINGS,
                label: "",
                component: RouteWithSubRoutes,
                routes: [
                    {
                        path: urls.COMPANY_BOOKINGS,
                        exact: true,
                        component: BookingPage,
                        labelComponent: () => <Translation>{t => t("routes.company.bookings", "Bookings")}</Translation>,
                        showSidebar: true,
                        linkLabel: () => <Translation>{t => t("sidebar.company.bookings", "Bookings")}</Translation>,
                        icon: "calendar",
                    },
                    {
                        path: urls.COMPANY_BOOKING_DETAILS,
                        exact: true,
                        labelComponent: () => <Translation>{t => t("routes.company.bookingDetails", "Booking details")}</Translation>,
                        component: BookingForm,
                    },
                ]
            },
            {
                path: urls.CUSTOMER_LIST,
                exact: true,
                labelComponent: () => <Translation>{t => t("routes.company.customerList", "Customers")}</Translation>,
                component: CustomerListPage,
                showSidebar: true,
                linkLabel: () => <Translation>{t => t("sidebar.company.customerList", "Customers")}</Translation>,
                icon: "group",
            },
            {
                path: urls.CUSTOMER_DETAILS,
                exact: true,
                labelComponent: () => <Translation>{t => t("routes.company.customerDetails", "Customer details")}</Translation>,
                component: CustomerDetailsPage,

            },
            {
                path: urls.USER_PROFILE,
                exact: true,
                labelComponent: () => <Translation>{t => t("routes.company.userProfile", "Profile")}</Translation>,
                component: EmployeeProfile,
                showSidebar: true,
                linkLabel: () => <Translation>{t => t("sidebar.company.userProfile", "Profile")}</Translation>,
                icon: "person",
            },
        ]
    }
];

export default [...commonRoutes, ...companySpecificRoutes];
