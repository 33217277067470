import React from "react";
import { Trans } from "react-i18next";
import _ from "lodash";
import { loadType } from "maps/classifiers";

export const packageTypeOptions = {
    UNIT: "Unit",
    EP: "Europallet",
    BARREL: "Barrel",
    BOX: "Box",
    CARTON: "Carton",
    FL: "Full load",
    ROLL: "Roll",
    CONTAINER20: "20' GP Container",
    CONTAINER40: "40' GP Container",
    CUBE40: "40' High Cube",
    OTHER: "Other",
};

const defaultPackageTypeTotalKey = Object.keys(packageTypeOptions)[0]
const packageTypeDimensions = {
    EP: { length: 120, width: 80 },
};

function sumFieldInShipments(shipments, field) {
    return shipments
        .map(s => Number(s[field]) || 0)
        .reduce((accumulator, quantity) => accumulator + quantity, 0);
}

export function getPackageTypeOptionsAsList() {
    const packageTypes = [];
    const keys = Object.keys(packageTypeOptions);
    keys.forEach((key) => {
        const packageTypeOption = {
            value: key,
            label: packageTypeOptions[key],
        };
        packageTypes.push(packageTypeOption);
    });
    return packageTypes;
}

export function getFilteredPackageTypeOptionsAsList(selectedLoadType) {

    const packageTypes = [];

    const isShowFCLOptions = selectedLoadType === loadType.FCL;
    const packageTypesForFCL = ["CONTAINER20", "CONTAINER40", "CUBE40"];

    for (const key in packageTypeOptions) {
        if (isShowFCLOptions || !packageTypesForFCL.includes(key)) {
            const translationKey = "form.packageType." + key.toLowerCase();
            const packageTypeOption = {
                value: key,
                label: <Trans i18nKey={translationKey}>{key}</Trans>
            };
            packageTypes.push(packageTypeOption);
        }
    }
    return packageTypes;
}

export function calculateLoadingMeter(width, length, quantity = 1, precision = 1) {
    const ldm = quantity * width * length / 2.4;
    const roundedLDM = _.round(ldm, precision);
    return roundedLDM;
}

export function calculateLoadingMeterUnitsInCentimeters(width, length, quantity = 1, precision = 1) {
    return calculateLoadingMeter(width / 100, length / 100, quantity, precision);
}

export function calculateCubicMeter(width, length, height, quantity = 1, precision = 1) {
    const cbm = width * length * height * quantity;
    const roundedCBM = _.round(cbm, precision);
    return roundedCBM;
}

export function calculateCubicMeterUnitsInCentimeters(width, length, height, quantity = 1, precision = 1) {
    return calculateCubicMeter(width / 100, length / 100, height / 100, quantity, precision);
}

export function getPackageTypeDimensions(packageType) {
    return packageTypeDimensions[packageType];
}

export function getNewShipment() {
    return {
        is_new: true,
        temp_id: _.uniqueId("new_"),
        quantity: null,
        package_type: "EP",
    };
}

export function getShipmentsTotals(shipments) {
    const totals = {};

    totals.quantity = sumFieldInShipments(shipments, "quantity");
    totals.weight = sumFieldInShipments(shipments, "weight");

    const totalVolume = sumFieldInShipments(shipments, "volume");
    totals.volume = _.round(totalVolume, 1);

    const totalLDM = sumFieldInShipments(shipments, "ldm");
    totals.ldm = _.round(totalLDM, 1);

    let allSamePackage; let firstType;
    if (shipments && shipments.length && shipments[0].package_type) {
        firstType = shipments[0].package_type;
        allSamePackage = shipments.map(s => s.package_type).every(packageType => packageType === firstType);
    }
    totals.package_type = allSamePackage ? firstType : defaultPackageTypeTotalKey;

    return totals;
}

export function getShipmentsTotalsText(shipments, prefix = null) {
    shipments = shipments || [];
    const totals = getShipmentsTotals(shipments);
    prefix = prefix || <Trans i18nKey="form.packagetype.roll">Total: </Trans>
    const key = "form.packageType." + totals.package_type.toLowerCase();
    const packageType = <Trans i18nKey={key}> {totals.package_type} </Trans>
    const totalsText = <>
        {prefix}  {`${totals.quantity} `}{packageType}{`, ${totals.weight} kg,	${totals.volume} m³, ${totals.ldm} LDM`}
    </>;
    return totalsText;
}

export function getShipmentsTotalsData(shipments, prefix = null) {
    let shipmentsData = shipments || [];
    shipmentsData.totals = getShipmentsTotals(shipments);
    shipmentsData.prefix = prefix || <Trans i18nKey="form.packagetype.roll">Total: </Trans>
    const key = "form.packageType." + shipmentsData.totals.package_type.toLowerCase();
    shipmentsData.packageType = <Trans i18nKey={key}> {shipmentsData.totals.package_type} </Trans>

    return shipmentsData;
}

