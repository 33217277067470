import React, { Component } from "react";
import PropTypes from "prop-types";
import { withTranslation, Trans } from "react-i18next";
import { FormGroup, Input } from "reactstrap";
import { withStyles } from '@material-ui/styles';
import { styles } from '../../../assets/stylesMUI/shipmentRowStyles';
import classNames from "classnames";
import _ from "lodash";

import ShipmentRowOptions from "./ShipmentRowOptions";
import ValidationMessage from "components/form/ValidationMessage";
import {
    calculateLoadingMeterUnitsInCentimeters,
    calculateCubicMeterUnitsInCentimeters,
    getPackageTypeDimensions,
} from "util/shipments";
import TextAreaPopup from "components/form/TextAreaPopup";
import InlineRequiredStar from "components/form/InlineRequiredStar";
import { Container, Grid, MenuItem, TextField, FormControl, InputLabel, Select, FormHelperText } from '@material-ui/core';

const stylesz = styles;

class ShipmentRow extends Component {
    static propTypes = {
        shipment: PropTypes.object.isRequired,
        onChange: PropTypes.func.isRequired,
        onDuplicate: PropTypes.func.isRequired,
        onRemove: PropTypes.func.isRequired,
        disableRemove: PropTypes.bool,
        readOnly: PropTypes.bool,
        validationErrors: PropTypes.object,
        isQuantityRequired: PropTypes.bool,
        packageTypeOptions: PropTypes.array.isRequired,
        classes: PropTypes.object.isRequired,
    };

    isInvalidFieldMessage = (field) => {
        return this.props.validationErrors ? this.props.validationErrors[field] : "";
    }

    onFieldChange = (field, value) => {
        if (value === "" && ["quantity", "weight", "width", "length", "height", "ldm", "volume", "price"].includes(field)) {
            value = null;
        }
        this.props.onChange(field, value);

        if (field === "package_type") {
            this.setDefaultDimensions();
            if (value !== "OTHER" && this.props.shipment.package_type_other) {
                this.props.onChange("package_type_other", "");
            }
        }

        if (["quantity", "height", "width", "length", "package_type"].includes(field)) {
            this.recalculateVolumeAndLDM();
        }
    }

    getInputValue = (value) => {
        return value === null || value === undefined ? "" : value;
    }
    isCheckboxChecked = (value) => {
        return value === null || value === undefined ? false : value;
    }

    handlePackageTypeSelect = (event) => {
        this.onFieldChange("package_type", event.target.value)
    }

    setDefaultDimensions = () => {
        const shipment = this.props.shipment;

        const package_type = shipment.package_type;

        const currentDimensions = getPackageTypeDimensions(package_type) || {};
        if (currentDimensions.length) {
            this.props.onChange("length", currentDimensions.length);
        }
        if (currentDimensions.width) {
            this.props.onChange("width", currentDimensions.width);
        }
    }

    recalculateVolumeAndLDM = () => {
        const shipment = this.props.shipment;

        const quantity = Number(shipment.quantity);
        const width = Number(shipment.width);
        const length = Number(shipment.length);
        const height = Number(shipment.height);
        // first check prerequisites for LDM calculation.
        if (_.isNumber(quantity) && quantity > 0 && _.isNumber(width) && width > 0 && _.isNumber(length) && length > 0) {
            const roundedLdm = calculateLoadingMeterUnitsInCentimeters(width, length, quantity);
            this.props.onChange("ldm", roundedLdm);

            // Now lets look into volume calculation, which adds height to previous prerequisites.
            if (_.isNumber(height) && height > 0) {
                const roundedVolume = calculateCubicMeterUnitsInCentimeters(width, length, height, quantity);
                this.props.onChange("volume", roundedVolume);
            }
        }
    }

    isNewOrDuplicate = () => {
        return this.props.shipment.temp_id;
    }

    isWidthOrLengthReadOnly = () => {
        if (this.props.readOnly) {
            return true;
        }
        return this.props.shipment.package_type === "EP";
    }

    componentDidMount() {
        if (this.props.shipment && this.props.shipment.is_new) {
            this.setDefaultDimensions();
            this.recalculateVolumeAndLDM();
        }
    }

    render() {
        const { shipment, t, classes } = this.props;

        return (
            <>
            <Container
                disableGutters
                maxWidth={false}
                className={classes.container}
            >
                <Grid
                    container
                    spacing={1}
                    className={classes.gridContainer}
                >
                    <Grid item xs={6} sm={2}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            className={classes.upperTextField}
                            label={t("form.shipmentRow.quantityLabel", "Quantity")}
                            onChange={(event) => this.onFieldChange("quantity", event.target.value)}
                            placeholder={t("form.shipmentRow.quantityPlaceholder", "Qty.")}
                            type="tel"
                            value={this.getInputValue(shipment.quantity)}
                            disabled={this.props.readOnly}
                            required={this.props.isQuantityRequired}
                            error={true && this.isInvalidFieldMessage("quantity")}
                            helperText={this.isInvalidFieldMessage("quantity")}
                        />
                        <FormControl
                            fullWidth
                            variant="outlined"
                            error={true && this.isInvalidFieldMessage("package_type")}
                        >
                            <InputLabel id="packageLabel">
                                {t("form.shipmentRow.packageLabel", "Package")}
                            </InputLabel>
                            <Select
                                labelId="packageLabel"
                                label={t("form.shipmentRow.packageLabel", "Package")}
                                variant="outlined"
                                name="package_type"
                                disabled={this.props.readOnly}
                                value={this.getInputValue(shipment.package_type)}
                                onChange={this.handlePackageTypeSelect}
                            >
                                {this.renderPackageDropdownOptios()}
                            </Select>
                            {
                                this.isInvalidFieldMessage("package_type") &&
                                <FormHelperText>{this.isInvalidFieldMessage("package_type")}</FormHelperText>
                            }
                        </FormControl>
                        {this.renderPackageTypeOther()}
                    </Grid>
                    <Grid item xs={6} sm={2}>
                        <TextField
                            className={classes.upperTextField}
                            variant="outlined"
                            fullWidth
                            label={t("form.shipmentRow.weightLabel", "Weight")}
                            onChange={(event) => this.onFieldChange("weight", event.target.value)}
                            placeholder={t("form.shipmentRow.weightPlaceholder", "kg")}
                            type="tel"
                            value={this.getInputValue(shipment.weight)}
                            disabled={this.props.readOnly}
                            required={this.props.isQuantityRequired}
                            error={true && this.isInvalidFieldMessage("weight")}
                            helperText={this.isInvalidFieldMessage("weight")}
                        />
                        <TextField
                            variant="outlined"
                            fullWidth
                            label={t("form.shipmentRow.volumeLabel", "Volume")}
                            onChange={(event) => this.onFieldChange("volume", event.target.value)}
                            placeholder={t("form.shipmentRow.volumePlaceholder", "Volume (m³)")}
                            type="tel"
                            value={this.getInputValue(shipment.volume)}
                            disabled={this.props.readOnly}
                            required={this.props.isQuantityRequired}
                            error={true && this.isInvalidFieldMessage("volume")}
                            helperText={this.isInvalidFieldMessage("volume")}
                        />
                    </Grid>
                    <Grid item xs={6} sm={2}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            className={classes.upperTextField}
                            label={t("form.shipmentRow.lengthLabel", "Length")}
                            onChange={(event) => this.onFieldChange("length", event.target.value)}
                            placeholder={t("form.shipmentRow.lengthPlaceholder", "L (cm)")}
                            type="tel"
                            value={this.getInputValue(shipment.length)}
                            disabled={this.isWidthOrLengthReadOnly()}
                            required={this.props.isQuantityRequired}
                            error={true && this.isInvalidFieldMessage("length")}
                            helperText={this.isInvalidFieldMessage("length")}
                        />
                        <TextField
                            variant="outlined"
                            fullWidth
                            label={t("form.shipmentRow.widthLabel", "Width")}
                            onChange={(event) => this.onFieldChange("width", event.target.value)}
                            placeholder={t("form.shipmentRow.widthPlaceholder", "W (cm)")}
                            type="tel"
                            value={this.getInputValue(shipment.width)}
                            disabled={this.isWidthOrLengthReadOnly()}
                            required={this.props.isQuantityRequired}
                            error={true && this.isInvalidFieldMessage("width")}
                            helperText={this.isInvalidFieldMessage("width")}
                        />
                    </Grid>
                    <Grid item xs={6} sm={2}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            className={classes.upperTextField}
                            label={t("form.shipmentRow.heightLabel", "Height")}
                            onChange={(event) => this.onFieldChange("height", event.target.value)}
                            placeholder={t("form.shipmentRow.heightPlaceholder", "H (cm)")}
                            type="tel"
                            value={this.getInputValue(shipment.height)}
                            disabled={this.props.readOnly}
                            error={true && this.isInvalidFieldMessage("height")}
                            helperText={this.isInvalidFieldMessage("height")}
                        />
                        <TextField
                            variant="outlined"
                            fullWidth
                            label={t("form.shipmentRow.ldmLabel", "LDM")}
                            onChange={(event) => this.onFieldChange("ldm", event.target.value)}
                            placeholder={t("form.shipmentRow.ldmPlaceholder", "LDM")}
                            type="tel"
                            value={this.getInputValue(shipment.ldm)}
                            disabled={this.props.readOnly}
                            error={true && this.isInvalidFieldMessage("ldm")}
                            helperText={this.isInvalidFieldMessage("ldm")}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextAreaPopup
                            value={this.getInputValue(shipment.goods_description)}
                            onChange={(event) => this.onFieldChange("goods_description", event.target.value)}
                            disabled={this.props.readOnly}
                            validationMessage={this.isInvalidFieldMessage("goods_description")}
                            inputProps={{
                                id: `shipment-row-goods-${shipment.id || shipment.temp_id}`,
                                labelText: t("form.shipmentRow.descriptionLabel", "Description"),
                                placeholder: t("form.shipmentRow.descriptionPlaceholder", "Description"),
                                disableValidationMessage: true,
                                required: true,

                            }}
                            textAreaProps={{
                                className: "shipment-goods-textarea form-control",
                                placeholder: t("form.shipmentRow.descriptionPlaceholder", "Description"),
                                disableValidationMessage: true,
                            }}
                        />
                    </Grid>
                    <hr />
                </Grid >
                <ShipmentRowOptions
                    onRemove={this.props.onRemove}
                    onCopy={this.props.onDuplicate}
                    isRemoveDisabled={this.props.disableRemove}
                    readOnly={this.props.readOnly}
                    isCheckboxChecked={this.isCheckboxChecked(shipment.stackable)}
                    onCheckboxChange={(event) => this.props.onChange("stackable", event.target.checked)}
                    tooltip={t("form.shipmentRow.stackableLabel", "Stackable")}
                />
            </Container >
            <hr />
            </>
        );
    }

    renderPackageTypeOther() {
        const { shipment, t } = this.props;
        if (shipment.package_type !== "OTHER") {
            return null;
        }

        return (
            <FormGroup>
                <div className="mr-1">
                    <Input
                        onChange={(event) => this.onFieldChange("package_type_other", event.target.value)}
                        type="text"
                        placeholder={t("form.shipmentRow.otherPackageTypePlaceholder", "Type")}
                        title={t("form.shipmentRow.otherPackageTypeTitle", "Other package type")}
                        className={
                            classNames("shipment-package-type-other-input mb-1",
                                { "is-invalid-custom": this.isInvalidFieldMessage("package_type_other") }
                            )
                        }
                        value={this.getInputValue(shipment.package_type_other)}
                        disabled={this.props.readOnly}
                    />
                    <InlineRequiredStar />
                </div>
                <ValidationMessage
                    message={this.isInvalidFieldMessage("package_type_other")}
                    isVisible={false && this.isInvalidFieldMessage("package_type_other")}
                />
            </FormGroup>
        );
    }

    renderPackageDropdownOptios() {
        return this.props.packageTypeOptions.map((packageType, index) => {
            const translationKey = "form.packageType." + packageType.value.toLowerCase();
            return (
                <MenuItem
                    label={packageType.value}
                    value={packageType.value}
                    key={index}
                    name={packageType.value}
                >{<Trans i18nKey={translationKey}>{packageType.value}</Trans>}
                </MenuItem>
            );
        });
    }
}

export default withTranslation()(withStyles(stylesz)(ShipmentRow));
