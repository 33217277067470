import React from "react";
import axios from "../axios";
import store from "redux/store";
import { Trans } from "react-i18next";
import {
    REST_AUTH_LOGIN,
    REST_AUTH_USER,
    REST_AUTH_LOGOUT,
} from "config/Api";
import { authFail, authSuccess, getUser, resetStore } from "redux/actions/auth";
import { logOut } from "../redux/actions/auth";
import { addNewLocalErrorAlert, clearLocalAlerts } from "./Alerts";


export const LOGIN_ALERT_GROUP = "loginAlert";
export const SIGNING_ALERT_GROUP = "signingAlert";

export function login(username, password) {
    clearLocalAlerts();
    return axios
        .post(
            REST_AUTH_LOGIN,
            {
                email: username,
                password: password,
            },
            {
                headers: {
                    Authorization: ``,
                },
            }
        )
        .then((resp) => {
            axios.defaults.headers.common[
                "Authorization"
            ] = `Token ${resp.data.key}`;
            store.dispatch(authSuccess(`Token ${resp.data.key}`));
            const expirationDate = new Date();
            expirationDate.setHours(expirationDate.getHours() + 24);
            clearLocalStorage();
            localStorage.setItem("token", `Token ${resp.data.key}`);
            localStorage.setItem("expirationDate", expirationDate);
            return true;
        })
        .catch((err) => {
            store.dispatch(authFail(err));
            addNewLocalErrorAlert(
                <Trans i18nKey="alerts.loginFailed">
                    Authentication failed! Please check your credentials.
                </Trans>,
                LOGIN_ALERT_GROUP
            );

            return false;
        });
}

export function logout() {
    return axios
        .post(REST_AUTH_LOGOUT)
        .then((resp) => {
            store.dispatch(logOut());
            clearLocalStorage();
            axios.defaults.headers.common["Authorization"] = null;
        })
        .catch((err) => {
            throw err;
        });
}

export function requestFailed401() {
    //store.dispatch(resetStore());
    clearLocalStorage();
}

export function errorClearData(errorMessage) {
    store.dispatch(resetStore());
    clearLocalStorage();

    if (errorMessage) {
        addNewLocalErrorAlert(errorMessage, LOGIN_ALERT_GROUP);
    }
}

export function setAuthUser(user) {
    store.dispatch(getUser(user));
}

export function getAuthUser() {
    return axios
        .get(REST_AUTH_USER)
        .then((resp) => {
            store.dispatch(getUser(resp.data));
            return resp.data;
        })
        .catch((err) => {
            throw err;
        });
}

export const clearLocalStorage = () => {
    localStorage.removeItem("expirationDate");
    localStorage.removeItem("token");
    localStorage.removeItem("authType"); // TODO: Is this even used anymore?
}
