import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { FormControl, TextField } from '@material-ui/core';

//import "./Input.scss";

class CustomInput extends React.Component {
    static propTypes = {
        value: PropTypes.any,
        defaultValue: PropTypes.any,
        type: PropTypes.string,
        labelText: PropTypes.string,
        onChange: PropTypes.func,
        onBlur: PropTypes.func,
        disabled: PropTypes.bool,
        className: PropTypes.string,
        placeholder: PropTypes.string,
        required: PropTypes.bool,
        extraProps: PropTypes.object,
        trimOnBlur: PropTypes.bool,
        // validation
        valid: PropTypes.bool,
        validText: PropTypes.string,
        invalid: PropTypes.bool,
        invalidText: PropTypes.string,
    };

    static defaultProps = {
        defaultValue: "",
        type: "",
        labelText: "",
        labelHidden: false,
        className: "",
        placeholder: "",
        disabled: false,
        onChange: function () { },
        onBlur: function () { },
        extraProps: {},
        valid: false,
        validText: "",
        invalid: false,
        invalidText: "",
        trimOnBlur: false,
    };

    static LABEL_DISABLED_CSS = "is-disabled";
    static ACTIVE_CSS = "is-active";
    static VALID_CSS = "custom-is-valid";
    static INVALID_CSS = "custom-is-invalid";
    static REQUIRED_CSS = "custom-required";

    constructor(props) {
        super(props);

        this.state = {
            active_css: "",
            value: this.valueOrDefault(props.value),
            valueChanged: false,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (!_.isEqual(prevProps.value, this.props.value)) {
            this.setState({
                value: this.valueOrDefault(this.props.value),
                valueChanged: false,
            });
        }
    }

    valueOrDefault = (value) => {
        return _.isNil(value) || _.isNaN(value)
            ? this.props.defaultValue
            : value;
    };

    onChange = (event) => {
        if (event.target.value !== this.state.value) {
            const isChange = this.props.onChange(event.target.value);
            if (isChange !== false) {
                this.setState({ value: event.target.value, valueChanged: true });
            }
        }
    };

    onFocus = (event) => {
        this.setState({ active_css: CustomInput.ACTIVE_CSS });
    };

    onBlur = (event) => {
        this.setState({ active_css: "" });

        if (this.state.valueChanged) {
            let value = event.target.value;

            if (this.props.trimOnBlur) {
                value = value.trim();
            }
            this.setState({ value });

            this.props.onBlur(value);
        }
    };

    getLabelClassName = () => {
        let className = "custom-label";
        className += this.addClassName(
            this.state.active_css,
            this.state.active_css
        );
        className += this.addClassName(
            CustomInput.REQUIRED_CSS,
            this.props.required
        );
        className += this.addClassName(CustomInput.VALID_CSS, this.props.valid);
        className += this.addClassName(
            CustomInput.INVALID_CSS,
            this.props.invalid
        );
        className += this.addClassName(
            CustomInput.LABEL_DISABLED_CSS,
            this.props.disabled
        );
        return className;
    };

    getInputClassName = () => {
        let className = "custom-input";
        className += this.addClassName(
            this.state.active_css,
            this.state.active_css
        );
        className += this.addClassName(CustomInput.VALID_CSS, this.props.valid);
        className += this.addClassName(
            CustomInput.INVALID_CSS,
            this.props.invalid
        );
        className += this.addClassName(
            this.props.className,
            this.props.className
        );
        return className;
    };

    addClassName = (className, condition) => {
        if (!className || !condition) {
            return "";
        }
        return " " + className;
    };

    render() {
        return (
            <FormControl
                fullWidth={true}>
                <TextField
                    variant="outlined"
                    label={this.props.labelText}
                    {...this.props.extraProps}
                    value={this.state.value}
                    type={this.props.type}
                    className={this.getInputClassName()}
                    onChange={this.onChange}
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    disabled={this.props.disabled}
                >
                </TextField>

                {this.props.valid && this.props.validText && (
                    <div className="custom-valid-feedback">
                        {this.props.validText}
                    </div>
                )}
                {this.props.invalid && this.props.invalidText && (
                    <div className="custom-invalid-feedback">
                        {this.props.invalidText}
                    </div>
                )}
            </FormControl>
        );
    }
}

export default CustomInput;
