import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Dialog, Box, Typography, Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import EscClosable from "components/modal/EscClosable";

class BookingTermsModal extends Component {
    static propTypes = {
        onToggle: PropTypes.func.isRequired,
        isModalOpen: PropTypes.bool.isRequired,
    };

    toggle = () => {
        this.props.onToggle();
    }

    render() {
        const { t, isModalOpen } = this.props;
        if (!isModalOpen) {
            return false;
        }
        return (
            <EscClosable onCloseModal={this.toggle}>
                <Dialog open={isModalOpen} onClose={this.toggle}>
                    <Box p={2}>
                        <CloseIcon
                            className="modalClose"
                            onClick={this.toggle} />
                        <Box p={2}>
                            <Typography
                                gutterBottom
                                variant="h5"
                                align="center"
                            >
                                {t("page.bookingTerms.heading", "Terms and Conditions")}
                            </Typography>
                            <Box p={2}>
                                {this.renderContent()}
                            </Box>
                            <div className="text-center">
                                <Button
                                    className="linkStyleButton"
                                    type="submit"
                                    onClick={this.toggle}
                                >{t("buttons.close", "Close")}
                                </Button>
                            </div>
                        </Box>
                    </Box>
                </Dialog>
            </EscClosable >
        );
    }

    renderContent() {
        const { t } = this.props;
        return (
            <>
                <p>
                    {t("bookingTerms.content.allAccordingToConventions", "All operations are performed in accordance with the ELEA General Terms and Conditions, Incoterms 2010 and the CMR Convention.")}
                </p>
            </>
        );
    }
}

export default withTranslation()(BookingTermsModal);
