import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import _ from "lodash";
import cloneDeep from "lodash/cloneDeep";

import ShipmentRow from "./ShipmentRow";
import { getNewShipment, getShipmentsTotalsData, getFilteredPackageTypeOptionsAsList } from "util/shipments";
import { areShipmentQuantitiesRequired } from "util/validations";
import { Typography, Container, Box, Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import TypographyLarge from "../TypographyLarge";

class Shipments extends Component {
    static propTypes = {
        shipments: PropTypes.array.isRequired,
        loadType: PropTypes.string,
        onChange: PropTypes.func.isRequired,
        readOnly: PropTypes.bool,
        validationErrors: PropTypes.oneOfType([PropTypes.object]),
    };

    addShipment = () => {
        const newShipment = getNewShipment();
        const newShipments = [...this.props.shipments, newShipment];
        this.props.onChange(newShipments);
    }

    updateShipment = (idx, field, value) => {
        const shipments = [...this.props.shipments];
        shipments[idx][field] = value;
        this.props.onChange(shipments);
    }

    duplicateShipment = (idx) => {
        const shipments = [...this.props.shipments];
        const duplicate = cloneDeep(shipments[idx]);

        delete duplicate.id;
        delete duplicate.is_new;
        duplicate.temp_id = _.uniqueId("duplicate_");
        duplicate.is_duplicate = true;

        shipments.splice(idx + 1, 0, duplicate);
        this.props.onChange(shipments);
    }

    removeShipment = (idx) => {
        const shipments = [...this.props.shipments];
        shipments.splice(idx, 1);
        this.props.onChange(shipments);
    }

    render() {
        const { shipments, t } = this.props;

        if (!shipments) {
            return false;
        }

        return (
            <>
                <div className="">
                    <TypographyLarge>
                        {t("form.shipments.header", "Goods Rows")}
                    </TypographyLarge>
                </div>
                <div>
                    <Box pt={2}>
                        {
                            shipments.map((shipment, idx) => {
                                return (
                                    <ShipmentRow
                                        key={`${shipment.id}-${shipment.temp_id}`}
                                        shipment={shipment}
                                        onChange={(field, value) => this.updateShipment(idx, field, value)}
                                        onDuplicate={() => this.duplicateShipment(idx)}
                                        onRemove={() => this.removeShipment(idx)}
                                        disableRemove={!shipments || shipments.length < 2}
                                        readOnly={this.props.readOnly}
                                        validationErrors={this.props.validationErrors && this.props.validationErrors.shipments ?
                                            this.props.validationErrors.shipments[shipment.id || shipment.temp_id] :
                                            {}
                                        }
                                        isQuantityRequired={areShipmentQuantitiesRequired(this.props.loadType)}
                                        packageTypeOptions={getFilteredPackageTypeOptionsAsList(this.props.loadType)}
                                    />
                                );
                            })
                        }
                    </Box>

                    <Container disableGutters maxWidth={false}>
                        <Box pt={1}>
                            <Button
                                className="linkStyleButton"
                                onClick={this.addShipment}
                                disabled={this.props.readOnly}
                                style={{
                                    textTransform: 'none',
                                    fontSize: '1rem',
                                    padding: '0'
                                }}
                            >
                                <AddIcon
                                    style={{
                                        marginBottom: '0.2rem',
                                        marginRight: '0.2rem'
                                    }}
                                />
                                {t("form.shipments.addRowButton", "Add new row")}
                            </Button>
                        </Box>
                    </Container>
                    <Container disableGutters maxWidth={false}>
                        <Box pt={2}>
                            <Typography
                                variant="h6"
                                gutterBottom
                                style={{ color: '#1F0242' }}
                            >
                                {t("form.shipments.totalsPrefix", "Total:")}
                            </Typography>
                            {this.renderTotals()}
                        </Box>
                    </Container>
                </div>
            </>
        );
    }

    renderTotals = () => {
        const { shipments, t } = this.props;
        const totalsData = getShipmentsTotalsData(shipments);
        return (
            <table
                className="totalsTable"
            >
                <tbody>
                    <tr>
                        <td>{t("form.shipments.quantity", "Quantity")}</td>
                        <td>{t("form.shipments.weight", "Weight")}</td>
                        <td>{t("form.shipments.volume", "Volume")}</td>
                        <td>LDM</td>
                    </tr>
                    <tr >
                        <td >{totalsData.totals.quantity}</td>
                        <td >{totalsData.totals.weight}</td>
                        <td >{totalsData.totals.volume}</td>
                        <td >{totalsData.totals.ldm}</td>
                    </tr>
                </tbody>
            </table>
        );

    }
}

export default withTranslation()(Shipments);
