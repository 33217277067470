import React, { Component, Fragment } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { FormGroup, Grid, TextField } from "@material-ui/core";


class AddititionalInfoBlockInputs extends Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
        readOnly: PropTypes.bool,
        onChange: PropTypes.func.isRequired,
        className: PropTypes.string,
    };

    render() {
        const { t } = this.props;
        return (
            <Fragment>
                    {this.props.data.dangerous &&
                        <Grid container spacing={2}>
                            <Grid item xs={6} sm={2}>
                                <FormGroup>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        onChange={(event) => this.props.onChange("dangerous_un_nr", event.target.value)}
                                        type="text"
                                        placeholder={t("forms.dangerousGoods.unitedNationsNumberPlaceholder", "UN number")}
                                        value={this.props.data.dangerous_un_nr}
                                        disabled={this.props.readOnly}
                                    />
                                </FormGroup>
                            </Grid>
                            <Grid item xs={6} sm={2}>
                                <FormGroup>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        onChange={(event) => this.props.onChange("dangerous_package_group", event.target.value)}
                                        type="text"
                                        placeholder={t("forms.dangerousGoods.packageGroupPlaceholder", "Package group")}
                                        value={this.props.data.dangerous_package_group}
                                        disabled={this.props.readOnly}
                                    />
                                </FormGroup>
                            </Grid>
                            <Grid item xs={6} sm={2}>
                                <FormGroup>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        onChange={(event) => this.props.onChange("dangerous_class", event.target.value)}
                                        type="text"
                                        placeholder={t("forms.dangerousGoods.classPlaceholder", "Class")}
                                        value={this.props.data.dangerous_class}
                                        disabled={this.props.readOnly}
                                    />
                                </FormGroup>
                            </Grid>
                            <Grid item xs={6} sm={2}>
                                <FormGroup>
                                    <TextField
                                        variant="outlined"
                                        onChange={(event) => this.props.onChange("dangerous_neq", event.target.value)}
                                        type="text"
                                        placeholder={t("forms.dangerousGoods.netQuantityPlaceholder", "Net quantity")}
                                        value={this.props.data.dangerous_neq}
                                        disabled={this.props.readOnly}
                                    />
                                </FormGroup>
                            </Grid>
                            <Grid item xs={6} sm={2}>
                                <FormGroup>
                                    <TextField
                                        variant="outlined"
                                        onChange={(event) => this.props.onChange("dangerous_technical_name", event.target.value)}
                                        type="text"
                                        placeholder={t("forms.dangerousGoods.technicalNamePlaceholder", "Technical name")}
                                        value={this.props.data.dangerous_technical_name}
                                        disabled={this.props.readOnly}
                                    />
                                </FormGroup>
                            </Grid>
                        </Grid>
                    }
                    {this.props.data.temperature_sensitive &&
                        <Grid container spacing={2}>
                            <Grid item xs={6} sm={2}>
                                <FormGroup>
                                    <TextField
                                        variant="outlined"
                                        onChange={(event) => this.props.onChange("temperature_min", event.target.value)}
                                        type="tel"
                                        placeholder={t("form.temperatureSensitive.minTemperatureLabel", "Min temp (°C)")}
                                        value={this.props.data.temperature_min || ""}
                                        disabled={this.props.readOnly}
                                    />
                                </FormGroup>
                            </Grid>
                            <Grid item xs={6} sm={2}>
                                <FormGroup>
                                    <TextField
                                        variant="outlined"
                                        onChange={(event) => this.props.onChange("temperature_max", event.target.value)}
                                        type="tel"
                                        placeholder={t("form.temperatureSensitive.maxTemperatureLabel", "Max temp (°C)")}
                                        value={this.props.data.temperature_max || ""}
                                        disabled={this.props.readOnly}
                                    />
                                </FormGroup>
                            </Grid>
                        </Grid>
                    }
            </Fragment>
        );
    }
}

export default withTranslation()(AddititionalInfoBlockInputs);
