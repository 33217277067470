import React from "react";
import clsx from "clsx";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  isCustomerMode,
  isAuthenticatedWithoutUser,
  isPublicMode,
} from "util/Access";
import SideBar from "components/page/Sidebar";
import Footer from "components/Layout/Footer/Footer";
import CookieConsent from "components/Misc/CookieConsent";
import Loading from "components/notifications/Loading";
import { withStyles } from "@material-ui/styles";
//import "./Layout.scss";

const styles = (theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
});

class SidebarLayout extends React.Component {
  static propTypes = {
    sidebarOpen: PropTypes.bool,
    classes: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      sidebarOpen: false,
    };
  }

  openDrawer = (sidebarOpen) => {
    this.setState({ sidebarOpen: true });
  };

  navLinkClick = (sidebarOpen) => {
    this.setState((prevState) => ({
      sidebarOpen: !prevState.sidebarOpen,
    }));
  };

  render() {
    const { classes } = this.props;
    return (
      <>
        {this.renderSidebar()}
        <div
          className={clsx("sidebar-layout-content", classes.content)}
          onClick={this.closeDrawer}
        >
          <div className={classes.toolbar} />
          <div className="content">
            {isAuthenticatedWithoutUser() ? (
              this.renderLoading()
            ) : (
              <>
                {this.props.children}
                {(isCustomerMode() || isPublicMode()) && <Footer />}
              </>
            )}
          </div>
          <CookieConsent />
        </div>
      </>
    );
  }

  renderSidebar() {
    return (
      <SideBar
        routes={this.props.routes}
        sidebarOpen={this.state.sidebarOpen}
        handleDrawerOpen={this.openDrawer}
        onNavLinkClick={this.navLinkClick}
      />
    );
  }

  renderLoading() {
    return (
      <div className="container-fluid">
        <div className="card">
          <div className="card-body text-center">
            <Loading />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, {})(withStyles(styles)(SidebarLayout));
