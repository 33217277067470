import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter, NavLink } from "react-router-dom";
import { Container, Row, Col, Card, CardHeader, CardBody, CardTitle } from "reactstrap";
import moment from "moment";

import ajax from "remotes/ajax";
import Loading from "components/notifications/Loading";
import { getOfferOverviewUrl } from "config/urls";
import formatRoute from "util/formatRoute";
import { dateFormat } from "util/dateTime";
import { getShipmentsTotalsText } from "util/shipments";

import { RenderDangerousAndTemperatureSensitiveGoods } from "components/page/offer/OfferPage";

class BookingStatusPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            booking: null,
            bookingError: false,
            isLoading: true,
        };
    }

    async componentDidMount() {
        this.fetchBookingByReference();
    }

    async fetchBookingByReference() {
        const reference = this.props.match.params.reference;
        if (reference) {
            try {
                this.setState({
                    isLoading: true,
                    bookingError: false,
                });
                const booking = await ajax.doGetBookingStatusByReference(reference);
                this.setState({
                    booking,
                    isLoading: false,
                });
            }
            catch (error) {
                this.setState({
                    isLoading: false,
                    bookingError: true,
                });
            }
        }
    }

    momentDate = date => {
        const dateMoment = moment(date);

        if (!dateMoment.isValid()) {
            return "";
        }

        return moment(date).format(dateFormat);
    }

    render() {
        const { t } = this.props;
        let bookingStatusBody;
        if (this.state.bookingError) {
            bookingStatusBody = <strong>{t("page.bookingOverview.bookingNotFound", "Booking is not found!")}</strong>;
        }
        else if (!this.state.booking) {
            bookingStatusBody = <Loading />;
        }
        else {
            bookingStatusBody = this.renderCardBody();
        }
        return (
            <div className="content">
                <Container disableGutters>
                    <Row className="card-row">
                        <Col sm="12">
                            <Card className="card-wizard">
                                <CardHeader>
                                    <CardTitle tag="h3" className="text-center pt-2">{t("page.bookingOverview.heading", "Booking overview")}</CardTitle>
                                </CardHeader>
                                <CardBody className="text-center py-4">
                                    {bookingStatusBody}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }

    renderCardBody() {
        return (
            <>
                {this.renderContactsSection()}
                <hr />
                {this.renderShipmentsSection()}
                {this.renderOfferSection()}

                <Row>
                    <Col>
                        <h4 className="text-muted">{this.props.t("page.bookingOverview.printTransportLabel", "Print your transport label")}</h4>
                    </Col>
                </Row>
            </>
        );
    }

    renderContactsSection() {
        const { t } = this.props;
        return (
            <>
                <h4 className="mt-0"><i className="fa fa-envelope-o mr-2" />{t("page.bookingOverview.headers.contacts", "Contacts")}</h4>
                <Row>
                    <Col md="6">
                        <Row>
                            <Col md="6" className="text-right">
                                {t("common.companyNameLabel", "Company name")}
                            </Col>
                            <Col md="6" className="text-left">
                                <strong>{this.state.booking.company_name}</strong>
                            </Col>
                        </Row>

                        <Row>
                            <Col md="6" className="text-right">
                                {t("common.contactPersonLabel", "Contact person")}
                            </Col>
                            <Col md="6" className="text-left">
                                <strong>{this.state.booking.contact_person}</strong>
                            </Col>
                        </Row>

                    </Col>
                    <Col md="6">
                        <Row>
                            <Col md="6" className="text-right">
                                {t("common.contactEmailLabel", "Contact e-mail")}
                            </Col>
                            <Col md="6" className="text-left">
                                <strong>{this.state.booking.contact_email}</strong>
                            </Col>
                        </Row>

                        <Row>
                            <Col md="6" className="text-right">
                                {t("common.contactPhoneLabel", "Contact phone")}
                            </Col>
                            <Col md="6" className="text-left">
                                <strong>{this.state.booking.contact_phone}</strong>
                            </Col>
                        </Row>

                    </Col>
                </Row>
            </>
        );
    }

    renderShipmentsSection() {
        const { t } = this.props;
        return (
            <>
                <h4 className="mt-0"><i className="fa fa-truck mr-2" />{t("page.bookingOverview.headers.shipment", "Shipment")}</h4>
                <Row>
                    <Col md="6">
                        <Row>
                            <Col md="6" className="text-right">
                                {t("common.routeLabel", "Route")}
                            </Col>
                            <Col md="6" className="text-left">
                                <strong>{formatRoute(this.state.booking)}</strong>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6" className="text-right">
                                {t("common.totalLabel", "Total")}
                            </Col>
                            <Col md="6" className="text-left">
                                <strong>{getShipmentsTotalsText(this.state.booking.rows, "")}</strong>
                            </Col>
                        </Row>
                    </Col>
                    <Col md="6">
                        <Row>
                            <Col md="6" className="text-right">
                                {t("common.pickupDateBookingLabel", "Ready for collection")}
                            </Col>
                            <Col md="6" className="text-left">
                                <strong>{this.momentDate(this.state.booking.pickup_date)}</strong>
                            </Col>
                        </Row>

                        <Row>
                            <Col md="6" className="text-right">
                                {t("common.deliveryDateBookingLabel", "Desired delivery date")}
                            </Col>
                            <Col md="6" className="text-left">
                                <strong>{this.momentDate(this.state.booking.delivery_date)}</strong>
                            </Col>
                        </Row>
                    </Col>
                    <RenderDangerousAndTemperatureSensitiveGoods
                        dangerous={this.state.booking.dangerous}
                        temperatureSensitive={this.state.booking.temperature_sensitive}
                    />
                </Row>
                <Row>
                    <Col md="3" className="text-right">
                        {t("common.commentLabel", "Comment")}
                    </Col>
                    <Col md="9" className="text-left">
                        <strong>{this.state.booking.customer_comments}</strong>
                    </Col>
                </Row>
            </>
        );
    }

    renderOfferSection() {
        const { t } = this.props;
        const offerReference = this.state.booking.quote_reference;
        if (!offerReference) {
            return null;
        }
        return (
            <>
                <hr></hr>
                <h4 className="mt-0"><i className="fa fa-file-text-o mr-2" />{t("page.bookingOverview.headers.offer", "Offer")}</h4>
                <Row className="justify-content-center">
                    <Col md="12">
                        <NavLink
                            to={getOfferOverviewUrl(offerReference)}
                            className="btn btn-primary"
                        >
                            {t("page.bookingOverview.goToOfferButton", "Link to offer")}
                        </NavLink>
                    </Col>
                </Row>
            </>
        );
    }
}

export default withRouter(withTranslation()(BookingStatusPage));
