import React from "react";
import { Tooltip as Muitooltip } from "@material-ui/core";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";

const theme = createTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: "1rem",
      },
    },
  },
});

export default function Tooltip(props) {
  return (
    <MuiThemeProvider theme={theme}>
      <Muitooltip title={props.title}>{props.children}</Muitooltip>
    </MuiThemeProvider>
  );
}
