import React, { useState, useEffect, useCallback, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col, Button, Modal, ModalBody } from "reactstrap";

import ajax from "remotes/ajax";
import Loading from "components/notifications/CenteredRowLoading";
import CloseButton from "components/modal/CloseButton";

const FiveStars = ({ onStarsClick, disabled, stars = 0 }) => {
    /*
        Either onStarsClick or disabled prop is required.
    */
    const [starHovered, setStarHovered] = useState(stars);

    return (
        <Row className="justify-content-center">
            {[1, 2, 3, 4, 5].map((star, i) => {
                const style = {
                    color: starHovered >= star ? "yellow" : "grey",
                    fontSize: "48px",
                    width: "48px"
                }
                return (
                    <Col
                        key={star}
                        xs="auto"
                    >
                        {disabled ?
                            <i className="fa fa-star" style={style}></i>
                            :
                            <i
                                className="fa fa-star pointer"
                                onClick={() => onStarsClick(star)}
                                onMouseEnter={() => setStarHovered(star)}
                                onMouseLeave={() => setStarHovered(0)}
                                style={style}
                            ></i>
                        }
                    </Col>
                )
            }
            )}
        </Row>
    );
}

const RejectOfferModal = ({ reference, onReject, buttonClassName = "" }) => {
    const { t } = useTranslation();
    const [modal, setModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);

    const toggle = useCallback(() => {
        if (!modal) {
            setError(false);
            setLoading(false);
        }
        setModal(!modal);
    }, [modal]);

    useEffect(() => {
        if (success === true && modal === true) {
            toggle();
        }
        else if (success === true && modal === false) {
            setSuccess(false);
            onReject();
        }
    }, [success, modal, toggle, onReject]);

    const onSubmit = (stars) => {
        setLoading(true);
        setError(false);
        const feedback = {
            stars
        };
        ajax.doPostRejectOffer(reference, feedback)
            .then(() => {
                setSuccess(true);
            })
            .catch(() => {
                setError(true);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <Fragment>
            <Button color="danger" onClick={toggle} className={buttonClassName}>{t("offer.rejectModal.openModalButton", "REJECT OFFER")}</Button>
            <Modal isOpen={modal} toggle={toggle} centered>
                <Row>
                    <Col className="mx-4">
                        <CloseButton onClick={toggle} />
                        <h5 style={{ paddingTop: "20px" }}>{t("offer.rejectModal.title", "Reject offer")}</h5>
                    </Col>
                </Row>
                <ModalBody>
                    {loading && <Loading ></Loading>}
                    {!loading &&
                        <>
                            <FiveStars onStarsClick={stars => onSubmit(stars)} />
                            <Row className="justify-content-center mt-3">
                                <Col xs="auto">
                                    <p>
                                        {t("offer.rejectModal.howToSubmit", "Click on a star to submit rejection.")}
                                    </p>
                                </Col>
                            </Row>
                        </>
                    }
                    {error && (
                        <Row>
                            <Col className="text-center">
                                <span className="error-msg is-invalid-custom-message-color">{t("common.unknownError", "Unknown error, try again.")}</span>
                            </Col>
                        </Row>

                    )}
                </ModalBody>
            </Modal>
        </Fragment>
    );
};

export { FiveStars };
export default RejectOfferModal;
