import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
    token: null, // TODO switch back to null
    error: null,
    loading: false,
    user: {
        id: null,
        first_name: "",
        last_name: "",
        role: "",
        email: "",
    },
    users: [],
    authType: null,
};

const registerStart = (state, action) => {
    return updateObject(state, {
        errorMessage: null,
        successMessage: null,
        loading: true
    })
};

const registerSuccess = (state, action) => {
    return updateObject(state, {
        successMessage: "User successfully registered!",
        loading: false
    })
};

const registerFail = (state, action) => {
    return updateObject(state, {
        errorMessage: action.error,
        loading: false
    })
};

const authStart = (state, action) => {
    return updateObject(state, {
        error: null,
        loading: true
    })
};

const authSuccess = (state, action) => {
    return updateObject(state, {
        token: action.token,
        error: null,
        loading: false
    })
};

const authFail = (state, action) => {
    return updateObject(state, {
        token: null,
        error: action.error,
        loading: false
    })
};

const logOut = (state, action) => {
    return updateObject(state, {
        token: null,
    })
};

const getUser = (state, action) => {
    if (!action.user) {
        return updateObject(state, {
            user: action.user,
        })
    }
    return updateObject(state, {
        user: {
            ...action.user,
        },
    })
};


const auth = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.REGISTER_START:
            return registerStart(state, action);
        case actionTypes.REGISTER_SUCCESS:
            return registerSuccess(state, action);
        case actionTypes.REGISTER_FAIL:
            return registerFail(state, action);
        case actionTypes.AUTH_START:
            return authStart(state, action);
        case actionTypes.AUTH_SUCCESS:
            return authSuccess(state, action);
        case actionTypes.AUTH_FAIL:
            return authFail(state, action);
        case actionTypes.LOG_OUT:
            return logOut(state, action);
        case actionTypes.GET_AUTH_USER:
            return getUser(state, action);
        default:
            return state;
    }
};

export default auth;
