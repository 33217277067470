import React, { Component, Fragment } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import ValidationMessage from "components/form/ValidationMessage";
import InputWithValidation from "components/form/InputWithValidation";
import GooglePlacesAutocomplete from "components/form/GooglePlacesAutocomplete";
import classNames from "classnames";
import ToggleExpand from "components/form/ToggleExpand";
import TransportHub, { TransportHubDirection } from "components/form/TransportHub";
import { Container, Grid, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';

class QuoteDirection extends Component {
    static propTypes = {
        quote: PropTypes.object.isRequired,
        countryOptions: PropTypes.array.isRequired,
        errors: PropTypes.object.isRequired,
        submitDone: PropTypes.bool.isRequired,
        updateQuoteFormField: PropTypes.func.isRequired,
        isReadOnly: PropTypes.bool,
    };


    constructor(props) {
        super(props);

        this.state = {
            showFromRegionDetails: false,
            showToRegionDetails: false,
        };
    }


    render() {
        return (
            <Container disableGutters maxWidth={false}>
                <Grid container spacing={3}>
                    {this.renderFromRegionOptions()}
                    {this.renderToRegionOptions()}
                </Grid>
            </Container >
        );
    }

    renderFromRegionOptions() {
        const { t } = this.props;
        return (
            <Grid item xs={6} sm={6}>
                <GooglePlacesAutocomplete
                    address={this.props.quote.from_location}
                    handleAddress={this.handleFromLocation}
                    onChange={(value) => this.props.updateQuoteFormField("from_location", value)}
                    validationError={this.props.errors.from_location}
                    name="from_location"
                    labelText={t("form.direction.fromLabel", "From")}
                    isRequired={true}
                    disabled={this.props.isReadOnly}
                />
                <TransportHub
                    value={this.props.quote.from_transport_hub}
                    direction={TransportHubDirection.FROM}
                    transportType={this.props.quote.transport_type}
                    onChange={(value) => this.props.updateQuoteFormField("from_transport_hub", value)}
                    readOnly={this.props.isReadOnly}
                />
                {this.state.showFromRegionDetails &&
                    <Fragment>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <InputWithValidation
                                    value={this.props.quote.from_company}
                                    onChange={(value) => this.props.updateQuoteFormField("from_company", value)}
                                    error={this.props.errors.from_company}
                                    name="from_company"
                                    type="text"
                                    placeholder={t("form.direction.companyPlaceholder", "Company")}
                                    labelText={t("form.direction.companyLabel", "Company")}
                                    disabled={this.props.isReadOnly}
                                    isRequired={false}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <InputWithValidation
                                    value={this.props.quote.from_address}
                                    onChange={(value) => this.props.updateQuoteFormField("from_address", value)}
                                    error={this.props.errors.from_address}
                                    name="from_address"
                                    type="text"
                                    placeholder={t("form.direction.addressPlaceholder", "Address")}
                                    labelText={t("form.direction.addressLabel", "Address")}
                                    disabled={this.props.isReadOnly}
                                    isRequired={false}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <InputWithValidation
                                    value={this.props.quote.from_region_code}
                                    onChange={(value) => this.props.updateQuoteFormField("from_region_code", value)}
                                    error={this.props.errors.from_region_code}
                                    name="from_region_code"
                                    type="text"
                                    labelText={t("form.direction.zipLabel", "Zip")}
                                    placeholder={t("form.direction.zipPlaceholder", "ZIP")}
                                    disabled={true}
                                    isRequired={true}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <InputWithValidation
                                    value={this.props.quote.from_city}
                                    onChange={(value) => this.props.updateQuoteFormField("from_city", value)}
                                    error={this.props.errors.from_city}
                                    name="from_city"
                                    type="text"
                                    placeholder={t("form.direction.cityPlaceholder", "City")}
                                    labelText={t("form.direction.cityLabel", "City")}
                                    disabled={this.props.isReadOnly}
                                    isRequired={false}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <InputWithValidation
                                    value={this.props.quote.from_county}
                                    onChange={(value) => this.props.updateQuoteFormField("from_county", value)}
                                    error={this.props.errors.from_county}
                                    name="from_county"
                                    type="text"
                                    placeholder={t("form.direction.countyPlaceholder", "County")}
                                    labelText={t("form.direction.countyLabel", "County")}
                                    disabled={this.props.isReadOnly}
                                    isRequired={false}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl
                                    fullWidth
                                    style={{ marginTop: '1rem' }}
                                    disabled
                                >
                                    <InputLabel
                                        id="from_country_code"
                                        htmlFor="from_country_code"
                                        style={{ paddingLeft: '1rem' }}
                                    >
                                        {t("form.direction.countryLabel", "Country")}
                                    </InputLabel>
                                    <Select
                                        labelId="from_country_code"
                                        label={t("form.direction.countryLabel", "Country")}
                                        variant="outlined"
                                        value={this.props.quote.from_country_code || ""}
                                        onChange={this.handleCountrySelectFrom}
                                        className={classNames({ "is-invalid-custom": this.props.errors.from_country_code })}
                                        name="from_country_code"
                                    >
                                        {this.renderOptios()}
                                    </Select>
                                    <ValidationMessage
                                        message={this.props.errors.from_country_code}
                                        isVisible={this.props.submitDone && this.props.errors.from_country_code}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Fragment>
                }
                <ToggleExpand
                    text={t("form.bookingList.toggleExpandFields", "Enter full address")}
                    isExpanded={this.state.showFromRegionDetails}
                    onToggle={() => this.toggleValue("showFromRegionDetails")}
                    isError={this.isFromDetailsErrors()}
                />
            </Grid >
        );
    }
    renderOptios() {
        return this.props.countryOptions.map((country, index) => {
            return (
                <MenuItem
                    label={country.label}
                    value={country.value}
                    key={index}
                    name={country.label}
                >{country.label}
                </MenuItem>
            );
        });
    }

    renderToRegionOptions() {
        const { t } = this.props;
        return (
            <Grid item xs={6} sm={6}>
                <GooglePlacesAutocomplete
                    address={this.props.quote.to_location}
                    handleAddress={this.handleToLocation}
                    onChange={(value) => this.props.updateQuoteFormField("to_location", value)}
                    validationError={this.props.errors.to_location}
                    name="to_location"
                    labelText={t("form.direction.toLabel", "To")}
                    isRequired={true}
                    disabled={this.props.isReadOnly}
                />
                <TransportHub
                    value={this.props.quote.to_transport_hub}
                    direction={TransportHubDirection.TO}
                    transportType={this.props.quote.transport_type}
                    onChange={(value) => this.props.updateQuoteFormField("to_transport_hub", value)}
                    readOnly={this.props.isReadOnly}
                />
                {this.state.showToRegionDetails &&
                    <Fragment>
                        <Grid container spacing={2}>
                            <Grid item sm={6}>
                                <InputWithValidation
                                    value={this.props.quote.to_company}
                                    onChange={(value) => this.props.updateQuoteFormField("to_company", value)}
                                    error={this.props.errors.to_company}
                                    name="to_company"
                                    type="text"
                                    placeholder={t("form.direction.companyPlaceholder", "Company")}
                                    labelText={t("form.direction.companyLabel", "Company")}
                                    disabled={this.props.isReadOnly}
                                    isRequired={false}
                                />
                            </Grid>
                            <Grid item sm={6}>
                                <InputWithValidation
                                    value={this.props.quote.to_address}
                                    onChange={(value) => this.props.updateQuoteFormField("to_address", value)}
                                    error={this.props.errors.to_address}
                                    name="to_address"
                                    type="text"
                                    placeholder={t("form.direction.addressPlaceholder", "Address")}
                                    labelText={t("form.direction.addressLabel", "Address")}
                                    disabled={this.props.isReadOnly}
                                    isRequired={false}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item sm={6}>
                                <InputWithValidation
                                    value={this.props.quote.to_region_code}
                                    onChange={(value) => this.props.updateQuoteFormField("to_region_code", value)}
                                    error={this.props.errors.to_region_code}
                                    name="to_region_code"
                                    type="text"
                                    labelText={t("form.direction.zipLabel", "Zip")}
                                    placeholder={t("form.direction.zipPlaceholder", "ZIP")}
                                    disabled={true}
                                    isRequired={true}
                                />
                            </Grid>
                            <Grid item sm={6}>
                                <InputWithValidation
                                    value={this.props.quote.to_city}
                                    onChange={(value) => this.props.updateQuoteFormField("to_city", value)}
                                    error={this.props.errors.to_city}
                                    name="to_city"
                                    type="text"
                                    placeholder={t("form.direction.cityPlaceholder", "City")}
                                    labelText={t("form.direction.cityLabel", "City")}
                                    disabled={this.props.isReadOnly}
                                    isRequired={false}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item sm={6}>
                                <InputWithValidation
                                    value={this.props.quote.to_county}
                                    onChange={(value) => this.props.updateQuoteFormField("to_county", value)}
                                    error={this.props.errors.to_county}
                                    name="to_county"
                                    type="text"
                                    placeholder={t("form.direction.countyPlaceholder", "County")}
                                    labelText={t("form.direction.countyLabel", "County")}
                                    disabled={this.props.isReadOnly}
                                    isRequired={false}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl
                                    fullWidth
                                    style={{ marginTop: '1rem' }}
                                    disabled>
                                    <InputLabel 
                                        id="to_country_code"
                                        htmlFor="to_country_code" 
                                        style={{ paddingLeft: '1rem' }}>
                                            {t("form.direction.countryLabel", "Country")}
                                    </InputLabel>
                                    <Select
                                        labelId="to_country_code"
                                        label={t("form.direction.countryLabel", "Country")}
                                        variant="outlined"
                                        value={this.props.quote.to_country_code || ""}
                                        onChange={this.handleCountrySelectTo}
                                        className={classNames({ "is-invalid-custom": this.props.errors.from_country_code })}
                                        name="to_country_code"
                                    >
                                        {this.renderOptios()}
                                    </Select>
                                    <ValidationMessage
                                        message={this.props.errors.to_country_code}
                                        isVisible={this.props.submitDone && this.props.errors.to_country_code}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Fragment>
                }
                <ToggleExpand
                    text={t("form.bookingList.toggleExpandFields", "Enter full address")}
                    isExpanded={this.state.showToRegionDetails}
                    onToggle={() => this.toggleValue("showToRegionDetails")}
                    isError={this.isToDetailsErrors()}
                />
            </Grid>
        );
    }

    handleFromLocation = (location, address, city, region_code, county, country_code) => {
        this.props.updateQuoteFormField("from_location", location);
        this.props.updateQuoteFormField("from_address", address);
        this.props.updateQuoteFormField("from_city", city);
        this.props.updateQuoteFormField("from_region_code", region_code);
        this.props.updateQuoteFormField("from_county", county);
        this.props.updateQuoteFormField("from_country_code", country_code);
    };

    handleToLocation = (location, address, city, region_code, county, country_code) => {
        this.props.updateQuoteFormField("to_location", location);
        this.props.updateQuoteFormField("to_address", address);
        this.props.updateQuoteFormField("to_city", city);
        this.props.updateQuoteFormField("to_region_code", region_code);
        this.props.updateQuoteFormField("to_county", county);
        this.props.updateQuoteFormField("to_country_code", country_code);
    };

    handleCountrySelectFrom = (event) => {
        this.props.updateQuoteFormField("from_country_code", event.target.value)
    };

    handleCountrySelectTo = (event) => {
        this.props.updateQuoteFormField("to_country_code", event.target.value)
    };

    isFromDetailsErrors = () => {
        const errors = this.props.errors;
        /* Details section (expand button) is considered to be in error state
            when the fields inside of it have errors and it is not expanded
        */
        if (!this.state.showFromRegionDetails &&
            errors && (
                errors.from_address ||
                errors.from_city ||
                errors.from_region_code ||
                errors.from_county ||
                errors.from_country_code
            )
        ) {
            return true;
        }

        return false;
    }

    isToDetailsErrors = () => {
        const errors = this.props.errors;
        if (!this.state.showToRegionDetails &&
            errors && (
                errors.to_address ||
                errors.to_city ||
                errors.to_region_code ||
                errors.to_county ||
                errors.to_country_code
            )) {
            return true;
        }

        return false;
    }

    toggleValue = key => {
        this.setState(prevState => ({
            [key]: !prevState[key],
        }));
    }
}

export default withTranslation()(QuoteDirection);
