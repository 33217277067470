import _ from "lodash";
import * as actionTypes from "redux/actions/actionTypes";
//import { updateObject } from "redux/utility";
import { languageTypes } from "util/General";

export const getInitialState = () => {
    const initialLanguage = localStorage.getItem("language") || languageTypes.EN;
    return initialLanguage;
}

const updateLanguage = (state, newLanguage) => {
    localStorage.setItem("language", newLanguage);
    return newLanguage;
};

const updateLanguageFromUserObject = (state, user) => {
    if (!user) {
        return state;
    }
    let activeLanguage = _.get(user, "language", "");

    activeLanguage = activeLanguage.toLowerCase();

    if (![languageTypes.ET, languageTypes.EN].includes(activeLanguage)) {
        activeLanguage = getInitialState();
    }

    return updateLanguage(state, activeLanguage);
};

export const language = (state = getInitialState(), action) => {
    switch (action.type) {
        case actionTypes.LANGUAGE_UPDATE:
            return updateLanguage(state, action.language);
        case actionTypes.GET_AUTH_USER:
            return updateLanguageFromUserObject(state, action.user);
        case actionTypes.LOG_OUT:
            return state;
        //return initialState;
        default:
            return state;
    }
};
