const styles = (theme) => ({
    checkBoxContainer: {
        border: '1px solid #ced4da',
        borderRadius: '4px',
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        height: '100%',
        '&:hover': {
            background: "#F6F8FB",
        },
    },
    checkBoxContainerChecked: {
        background: 'rgba(239, 231, 252, 0.4)',
        border: '2px solid #611AD0'
    },
    formLabel: {
        marginTop: '1rem',
        paddingBottom: '0',
        fontWeight: '500',
        color: 'inherit',
        fontSize: '1.1rem'
    },
    label: {
        fontWeight: '800',
        marginBottom: '0',
        [theme.breakpoints.up("xs")]: {
            padding: '.5rem',
            marginLeft: '.5rem',
        },
        [theme.breakpoints.up("sm")]: {
            padding: '1rem 4rem 1rem 2rem',
        },
    },
    radio: {
        position: 'absolute',
        right: '0',
        top: '0',
    },
    svgContainer: {
        [theme.breakpoints.up("sm")]: {
            margin: '1rem'
        },
    },
    checkBoxHelperText: {
        marginBottom: '0',
        color: '#0062EC',
        fontWeight: '400'
    },
    checkedText: {
        color: '#611AD0',
        fontWeight: '500'
    }
});

export { styles };