import React from "react";
import { useTranslation } from "react-i18next";
import { Row, Col, Table } from "reactstrap";

import { getTotalPricesPerCurrencyText } from "util/offers";

export function PricesReadOnly(props) {
    const { prices } = props;
    const { t } = useTranslation();

    const priceRows = prices.map((price, index) => {
        return (
            <tr key={index}>
                <td className="text-right">{price.price}</td>
                <td className="text-right">{price.currency}</td>
                <td className="text-left">{price.description}</td>
            </tr>
        );
    });

    return (
        <>
            {(prices && prices.length > 0) &&
                <Row className="justify-content-center">
                    <Col sm="12" md="10" lg="8">
                        <Table className="prices-read-only-table">
                            <thead>
                                <tr>
                                    <th className="text-right w80">{t("offerPage.prices.priceHeader", "Price")}</th>
                                    <th className="text-right w80">{t("offerPage.prices.currencyHeader", "Currency")}</th>
                                    <th className="text-left">{t("offerPage.prices.descriptionHeader", "Description")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {priceRows}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            }
            <h2 className="mt-1 prices-total-price mb-0"> {getTotalPricesPerCurrencyText(prices)}</h2>
            <h5 className="text-muted">{t("offerPage.prices.excludesVatText", "*Prices exclude VAT")}</h5>
        </>
    );
}
