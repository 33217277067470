import React, { Component } from 'react';
import { Route } from "react-router-dom";
import PropTypes from "prop-types";

// routes
import { withRouter } from "react-router";


export function SubRoute(route) {
    return (
        <Route
            {...route}
            component={props => (
                // pass the sub-routes down to keep nesting
                <route.component {...props} routes={route.routes} />
            )}
        />
    );
}

class RouteWithSubRoutes extends Component {
    static propTypes = {
        routes: PropTypes.array,
    }
    render() {
        // A special wrapper for <Route> that knows how to
        // handle "sub"-routes by passing them in a `routes`
        // prop to the component it renders.


        return (
            <>
                {this.props.routes.map((route, i) => (
                    <SubRoute key={this.props.location.key + i} {...route} />
                ))}
            </>
        );
    }
}

export default withRouter(RouteWithSubRoutes);
