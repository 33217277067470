import React from "react";
import { withRouter } from "react-router-dom";
import { Col, Container, Row, Card, CardBody } from "reactstrap";

import BrandLogoText from "components/Misc/BrandLogoText";

function PrivacyPolicyContent(props) {
    return (
        <article className="regular-14 text-left">
            <h3 className="heading text-center">Privacy Policy</h3>
            <p className="semibold-14">1. Subheading</p>
            <p>1.1.  Point 1.1</p>
            <p>1.2.  ...</p>
            <p>1.3.  ...</p>
            <p>1.4.  ...</p>


        </article>
    );
}

function PrivacyPolicy(props) {
    return (
        <Container className="align-middle">
            <Row className="text-center justify-content-center">
                <Col>
                    <Card>
                        <CardBody className="px-3 px-md-4" style={{ "paddingTop": "32px" }}>
                            <BrandLogoText />
                            <Row>
                                <Col>
                                    <PrivacyPolicyContent />
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default withRouter(PrivacyPolicy);
