import React from "react";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import get from "lodash/get";
import { Box, Grid, Button } from '@material-ui/core';
import ajax from "remotes/ajax";
import UtilAlert from "util/toastAlerts";
import { getServerErrorMessage } from "util/Errors";
import TypographyLarge from "components/form/TypographyLarge";
import { withLocalToasts } from "components/hoc";
import CustomInput from "components/form/InputWithValidation";
import WrappedPulseLoader from "components/Loader/WrappedPulseLoader";


class UserDetailsForm extends React.Component {
    FORM_ID = "user-details-form";
    FORM_WRAPPER_CLASS = "change-existing-password__wrapper";

    state = {
        // new customer data
        email: "",
        first_name: "",
        last_name: "",
        old_password: "",
        // other
        validationErrors: {},
        loading: false,
        disabled: false
    };


    componentDidMount() {
        this.getUserDetails();
    }

    componentWillUnmount() {
        this.props.dismissLocalToasts();
    }

    render() {
        return (
            <div className={this.FORM_WRAPPER_CLASS}>
                <Box pb={2}>
                    <TypographyLarge> 
                        {this.props.t("user.userDetailsForm.title", "User details")}
                    </TypographyLarge>
                </Box>
                <Box pb={8}>
                    {this.renderContent()}
                </Box>
            </div >
        );
    }

    renderContent() {
        return this.renderForm();
    }

    renderForm() {
        const { validationErrors, loading, disabled } = this.state;
        return (
            <form
                onSubmit={this.onSubmit}
                id={this.FORM_ID}
            >
                <Grid container disableGutters maxWidth={false}>
                    <Grid item xs={12} sm={12}>
                        <CustomInput
                            value={this.state.email}
                            error={validationErrors.email}
                            onChange={(value) =>
                                this.setState({ email: value })
                            }
                            type="text"
                            labelText={this.props.t("user.userDetailsForm.emailLabel", "Email")}
                            placeholder={this.props.t("user.userDetailsForm.emailPlaceholder", "Email")}
                            disabled={true}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <CustomInput
                            value={this.state.first_name}
                            error={validationErrors.first_name}
                            onChange={(value) =>
                                this.setState({ first_name: value })
                            }
                            type="text"
                            labelText={this.props.t("user.userDetailsForm.firstNameLabel", "First name")}
                            placeholder={this.props.t("user.userDetailsForm.firstNamePlaceholder", "First name")}
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <CustomInput
                            value={this.state.last_name}
                            error={validationErrors.last_name}
                            onChange={(value) =>
                                this.setState({ last_name: value })
                            }
                            type="text"
                            labelText={this.props.t("user.userDetailsForm.lastNameLabel", "Last name")}
                            placeholder={this.props.t("user.userDetailsForm.lastNamePlaceholder", "Last name")}
                            disabled={disabled}
                        />
                    </Grid>
                </Grid>
                <Box mt={4}>
                    <Grid container>
                        <Button
                            variant="contained"
                            size="large"
                            className="submitButton"
                            form={this.FORM_ID}
                            disabled={disabled || loading}
                            type="submit"
                        >
                            {loading ? <WrappedPulseLoader /> : this.props.t("user.userDetailsForm.submitButton", "Update")}
                        </Button>
                    </Grid>
                </Box>
            </form>
        );
    }

    onValidate = () => {
        return true; // leaving validations to server-side atm.
    }

    onSubmit = (e) => {
        e.preventDefault();
        UtilAlert.dismissAlerts(this.state.localAlertIds);
        this.setState({
            validationErrors: {}
        });
        if (!this.onValidate()) {
            const alertId = UtilAlert.createValidationAlert(this.props.t("user.userDetailsForm.validationErrorMessage", "Please check the fields for invalid data."));
            this.props.addLocalToast(alertId);
            return;
        }

        this.updateUserDetails();
    };

    getUserDetails = () => {
        this.setState({ loading: true });
        ajax.doGetUserDetails()
            .then(data => {
                this.setState({
                    ...data
                });
                // store.dispatch(getUser(data)); // Makes the whole page refresh and get stuck in loop because called in componentDidMount. 
            })
            .catch(error => {
                this.setState({ disabled: true });
                const errorMessage = getServerErrorMessage(error);
                const errorTitle = this.props.t("user.userDetailsForm.getDetailsErrorMessage",
                    "Error occured during when trying to access user details. Please try refreshing the page.");
                const alertId = UtilAlert.createAlert(errorMessage, {
                    type: UtilAlert.ALERT_TYPE.ERROR,
                    title: errorTitle,
                    autoClose: false
                });
                this.props.addLocalToast(alertId);
            })
            .finally(() => this.setState({ loading: false }));
    }

    updateUserDetails = () => {
        const { first_name, last_name } = this.state;
        this.setState({ loading: true });
        ajax.doUpdateUserDetails({ first_name, last_name })
            .then(data => {
                const successMessage = this.props.t("user.userDetailsForm.updateDetailsSuccessMessage", "User details have been updated.");
                const alertId = UtilAlert.createAlert(successMessage, {
                    type: UtilAlert.ALERT_TYPE.SUCCESS
                });
                this.props.addLocalToast(alertId);
                this.setState({
                    ...data
                });
                // store.dispatch(getUser(data)); // Makes the whole page refresh currently, which we don't want
            })
            .catch(error => {
                const data = get(error, "response.data", null);
                if (typeof data === "object" && data !== null) {
                    this.setState({ validationErrors: data });
                } else {
                    const errorMessage = getServerErrorMessage(error);
                    const errorTitle = this.props.t("user.userDetailsForm.updateDetailsErrorMessage", "Error occured during user details update.");
                    const alertId = UtilAlert.createAlert(errorMessage, {
                        type: UtilAlert.ALERT_TYPE.ERROR,
                        title: errorTitle,
                        autoClose: false
                    });
                    this.props.addLocalToast(alertId);
                }
            })
            .finally(() => this.setState({ loading: false }));
    }
}

export default withRouter(withTranslation()(withLocalToasts(UserDetailsForm)));
