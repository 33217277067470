import React from "react";
import { Trans } from "react-i18next";
import _ from "lodash";
import moment from "moment";
import { isDateAfterYesterday, isDateEqualOrAfterCustomDate } from "util/dateTime";
import { transportType, loadType, transportTypeLoadTypes } from "maps/classifiers";
import { packageTypeOptions } from "util/shipments";
import { listOfSeaTransportSpecificIncoterms } from "components/form/Incoterms";

const invalidChoiceErrorText = <Trans i18nKey="validations.invalidChoice">Not a valid choice.</Trans>;

const filledByLocationValidationMessage = <Trans i18nKey="validations.filledByLocation">Filled through location field automatically.</Trans>;
const validationFieldMessages = {
    from_region_code: filledByLocationValidationMessage,
    from_country_code: filledByLocationValidationMessage,
    to_region_code: filledByLocationValidationMessage,
    to_country_code: filledByLocationValidationMessage,
}

function createError(field, errorText) {
    return { [field]: errorText || validationFieldMessages[field] || <Trans i18nKey="validations.fieldIsRequired">This field is required.</Trans> };
}

export function areShipmentQuantitiesRequired(load_type) {
    return [loadType.LTL, loadType.LCL].includes(load_type);
}

function validateTransportType(field, data) {
    if (!data) {
        return createError(field);
    }
    else if ([transportType.ROAD, transportType.RAIL, transportType.SEA, transportType.AIR].includes(data) === false) {
        return createError(field, <Trans i18nKey="validations.invalidChoice">Not a valid choice.</Trans>);
    }
    return {};
}

function validateLoadType(field, load_type, transport_type) {
    const validLoadTypes = transportTypeLoadTypes[transport_type] || [];

    if (validLoadTypes.length === 0) {
        if (load_type !== null) {
            return createError(field, <Trans i18nKey="validations.loadTypeNotNull">Load type should not be selected.</Trans>);
            //throw Error("Load type should be null if no valid options.");
        }
    }
    else {
        if (load_type == null) {
            return createError(field);
        }
        else if (!validLoadTypes.includes(load_type)) {
            return createError(field, invalidChoiceErrorText);
        }
    }

    return {};
}

function validateRequiredField(field, data) {
    if (data !== 0 && !data) {
        return createError(field);
    }
    return {};
}

function validateRequiredFields(fields, data) {
    const errors = {};

    for (const field of fields) {
        Object.assign(errors, validateRequiredField(field, data[field]));
    }

    return errors;
}

function validateShipment(shipment, load_type) {
    const requiredFields = ["weight", "goods_description"];

    if (areShipmentQuantitiesRequired(load_type)) {
        requiredFields.push("quantity");
    }
    if (shipment.package_type === "OTHER") {
        requiredFields.push("package_type_other");
    }

    const errors = validateRequiredFields(requiredFields, shipment);

    const packageTypesForFCL = ["CONTAINER20", "CONTAINER40", "CUBE40"];
    if (load_type !== loadType.FCL && packageTypesForFCL.includes(shipment.package_type)) {
        const packageTypeOption = packageTypeOptions[shipment.package_type];
        const errorMessage = <Trans i18nKey="validations.shipments.invalidPackageTypeForLoadType">{{ packageTypeOption }} is only valid for Full Container Load (FCL).</Trans>
        errors.package_type = errorMessage;
    }

    return errors;
}

function validateShipments(shipments, load_type) {
    if (!shipments) {
        return createError("shipments_empty", <Trans i18nKey="validations.shipments.emptyList">This list may not be empty.</Trans>);
    }
    let isErrors = false;
    const rows = {};
    for (const shipment of shipments) {
        const shipment_errors = validateShipment(shipment, load_type);
        if (!_.isEmpty(shipment_errors)) {
            isErrors = true;
        }

        if (shipment.id) {
            rows[shipment.id] = shipment_errors;
        }
        else if (shipment.temp_id) {
            rows[shipment.temp_id] = shipment_errors;
        }
    }
    return isErrors ? { shipments: rows } : {};
}

function validateIncoterms(incoterms, transport_type) {
    if (!incoterms) {
        return {};
    }

    if (listOfSeaTransportSpecificIncoterms.includes(incoterms) && transport_type !== transportType.SEA) {
        return createError("incoterms", <Trans i18nKey="validations.incotermsValidOnlyForSea">{{ incoterms }} incoterms is specific to sea transport.</Trans>);
    }

    return {};
}

function validatePrice(price) {
    const requiredFields = ["price_type", "price", "currency", "description"];
    const errors = validateRequiredFields(requiredFields, price);

    return errors;
}

function validatePrices(prices) {
    if (!prices) {
        return createError("prices_empty", <Trans i18nKey="validations.prices.emptyList">Atleast one price line is required.</Trans>);
    }

    let isErrors = false;
    const rows = {};
    for (const price of prices) {
        const price_errors = validatePrice(price);
        if (!_.isEmpty(price_errors)) {
            isErrors = true;
        }

        if (price.id) {
            rows[price.id] = price_errors;
        }
        else if (price.temp_id) {
            rows[price.temp_id] = price_errors;
        }
    }

    return isErrors ? { prices: rows } : {};
}

function validatePickupDate(pickup_date) {
    if (pickup_date && !isDateAfterYesterday(pickup_date)) {
        return { pickup_date: <Trans i18nKey="validations.pickupDateInPast">Pickup date may not be a past date.</Trans> };
    }

    return {};
}

function validateDeliveryDate(delivery_date, pickup_date) {
    const momentDeliveryDate = moment(delivery_date);
    if (momentDeliveryDate.isValid()) {
        const momentPickupDate = moment(pickup_date);
        if (momentPickupDate.isValid() && !isDateEqualOrAfterCustomDate(momentDeliveryDate, momentPickupDate)) {
            return { delivery_date: <Trans i18nKey="validations.deliveryDateBeforePickupDate">Delivery date may not be before pickup date.</Trans> };
        }
        else if (!isDateAfterYesterday(momentDeliveryDate)) {
            return { delivery_date: <Trans i18nKey="validations.deliveryDateInPast">Delivery date may not be a past date.</Trans> };
        }
    }

    return {};
}

function validateDueDate(due_date) {
    if (due_date && moment(due_date) < moment().startOf("day")) {
        return { due_date: <Trans i18nKey="validations.dueDateInPast">Due date may not be a past date.</Trans> };
    }

    return {};
}

export function validateOffer(quote, options = {}) {
    const errors = {};

    const defaultOptions = {
        mode: "OFFER"
    }
    const config = { ...defaultOptions, ...options };

    const commonRequiredField = [
        "transport_type",
        "rows",
        "from_location", "from_region_code", "from_country_code",
        "to_location", "to_region_code", "to_country_code",
        "company_name", "contact_email"
    ];
    const quoteAdditionalFields = [];
    const offerAdditionalRequiredFields = ["prices"];
    const bookingAdditionalRequiredFields = [
        "from_company", "from_contact_person", "from_contact_phone",
        "to_company", "to_contact_person", "to_contact_phone",
        "contact_person",
        "is_terms_checked"
    ];
    const requiredFields = [
        ...commonRequiredField,
        ...(config.mode === "QUOTE" ? quoteAdditionalFields : []),
        ...(config.mode === "OFFER" ? offerAdditionalRequiredFields : []),
        ...(config.mode === "BOOKING" ? bookingAdditionalRequiredFields : [])
    ];

    Object.assign(errors, validateRequiredFields(requiredFields, quote));
    Object.assign(errors, validateTransportType("transport_type", quote.transport_type));
    Object.assign(errors, validateLoadType("load_type", quote.load_type, quote.transport_type));
    Object.assign(errors, validateShipments(quote.rows, quote.load_type));
    Object.assign(errors, validateIncoterms(quote.incoterms, quote.transport_type));
    Object.assign(errors, validatePickupDate(quote.pickup_date));
    Object.assign(errors, validateDeliveryDate(quote.delivery_date, quote.pickup_date));

    if (config.mode === "OFFER") {
        Object.assign(errors, validatePrices(quote.prices));
        //Object.assign(errors, validateQuotePriceOffers(quote.offers));
        Object.assign(errors, validateDueDate(quote.due_date));
    }
    return errors;
}

export function validateQuote(quote) {
    return validateOffer(quote, { mode: "QUOTE" })
}

export function validateBooking(booking) {
    return validateOffer(booking, { mode: "BOOKING" })
}
