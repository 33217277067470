import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Button } from '@material-ui/core';

export default class ToggleExpand extends Component {
    static propTypes = {
        isError: PropTypes.bool,
        isExpanded: PropTypes.bool.isRequired,
        onToggle: PropTypes.func.isRequired,
        text: PropTypes.string.isRequired,
    };

    render() {
        return (
            <Button
                className={classNames("linkStyleButton",
                    {
                        "is-invalid-custom-message-color": this.props.isError,
                    })
                }
                onClick={this.props.onToggle}
                style={{
                    textTransform: 'none',
                    fontSize: '1rem'
                }}
            >
                {this.props.text}
                {this.props.isExpanded &&
                    <ExpandLessIcon
                        style={{
                            marginBottom: '0.2rem',
                            marginRight: '0.2rem'
                        }}
                    />
                }
                {!this.props.isExpanded &&
                    <ExpandMoreIcon
                        style={{
                            marginBottom: '0.2rem',
                            marginRight: '0.2rem'
                        }}
                    />
                }
            </Button>
        );
    }
}
