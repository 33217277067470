
import et from "date-fns/locale/et";
import en from "date-fns/locale/en-GB";

const numberFormatLocale = "et";
const currencyBaseOptions = { style: "currency", currency: "EUR" };

export const currencyFormatter = Intl.NumberFormat(numberFormatLocale, {
    ...currencyBaseOptions,
    minimumFractionDigits: 2,
});

const languageTypeMeta = [
    {
        property: "ET",
        code: "et",
        dateFnLocale: et
    },
    {
        property: "EN",
        code: "en",
        dateFnLocale: en
    }
];

export const languageTypes = languageTypeMeta.reduce((obj, meta) => ({ ...obj, [meta.property]: meta.code }), {});
export const languageTypeDateFnLocales = languageTypeMeta.reduce((obj, meta) => ({ ...obj, [meta.code]: meta.dateFnLocale }), {});

export function normalizeStringNumber(strNum) {
    return strNum.replace(",", ".").replace(/[^0-9.-]/g, "");
}

export function getPageCount(dataSize, sizePerPage) {
    if (sizePerPage === 0) {
        return 1;
    }

    return Math.ceil(dataSize / sizePerPage);
}
