import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { TextField, Link, Box } from '@material-ui/core';

import urls from "config/urls";


class EmailForm extends Component {
    render() {
        const { t } = this.props;
        return (
            <>
                <Box mt={2}>
                    <TextField
                        variant="outlined"
                        fullWidth={true}
                        label={t("authentication.login.emailLabel", "Email")}
                        name="email"
                        onChange={this.props.handleChanged}
                    >
                    </TextField>
                </Box>
                <Box mt={2}>
                    <TextField
                        variant="outlined"
                        fullWidth={true}
                        label={t("authentication.login.passwordLabel", "Password")}
                        name="password"
                        type="password"
                        onChange={this.props.handleChanged}
                    >
                    </TextField>
                </Box>
                <Box>
                    <Link
                        component="button"
                        className="linkStyleButton"
                        style={{ float: 'right' }}

                    >
                        <NavLink
                            style={{ color: 'inherit' }}
                            to={urls.FORGOT_PASSWORD}
                        >
                            {t("authentication.login.forgotPasswordLink", "Forgot password?")}
                        </NavLink>
                    </Link>
                </Box>
            </>
        );
    }
}

export default withTranslation()(EmailForm);
