import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { TextField } from "@material-ui/core";

export default class InputWithValidation extends Component {
    static propTypes = {
        value: PropTypes.any,
        error: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
        onChange: PropTypes.func.isRequired,
        labelText: PropTypes.string.isRequired,
        className: PropTypes.string,
        type: PropTypes.string,
        placeholder: PropTypes.string,
        name: PropTypes.string,
        disabled: PropTypes.bool,
        isRequired: PropTypes.bool,
    };

    render() {
        return (
            <div style={{ marginTop: '1rem' }}>
                <TextField
                    variant="outlined"
                    fullWidth
                    label={this.props.labelText}
                    htmlFor={this.props.name}
                    onChange={(event) => this.props.onChange(event.target.value)}
                    type={this.props.type || "text"}
                    className={classNames(this.props.className, { "is-invalid-custom": this.props.error })}
                    value={this.props.value || ""}
                    name={this.props.name}
                    disabled={this.props.disabled}
                    required={this.props.isRequired}
                    error={true && this.props.error != null}
                    helperText={this.props.error}
                />
                {/* <ValidationMessage
                    message={this.props.error}
                    isVisible={!!this.props.error}
                /> */}
            </div>
        );
    }
}
