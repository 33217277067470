import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { dateFormat } from "util/dateTime";
import moment from "moment";
import { onViewQuoteHistory, onCloseQuoteView, onQuoteFetchFail } from "redux/actions/quotes";
import EscClosable from "components/modal/EscClosable";
import Route from "components/notifications/Route";
import QuoteHistoryEvents from "components/page/quotes/QuoteHistoryEvents";
import ajax from "remotes/ajax";
import Loading from "components/notifications/Loading";
import urls from "config/urls";
import { Box, Dialog, Typography, Grid } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

class QuoteHistory extends Component {
    static propTypes = {
        quote: PropTypes.object.isRequired,
        quoteError: PropTypes.string,
        onViewQuoteHistory: PropTypes.func.isRequired,
        ˇ: PropTypes.func.isRequired,
        onQuoteFetchFail: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            errors: {},
            isLoading: false,
            isModalOpen: true
        };
    }

    async componentDidMount() {
        this.fetchHistoryById();
    }

    async fetchHistoryById() {
        const id = this.props.match.params.id;
        if (id) {
            this.setState({
                isLoading: true,
            });
            ajax.doGetQuote(id)
                .then(data => this.props.onViewQuoteHistory(data))
                .catch(error => this.props.onQuoteFetchFail(error.statusText))
                .finally(() => this.setState({ isLoading: false }))
        }
    }

    render() {
        const { isModalOpen } = this.state;
        let quoteFormBody;
        if (this.props.quoteError) {
            quoteFormBody = this.renderQuoteError();
        }
        else if (!this.props.quote || !this.props.quote.id) {
            quoteFormBody = this.renderNoQuote();
        }
        else {
            quoteFormBody = this.renderHistory();
        }

        return (
            <EscClosable onCloseModal={this.onClose}>
                <Dialog open={isModalOpen} onClose={this.onClose}>
                    <Box p={2}>
                        <CloseIcon
                            className="modalClose"
                            onClick={this.onClose} />
                        <Box p={2}>
                            <Typography
                                gutterBottom
                                variant="h4">
                                {this.props.t("page.quoteHistory.heading", "Quote history")}
                            </Typography>
                            {quoteFormBody}
                        </Box>
                    </Box>
                </Dialog>
            </EscClosable>
        );
    }

    renderNoQuote() {
        return (
            <div className="row justify-content-center">
                <Loading />
            </div>
        );
    }

    renderQuoteError() {
        return (
            <div className="row justify-content-center">
                <span className="error-msg text-danger">{String(this.props.t("page.quoteHistory.errorFetchingQuoteMessage", "Error while fetching quote."))}</span>
            </div>
        );
    }

    renderHistory() {
        const { t } = this.props;
        return (
            <>
                <Box mt={2}>
                    <Typography
                        variant="h5">
                        {t("page.quoteHistory.shipmentsHeader", "Shipments")}
                    </Typography>
                </Box>
                <Box mt={2}>
                    {this.renderShipmentItem(t("common.routeLabel", "Route"), < Route params={this.props.quote} />)}
                    {this.renderShipmentItem(t("common.weightLabel", "Weight"), this.props.quote.total_weight + " kg" || "")}
                    {this.renderShipmentItem(t("common.ldmLabel", "LDM"), this.props.quote.total_ldm || "")}
                    {this.renderShipmentItem(t("common.pickupDateLabel", "Ready for collection"), this.displayDate(this.props.quote.pickup_date))}
                    {this.renderShipmentItem(t("common.deliveryDateLabel", "Desired delivery date"), this.displayDate(this.props.quote.delivery_date))}
                </Box>
                <Box mt={2}>
                    <Typography
                        variant="h4">
                        {t("page.quoteHistory.timelineHeader", "Timeline")}
                    </Typography>
                </Box>
                <Box mt={2}>
                    <QuoteHistoryEvents events={this.props.quote.events} />
                </Box>
            </>
        );
    }

    renderShipmentItem = (label, content) => {
        return (
            <Grid container spacing={2}>
                <Grid item sm={4}>
                    <Typography
                        variant="label">
                        {label}
                    </Typography>
                </Grid>
                <Grid
                    item
                    style={{ fontWeight: '600' }}
                >
                    {content}
                </Grid>
            </Grid>
        );
    }

    onClose = () => {
        this.setState({ isModalOpen: false })
        this.props.onCloseQuoteView();
        this.props.history.push(urls.COMPANY_QUOTES);
    }

    displayDate(date) {
        if (date && this.isDateValid(date)) {
            return moment(date).format(dateFormat);
        }
        return date;
    }

    isDateValid(date) {
        return !date || (moment(date).isValid && moment(date).isValid());
    }
}

function mapStateToProps(state) {
    return {
        quote: state.viewQuoteHistory.quote,
        quoteError: state.viewQuoteHistory.error,
    };
}

export default withRouter(connect(
    mapStateToProps,
    { onViewQuoteHistory, onCloseQuoteView, onQuoteFetchFail },
)(withTranslation()(QuoteHistory)));
