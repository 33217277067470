import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
    onSetBookingFilterClient, onSetBookingFilterLocationFrom, onSetBookingFilterLocationTo,
    onSetBookingFilterPickupStart, onSetBookingFilterPickupEnd,
    onSetBookingFilterGenericSearch
} from "redux/actions/bookings";
import DateRange from "components/form/DateRange";
import { Box, Paper, Grid, FormGroup, TextField, Typography } from '@material-ui/core';


class BookingSearch extends Component {
    static propTypes = {
        chosenLocationFrom: PropTypes.string,
        chosenLocationTo: PropTypes.string,
        chosenClient: PropTypes.string,
        chosenPickupStart: PropTypes.object,
        chosenPickupEnd: PropTypes.object,
        chosenGenericSearch: PropTypes.string,
    };

    render() {
        const { t } = this.props;
        return (
            <Paper elevation={3}>
                <Box p={4}>
                    <Typography
                        gutterBottom
                        component="label"
                        style={{
                            fontWeight: '500',
                            fontSize: '1.4rem'
                        }}
                    >{
                            t("common.statusLabel", "Filter booking list")}
                    </Typography>
                    <Grid container spacing={1}>
                        <Grid item sm={2}>
                            <FormGroup>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    label={t("common.fromLabel", "From")}
                                    type="text"
                                    name="filter-from"
                                    value={this.props.chosenLocationFrom}
                                    onChange={(event) => this.props.onSetBookingFilterLocationFrom(event.target.value)}
                                />
                            </FormGroup>
                        </Grid>
                        <Grid item sm={2}>
                            <FormGroup>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    label={t("common.toLabel", "To")}
                                    type="text"
                                    name="filter-to"
                                    value={this.props.chosenLocationTo}
                                    onChange={(event) => this.props.onSetBookingFilterLocationTo(event.target.value)}
                                />
                            </FormGroup>
                        </Grid>
                        <Grid item sm={2}>
                            <FormGroup>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    label={t("common.clientLabel", "Client")}
                                    type="text"
                                    name="filter-client"
                                    value={this.props.chosenClient}
                                    onChange={(event) => this.props.onSetBookingFilterClient(event.target.value)}
                                />
                            </FormGroup>
                        </Grid>
                        <Grid item sm={2}>
                            <FormGroup>
                                <DateRange
                                    startDate={this.props.chosenPickupStart}
                                    endDate={this.props.chosenPickupEnd}
                                    onStartDateChange={this.props.onSetBookingFilterPickupStart}
                                    onEndDateChange={this.props.onSetBookingFilterPickupEnd}
                                />
                            </FormGroup>
                        </Grid>
                        <Grid item sm={4}>
                            <FormGroup>
                                <TextField
                                    variant="outlined"
                                    label={t("common.searchLabel", "Search")}
                                    type="text"
                                    name="filter-search"
                                    value={this.props.chosenGenericSearch}
                                    onChange={(event) => this.props.onSetBookingFilterGenericSearch(event.target.value)}
                                />
                            </FormGroup>
                        </Grid>
                    </Grid>
                </Box>
            </Paper>
        );
    }
}

function mapStateToProps(state) {
    return {
        chosenClient: state.bookingSearch.chosenClient,
        chosenLocationFrom: state.bookingSearch.chosenLocationFrom,
        chosenLocationTo: state.bookingSearch.chosenLocationTo,
        chosenPickupStart: state.bookingSearch.chosenPickupStart,
        chosenPickupEnd: state.bookingSearch.chosenPickupEnd,
        chosenGenericSearch: state.bookingSearch.chosenGenericSearch,
    };
}

export default connect(
    mapStateToProps,
    {
        onSetBookingFilterClient, onSetBookingFilterLocationFrom, onSetBookingFilterLocationTo,
        onSetBookingFilterPickupStart, onSetBookingFilterPickupEnd, onSetBookingFilterGenericSearch
    },
)(withTranslation()(BookingSearch));
