import React, { Fragment } from "react";
import get from "lodash/get";

import { flattenNestedObjectArray, filterEnabledRoutes, handleDynamicAsBoolean } from "util/index";

const sourceToValidComponentChildren = source => {
    switch (typeof source) {
        case "function": return source();
        case "object": {
            if (Array.isArray(source)) {
                const sourceArray = source.map(s => sourceToValidComponentChildren(source));
                return <Fragment>
                    {sourceArray}
                </Fragment>
            } else {
                return source;
            }
        }
        case "string": return source;
        default: return source;
    }
}

export default function mapRoutesToLinks(routes) {
    const enabledRoutes = filterEnabledRoutes(routes);
    const flattenedRoutes = flattenNestedObjectArray(enabledRoutes, "routes");
    return flattenedRoutes
        .filter(route => {
            const { disabled, showSidebar } = route;
            const show = !handleDynamicAsBoolean(disabled) && handleDynamicAsBoolean(showSidebar);
            return show;
        })
        .sort((routeA, routeB) => get(routeA, "sortIndex", 0) - get(routeB, "sortIndex", 0))
        .map(route => {
            const { path, icon, label, labelComponent, linkLabel, showSidebar } = route;

            const textSource = linkLabel || labelComponent || label;
            return {
                to: Array.isArray(path) ? path[0] : path,
                icon: icon || "",
                text: sourceToValidComponentChildren(textSource),
                show: handleDynamicAsBoolean(showSidebar),
            };
        })
}
