export function getTotalPricesPerCurrencyText(prices) {
    const priceRows = prices || [];

    const currentTotals = {};
    for (const row of priceRows) {
        const currency = row.currency;
        const price = row.price ? Number(row.price) : 0;
        const currentCurrencyTotal = currentTotals[currency] || 0;
        currentTotals[currency] = currentCurrencyTotal + price;
    }

    const totalPricesPerCurrency = Object.keys(currentTotals)
        .sort()
        .map(key => ({ currency: key, price: currentTotals[key] }))
        .filter(({ price }) => price !== 0)
        .map(({ currency, price }) => `${price} ${currency}`)
        .join(", ");
    return totalPricesPerCurrency;
}
