import React from "react";
import { Trans } from "react-i18next";

const customerUserRoles = {
    ADMIN: "ADMIN",
    REGULAR_USER: "REGULAR_USER",
}

const customerUserRolesTranslations = {
    ADMIN: <Trans i18nKey="enums.customeruserRoles.admin">Admin</Trans>,
    REGULAR_USER: <Trans i18nKey="enums.customeruserRoles.regularUser">Regular user</Trans>
};

const getCustomerUserRoleTranslated = role => {
    const translation = customerUserRolesTranslations[role];
    if (!translation) {
        return role;
    } else if (typeof translation === "function") {
        return translation();
    } else {
        return translation;
    }
}

export { customerUserRoles, customerUserRolesTranslations, getCustomerUserRoleTranslated };
export default customerUserRoles;
