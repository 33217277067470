import React from "react";
import { toast } from "react-toastify";
import toastAlerts from "util/toastAlerts";

export default function withLocalToasts(WrappedComponent) {
    return class extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                toastIds: []
            }
            this.addLocalToast = this.addLocalToast.bind(this);
            this.dismissLocalToasts = this.dismissLocalToasts.bind(this);
        }

        componentWillUnmount() {
            this.dismissLocalToasts();
        }

        dismissLocalToasts() {
            for (const id of this.state.toastIds) {
                toast.dismiss(id);
            }
        }

        addLocalToast(id) {
            this.setState((prevState) => {
                return {
                    toastIds: [...prevState.toastIds, id],
                };
            });
        }

        render() {
            return <WrappedComponent addLocalToast={this.addLocalToast} dismissLocalToasts={this.dismissLocalToasts} toastAlerts={toastAlerts} {...this.props} />;
        }
    };
}
