import React from "react";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import get from "lodash/get";

import ajax from "remotes/ajax";
import { getServerErrorMessage } from "util/Errors";

import GenericCardForm from "components/form/GenericCardForm";
import LoadingButton from "components/form/LoadingButton";
import { withLocalToasts } from "components/hoc";
import { Grid, Box, Select, MenuItem } from '@material-ui/core';


const getInitialFormState = () => ({
    manager: null,
});

class CustomerManagerForm extends React.Component {
    static propTypes = {
        customerId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
    }

    state = {
        // new customer data
        ...getInitialFormState(),
        options: [],
        // other
        loading: false,
        optionsLoading: false,
        validationErrors: {}
    };

    FORM_ID = "customer-manager-form";

    componentDidMount() {
        this.getCustomerManager();
        this.getManagerOptions();
    }

    render() {
        return (
            <div
                className="customer-manager-form__wrapper"
                style={{ width: '50%' }}
            >
                <GenericCardForm
                    title={this.props.t("customer.managerForm.title", "Key account manager")}
                >
                    {this.renderContent()}
                </GenericCardForm>
            </div>
        );
    }

    renderContent() {
        return this.renderForm();
    }

    renderForm() {
        const { loading, manager } = this.state;
        return (
            <form
                onSubmit={this.onSubmit}
                id={this.FORM_ID}
                className="customer-manager-form__form text-left"
            >
                <Grid container>
                    <Select
                        variant="outlined"
                        fullWidth
                        value={manager || ""}
                        onChange={item => this.setState({ manager: item.target.value })}
                    >
                        {this.renderAccountManagerOptions()}
                    </Select>
                </Grid>
                <Box mt={2}>
                    <LoadingButton
                        size="large"
                        className="submitButton"
                        style={{ width: '8rem' }}
                        form={this.FORM_ID}
                        loading={loading}
                    >
                        {this.props.t("customer.managerForm.submitButton", "Save manager")}
                    </LoadingButton>
                </Box>
            </form>
        );
    }

    onValidate = () => {
        return true;
    }

    onSubmit = (e, id) => {
        e.preventDefault();
        this.props.dismissLocalToasts();
        if (this.onValidate()) {
            this.updateCustomerManager(id);
        }

    };
    renderAccountManagerOptions = () => {
        return this.state.options.map((option, index) => {
            return (
                <MenuItem
                    label={option.name}
                    value={option.id}
                    key={index}
                    name={option.label}
                >{option.name}</MenuItem>
            )
        });
    };

    getManagerOptions() {
        const { t, toastAlerts, addLocalToast } = this.props;
        this.setState({ optionsLoading: true });
        ajax.doGetCustomerManagerOptionList()
            .then(data => {
                this.setState({ options: data })
            })
            .catch(error => {
                const errorMessage = getServerErrorMessage(error);
                const errorTitle = t("customer.managerForm.optionsErrorTitle", "Error when loading customer manager options.");
                addLocalToast(
                    toastAlerts.createAlert(errorMessage, {
                        type: toastAlerts.ALERT_TYPE.ERROR,
                        title: errorTitle,
                        autoClose: false
                    })
                );
            })
            .finally(() => this.setState({ optionsLoading: false }))

    }

    getCustomerManager() {
        const { customerId, t, toastAlerts, addLocalToast } = this.props;
        this.setState({ loading: true });
        ajax.doGetCustomerDetails(customerId)
            .then(data => {
                this.setState({
                    manager: data.manager
                });
            })
            .catch(error => {
                const errorMessage = getServerErrorMessage(error);
                const errorTitle = t("customer.managerForm.getManagerErrorMessage", "Error loading customer manager.");
                const alertId = toastAlerts.createAlert(errorMessage, {
                    type: toastAlerts.ALERT_TYPE.ERROR,
                    title: errorTitle,
                    autoClose: false
                });
                addLocalToast(alertId);

            })
            .finally(() => this.setState({ loading: false }))
    }

    updateCustomerManager() {
        const { customerId, t, toastAlerts, addLocalToast } = this.props;
        const { manager } = this.state;
        const payload = { manager };
        this.setState({ loading: true });
        ajax.doPatchCustomerDetails(customerId, payload)
            .then(data => {
                this.setState({
                    manager: data.manager
                });
                const successMessage = t("customer.managerForm.updateSuccessMessage", "Customer manager has been changed.");
                const alertId = toastAlerts.createAlert(successMessage, {
                    type: toastAlerts.ALERT_TYPE.SUCCESS,
                    autoClose: true
                });
                addLocalToast(alertId);
            })
            .catch(error => {
                const payload = get(error, "response.data", null);
                if (typeof payload === "object" && payload !== null) {
                    this.setState({
                        validationErrors: payload
                    });
                } else {
                    const errorMessage = getServerErrorMessage(error);
                    const errorTitle = t("customer.managerForm.errorMessage", "Error occured during customer manager update.");
                    const alertId = toastAlerts.createAlert(errorMessage, {
                        type: toastAlerts.ALERT_TYPE.ERROR,
                        title: errorTitle,
                        autoClose: false
                    });
                    addLocalToast(alertId);
                }
            })
            .finally(() => this.setState({ loading: false }))
    }
}

export default withRouter(withTranslation()(withLocalToasts(CustomerManagerForm)));
