import React, { useState } from 'react';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import { List, Toolbar, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import AssignmentIcon from '@material-ui/icons/Assignment';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import PersonIcon from '@material-ui/icons/Person';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import GroupIcon from '@material-ui/icons/Group';
import PropTypes from "prop-types";
import TopNavbar from "components/Navbar/Navbar";
import BrandLogoText from "components/Misc/BrandLogoText";
import mapRoutesToLinks from "util/mapRoutesToLinks";
import { NavLink } from "react-router-dom";
import { useStyles } from '../../assets/stylesMUI/sidebarStyles';

export default function SideBar(props) {
    const { routes, sidebarOpen, onNavLinkClick } = props;
    const classes = useStyles(props);
    const [activeRoute, setActiveRoute] = useState("");

    const propTypes = {
        name: PropTypes.string,
        routes: PropTypes.array,
    };
    const getRouteLinks = () => {
        return mapRoutesToLinks(routes);
    }

    const getIcon = (icon) => {
        switch (icon) {
            case 'assignment':
                return <AssignmentIcon />
            case 'calendar':
                return <CalendarTodayIcon />
            case 'personAdd':
                return <PersonAddIcon />
            case 'person':
                return <PersonIcon />
            case 'newOffer':
                return <AddCircleIcon />
            case 'group':
                return <GroupIcon />
            default:
                return <i className={icon}></i>
        }
    }

    const renderRouteLinks = () => {
        const links = getRouteLinks();
        const menuItems = links.map((link, i) => (
            < ListItem className={classes.listItem}
                button
                key={`${link.to}-${i}`}
            >
                <NavLink
                    onClick={() => setActiveRoute(link.to)}
                    className={clsx(classes.navLink, (activeRoute === link.to) && classes.navLinkActive)}
                    to={link.to}
                >
                    <ListItemIcon
                        className={clsx(classes.icon, (activeRoute === link.to) && classes.activeLinkIcon)}
                    >
                        {getIcon(link.icon)}
                    </ListItemIcon>
                    <ListItemText
                        primary={link.text}
                        classes={{ primary: classes.listItemText }} />
                </NavLink>
            </ListItem >
        ));


        return (
            <>
                {menuItems}
            </>
        );
    }

    return (
        <div className={classes.root}>
            <div className={clsx(classes.appBar, {
                [classes.appBarShift]: sidebarOpen,
            })}
            >
                <Toolbar className={classes.toolBar}>
                    <TopNavbar
                        routes={propTypes.routes}
                    />
                </Toolbar>
            </div>
            <Drawer
                variant="permanent"
                className={clsx("sidebar", classes.drawer, {
                    [classes.drawerOpen]: sidebarOpen,
                    [classes.drawerClose]: !sidebarOpen,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: sidebarOpen,
                        [classes.drawerClose]: !sidebarOpen,
                    }),
                }}
                onMouseEnter={onNavLinkClick}
                onMouseLeave={onNavLinkClick}
            >
                <div className={classes.toolbar}>
                    <BrandLogoText
                        showText={sidebarOpen} />
                </div>
                <List className={classes.listItems}>
                    {renderRouteLinks()}
                </List>
                {/* <div className={classes.toggleIconContainer}>
                    <IconButton onClick={onNavLinkClick} className={classes.toggleIcon}>
                        {sidebarOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </div> */}
            </Drawer>
        </div >
    );
}
