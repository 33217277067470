import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { loadType, transportTypeLoadTypes } from "maps/classifiers";
import { Box, Grid, FormControl, FormLabel, FormControlLabel, RadioGroup, Radio } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { styles } from '../../assets/stylesMUI/transportCheckboxesStyles';
import clsx from 'clsx';

const stylesz = styles;

class LoadType extends Component {
    static propTypes = {
        value: PropTypes.string,
        transportType: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
        readOnly: PropTypes.bool,
        classes: PropTypes.object.isRequired,
        checkboxHelperText: PropTypes.func,
        selectedValue: PropTypes.string
    };

    componentDidUpdate(prevProps) {
        if (prevProps.transportType !== this.props.transportType && this.props.value !== null) {
            const currentOptions = this.getOptions();
            if (!currentOptions.includes(this.props.value)) {
                this.props.onChange(null);
            }
        }
    }

    render() {
        const { t, classes } = this.props;
        const loadTypeOptions = this.getOptions();

        if (loadTypeOptions.length === 0) {
            return <></>;
        }

        return (
            <Box mt={2}>
                <FormControl
                    component="fieldset"
                    className="choose-transport-type"
                >
                    <FormLabel
                        component="p"
                        htmlFor="load_type"
                        className={classes.formLabel}>
                        {t("form.loadType.loadTypeLabel", "Choose load Type")}
                    </FormLabel>
                    <RadioGroup row aria-label="load_type" name="load_type">
                        <Grid container spacing={3}>
                            {loadTypeOptions.includes(loadType.LTL) &&
                                <Grid item xs={6} sm={3}>
                                    <div className={clsx(classes.checkBoxContainer,
                                        (this.props.selectedValue === loadType.LTL) && classes.checkBoxContainerChecked)}>
                                        <div>
                                            <FormControlLabel
                                                className={classes.label}
                                                value={loadType.LTL}
                                                control={<Radio color="default" className={classes.radio} />}
                                                name="load_type"
                                                label={this.getHelperText(loadType.LTL, "Less Than Truckload (LTL)")}
                                                onChange={(e) => this.props.onChange(e)}
                                                disabled={this.props.readOnly}
                                            />
                                        </div>
                                    </div>
                                </Grid>
                            }
                            {loadTypeOptions.includes(loadType.FTL) &&
                                <Grid item xs={6} sm={3}>
                                    <div className={clsx(classes.checkBoxContainer,
                                        (this.props.selectedValue === loadType.FTL) && classes.checkBoxContainerChecked)}>
                                        <div>
                                            <FormControlLabel
                                                className={classes.label}
                                                value={loadType.FTL}
                                                control={<Radio color="default" className={classes.radio} />}
                                                name="load_type"
                                                label={this.getHelperText(loadType.FTL, "Full Truckload (FTL)")}
                                                onChange={(e) => this.props.onChange(e)}
                                                disabled={this.props.readOnly}
                                            />
                                        </div>
                                    </div>
                                </Grid>
                            }
                            {loadTypeOptions.includes(loadType.LCL) &&
                                <Grid item xs={6} sm={3}>
                                    <div className={clsx(classes.checkBoxContainer,
                                        (this.props.selectedValue === loadType.LCL) && classes.checkBoxContainerChecked)}>
                                        <div>
                                            <FormControlLabel
                                                className={classes.label}
                                                value={loadType.LCL}
                                                control={<Radio color="default" className={classes.radio} />}
                                                name="load_type"
                                                label={this.getHelperText(loadType.LCL, "Less Than Container Load (LCL)")}
                                                onChange={(e) => this.props.onChange(e)}
                                                disabled={this.props.readOnly}
                                            />
                                        </div>
                                    </div>
                                </Grid>
                            }
                            {loadTypeOptions.includes(loadType.FCL) &&
                                <Grid item xs={6} sm={3}>
                                    <div className={clsx(classes.checkBoxContainer,
                                        (this.props.selectedValue === loadType.FCL) && classes.checkBoxContainerChecked)}>
                                        <div>
                                            <FormControlLabel
                                                className={classes.label}
                                                name="load_type"
                                                control={<Radio color="default" className={classes.radio} />}
                                                value={loadType.FCL}
                                                label={this.getHelperText(loadType.FCL, "Full Container Load (FCL)")}
                                                onChange={(e) => this.props.onChange(e)}
                                                disabled={this.props.readOnly}
                                            />
                                        </div>
                                    </div>
                                </Grid>
                            }
                        </Grid>
                    </RadioGroup>
                </FormControl>
                {this.props.children}
            </Box>
        );
    }

    getHelperText = (loadType, labelText) => {
        const { t, classes } = this.props;
        const translationKey = "form.loadType." + loadType;
        if (this.props.selectedValue === loadType) {
            return (
                <>
                <span>{t(translationKey, labelText)}</span>
                <p className={clsx(classes.checkBoxHelperText, classes.checkedText)}
                >
                    {t("form.checkboxHelperText.checked", "Checked")}
                </p >
                </>
            )
        } else {
            return (
                <>
                <span>{t(translationKey, labelText)}</span>
                <p className={classes.checkBoxHelperText}>
                    {t("form.checkboxHelperText.choose", "Choose")}
                </p>
                </>
            )
        }
    }

    getOptions = () => {
        return transportTypeLoadTypes[this.props.transportType] || [];
    }
}

export default withTranslation()(withStyles(stylesz)(LoadType));
