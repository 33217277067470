import React, { Component, Fragment } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import classNames from "classnames";
import ValidationMessage from "components/form/ValidationMessage";
import { transportType } from "maps/classifiers";
import { FormControl, Select, InputLabel, MenuItem } from "@material-ui/core";

export const incotermOptions = [
    { value: "EXW", label: "Ex Works (EXW)" },
    { value: "FCA", label: "Free Carrier (FCA)" },
    { value: "CPT", label: "Carriage Paid To (CPT)" },
    { value: "CIP", label: "Carriage and Insurance Paid to (CIP)" },
    { value: "DPU", label: "Delivered At Place Unloaded (DPU)" },
    { value: "DAP", label: "Delivered At Place (DAP)" },
    { value: "DDP", label: "Delivered Duty Paid (DDP)" },
    { value: "FAS", label: "Free Alongside Ship (FAS)" }, // sea only
    { value: "FOB", label: "Free on Board (FOB)" }, // sea only
    { value: "CFR", label: "Cost and Freight (CFR)" }, // sea only
    { value: "CIF", label: "Cost, Insurance & Freight (CIF)" }, // sea only
];

export const listOfSeaTransportSpecificIncoterms = ["FAS", "FOB", "CFR", "CIF"];

class Incoterms extends Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
        errors: PropTypes.object.isRequired,
        submitDone: PropTypes.bool.isRequired,
        updateField: PropTypes.func.isRequired,
        communicationCountryCode: PropTypes.string.isRequired,
        isRequired: PropTypes.bool,
        isReadOnly: PropTypes.bool,
    };

    componentDidUpdate(prevProps) {
        this.updateIncoterms(prevProps.data);
    }

    render() {
        const { t } = this.props;
        return (
            <Fragment>
                <FormControl
                    fullWidth
                    disabled={this.props.isReadOnly}
                >
                    <InputLabel
                        id="incoterms"
                        style={{marginLeft: '1rem'}}
                    >
                        {t("form.incoterms.incotermsLabel", "Incoterms")}
                    </InputLabel>
                    <Select
                        labelId="incoterms"
                        label={t("form.incoterms.incotermsLabel", "Incoterms")}
                        variant="outlined"
                        value={this.props.data.incoterms || ""}
                        onChange={(option) => this.props.updateField("incoterms", option.target.value)}
                        className={classNames({ "is-invalid-custom": this.props.errors.from_country_code })}
                        name="incoterms"
                    >
                        {this.renderIncotermsDropdownOptopns()}
                    </Select>
                    <ValidationMessage
                        message={this.props.errors.incoterms}
                        isVisible={this.props.submitDone && this.props.errors.incoterms}
                    />
                </FormControl>
            </Fragment>
        );
    }

    getIncotermsOptions = () => {
        if (this.props.data && this.props.data.transport_type === transportType.SEA) {
            return incotermOptions;
        }

        return incotermOptions.filter((option) => !listOfSeaTransportSpecificIncoterms.includes(option.value));
    };

    renderIncotermsDropdownOptopns = () => {
        let incotermOptions = this.getIncotermsOptions()

        return incotermOptions.map((option, index) => {
            return (
                <MenuItem
                    label={option.label}
                    value={option.value}
                    key={index}
                    name={option.label}
                >{option.label}
                </MenuItem>
            );
        });
    };

    updateIncoterms = (prevData = null) => {
        const data = this.props.data;
        const communicationCountryCode = this.props.communicationCountryCode;
        if (!data || !communicationCountryCode) {
            return;
        }

        if (prevData) {
            const prevFromCountryCode = prevData.from_country_code;
            const fromCountryCode = data.from_country_code;
            // Don't update if fields haven't changed when previous data has been given but relevant fields haven't changed.
            if (prevFromCountryCode === fromCountryCode) {
                return;
            }
            // Don't update if hasn't changed from no selection or from/to communication country
            if (![null, "", communicationCountryCode].includes(prevFromCountryCode) && fromCountryCode !== communicationCountryCode) {
                return;
            }
        }

        let newIncoterms = "DAP";
        if (data.from_country_code !== communicationCountryCode) {
            newIncoterms = "FCA";
        }

        if (newIncoterms !== data.incoterms) {
            this.props.updateField("incoterms", newIncoterms);
        }
    };
}

export default withTranslation()(Incoterms);
