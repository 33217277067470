import * as actionType from "redux/actions/actionTypes";

export const bookings = (state = null, action) => {
    switch (action.type) {
        case actionType.BOOKINGS_RECEIVED:
            return action.bookings;

        case actionType.BOOKINGS_FETCH_FAIL:
            return null;

        case actionType.SAVE_BOOKING_UPDATE:
            if (state) {
                return state.map((booking) => (booking.id === action.booking.id ? action.booking : booking));
            } else {
                return state;
            }
        default:
            return state;
    }
};

export const editingBooking = (state = { booking: {}, readOnly: true }, action) => {
    switch (action.type) {
        case actionType.PREFILL_BOOKING_FORM:
        case actionType.EDIT_BOOKING:
            return {
                isOpen: true,
                booking: action.booking,
                readOnly: false,
            };
        case actionType.VIEW_BOOKING:
            return {
                isOpen: true,
                booking: action.booking,
                readOnly: true,
            };
        case actionType.UPDATE_BOOKING_FORM_FIELD:
            return {
                ...state,
                booking: {
                    ...state.booking,
                    [action.key]: action.value,
                },
            };
        case actionType.SAVE_BOOKING_UPDATE:
        case actionType.CLOSE_BOOKING_FORM:
            return {
                isOpen: false,
                booking: {},
                readOnly: false,
            };
        case actionType.BOOKING_FETCH_FAIL:
            return {
                ...state,
                error: action.error,
            };

        default:
            return state;
    }
};
