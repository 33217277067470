import _ from "lodash";
import { withTranslation } from "react-i18next";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { quoteStatusColors } from "maps/colorCodes";
import {
    onSetFilterStatus, onSetFilterClient, onSetFilterLocationFrom,
    onSetFilterLocationTo, onSetFilterPickupStart, onSetFilterPickupEnd,
    onSetFilterGenericSearch,
} from "redux/actions/quotes";
import { quoteStatus } from "maps/classifiers";
import { defaultQuoteStatusFilter } from "maps/filters";
import DateRange from "components/form/DateRange";
import clsx from 'clsx';
import { withStyles } from '@material-ui/styles';
import { styles } from '../../../assets/stylesMUI/shipmentRowStyles';
import { Box, Paper, Grid, Typography, FormGroup, Checkbox, FormControlLabel, TextField } from '@material-ui/core';

const stylesz = styles;

class Search extends Component {
    static propTypes = {
        isCustomerMode: PropTypes.bool,
        chosenStatuses: PropTypes.object.isRequired,
        onSetFilterStatus: PropTypes.func.isRequired,
        chosenLocationFrom: PropTypes.string,
        chosenLocationTo: PropTypes.string,
        chosenClient: PropTypes.string,
        chosenPickupStart: PropTypes.object,
        chosenPickupEnd: PropTypes.object,
        chosenGenericSearch: PropTypes.string,
        classes: PropTypes.object.isRequired,
    };

    render() {
        const { t, classes } = this.props;
        return (
            <Paper elevation={3} className="search-filter">
                <Box p={4}>
                    <Typography
                        gutterBottom
                        component="label"
                        style={{
                            fontWeight: '500',
                            fontSize: '1.4rem'
                        }}
                    >{
                            t("common.statusLabel", "Filter quote list")}
                    </Typography>
                    <Box pt={2} pb={2}>
                        <Grid container spacing={1}>
                            {_.keys(defaultQuoteStatusFilter).map((value) => {
                                return (
                                  <Grid item xs={2} sm={2} key={value}>
                                    <div style={{ height: "5rem" }}>
                                      <div
                                        className={clsx(
                                          classes.checkBoxContainer,
                                          classes.containerNarrow,
                                          !!this.props.chosenStatuses[value] &&
                                            this.getClassForCheckboxColor(quoteStatusColors[value])
                                        )}
                                      >
                                        <div>
                                          <FormControlLabel
                                            className={clsx(
                                              "quote-list-filter-checkbox-label",
                                              classes.checkboxLabel
                                            )}
                                            key={value}
                                            control={
                                              <Checkbox
                                                checked={
                                                  !!this.props.chosenStatuses[
                                                    value
                                                  ]
                                                }
                                                className={clsx(classes.checkbox, 
                                                    !!this.props.chosenStatuses[value] && "quote-status-checked", quoteStatusColors[value] )}
                                                onChange={(event) =>
                                                  this.props.onSetFilterStatus(
                                                    value,
                                                    event.target.checked
                                                  )
                                                }
                                                key={value}
                                                color="secondary"
                                              />
                                            }
                                            label={
                                              <span
                                                className={classes.labelText}
                                              >
                                                {quoteStatus[value]}
                                              </span>
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </Grid>
                                );
                            })}
                        </Grid>
                    </Box>
                    <Box pt={2}>
                        <Grid container spacing={1}>
                            <Grid item sm={2}>
                                <FormGroup>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        label={t("common.fromLabel", "From")}
                                        type="text"
                                        name="filter-from"
                                        value={this.props.chosenLocationFrom}
                                        onChange={(event) => this.props.onSetFilterLocationFrom(event.target.value)}
                                    />
                                </FormGroup>
                            </Grid>
                            <Grid item sm={2}>
                                <FormGroup>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        label={t("common.toLabel", "To")}
                                        type="text"
                                        name="filter-to"
                                        value={this.props.chosenLocationTo}
                                        onChange={(event) => this.props.onSetFilterLocationTo(event.target.value)}
                                    />
                                </FormGroup>
                            </Grid>
                            {!this.props.isCustomerMode &&
                                <Grid item sm={2}>
                                    <FormGroup>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            label={t("common.clientLabel", "Client")}
                                            type="text"
                                            name="filter-client"
                                            value={this.props.chosenClient}
                                            onChange={(event) => this.props.onSetFilterClient(event.target.value)}
                                        />
                                    </FormGroup>
                                </Grid>
                            }
                            <Grid item sm={2}>
                                <FormGroup>
                                    <DateRange
                                        startDate={this.props.chosenPickupStart}
                                        endDate={this.props.chosenPickupEnd}
                                        onStartDateChange={this.props.onSetFilterPickupStart}
                                        onEndDateChange={this.props.onSetFilterPickupEnd}
                                    />
                                </FormGroup>
                            </Grid>
                            <Grid item sm={4}>
                                <FormGroup>
                                    <TextField
                                        variant="outlined"
                                        label={t("common.searchLabel", "Search")}
                                        type="text"
                                        name="filter-search"
                                        value={this.props.chosenGenericSearch}
                                        onChange={(event) => this.props.onSetFilterGenericSearch(event.target.value)}
                                    />
                                </FormGroup>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Paper>
        );
    }

    getClassForCheckboxColor = (color) => {
        const { classes } = this.props;
        switch (color) {
            case 'marker-green':
                return classes.markerGreen
            case 'marker-red':
                return classes.markerRed
            case 'marker-blue':
                return classes.markerBlue
            case 'marker-yellow':
                return classes.markerYellow
            case 'marker-purple':
                return classes.markerPurple
            case 'grey':
                return classes.markerGray
            default:
                return ''
        }
    }

}



function mapStateToProps(state) {
    return {
        chosenStatuses: state.quoteSearch.chosenStatuses,
        chosenClient: state.quoteSearch.chosenClient,
        chosenLocationFrom: state.quoteSearch.chosenLocationFrom,
        chosenLocationTo: state.quoteSearch.chosenLocationTo,
        chosenPickupStart: state.quoteSearch.chosenPickupStart,
        chosenPickupEnd: state.quoteSearch.chosenPickupEnd,
        chosenGenericSearch: state.quoteSearch.chosenGenericSearch,
    };
}



export default connect(
    mapStateToProps,
    {
        onSetFilterStatus, onSetFilterClient, onSetFilterLocationFrom, onSetFilterLocationTo,
        onSetFilterPickupStart, onSetFilterPickupEnd, onSetFilterGenericSearch,
    },
)(withTranslation()(withStyles(stylesz)(Search)));
