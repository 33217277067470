// Everything about react router (subroutes and so on... ) https://reacttraining.com/react-router/web/example/route-config
import React from "react";
import { Translation } from "react-i18next";

import urls from "config/urls";
import commonRoutes from "./common";
import { isPublicMode } from "util/Access";

import RouteWithSubRoutes from "components/Routing/RouteWithSubRoutes";
import LoginView from "views/public/Login";
import ForgotPasswordView from "views/public/ForgotPassword";
import ChangePasswordView from "views/public/ChangePassword";
import QuoteFormPage from "components/page/quotes/QuoteFormPage";
import BookingFormPage from "components/page/bookings/BookingFormPage";
import CreateCustomerPage from "components/page/user/CreateCustomerPage";
import ActivateUserPage from "components/page/user/ActivateUserPage";

export const publicSpecificRoutes = [
    {
        path: urls.DOMAIN,
        component: RouteWithSubRoutes,
        label: "",
        disabled: () => !isPublicMode(),
        routes: [
            {
                path: [urls.DOMAIN, urls.NEW_QUOTE, urls.COMPANY_QUOTES],
                exact: true,
                labelComponent: () => <Translation>{t => t("routes.public.quote", "Quote")}</Translation>,
                component: QuoteFormPage,
                showSidebar: true,
                linkLabel: () => <Translation>{t => t("sidebar.public.quote", "Quote")}</Translation>,
                icon: 'assignment'
            },
            {
                path: [urls.NEW_BOOKING, urls.COMPANY_BOOKINGS],
                exact: true,
                labelComponent: () => <Translation>{t => t("routes.public.booking", "Booking")}</Translation>,
                component: BookingFormPage,
                showSidebar: true,
                linkLabel: () => <Translation>{t => t("sidebar.public.booking", "Booking")}</Translation>,
                icon: "calendar"
            },
            {
                exact: true,
                path: urls.FORGOT_PASSWORD,
                labelComponent: () => <Translation>{t => t("routes.public.forgotPassword", "Forgot password")}</Translation>,
                component: ForgotPasswordView,
            },
            {
                path: urls.CHANGE_PASSWORD,
                labelComponent: () => <Translation>{t => t("routes.public.changePassword", "Change password")}</Translation>,
                component: ChangePasswordView,
            },
            {
                path: urls.NEW_CUSTOMER,
                labelComponent: () => <Translation>{t => t("routes.public.newCustomer", "Sign up")}</Translation>,
                component: CreateCustomerPage,
                showSidebar: true,
                linkLabel: () => <Translation>{t => t("sidebar.public.newCustomer", "Sign up")}</Translation>,
                icon: "personAdd"
            },
            {
                path: urls.ACTIVATE_USER,
                labelComponent: () => <Translation>{t => t("routes.public.activateUser", "Activate user")}</Translation>,
                component: ActivateUserPage,
            },
            {
                path: [urls.LOGIN, urls.ANY],
                labelComponent: () => <Translation>{t => t("routes.public.logIn", "Log in")}</Translation>,
                component: LoginView,
                showSidebar: true,
                linkLabel: () => <Translation>{t => t("sidebar.public.login", "Log in")}</Translation>,
                icon: "person"
            },
        ]
    }
];

export default [...commonRoutes, ...publicSpecificRoutes];
