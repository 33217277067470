import moment from "moment";

export const dateFormat = "DD.MM.YYYY";
export const dateFormatWoYear = "DD.MM THH:mm";
export const timeFormat = "HH:mm";
export const dateTimeFormat = "YYYY-MM-DDTHH:mm:ssZ";

// Backend expectation: YYYY-MM-DDThh:mm[:ss[.uuuuuu]][+HH:MM|-HH:MM|Z].

function getMomentEndOfYesterday() {
    return moment().subtract(1, "day").endOf("day");
}

export function isDateAfterYesterday(date) {
    date = moment(date);
    const yesterday = getMomentEndOfYesterday();
    return date.isAfter(yesterday);
}

export function isDateEqualOrAfterCustomDate(date, customDate) {
    const momentDate = moment(date);
    const momentCustomDate = moment(customDate);
    if (momentDate.isValid() && momentCustomDate.isValid()) {
        return momentDate.isSameOrAfter(customDate, "day");
    }
    return false;
}

export function isDateAfterYesterdayAndCustomDate(date, customDate) {
    return isDateAfterYesterday(date) &&
        (!customDate || isDateEqualOrAfterCustomDate(date, customDate));
}
