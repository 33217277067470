import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import cloneDeep from "lodash/cloneDeep";
import classNames from "classnames";
import _ from "lodash";
import ValidationMessage from "components/form/ValidationMessage";
import { getNewPrice } from "util/quotes";
import { getTotalPricesPerCurrencyText } from "util/offers";
import { Grid, FormGroup, TextField, IconButton, Box, Button, Typography, Select, MenuItem } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import AddIcon from '@material-ui/icons/Add';

class QuotePrices extends Component {
    static propTypes = {
        prices: PropTypes.array.isRequired,
        priceTypeOptions: PropTypes.array.isRequired,
        onChange: PropTypes.func.isRequired,
        validationErrors: PropTypes.object,
        readOnly: PropTypes.bool,
    };

    constructor(props) {
        super(props);
        this.state = {
            totalPricesPerCurrency: "",
        }
    }

    addPrice = () => {
        const newPrice = getNewPrice();
        this.props.onChange([...this.props.prices, newPrice]);
    }

    handleUpdatePrice = (event) => {
        let value = event.target.value;
        let idx = event.target.name;
        this.updatePrice(idx, "currency", value)
    }

    updatePrice = (idx, key, value) => {
        const prices = [...this.props.prices];
        if (prices[idx]) {
            if (prices[idx][key] === value) {
                return false;
            }

            prices[idx][key] = value;
            this.props.onChange(prices);
        }

        if (["price", "currency"].includes(key)) {
            this.updateTotalPrice();
        }

        return true;
    }

    updateTotalPrice() {
        const { prices } = this.props;
        this.setState({ totalPricesPerCurrency: getTotalPricesPerCurrencyText(prices) });
    }

    removePrice = (idx) => {
        const prices = [...this.props.prices];
        prices.splice(idx, 1);
        this.props.onChange(prices);
    }

    copyAndAddPrice = (idx) => {
        const prices = [...this.props.prices];
        const duplicate = cloneDeep(prices[idx]);
        prices.splice(idx + 1, 0, duplicate);
        this.props.onChange(prices);
    }

    handleChangePriceType = (event, child) => {
        let value = event.target.value;
        let idx = event.target.name;
        this.changePriceType(idx, value);
    }

    changePriceType(idx, value) {
        if (this.updatePrice(idx, "price_type", value)) {
            const selectedPriceTypeOption = this.props.priceTypeOptions.find(e => e.value === value);
            const newDescription = selectedPriceTypeOption ? selectedPriceTypeOption.description : "";
            this.updatePrice(idx, "description", newDescription);
        }
    }

    isRemovePriceDisabled() {
        return this.props.readOnly || !this.props.prices || this.props.prices.length < 2;
    }

    isInvalidFieldMessage = (field, price) => {
        if (!this.props.validationErrors) {
            return "";
        }

        const price_errors = this.props.validationErrors[price.id || price.temp_id];
        if (!price_errors) {
            return "";
        }
        return price_errors[field];
    }

    getCurrencyOptions() {
        return [
            { value: "EUR", label: "Euro (€)" },
            { value: "USD", label: "USD ($)" },
        ];
    }

    getPricesTotalPriceInfo = (prices) => {
        const rows = prices || [];
        return rows.map(row => ({ price: row.price ? Number(row.price) : 0, currency: row.currency }));
    }

    componentDidUpdate(prevProps) {
        const oldTotalPriceInfo = this.getPricesTotalPriceInfo(prevProps.prices);
        const currentTotalPriceInfo = this.getPricesTotalPriceInfo(this.props.prices);
        if (!_.isEqual(oldTotalPriceInfo, currentTotalPriceInfo)) {
            this.updateTotalPrice();
        }
    }

    componentDidMount() {
        const priceRowsLen = _.get(this.props, "prices.length", -1);
        if (priceRowsLen === -1) {
            return;
        }
        else if (priceRowsLen === 0) {
            this.addPrice();
        }
        else {
            this.updateTotalPrice();
        }
    }

    render() {
        const { t } = this.props;
        const prices = this.props.prices.sort((a, b) => a.id - b.id).map((val, idx) => {
            return (
                <div style={{ position: 'relative' }}>
                    <Grid container key={idx} spacing={2}>
                        <Grid item xs={6} sm={2}>
                            <FormGroup>
                                <Select
                                    name={idx}
                                    variant="outlined"
                                    onChange={this.handleChangePriceType}
                                    options={this.props.priceTypeOptions}
                                    value={this.props.prices[idx].price_type || ""}
                                    placeholder={t("form.prices.priceTypePlaceholder", "Price type")}
                                    className={
                                        classNames(
                                            { "is-invalid-custom": this.isInvalidFieldMessage("price_type", val) }
                                        )
                                    }
                                    disabled={this.props.readOnly}
                                >
                                    {this.renderPriceTypeOptions()}
                                </Select>
                                <ValidationMessage
                                    message={this.isInvalidFieldMessage("price_type", val)}
                                    isVisible={false && this.isInvalidFieldMessage("price_type", val)}
                                />
                            </FormGroup>
                        </Grid>
                        <Grid item xs={6} sm={2}>
                            <FormGroup>
                                <TextField
                                    variant="outlined"
                                    autoFocus
                                    onChange={(event) => this.updatePrice(idx, "price", event.target.value)}
                                    type="number"
                                    placeholder={t("form.prices.pricePlaceholder", "Price")}
                                    value={this.props.prices[idx].price || ""}
                                    min="0"
                                    step=".01"
                                    disabled={this.props.readOnly}
                                    required
                                    label={t("form.prices.pricePlaceholder", "Price")}
                                    error={true && this.isInvalidFieldMessage("price", val).length > 0}
                                    helperText={this.isInvalidFieldMessage("price", val)}
                                ></TextField>
                            </FormGroup>
                        </Grid>
                        <Grid item xs={6} sm={2}>
                            <FormGroup>
                                <Select
                                    name={idx}
                                    variant="outlined"
                                    onChange={this.handleUpdatePrice}
                                    options={this.props.priceTypeOptions}
                                    value={this.props.prices[idx].currency || ""}
                                    placeholder={t("form.prices.priceCurrencyPlaceholder", "Currency")}
                                    className={
                                        classNames(
                                            { "is-invalid-custom": this.isInvalidFieldMessage("currency", val) }
                                        )
                                    }
                                    disabled={this.props.readOnly}
                                >
                                    {this.renderCurrencyOptions()}
                                </Select>
                                <ValidationMessage
                                    message={this.isInvalidFieldMessage("currency", val)}
                                    isVisible={false && this.isInvalidFieldMessage("currency", val)}
                                />
                            </FormGroup>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            <FormGroup>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    onChange={(event) => this.updatePrice(idx, "description", event.target.value)}
                                    type="text"
                                    placeholder={t("form.prices.descriptionPlaceholder", "Description")}
                                    value={this.props.prices[idx].description || ""}
                                    disabled={this.props.readOnly}
                                    required
                                    label={t("form.prices.descriptionPlaceholder", "Description")}
                                    error={true && this.isInvalidFieldMessage("description", val).length > 0}
                                    helperText={this.isInvalidFieldMessage("description", val)}
                                ></TextField>
                            </FormGroup>
                        </Grid >

                        <Grid item xs={6} sm={1}>
                        </Grid>
                    </Grid >
                    {!this.isRemovePriceDisabled() &&
                        <IconButton
                            style={{ position: 'absolute', top: '2rem', right: '-2rem', width: '1rem' }}
                            aria-label="remove"
                            type="button"
                            className="btn btn-outline form-control remove-price"
                            onClick={() => this.removePrice(idx)}
                            disabled={this.isRemovePriceDisabled()}
                        >
                            <HighlightOffIcon
                                style={{ color: '#f44336' }}
                            />
                        </IconButton>
                    }
                </div>
            );
        });

        return (
            <div>
                <Grid container>
                    <Grid item sm={12}>
                        {prices}
                    </Grid>
                </Grid>
                <Box pb={2}>
                    <Grid container>
                        <Grid item
                            style={{
                                display: this.props.readOnly ? 'none' : 'block',
                                marginRight: 'auto'
                            }}
                        >
                            <Button
                                color="primary"
                                onClick={this.addPrice}
                                type="button"
                                disabled={this.props.readOnly}
                                style={{ textTransform: 'none' }}
                            >
                                <AddIcon />
                                {t("form.prices.addPriceButton", "Add Price")}
                            </Button>
                        </Grid>
                        <Grid item >
                            <Typography
                                variant="h5"
                                style={{ textTransform: 'uppercase' }}
                            >
                                {t("form.prices.totalPriceText", "Total price")}: {this.state.totalPricesPerCurrency}
                            </Typography>
                            <Typography
                                variant="body2"
                            >
                                {t("form.prices.excludesVatText", "*Prices exclude VAT")}
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </div >
        );
    }

    renderPriceTypeOptions = () => {
        return this.props.priceTypeOptions.map((option, index) => {
            return (
                <MenuItem
                    label={option.label}
                    value={option.value}
                    key={index}
                    name={option.label}
                >{option.label}
                </MenuItem>
            );
        });
    }

    renderCurrencyOptions = () => {
        return this.getCurrencyOptions().map((option, index) => {
            return (
                <MenuItem
                    label={option.label}
                    value={option.value}
                    key={index}
                    name={option.label}
                >{option.label}
                </MenuItem>
            );
        });
    }
}

export default withTranslation()(QuotePrices);
