import React from "react";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Container, Box } from '@material-ui/core';
import CustomerList from "components/user/CustomerList";
import TypographyLargest from "components/form/TypographyLargest";

class CustomerListPage extends React.Component {

    componentDidMount() {
    }

    render() {
        return (
            <Container disableGutters maxWidth={false}>
                <Box m={2}>
                    <TypographyLargest>
                        {this.props.t("customer.customerList.title", "Customers")}
                    </TypographyLargest>
                    <CustomerList />
                </Box>
            </Container>
        );
    }
}

export default withRouter(withTranslation()(CustomerListPage));
