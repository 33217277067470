import React from "react";
import { Paper, Box, Typography } from '@material-ui/core';

export default function GenericCardForm(props) {
    const { children, title } = props;

    return (
        <div className="customer-manager-form__wrapper">
            <Box pt={2}>
                <Paper elevation={3}>
                    <Box p={2}>
                        <Typography
                            variant="h4"
                            gutterBottom>
                            {title}
                        </Typography>
                        <Box pt={2}>
                            {children}
                        </Box>
                    </Box>
                </Paper>
            </Box>
        </div>
    );
}
