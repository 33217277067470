// Everything about react router (subroutes and so on... ) https://reacttraining.com/react-router/web/example/route-config

import React from "react";
import { Translation } from "react-i18next";

import urls from "config/urls";

import PrivacyPolicyView from "views/public/PrivacyPolicy";
import CookiePolicyView from "views/public/CookiePolicy";
import QuoteThankYou from "components/page/quotes/QuoteThankYou";
import OfferPage from "components/page/offer/OfferPage";
import BookingFormPage from "components/page/bookings/BookingFormPage";
import BookingStatusPage from "components/page/bookings/BookingStatusPage";
import BookingThankYou from "components/page/bookings/BookingThankYou";

const routes = [
    {
        path: urls.PRIVACY_POLICY,
        exact: true,
        labelComponent: () => <Translation>{t => t("routes.common.privacyPolicy", "Privacy Policy")}</Translation>,
        component: PrivacyPolicyView,
    },
    {
        path: urls.COOKIE_POLICY,
        exact: true,
        labelComponent: () => <Translation>{t => t("routes.common.cookies", "Cookies")}</Translation>,
        component: CookiePolicyView,
    },
    {
        path: urls.QUOTE_THANK_YOU,
        exact: true,
        label: "",
        component: QuoteThankYou,
    },
    {
        path: urls.OFFER_OVERVIEW,
        exact: true,
        labelComponent: () => <Translation>{t => t("routes.common.offerOverview", "Offer")}</Translation>,
        component: OfferPage,
    },
    {
        path: urls.BOOKING_BY_OFFER_REFERENCE,
        exact: true,
        labelComponent: () => <Translation>{t => t("routes.common.bookingByOfferReference", "Offer booking")}</Translation>,
        component: BookingFormPage,
    },
    {
        path: urls.BOOKING_OVERVIEW,
        exact: true,
        labelComponent: () => <Translation>{t => t("routes.common.bookingOverview", "Booking overview")}</Translation>,
        component: BookingStatusPage,
    },
    {
        path: urls.BOOKING_THANK_YOU,
        exact: true,
        label: "",
        component: BookingThankYou,
    }

];

export default routes;
