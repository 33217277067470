import React, { Component } from "react";
import { connect } from "react-redux";
import { BrowserRouter, Redirect, Switch } from "react-router-dom";
import { FadeLoader } from "react-spinners";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import { withTranslation } from "react-i18next";
import { ToastContainer } from 'react-toastify';
import i18n from "i18next";
import moment from "moment";
import et from "date-fns/locale/et";
import en from "date-fns/locale/en-GB";
import CssBaseline from '@material-ui/core/CssBaseline';

import { filterEnabledRoutes } from "util/index";

import { SubRoute } from "components/Routing/RouteWithSubRoutes";
import SidebarLayout from "./components/Layout/SidebarLayout";

import "bootstrap/dist/css/bootstrap.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import 'react-toastify/dist/ReactToastify.css';
import './assets/sass/datetime-picker.scss';
import './assets/sass/font-awesome.scss';

//import './assets/sass/_CUSTOM.scss';
import './assets/sass/faceLiftStyles.scss';
import './assets/sass/faceLiftColors.scss';
import "./index.css";
import { StyledProvider } from 'components-extra'

// urls
import urls, { getDefaultUrl } from "config/urls";
// routes
import routes from "routes";

import {
    isAuthenticatedWithoutUser
} from "util/Access";
import { checkAuthState, resetStore } from "./redux/actions/auth";

import { languageTypes } from "util/General";
import axios from "./axios";

class App extends Component {
    componentDidMount() {
        //this.props.resetStore();
        this.props.checkAuthState();
        this.updateLocalLanguage(this.props.language);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.language && this.props.language !== prevProps.language) {
            this.updateLocalLanguage(this.props.language);
        }
    }

    updateLocalLanguage = (newLanguage) => {
        // change frontend language
        i18n.changeLanguage(newLanguage);
        // locale language
        moment.locale(newLanguage);
        registerLocale(newLanguage, newLanguage === languageTypes.EN ? en : et);
        setDefaultLocale(newLanguage);
        axios.defaults.headers["Accept-Language"] = newLanguage;
    };

    render() {
        return (<StyledProvider>
            <BrowserRouter>
                <CssBaseline />
                <ToastContainer />
                {this.renderRoutes()}
            </BrowserRouter>
        </StyledProvider>
        );
    }

    renderRoutes() {
        if (isAuthenticatedWithoutUser()) {
            return (
                <div
                    className=""
                    style={{ backgroundColor: "#F7F8FC" }}
                >
                    <FadeLoader
                        css=""
                        size={250}
                        loading={true}
                    />
                </div>
            )
        }

        const routes = this.getRoutes();
        return (
            <SidebarLayout {...this.props} routes={routes}>
                <Switch>
                    {routes.map((route) => {
                        return (
                            <SubRoute key={route.path} {...route} />
                        );
                    })}
                    <Redirect
                        from={urls.ANY}
                        to={getDefaultUrl(this.props.user)}
                    />
                </Switch>
            </SidebarLayout>
        )
    }

    getRoutes() {
        /*
            Skip depth 0 excludes first layer of paths that have their path match to "/". It still includes any nested wraps inside.
            If we don't unwrap these routes (only used to make a generic disabled check for all nested routes when filtering) 
            redirecting wouldn't work as "/" paths would always get matched in the Switch instead of reaching Redirect.
        */

        return filterEnabledRoutes(routes, { skipDepth: 0 });
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        token: state.auth.token,
        language: state.language,
    };
};

export default connect(mapStateToProps, { checkAuthState, resetStore })(
    withTranslation()(App)
);
