import React from "react";
import BookingDatatableList from "components/page/bookings/BookingDatatableList";
import BookingSearch from "components/page/bookings/BookingSearch";
import { Container, Box } from '@material-ui/core';

export default function BookingPage() {
    return (
        <div className="content">
            <Container disableGutters maxWidth={false}>
                <Box mt={6} mb={2}>
                    <BookingSearch />
                </Box>
                <BookingDatatableList />
            </Container>
        </div >
    );
}
