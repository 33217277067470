import React from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Card, CardBody, Row, Col } from "reactstrap";
import get from "lodash/get";

import urls from "config/urls";
import ajax from "remotes/ajax";
import UtilAlert from "util/toastAlerts";

import BrandLogoText from "components/Misc/BrandLogoText";
import WrappedLoader from "components/Loader/WrappedLoader";

import "./CreateCustomerPage.scss";
import { getServerErrorMessage } from "util/Errors";

class ActivateUserPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            localAlertIds: []
        };
    }

    componentDidMount() {
        const id = this.props.match.params.id;
        const token = this.props.match.params.token;
        this.activateUser(id, token);
    }

    componentWillUnmount() {
        UtilAlert.dismissAlerts(this.state.localAlertIds);
    }

    render() {
        return (
            <Card className="activate-user-page__card small-card">
                <CardBody className="px-3 px-md-4">
                    <BrandLogoText />
                    <p className="bold-24 text-center">
                        {this.props.t("user.activateUser.title", "Activate user")}
                    </p>
                    {this.renderContent()}
                </CardBody>
            </Card>
        );
    }

    renderContent() {
        const { loading } = this.state;
        if (loading) {
            return (
                <>
                    <WrappedLoader />
                </>
            )
        }
        return (
            <Row className="text-center">
                <Col>
                    <Link to={urls.LOGIN}>{this.props.t("authentication.forgotPassword.loginLink", "Login")}</Link>
                </Col>
            </Row>
        );
    }

    addAlertId = id => {
        this.setState((prevState) => {
            return {
                localAlertIds: [...prevState.localAlertIds, id],
            };
        });
    }

    activateUser(id, token) {
        this.setState({ loading: true });
        ajax.doActivateUser(id, token)
            .then(resp => {
                const successTitle = this.props.t("user.activateUser.successTitle", "User activated");
                const successMessage = this.props.t("user.activateUser.successMessage", "User has been activated. You can log in now.");

                UtilAlert.createAlert(successMessage, {
                    type: UtilAlert.ALERT_TYPE.SUCCESS,
                    title: successTitle
                });
                this.props.history.push(urls.LOGIN);
            })
            .catch(error => {
                const status = get(error, "response.status", null);

                let errorMessage;
                let errorType = UtilAlert.ALERT_TYPE.ERROR;
                let errorTitle = this.props.t("user.activateUser.errorTitle", "Error during activation");

                if (status === 404) {
                    errorMessage = this.props.t("user.activateUser.userNotFoundErrorMessage", "User not found.");
                } else if (status === 401) {
                    errorTitle = this.props.t("user.activateUser.tokenErrorTitle", "Inactive token");
                    const isActive = get(error, "response.data.is_active", false);
                    if (isActive) {
                        errorMessage = this.props.t("user.activateUser.userActiveTokenErrorMessage", "Token is inactive and user is already activated.");
                        errorType = UtilAlert.ALERT_TYPE.INFO;
                    } else {
                        errorMessage = this.props.t("user.activateUser.userTokenErrorMessage", "Token is not active anymore.");
                        errorType = UtilAlert.ALERT_TYPE.WARNING;
                    }
                } else {
                    errorMessage = getServerErrorMessage(error);
                }

                const alertId = UtilAlert.createAlert(errorMessage, {
                    type: errorType,
                    title: errorTitle,
                    autoClose: false
                })
                this.addAlertId(alertId);
            })
            .finally(() => {
                this.setState({ loading: false });
            })
    }
}

export default withTranslation()(ActivateUserPage);
