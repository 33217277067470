import React from "react";
import Search from "components/page/quotes/Search";
import QuotesDatatableList from "components/page/quotes/QuotesDatatableList";
import { Container, Box } from '@material-ui/core';

export default function QuotePage() {
    return (
        <div className="content">
            <Container
                disableGutters
                maxWidth={false}
            >
                <Box mt={6} mb={2}>
                    <Search />
                </Box>
                <QuotesDatatableList />
            </Container>
        </div >
    );
}
