import React, { Component } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { withStyles } from '@material-ui/styles';
import { styles } from '../../../assets/stylesMUI/shipmentRowStyles';
import Tooltip from "../Tooltip";
import { Checkbox } from "@material-ui/core";

const stylesz = styles;

class ShipmentRowOptions extends Component {
    static propTypes = {
        onRemove: PropTypes.func.isRequired,
        onCopy: PropTypes.func.isRequired,
        isRemoveDisabled: PropTypes.bool.isRequired,
        readOnly: PropTypes.bool,
        classes: PropTypes.object.isRequired,
        isCheckboxChecked: PropTypes.bool.isRequired,
        onCheckboxChange: PropTypes.func.isRequired,
        tooltip: PropTypes.string.isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            dropdownOpen: false,
        };
    }

    toggle = () => {
        this.setState((state) => ({
            dropdownOpen: !state.dropdownOpen,
        }));
    }

    render() {
        const { t, classes } = this.props;
        return (
            <div className={classes.descriptionDropdownDiv}>
                 <div>
                    <Tooltip title={this.props.tooltip}>
                        <Checkbox
                        color="default"
                        disabled={this.props.readOnly}
                        checked={this.props.isCheckboxChecked}
                        onChange={this.props.onCheckboxChange}
                        inputProps={{ "aria-label": "primary checkbox" }}
                        />
                    </Tooltip>
                </div>
                <Dropdown
                    isOpen={this.state.dropdownOpen}
                    toggle={this.toggle}
                    disabled={this.props.readOnly}
                >
                    <DropdownToggle
                        className={classes.dropdownToggle}
                        disabled={this.props.readOnly}
                    >
                        <i className="fa fa-ellipsis-v" />
                    </DropdownToggle>
                    <DropdownMenu right>
                        <DropdownItem onClick={() => this.props.onCopy()}>
                            {t("form.shipmentRow.options.copy", "Copy")}
                        </DropdownItem>
                        <DropdownItem
                            onClick={() => this.props.onRemove()}
                            disabled={this.props.isRemoveDisabled}
                            className={this.props.isRemoveDisabled ? "d-none" : ""}
                        >
                            {t("form.shipmentRow.options.remove", "Remove")}
                        </DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </div>
        );
    }
}

export default withTranslation()(withStyles(stylesz)(ShipmentRowOptions));
