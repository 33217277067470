import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { FormGroup, TextField } from "@material-ui/core";
import { transportType as TransportType } from "maps/classifiers";

export const TransportHubDirection = {
    FROM: "FROM",
    TO: "TO",
};

class DirectionTransportHub extends Component {
    static propTypes = {
        value: PropTypes.string,
        direction: PropTypes.string.isRequired,
        transportType: PropTypes.string,
        onChange: PropTypes.func.isRequired,
        readOnly: PropTypes.bool,
    };

    componentDidUpdate(prevProps) {
        if (prevProps.transportType !== this.props.transportType) {
            this.props.onChange("");
        }
    }

    render() {
        const { value, transportType, onChange } = this.props;
        if (!this.isValidTransportType(transportType)) {
            return null;
        }

        const { placeholder } = this.getLabelPlaceholderAndName();

        return (
            <FormGroup>
                <TextField
                    variant="outlined"
                    fullWidth
                    onChange={(event) => onChange(event.target.value)}
                    type="text"
                    placeholder={placeholder}
                    value={value || ""}
                    disabled={this.props.readOnly}
                />
            </FormGroup>
        );
    }

    isValidTransportType(transportType) {
        return [TransportType.SEA, TransportType.AIR].includes(transportType);
    }

    getLabelPlaceholderAndName() {
        const { t, direction, transportType } = this.props;
        if (direction === TransportHubDirection.FROM) {
            const name = "from_transport_hub";
            if (transportType === TransportType.SEA) {
                return {
                    label: t("form.transportHub.loadingPort", "Loading port"),
                    placeholder: t("form.transportHub.portPlaceholder", "Port"),
                    name
                }
            } else if (transportType === TransportType.AIR) {
                return {
                    label: t("form.transportHub.loadingAirport", "Loading Airport"),
                    placeholder: t("form.transportHub.airportPlaceholder", "Airport"),
                    name
                };
            }
        } else if (direction === TransportHubDirection.TO) {
            const name = "to_transport_hub";
            if (transportType === TransportType.SEA) {
                return {
                    label: t("form.transportHub.unloadingPort", "Unloading port"),
                    placeholder: t("form.transportHub.portPlaceholder", "Port"),
                    name
                }
            } else if (transportType === TransportType.AIR) {
                return {
                    label: t("form.transportHub.unloadingAirport", "Unloading Airport"),
                    placeholder: t("form.transportHub.airportPlaceholder", "Airport"),
                    name
                };
            }
        }
        return {};
    }
}

export default withTranslation()(DirectionTransportHub);
