import React from "react";
import { useTranslation, Trans } from "react-i18next";
import CookieConsent from "react-cookie-consent";
import { Link } from "react-router-dom";
import urls from "config/urls";

const CustomCookieConsent = () => {
    const { t } = useTranslation();

    return (
        <CookieConsent
            location="bottom"
            buttonText={<Trans i18nKey="cookies.consentButton">Agree</Trans>}
            buttonClasses="btn btn-secondary"
            containerClasses="cookieConsent"
            cookieName="cookie_consent"
            style={{ background: "#29B88C", zIndex: '2000', width: '100vw' }}
            buttonStyle={{ color: "#FFFFFF", background: "#611AD0", borderRadius: '4px' }}
            expires={30}
            onAccept={() => console.log("User accepted cookies.")}
            overlay={false}
        >
            <div className="regular-14 color-white">
                {t("cookies.consentParagraphOne", "")}{" "}
                <span className="regular-12 color-white">
                    <Trans i18nKey="cookies.consentParagrapTwoWithlink">
                        By continuing to use the website, you accept cookies in accordance with the <Link to={urls.COOKIE_POLICY}>cookie policy</Link>.
                    </Trans>
                </span>
            </div>
        </CookieConsent >
    );
}

export default CustomCookieConsent;
