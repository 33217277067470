import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import moment from "moment";
import { dateFormat, timeFormat } from "util/dateTime";
import { quouteEventType } from "maps/classifiers";
import { Grid } from '@material-ui/core';

class QuoteHistoryEvents extends Component {
    static propTypes = {
        events: PropTypes.array.isRequired,
    };

    render() {
        const { t } = this.props;
        return (
            <Grid container spacing={2}>
                {this.renderTitle(t("page.quoteHistory.tableHeaders.time", "Time"))}
                {this.renderTitle(t("page.quoteHistory.tableHeaders.description", "Description"))}
                {this.renderTitle(t("page.quoteHistory.tableHeaders.user", "User"))}

                {this.renderRows()}

            </Grid>
        );
    }

    renderTitle(heading) {
        return (
            <Grid item sm={4}>
                {heading}
            </Grid>
        );
    }
    renderRows() {
        return (
            this.props.events.map((val, idx) => {
                return (
                    <>
                        <Grid item sm={4} style={{ fontWeight: '600' }}>
                            {this.displayDateAndTime(this.props.events[idx].timestamp)}
                        </Grid>
                        <Grid item sm={4} style={{ fontWeight: '600' }}>
                            {quouteEventType[this.props.events[idx].type]}
                        </Grid>
                        <Grid item sm={4} style={{ fontWeight: '600' }}>
                            {this.props.events[idx].user_name}
                        </Grid>
                    </>
                );
            })
        );
    }

    displayDateAndTime(date) {
        if (date && this.isDateValid(date)) {
            return moment(date).format(`${dateFormat} ${timeFormat}`);
        }
        return date;
    }

    isDateValid(date) {
        return !date || (moment(date).isValid && moment(date).isValid());
    }
}

export default withTranslation()(QuoteHistoryEvents);
