import React from "react";

import { Trans } from "react-i18next";

export function getServerErrorMessage(error) {
    let errorMessage =
        <Trans i18nKey="alerts.unknownErrorText">
            Unknown error!
        </Trans>
    if (!error) {
        return errorMessage;
    } else if (error.response) {
        const data = error.response.data;
        if (data && data.errors) {
            return data.errors.map((error) => error.code).join(" ");
        }
        if (data && data.code) {
            return data.code;
        }
    } else if (error.request) {
        return error.request.type || errorMessage;
    } else {
        return error.message || errorMessage;
    }

    return errorMessage;
}

export function getErrorMessageList(
    errors = [],
    contentFunc,
    topText = "",
    keyFunc
) {
    const message = (
        <>
            {topText && <p>{topText}</p>}
            <ul className="mb-0">
                {errors.map((e, i) => (
                    <li key={keyFunc ? keyFunc(e, i) : i}>{contentFunc(e)}</li>
                ))}
            </ul>
        </>
    );

    return message;
}
