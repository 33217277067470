import React from "react";
import CloseIcon from '@material-ui/icons/Close';

export default function CloseButton({ onClick }) {
    return (
        <CloseIcon
            onClick={onClick}
            fontSize="large"
            className="modalClose"
        />
    );
}
