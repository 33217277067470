import i18n from "i18next";

import common_et from "./i18n/et.json";
import common_en from "./i18n/en.json";
import { initReactI18next } from "react-i18next";

import { getInitialState } from "redux/reducers/language";

i18n.use(initReactI18next).init({
    interpolation: { escapeValue: false },
    lng: getInitialState(),
    ns: ["common"],
    defaultNS: "common",
    keySeparator: ".",
    returnEmptyString: false,
    resources: {
        et: {
            common: common_et,
        },
        en: {
            common: common_en,
        },
    },
});

export default i18n;
