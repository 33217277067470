import React from "react";
import QuoteForm from "components/page/quotes/QuoteForm";

export default function QuoteFormPage() {
    return (
        <div className="content">
            <QuoteForm isModalMode={false} isOfferMode={false} />
        </div>
    );
}
