import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import Loading from "components/notifications/Loading";
import connect from "react-redux/es/connect/connect";
import { onQuotesReceived, onArchiveQuote, onUnarchiveQuote } from "redux/actions/quotes";
import { fetchQuotes } from "remotes/fetching";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";

import { formatRouteFrom, formatRouteTo } from "util/formatRoute";
import moment from "moment";
import { dateFormat } from "util/dateTime";

import { quoteStatus, transportTypeIcon } from "maps/classifiers";
import { quoteStatusColors } from "maps/colorCodes";
import ajax from "remotes/ajax";
import "./Quotes.css";
import QuoteStatusNotification from "components/page/quotes/QuoteStatusNotification";
import { getQuoteHistoryUrl, getQuoteOfferUrl, getQuoteDetailsrUrl } from "config/urls";
import ConfirmationModal from "components/form/ConfirmationModal";
import { toast } from "react-toastify";
import { Paper, Box, Grid, Button } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import DirectionsRailwayIcon from "@material-ui/icons/DirectionsRailway";
import DirectionsBoatIcon from "@material-ui/icons/DirectionsBoat";
import FlightTakeoffIcon from "@material-ui/icons/FlightTakeoff";
import HistoryIcon from '@material-ui/icons/History';
import EditIcon from '@material-ui/icons/Edit';
import ArchiveIcon from '@material-ui/icons/Archive';
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import SearchIcon from '@material-ui/icons/Search';
import TypographyLarge from "components/form/TypographyLarge";
import Tooltip from "components/form/Tooltip";

class MySearch extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.searchValue !== prevProps.searchValue ||
            this.props.refreshOnChange !== prevProps.refreshOnChange
        ) {
            this.props.onSearch(this.props.searchValue);
        }
    }

    render() {
        return <></>;
    }
}

export class QuotesDatatableList extends Component {
    static propTypes = {
        quotes: PropTypes.oneOfType([PropTypes.array]),
        isCustomerMode: PropTypes.bool,
        chosenStatuses: PropTypes.object,
        chosenLocationFrom: PropTypes.string,
        chosenLocationTo: PropTypes.string,
        chosenClient: PropTypes.string,
        chosenPickupStart: PropTypes.object,
        chosenPickupEnd: PropTypes.object,
        chosenGenericSearch: PropTypes.string,
        onQuotesReceived: PropTypes.func.isRequired,
        onArchiveQuote: PropTypes.func.isRequired,
        onUnarchiveQuote: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            isArchiveConfirmationModal: false,
            archiveQuote: null,
            isArchiveLoading: false,
            isArchiveError: false,
            refreshSearch: false,
            quotesLoading: false
        };

        this.filterLocationFrom = null;
        this.filterLocationTo = null;
        this.filterClient = null;
    }

    async componentDidMount() {
        this._fetchQuotes();
    }

    componentDidUpdate(prevProps, prevState) {
        /* Its important that quotes are initialized with some items, otherwise datatable won't be rendered and
        filters won't be mounted. React doesn't like when we change state of unmounted components.
        */
        const quotes = this.props.quotes;
        if (Array.isArray(quotes) && quotes.length) {
            this.filterLocationFrom(this.props.chosenLocationFrom);
            this.filterLocationTo(this.props.chosenLocationTo);
            if (this.filterClient) this.filterClient(this.props.chosenClient);
            if (prevState.refreshSearch === this.state.refreshSearch) {
                this.refreshSearchFilter();
            }
        }
    }

    render() {
        return (
            <Paper elevation={3}>
                <Box p={4}>
                    <Grid container>
                        <TypographyLarge>{this.props.t("quoteList.heading", "Quote list")}
                        </TypographyLarge>
                        <div style={{ marginLeft: 'auto' }}>
                            <Button
                                variant="contained"
                                size="small"
                                onClick={this.refreshQuotes}
                                style={{ minWidth: '26px', background: '#0062EC', color: '#FFFFFF' }}
                            >
                                <RefreshIcon />
                            </Button>
                        </div>
                    </Grid>
                    {this.renderTable()}
                </Box>
            </Paper>
        );
    }

    renderTable() {
        const { t } = this.props;
        const quotesOrDefault = this.props.quotes || [];
        const filteredQuotes = this.filterQuotes(quotesOrDefault);
        let NoDataIndication;
        if (!this.props.quotes || this.state.quotesLoading) {
            NoDataIndication = <Loading />;
        }
        else if (!this.props.quotes.length) {
            NoDataIndication = <div className="card-content text-center">{t("quoteList.noDataText", "No quotes to show.")}</div>;
        }
        else {
            NoDataIndication = <div className="card-content text-center">{t("quoteList.noDataMatchingFiltersText", "None of the quotes matched active filters.")}</div>;
        }

        const columns = [
            {
                dataField: "id",
                text: "ID",
                sort: true,
                classes: "quote-col-id pr-0",
                headerClasses: "quote-col-header-id pr-0",
                filterValue: (cell) => cell,
                formatter: this.idColumnFormatter,
            },
            {
                dataField: "status",
                text: t("common.tableHeaders.status", "Status"),
                formatter: this.statusColumnFormatter.bind(this),
                sort: true,
                classes: "text-center quote-col-status",
                headerClasses: "text-center quote-col-header-status",
            },
            {
                dataField: "transport_type",
                text: t("common.tableHeaders.type", "Type"),
                sort: true,
                formatter: this.typeColumnFormatter,
                classes: "text-center quote-col-transport-type px-0",
                headerClasses: "text-center quote-col-header-transport-type px-0",
                searchable: false,
            },
            {
                dataField: "pickup_date",
                text: t("common.tableHeaders.pickupDate", "Pickup date"),
                sort: true,
                formatter: (cell) => cell && moment(cell).format(dateFormat),
                classes: "quote-col-pickup-date",
                headerClasses: "quote-col-header-pickup-date",
            },
            {
                dataField: "from_country_code",
                text: t("common.tableHeaders.from", "From"),
                sort: true,
                sortValue: this.fromColumnFormatter,
                filterValue: this.fromColumnFormatter,
                formatter: this.fromColumnFormatter,
                classes: "quote-col-route-from",
                headerClasses: "quote-col-header-route-from",
                filter: textFilter({
                    className: "d-none",
                    getFilter: (filter) => {
                        this.filterLocationFrom = filter;
                    },
                }),
            },
            {
                dataField: "to_country_code",
                text: t("common.tableHeaders.to", "To"),
                sort: true,
                sortValue: this.toColumnFormatter,
                filterValue: this.toColumnFormatter,
                formatter: this.toColumnFormatter,
                classes: "quote-col-route-to",
                headerClasses: "quote-col-header-route-to",
                filter: textFilter({
                    className: "d-none",
                    getFilter: (filter) => {
                        this.filterLocationTo = filter;
                    },
                }),
            },
            {
                dataField: "total_weight",
                text: t("common.tableHeaders.weight", "Weight (kg)"),
                sort: true,
                classes: "quote-col-weight",
                headerClasses: "quote-col-header-weight",
            },
            {
                dataField: "total_ldm",
                text: "LDM",
                sort: true,
                classes: "quote-col-ldm",
                headerClasses: "quote-col-header-ldm",
            },
            {
                dataField: "company_name",
                text: t("common.tableHeaders.client", "Client"),
                sort: true,
                classes: "quote-col-client",
                headerClasses: "quote-col-header-client",
                filter: textFilter({
                    className: "d-none",
                    getFilter: (filter) => {
                        this.filterClient = filter;
                    },
                }),
                hidden: this.props.isCustomerMode
            },
            {
                dataField: "price",
                text: t("common.tableHeaders.price", "Price (€)"),
                sort: true,
                classes: "quote-col-price",
                headerClasses: "quote-col-header-price",
            },
            {
                dataField: "",
                text: t("common.tableHeaders.actions", "Action"),
                formatter: this.actionColumnFormatter.bind(this),
                classes: "td-actions text-right quote-col-actions frozen-last-column",
                headerClasses: "text-right quote-col-header-actions frozen-last-column",
                searchable: false,
            },
            {
                dataField: "created_on",
                text: t("common.tableHeaders.created", "Created"),
                formatter: (cell) => { return new moment(cell).format(dateFormat); },
                sort: true,
                hidden: true,
                searchable: false,
                classes: "",
                headerClasses: "",
            },
        ];

        const defaultSorted = [{
            dataField: "created_on",
            order: "desc",
        }];

        const paginationOptions = {
            hidePageListOnlyOnePage: true,
            sizePerPage: 50,
            hideSizePerPage: filteredQuotes.length < 10,
        };

        const rowEvents = {
            onClick: (e, row) => {
                if (["SUBMITTED", "OFFER_MADE", "OFFER_OPENED", "OFFER_REJECTED", "BOOKING_STARTED", "WON"].includes(row.status)) {
                    this.onClickEdit(row);
                } else {
                    this.onClickView(row);
                }
            },
        };

        return (
            <>
                <Box pt={2}>
                    <ToolkitProvider
                        keyField="id"
                        data={filteredQuotes}
                        columns={columns}
                        bootstrap4={true}
                        search={{
                            // searchFormatted: true,
                        }}
                    >
                        {
                            props => (
                                <div>
                                    <MySearch
                                        {...props.searchProps}
                                        searchValue={this.props.chosenGenericSearch}
                                        refreshOnChange={this.state.refreshSearch}
                                    />
                                    <BootstrapTable
                                        {...props.baseProps}
                                        defaultSorted={defaultSorted}
                                        bordered={false}
                                        wrapperClasses="table-responsive overflow-auto"
                                        classes=""
                                        rowClasses={this.getRowClass.bind(this)}//"quote-item"
                                        pagination={paginationFactory(paginationOptions)}
                                        filter={filterFactory()}
                                        noDataIndication={NoDataIndication}
                                        rowEvents={rowEvents}
                                    />
                                </div>
                            )
                        }
                    </ToolkitProvider>
                </Box>
                <ConfirmationModal
                    isOpen={this.state.isArchiveConfirmationModal}
                    onCancel={this.onCancelArchive}
                    onConfirm={this.onConfirmArchive}
                    isLoading={this.state.isArchiveLoading}
                    isError={this.state.isArchiveError}
                    headerTitle={t("quoteList.archiveQuoteConfirmationTitle", "Confirm quote archivation")}
                >
                    <p className="text-center">{t("quoteList.archiveQuoteConfirmationText", "Are you sure you want to archive this quote?")}</p>
                </ConfirmationModal>
            </>
        );
    }

    filterQuotes(quotes) {
        let filteredQuotes = this.filterByStatus(quotes);
        //        filteredQuotes = this.filterByLocationFrom(filteredQuotes);
        //        filteredQuotes = this.filterByLocationTo(filteredQuotes);
        //        filteredQuotes = this.filterByClient(filteredQuotes);
        filteredQuotes = this.filterByPickupStart(filteredQuotes);
        filteredQuotes = this.filterByPickupEnd(filteredQuotes);
        return filteredQuotes;
    }

    filterByStatus(data) {
        const statusValues = this.props.chosenStatuses;
        const activeStatuses = Object.keys(statusValues).filter((status) => statusValues[status]);
        if (activeStatuses.length) {
            return data.filter((row) => {
                return activeStatuses.includes(row.status);
            });
        }
        return data;
    }

    filterByLocationFrom(quotes) {
        if (this.props.chosenLocationFrom) {
            return quotes.filter(q => formatRouteFrom(q).toLowerCase().includes(this.props.chosenLocationFrom.trim().toLowerCase()));
        }
        return quotes;
    }

    filterByLocationTo(quotes) {
        if (this.props.chosenLocationTo) {
            return quotes.filter(q => formatRouteTo(q).toLowerCase().includes(this.props.chosenLocationTo.trim().toLowerCase()));
        }
        return quotes;
    }

    filterByClient(quotes) {
        if (this.props.chosenClient) {
            return quotes.filter(q => q.company_name.toLowerCase().includes(this.props.chosenClient.trim().toLowerCase()));
        }
        return quotes;
    }

    filterByPickupStart(quotes) {
        if (this.props.chosenPickupStart) {
            const pickupStartDate = this.props.chosenPickupStart ? new moment(this.props.chosenPickupStart) : null;
            return quotes.filter(q => new moment(q.pickup_date) >= pickupStartDate);
        }
        return quotes;
    }

    filterByPickupEnd(quotes) {
        if (this.props.chosenPickupEnd) {
            const pickupEndDate = this.props.chosenPickupEnd ? new moment(this.props.chosenPickupEnd) : null;
            return quotes.filter(q => new moment(q.pickup_date) <= pickupEndDate);
        }
        return quotes;
    }

    idColumnFormatter(cell, row) {
        return (
            <>
                {cell} <QuoteStatusNotification quote={row} />
            </>
        );
    }

    statusColumnFormatter(cell, row) {
        return (
            <label
                className={`statusLabel status-${quoteStatusColors[row.status]}`}
            >
                {quoteStatus[row.status]}
            </label>
        );
    }

    typeColumnFormatter(cell, row) {
        switch (transportTypeIcon[row.transport_type]) {
            case 'localShipping':
                return <LocalShippingIcon />
            case 'directionsRailway':
                return <DirectionsRailwayIcon />
            case 'directionsBoat':
                return <DirectionsBoatIcon />
            case 'flightTakeoff':
                return <FlightTakeoffIcon />
            default:
                return null
        }
    }

    fromColumnFormatter(cell, row) {
        return formatRouteFrom(row);
    }

    toColumnFormatter(cell, row) {
        return formatRouteTo(row);
    }

    actionColumnFormatter(cell, row) {
        const { t } = this.props;
        return (
            <>
                <Tooltip title={t("quoteList.quoteHistoryTooltip", "Quote history")}>
                    <button
                        type="button"
                        className="btn btn-link btn-xs linkStyleButton"
                        onClick={() => this.onClickView(row)}
                    >
                        <HistoryIcon />
                    </button>
                </Tooltip>
                {row.status !== "ARCHIVED" && row.status !== "WON" && (
                    <Tooltip title={t("quoteList.editTooltip", "Edit")}>
                        <button
                            type="button"
                            className="btn btn-link btn-xs linkStyleButton"
                            onClick={() => this.onClickEdit(row)}
                        >
                            <EditIcon />
                        </button>
                    </Tooltip>
                )}
                {(row.status === "ARCHIVED" || row.status === "WON") && (
                    <Tooltip title={t("quoteList.detailsTooltip", "Details")}>
                        <button
                            type="button"
                            className="btn btn-link btn-xs linkStyleButton"
                            onClick={() => this.onClickDetails(row)}
                        >
                            <SearchIcon />
                        </button>
                    </Tooltip>
                )}
                {row.status !== "ARCHIVED" && row.status !== "WON" && (
                    <Tooltip title={t("quoteList.archiveTooltip", "Archive")}>
                        <button
                            type="button"
                            className="btn btn-link btn-xs linkStyleButton"
                            onClick={() => this.onClickArchive(row)}
                        >
                            <ArchiveIcon />
                        </button>
                    </Tooltip>
                )}
                {row.status === "ARCHIVED" && (
                    <Tooltip title={t("quoteList.unarchiveTooltip", "Unarchive")}>
                        <button
                            type="button"
                            className="btn btn-link btn-xs linkStyleButton"
                            onClick={() => this.onClickUnarchive(row)}
                        >
                            <UnarchiveIcon />
                        </button>
                    </Tooltip>
                )}
            </>
        );
    }

    getRowClass(row) {
        return `quote-item status-${quoteStatusColors[row.status]}`;
    }

    onClickView = async (quote) => {
        const quoteHistoryRoute = getQuoteHistoryUrl(quote.id);
        this.props.history.push(quoteHistoryRoute);
    }

    onClickEdit = async (quote) => {
        const getQuoteOfferRoute = getQuoteOfferUrl(quote.id);
        this.props.history.push(getQuoteOfferRoute);
    }

    onClickDetails = async (quote) => {
        const getQuoteDetailsRoute = getQuoteDetailsrUrl(quote.id);
        this.props.history.push(getQuoteDetailsRoute);
    }

    onClickArchive(quote) {
        this.setState({
            archiveQuote: quote,
            isArchiveLoading: false,
            isArchiveError: false,
        });
        this.toggleArchiveConfirmationModal();
    }

    onConfirmArchive = async () => {
        this.setState({
            isArchiveLoading: true,
            isArchiveError: false,
        });
        const quote = this.state.archiveQuote;
        try {
            const updatedQuote = await ajax.doArchiveQuote(quote.id);
            this.props.onArchiveQuote(updatedQuote);
            this.toggleArchiveConfirmationModal();
            toast.success(this.props.t("quoteList.archiveSuccessMessage", `Quote has successfully been archived.`));

            this.setState({
                archiveQuote: null,
            });
        }
        catch (error) {
            this.setState({
                isArchiveError: true,
            });
        }

        this.setState({
            isArchiveLoading: false,
        });
    }

    onCancelArchive = () => {
        this.setState({
            archiveQuote: null,
        });
        this.toggleArchiveConfirmationModal();
    }

    toggleArchiveConfirmationModal = () => {
        this.setState({
            isArchiveConfirmationModal: !this.state.isArchiveConfirmationModal,
        });
    }

    onClickUnarchive = async (quote) => {
        let updatedQuote;
        try {
            updatedQuote = await ajax.doUnarchiveQuote(quote.id);
        }
        catch (error) {
            toast.error(`Failed to unarchive quote ${quote.id}.`);
            return;
        }
        this.props.onUnarchiveQuote(updatedQuote);
        toast.success(this.props.t("form.quote.alerts.unarchiveQuoteSuccessMessage", `Quote has successfully been unarchived.`));
    }

    refreshQuotes = async () => {
        this.props.onQuotesReceived(null);
        this._fetchQuotes();
    }

    refreshSearchFilter = () => {
        this.setState({
            refreshSearch: !this.state.refreshSearch,
        });
    }

    _fetchQuotes() {
        this.setState({ quotesLoading: true });
        fetchQuotes().finally(() => {
            this.setState({ quotesLoading: false });
        })
    }
}

function mapStateToProps(state) {
    return {
        quotes: state.quotes,
        chosenStatuses: state.quoteSearch.chosenStatuses,
        chosenClient: state.quoteSearch.chosenClient,
        chosenLocationFrom: state.quoteSearch.chosenLocationFrom,
        chosenLocationTo: state.quoteSearch.chosenLocationTo,
        chosenPickupStart: state.quoteSearch.chosenPickupStart,
        chosenPickupEnd: state.quoteSearch.chosenPickupEnd,
        chosenGenericSearch: state.quoteSearch.chosenGenericSearch,
    };
}

export default withRouter(connect(mapStateToProps, { onQuotesReceived, onArchiveQuote, onUnarchiveQuote })(withTranslation()(QuotesDatatableList)));
