import React from "react";
import { Trans } from "react-i18next";

const userRoles = {
    COMPANY_SALES: "SALES",
    COMPANY_LOGISTICS: "LOGISTICS",
    COMPANY_MANAGER: "MANAGER",
    //CLIENT: "CLIENT",
    CUSTOMER: "CUSTOMER"
}

const userRolesTranslations = {
    SALES: <Trans i18nKey="enums.userRoles.sales">Sales</Trans>,
    LOGISTICS: <Trans i18nKey="enums.userRoles.logistics">Logistics</Trans>,
    MANAGER: <Trans i18nKey="enums.userRoles.manager">Manager</Trans>,
    CUSTOMER: <Trans i18nKey="enums.userRoles.customer">Customer</Trans>
}

const companyUserRoles = [userRoles.COMPANY_MANAGER, userRoles.COMPANY_LOGISTICS, userRoles.COMPANY_SALES];
const companySalesRoles = [userRoles.COMPANY_MANAGER, userRoles.COMPANY_SALES];

const customerUserRoles = [userRoles.CUSTOMER];

const getIsCompanyRole = role => companyUserRoles.includes(role);
const getIsSalesRole = role => companySalesRoles.includes(role);
const getUserRoleTranslated = role => {
    const translation = userRolesTranslations[role];
    if (!translation) {
        return role;
    } else if (typeof translation === "function") {
        return translation();
    } else {
        return translation;
    }
}

export { companyUserRoles, customerUserRoles, getIsSalesRole, getIsCompanyRole, userRolesTranslations, getUserRoleTranslated };
export default userRoles;
