import React from "react";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next"
import { Col, Container, Row, Card, CardBody } from "reactstrap";

// datatable
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

import BrandLogoText from "components/Misc/BrandLogoText";

function CookiePolicyContent(props) {
    const { t } = useTranslation();

    const cookies = [
        {
            name: "cookie_consent",
            description: t("cookies.cookieConsentTypeDescription", "Used to remember whether the visitor has already accepted the terms of use for the cookies. Expires in 30 days.")
        },
        {
            name: "cookie_consent-legacy",
            description: t("cookies.cookieConsentLegacyTypeDescription", "Used to remember whether the visitor has already accepted the terms of use for the cookies. Expires in 30 days.")
        },
        {
            name: "csrftoken",
            description: t("cookies.csrfTokenTypeDescription", "Protects against CSRF attacks. Expires after 1 year.")
        },
        {
            name: "sessionid",
            description: t("cookies.sessionIdTypeDescription", "A session cookie that expires after a user session ends or when the browser is closed.")
        },
    ];

    const columns = [
        {
            dataField: "name",
            text: t("cookies.columns.name", "Name"),
        },
        {
            dataField: "description",
            text: t("cookies.columns.description", "Description"),
        },
    ];

    return (
        <article className="regular-14 text-left">
            <h3 className="heading text-center">{t("cookies.title", "Cookies")}</h3>
            <p>{t("cookies.introductionText", "This website uses cookies. You can disable cookies at any time by changing web browser settings and deleting existing cookies.")}</p>

            <p className="semibold-14">{t("cookies.cookiesUsed", "Cookies used")}</p>
            <BootstrapTable
                bootstrap4
                bordered={false}
                classes="no-top-border"
                keyField="name"
                data={cookies}
                columns={columns}
            />
        </article>
    );
}

function CookiePolicy(props) {
    return (
        <Container className="align-middle">
            <Row className="text-center justify-content-center">
                <Col>
                    <Card>
                        <CardBody className="px-3 px-md-4" style={{ "paddingTop": "32px" }}>
                            <BrandLogoText />
                            <Row>
                                <Col>
                                    <CookiePolicyContent />
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default withRouter(CookiePolicy);
