import * as actionType from "redux/actions/actionTypes";

export const onBookingsReceived = (bookings) => {
    return {
        type: actionType.BOOKINGS_RECEIVED,
        bookings,
    };
};

export const onBookingsFetchFail = (error) => {
    return {
        type: actionType.BOOKINGS_FETCH_FAIL,
        error,
    };
};

export const onPrefillBookingForm = (booking) => {
    return {
        type: actionType.PREFILL_BOOKING_FORM,
        booking,
    };
};

export const onEditBooking = (booking) => {
    return {
        type: actionType.EDIT_BOOKING,
        booking,
    };
};

export const onViewBooking = (booking) => {
    return {
        type: actionType.VIEW_BOOKING,
        booking,
    };
};

export const onBookingFetchFail = (error) => {
    return {
        type: actionType.BOOKING_FETCH_FAIL,
        error,
    };
};

export const onSaveBookingUpdate = (booking) => {
    return {
        type: actionType.SAVE_BOOKING_UPDATE,
        booking,
    };
};

export const onCloseBookingForm = () => {
    return {
        type: actionType.CLOSE_BOOKING_FORM,
    };
};

export const updateBookingFormField = (key, value) => {
    return {
        type: actionType.UPDATE_BOOKING_FORM_FIELD,
        key,
        value,
    };
};

export const onSetBookingFilterClient = (client) => {
    return {
        type: actionType.SET_BOOKING_FILTER_CLIENT,
        client,
    };
};

export const onSetBookingFilterLocationFrom = (location) => {
    return {
        type: actionType.SET_BOOKING_FILTER_LOCATION_FROM,
        location,
    };
};

export const onSetBookingFilterLocationTo = (location) => {
    return {
        type: actionType.SET_BOOKING_FILTER_LOCATION_TO,
        location,
    };
};

export const onSetBookingFilterPickupStart = (date) => {
    return {
        type: actionType.SET_BOOKING_FILTER_PICKUP_START,
        date,
    };
};

export const onSetBookingFilterPickupEnd = (date) => {
    return {
        type: actionType.SET_BOOKING_FILTER_PICKUP_END,
        date,
    };
};

export const onSetBookingFilterGenericSearch = (search) => {
    return {
        type: actionType.SET_BOOKING_FILTER_GENERIC_SEARCH,
        search,
    };
};
