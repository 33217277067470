import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { transportType } from "maps/classifiers";
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff';
import DirectionsBoatIcon from '@material-ui/icons/DirectionsBoat';
import DirectionsRailwayIcon from '@material-ui/icons/DirectionsRailway';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import { Box, Grid, FormControl, FormLabel, FormControlLabel, RadioGroup, Radio } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { styles } from '../../assets/stylesMUI/transportCheckboxesStyles';
import clsx from 'clsx';

const stylesz = styles;

class TransportType extends Component {
    static propTypes = {
        value: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
        readOnly: PropTypes.bool,
        classes: PropTypes.object.isRequired,
        selectedValue: PropTypes.string
    };


    render() {
        const { t, onChange, value, readOnly, classes } = this.props;
        return (
            <Box mt={2}>
                <FormControl className="choose-transport-type" component="fieldset" >
                    <FormLabel
                        component="p"
                        htmlFor="transport_type"
                        className={classes.formLabel}>
                        {t("form.transportType.transportTypeLabel", "Choose transport type")}
                    </FormLabel>
                    <RadioGroup
                        aria-label="transport_type"
                        name="transport_type"
                        value={value}
                        row >
                        <Grid container spacing={3}>
                            <Grid
                                item xs={6} sm={3}
                            >
                                <div className={clsx(classes.checkBoxContainer,
                                    (this.props.selectedValue === transportType.ROAD) && classes.checkBoxContainerChecked)}>
                                    <div className={classes.svgContainer}>
                                        <LocalShippingIcon />
                                    </div>
                                    <div>
                                        <FormControlLabel
                                            className={classes.label}
                                            name="transport_type"
                                            value={transportType.ROAD}
                                            control={<Radio color="default" className={classes.radio} />}
                                            label={this.getHelperText(transportType.ROAD, "Road transport")}
                                            onChange={(e) => onChange(e)}
                                            disabled={readOnly}
                                        />
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <div className={clsx(classes.checkBoxContainer,
                                    (this.props.selectedValue === transportType.RAIL) && classes.checkBoxContainerChecked)}>
                                    <div className={classes.svgContainer}>
                                        <DirectionsRailwayIcon />
                                    </div>
                                    <div>
                                        <FormControlLabel
                                            className={classes.label}
                                            name="transport_type"
                                            value={transportType.RAIL}
                                            control={<Radio color="default" className={classes.radio} />}
                                            label={this.getHelperText(transportType.RAIL, "Rail transport")}
                                            onChange={(e) => onChange(e)}
                                            disabled={readOnly} />
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <div className={clsx(classes.checkBoxContainer,
                                    (this.props.selectedValue === transportType.SEA) && classes.checkBoxContainerChecked)}>
                                    <div className={classes.svgContainer}>
                                        <DirectionsBoatIcon />
                                    </div>
                                    <div>
                                        <FormControlLabel
                                            className={classes.label}
                                            name="transport_type"
                                            value={transportType.SEA}
                                            control={<Radio color="default" className={classes.radio} />}
                                            label={this.getHelperText(transportType.SEA, "Sea transport")}
                                            onChange={(e) => onChange(e)}
                                            disabled={readOnly} />
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <div className={clsx(classes.checkBoxContainer,
                                    (this.props.selectedValue === transportType.AIR) && classes.checkBoxContainerChecked)}>
                                    <div className={classes.svgContainer}>
                                        <FlightTakeoffIcon />
                                    </div>
                                    <div>
                                        <FormControlLabel
                                            className={classes.label}
                                            name="transport_type"
                                            value={transportType.AIR}
                                            disabled={readOnly}
                                            control={<Radio color="default" className={classes.radio} />}
                                            label={this.getHelperText(transportType.AIR, "Air transport")}
                                            onChange={(e) => onChange(e)} />
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </RadioGroup>
                </FormControl>
            </Box>
        );
    }

    getHelperText = (transportType, labelText) => {
        const { t, classes } = this.props;
        const translationKey = "form.transportType." + transportType.toLowerCase();
        if (this.props.selectedValue === transportType) {
            return (
                <>
                <span>{t(translationKey, labelText)}</span>
                <p className={clsx(classes.checkBoxHelperText, classes.checkedText)}
                >
                    {t("form.checkboxHelperText.checked", "Checked")}
                </p >
                </>
            )
        } else {
            return (
                <>
                <span>{t(translationKey, labelText)}</span>
                <p className={classes.checkBoxHelperText}>
                    {t("form.checkboxHelperText.choose", "Choose")}
                </p>
                </>
            )
        }
    }
}

export default withTranslation()(withStyles(stylesz)(TransportType));
