/* eslint-disable react/no-did-mount-set-state */
import _ from "lodash";
import { withTranslation } from "react-i18next";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ajax from "remotes/ajax";
import Loading from "components/notifications/Loading";
import { Button, Grid, Dialog, Box, Container } from '@material-ui/core';
import AdditionalOptionsSection from "../../form/AdditionalOptionsSection";
import {
    onSetCountryList,
    onSetCountryListFetchFail,
    onSetTransportManagerList,
    onSetTransportManagerListFetchFail,
    onSetPriceTypeList,
    onSetPriceTypeListFetchFail,
    onSetCommunicationCountryCode,
    onSetCommunicationCountryCodeFetchFail,
} from "redux/actions";
import {
    onQuoteCreated,
    onViewQuote,
    onCloseQuoteForm,
    onSaveQuoteUpdate,
    updateQuoteFormField,
    onEditQuote,
    onUnarchiveQuote,
    onQuoteFetchFail,
} from "redux/actions/quotes";
import CloseButton from "components/modal/CloseButton";
import QuoteFormFields from "components/page/quotes/QuoteFormFields";
import { toast } from "react-toastify";
import { validateOffer, validateQuote } from "util/validations";
import ConfirmChangesModal from "components/form/ConfirmChangesModal";
import { findImportantChanges, getNewQuote, getNewOffer, getDefaultDueDate, getNewPrefillQuoteObject } from "util/quotes";
import { withRouter, NavLink } from "react-router-dom";
import urls, { getBookingUrl, getOfferOverviewUrl } from "config/urls";
import ErrorMessage from "components/form/ErrorMessage";
import { isCompanyMode, isCustomerMode, isPublicMode } from "util/Access";
import { ArrowForward } from "@material-ui/icons";
import TypographyLargest from "components/form/TypographyLargest";

export class QuoteForm extends Component {
    static propTypes = {
        quote: PropTypes.object.isRequired,
        prefillId: PropTypes.number,
        readOnly: PropTypes.bool,
        isModalMode: PropTypes.bool,
        isOfferMode: PropTypes.bool,
        isCustomerMode: PropTypes.bool,
        countryOptions: PropTypes.array.isRequired,
        transportManagerOptions: PropTypes.array.isRequired,
        onSaveQuoteUpdate: PropTypes.func.isRequired,
        updateQuoteFormField: PropTypes.func.isRequired,
        onCloseQuoteForm: PropTypes.func.isRequired,
        onSetCountryList: PropTypes.func.isRequired,
        onSetCountryListFetchFail: PropTypes.func.isRequired,
        onSetTransportManagerList: PropTypes.func.isRequired,
        onSetTransportManagerListFetchFail: PropTypes.func.isRequired,
        priceTypeOptions: PropTypes.array.isRequired,
        onSetPriceTypeList: PropTypes.func.isRequired,
        onSetPriceTypeListFetchFail: PropTypes.func.isRequired,
        communicationCountryCode: PropTypes.string.isRequired,
        onSetCommunicationCountryCode: PropTypes.func.isRequired,
        onSetCommunicationCountryCodeFetchFail: PropTypes.func.isRequired,
        onViewQuote: PropTypes.func.isRequired,
        onEditQuote: PropTypes.func.isRequired,
        onUnarchiveQuote: PropTypes.func.isRequired,
        onQuoteFetchFail: PropTypes.func.isRequired,
        quoteError: PropTypes.string,
    };

    constructor(props) {
        super(props);

        this.state = {
            errors: {},
            initialQuote: {},
            submitDone: false,
            confirmationModal: false,
            importantChanges: [],
        };
    }

    async componentDidMount() {
        const { t } = this.props;
        const isPublic = isPublicMode();
        const isCompany = isCompanyMode();
        const id = this.props.match.params.id;
        const prefillId = this.props.prefillId;

        if (prefillId && !isPublic) {
            this.setState({
                isLoading: true,
            });
            ajax.doGetQuote(prefillId)
                .then(data => {
                    const quote = getNewPrefillQuoteObject(data, { isOfferMode: this.props.isOfferMode });
                    this.props.onEditQuote(quote);

                    this.setState({
                        initialQuote: _.cloneDeep(quote)
                    });
                })
                .catch(error => {
                    this.props.onQuoteFetchFail(error.statusText);
                    toast.error(t("form.quote.errorFetchQuotePrefillDataMessage", "Failed to load data for prefilling."))
                })
                .finally(() => this.setState({ isLoading: false }))
        } else if (!isPublic && id) {
            this.setState({
                isLoading: true,
            });
            ajax.doGetQuote(id)
                .then(data => {
                    const quote = this.setDefaultValuesForNewOffers(data);
                    if (this.props.isCustomerMode) {
                        this.props.onViewQuote(quote);
                    } else {
                        this.props.onEditQuote(quote);
                    }

                    this.setState({
                        initialQuote: _.cloneDeep(data)
                    });
                })
                .catch(error => this.props.onQuoteFetchFail(error.statusText))
                .finally(() => this.setState({ isLoading: false }))
        }
        else {
            let quote;
            if (this.props.isOfferMode) {
                quote = getNewOffer();
            } else {
                quote = getNewQuote();
            }
            this.props.onEditQuote(quote);
        }

        this.setState({
            initialQuote: _.cloneDeep(this.props.quote),
            importantChanges: [],
        });

        if (!this.props.countryOptions.length) {
            try {
                this.setState({
                    isLoading: true,
                    hasError: false,
                });

                const countries = await ajax.doGetQuoteCountryList();
                this.props.onSetCountryList(_.sortBy(countries));

                this.setState({
                    isLoading: false,
                });
            }
            catch (error) {
                this.props.onSetCountryListFetchFail(error.statusText);
                this.setState({
                    isLoading: false,
                    hasError: true,
                });
            }
        }

        if (isCompany && !this.props.transportManagerOptions.length) {
            try {
                this.setState({
                    isLoading: true,
                    hasError: false,
                });

                const transport_managers = await ajax.doGetTransportManagerList();
                this.props.onSetTransportManagerList(_.sortBy(transport_managers));

                this.setState({
                    isLoading: false,
                });
            }
            catch (error) {
                this.props.onSetTransportManagerListFetchFail(error.statusText);
                this.setState({
                    isLoading: false,
                    hasError: true,
                });
            }
        }

        if (isCompany && !this.props.priceTypeOptions.length) {
            try {
                this.setState({
                    isLoading: true,
                    hasError: false,
                });

                const priceTypeOptions = await ajax.doGetPriceTypeList();
                this.props.onSetPriceTypeList(_.sortBy(priceTypeOptions));

                this.setState({
                    isLoading: false,
                });
            }
            catch (error) {
                this.props.onSetPriceTypeListFetchFail(error.statusText);
                this.setState({
                    isLoading: false,
                    hasError: true,
                });
            }
        }

        if (!this.props.communicationCountryCode) {
            try {
                this.setState({
                    isLoading: true,
                    hasError: false,
                });

                const communicationCountryCode = await ajax.doGetCommunicationCountryCode();
                this.props.onSetCommunicationCountryCode(communicationCountryCode);

                this.setState({
                    isLoading: false,
                });
            }
            catch (error) {
                this.props.onSetCommunicationCountryCodeFetchFail(error.statusText);
                this.setState({
                    isLoading: false,
                    hasError: true,
                });
            }
        }
    }

    render() {
        const { t } = this.props;
        let quoteFormBody;
        if (this.props.quoteError) {
            quoteFormBody = this.renderQuoteError();
        }
        else if (!this.props.quote || !(this.props.quote.id || this.props.quote.is_new)) {
            quoteFormBody = this.renderNoQuote();
        }
        else {
            quoteFormBody = this.renderForm();
        }
        const isModalMode = this.props.isModalMode;

        return (
            <>
                <ConfirmChangesModal
                    headerTitle={t("form.quote.confirmChanges.heading", "Confirm important changes")}
                    isOpen={this.state.confirmationModal}
                    isError={this.state.error}
                    isLoading={this.state.loading}
                    onCancel={this.cancelImportantChanges}
                    onConfirm={this.confirmImportantChanges}
                    onToggle={this.toggleConfirmationModal}
                    changesMade={this.state.importantChanges}
                />

                {isModalMode && <Dialog
                    onClose={this.onClose}
                    open={isModalMode}
                    maxWidth="lg"
                >
                    {this.renderNewQuoteOrOffer(quoteFormBody)}
                </Dialog>}
                {!isModalMode && this.renderNewQuoteOrOffer(quoteFormBody)}
            </>
        );
    }

    renderNewQuoteOrOffer(quoteFormBody) {
        const { t, isModalMode } = this.props;
        const padding = isModalMode ? 4 : 0;
        return (
            <Box p={padding}>
                <div className="">
                    {isModalMode && <CloseButton onClick={this.onClose} />}
                    <TypographyLargest>
                        {this.props.isOfferMode ?
                            t("form.quote.offerHeading", "Make an offer")
                            :
                            t("form.quote.quoteHeading", "Quote")
                        }
                    </TypographyLargest>
                </div>
                <div className="">
                    {quoteFormBody}
                </div>
            </Box>
        );
    }

    renderNoQuote() {
        return (
            <div className="row justify-content-center">
                <Loading />
            </div>
        );
    }

    renderQuoteError() {
        return (
            <ErrorMessage
                isError={true}
                errorMessage="Error while fetching quote."
            />
        );
    }

    renderSubmitButton() {
        const { t, readOnly } = this.props;
        if (readOnly) {
            return false;
        }

        let isDisabled = false;
        let buttonText = t("form.quote.buttons.submit", "Send");
        if (!this.state.confirmationModal && this.state.loading) {
            isDisabled = true;
            buttonText = <Loading />;
        }

        return (
            <Container disableGutters maxWidth={false}>
                <Box m={2}>
                    <Grid container>
                        <div style={{ marginRight: 'auto' }}>
                            <AdditionalOptionsSection
                                data={this.props.quote}
                                onChange={this.props.updateQuoteFormField}
                                readOnly={this.props.readOnly}
                            />
                        </div>
                        {this.renderPrefillNewQuoteOrOffer()}
                        <div className="submitButtonContainer">
                            <Button
                                variant="contained"
                                type="submit"
                                size="large"
                                disabled={isDisabled}
                                className="submitButton"
                            >
                                {buttonText}
                                <ArrowForward style={{ margin: '0 0 2px 2px' }} />
                            </Button>
                        </div>
                    </Grid>
                </Box>
            </Container>
        );
    }

    renderOpenBookingDetailsButton() {
        const { quote, t } = this.props;
        if (!isCustomerMode()) {
            return null;
        }
        if (!["OFFER_MADE", "OFFER_OPENED", "OFFER_REJECTED", "BOOKING_STARTED", "WON"].includes(_.get(quote, "status", null))) {
            return false;
        }
        const offerReference = quote.reference;
        return (
            <div className="row justify-content-center">
                <div>
                    <NavLink
                        className="btn btn-primary btn-wd mx-2"
                        to={getOfferOverviewUrl(offerReference)}
                    >
                        {t("form.quote.buttons.goToOffer", "GO TO OFFER")}
                    </NavLink>
                </div>
            </div>
        );
    }

    renderOfferOverviewButton() {
        const { quote, t } = this.props;
        if (!isCompanyMode() && !isCustomerMode()) {
            return null;
        }
        if (!quote || quote.status !== "WON" || !quote.booking_ids || quote.booking_ids.length === 0) {
            return false;
        }
        const bookingReference = quote.booking_ids[0];
        return (
            <div className="row justify-content-center">
                <div className="col col-auto">
                    <NavLink
                        className="btn btn-primary btn-wd mx-2"
                        to={getBookingUrl(bookingReference)}
                    >
                        {t("form.quote.buttons.goToBooking", "GO TO BOOKING")}
                    </NavLink>
                </div>
            </div>
        );
    }

    renderUnarchiveButton() {
        const { quote, t } = this.props;
        if (!isCompanyMode()) {
            return null;
        };
        if (!quote || quote.status !== "ARCHIVED") {
            return false;
        }

        let isDisabled = false;
        let buttonText = t("form.quote.buttons.unarchive", "UNARCHIVE");
        if (!this.state.confirmationModal && this.state.loading) {
            isDisabled = true;
            buttonText = <Loading />;
        }

        return (
            <div className="col-auto">
                <button
                    type="button"
                    className="btn btn-primary btn-wd mx-2"
                    onClick={() => this.onClickUnarchive(quote)}
                    disabled={isDisabled}
                >
                    {buttonText}
                </button>
            </div>
        );
    }

    renderPrefillNewQuoteOrOffer() {
        const { quote, t } = this.props;
        if (!quote.id) {
            return null;
        }

        const to = {
            pathname: isCompanyMode() ? urls.COMPANY_NEW_OFFER : urls.NEW_QUOTE,
            state: {
                prefillId: quote.id
            }
        }

        return (
            <div className="justify-content-center">
                <Button
                    variant="contained"
                    size="large"
                    className="submitButton"
                    style={{ marginRight: '1rem' }}
                >
                    <NavLink
                        style={{ color: 'white', textDecoration: 'none' }}
                        to={to}
                    >
                        {isCompanyMode() && t("form.quote.buttons.newOffer", "NEW OFFER")}
                        {!isCompanyMode() && t("form.quote.buttons.newQuote", "NEW QUOTE")}
                    </NavLink>
                </Button>
            </div>
        );
    }

    renderForm() {
        const { t } = this.props;
        return (
            <form method="#" action="#" className="mr-2" onSubmit={this.preSubmitQuoteForm.bind(this)} autoComplete="off">
                <QuoteFormFields
                    quote={this.props.quote}
                    isOfferMode={this.props.isOfferMode}
                    countryOptions={this.props.countryOptions}
                    transportManagerOptions={this.props.transportManagerOptions}
                    priceTypeOptions={this.props.priceTypeOptions}
                    errors={this.state.errors}
                    submitDone={this.state.submitDone}
                    updateQuoteFormField={this.props.updateQuoteFormField}
                    communicationCountryCode={this.props.communicationCountryCode}
                    readOnly={this.props.readOnly}
                />

                <div className="text-center">
                    <ErrorMessage
                        isInvisible={true}
                        isError={this.state.error && !this.state.confirmationModal}
                    />
                    <ErrorMessage
                        isError={this.isAnyValidationErrors()}
                        errorMessage={t("form.quote.validationErrorsMessage", "Some fields have validation errors.")}
                    />
                </div>
                <div>
                    {this.renderOpenBookingDetailsButton()}
                    {this.renderOfferOverviewButton()}
                    {this.renderUnarchiveButton()}
                    {this.renderSubmitButton()}
                </div>

            </form>
        );
    }

    onClose = () => {
        this.props.onCloseQuoteForm();
        this.props.history.push(urls.COMPANY_QUOTES);
    }

    toggleConfirmationModal = () => {
        this.setState({
            confirmationModal: !this.state.confirmationModal,
        });
    }

    confirmImportantChanges = () => {
        this.submitQuoteForm();
    }

    cancelImportantChanges = () => {
        this.toggleConfirmationModal();
        this.setState({
            error: null,
        });
    }

    preSubmitQuoteForm = (event) => {
        event.preventDefault();
        event.stopPropagation();

        this.setState({
            submitDone: true,
        });

        if (this.validateForm()) {
            // Try to check for important changes when changing existing quote.
            if (this.props.quote.id) {
                const importantChanges = findImportantChanges(this.state.initialQuote, this.props.quote);
                this.setState({
                    importantChanges,
                });
                if (importantChanges && importantChanges.length) {
                    this.toggleConfirmationModal();
                    return;
                }
            }
            this.submitQuoteForm();
            return;
        }
    }

    async submitQuoteForm() {
        const { readOnly, quote, isOfferMode, t } = this.props;
        if (readOnly) {
            toast.warn(t("form.quote.alerts.readOnlyWarningMessage", "Form is in read-only mode."));
            return;
        }

        this.setState({
            loading: true,
            error: false,
        });


        try {
            let returnedData;
            let toastMessage;
            if (quote.id) {
                // If quote has id then next step is always an offer
                returnedData = await ajax.doMakeOffer(quote);
                toastMessage = t("form.quote.alerts.submitQuoteOfferSuccessMessage", "Offer has successfully been sent.");
            }
            else {
                if (isOfferMode) {
                    returnedData = await this.makeNewQuoteOffer(quote);
                    toastMessage = t("form.quote.alerts.createNewOfferSuccessMessage", "Offer has successfully been sent.");
                } else {
                    returnedData = await this.makeNewQuote(quote)
                    toastMessage = t("form.quote.alerts.createQuoteSuccessMessage", "Quote has successfully been sent.");
                }
            }
            this.props.onSaveQuoteUpdate(returnedData);
            if (isOfferMode) {
                toast.success(toastMessage);
                this.props.history.push(urls.COMPANY_QUOTES);
            } else {
                this.props.history.push(urls.QUOTE_THANK_YOU);
            }
        } catch (error) {
            this.setState({
                error: true,
            });
        } finally {
            this.setState({
                loading: false,
            });
        }

    }

    onClickUnarchive = async (quote) => {
        const { t } = this.props;
        let updatedQuote;
        try {
            updatedQuote = await ajax.doUnarchiveQuote(quote.id);
            this.props.onUnarchiveQuote(updatedQuote);
            this.props.onEditQuote(quote);
            toast.success(t("form.quote.alerts.unarchiveQuoteSuccessMessage", "Quote has successfully been unarchived."));
        }
        catch (error) {
            toast.error(t("form.quote.alerts.unarchiveQuoteErrorMessage", "Failed to unarchive quote."));
            return;
        }
    }

    validateForm() {
        const quote = this.props.quote;
        const errors = this.props.isOfferMode ? validateOffer(quote) : validateQuote(quote);
        this.setState({
            // NB! setState is async!
            errors,
        });

        return !Object.keys(errors).length;
    }

    async makeNewQuoteOffer(quote) {
        const returnedData = await ajax.doMakeOfferDirectly(quote);
        this.props.onQuoteCreated(returnedData);

        return returnedData;
    }

    async makeNewQuote(quote) {
        const returnedData = await ajax.doMakeQuote(quote);
        this.props.onQuoteCreated(returnedData);

        return returnedData;
    }

    findCountryLabel = (value) => {
        const countries = this.props.countryOptions.filter(country => { return country.value === value; });
        if (countries && countries.length > 0) {
            return countries[0].label;
        }
        return value;
    }

    isAnyValidationErrors = () => {
        return !_.isEmpty(this.state.errors);
    }

    setDefaultValuesForNewOffers(quote) {
        // const quote = this.props.quote;
        if (!quote) {
            return quote;
        }
        if (quote.is_new || quote.status === "SUBMITTED") {
            // this.props.updateQuoteFormField("due_date", getDefaultDueDate());
            quote.due_date = getDefaultDueDate();
        }

        return quote;
    }
}

function mapStateToProps(state, parentProps) {
    return {
        quote: state.editingQuote.quote,
        countryOptions: state.countryOptions,
        transportManagerOptions: state.transportManagerOptions,
        priceTypeOptions: state.priceTypeOptions,
        communicationCountryCode: state.communicationCountryCode,
        quoteError: state.editingQuote.error,
        readOnly: state.editingQuote.readOnly || parentProps.readOnly,
        isCustomerMode: isCustomerMode(),
        prefillId: parentProps.prefillId || _.get(parentProps.location.state, "prefillId", null),
    };
}

export default withRouter(connect(
    mapStateToProps,
    {
        onQuoteCreated, onViewQuote, onSaveQuoteUpdate, updateQuoteFormField, onCloseQuoteForm, onEditQuote, onUnarchiveQuote, onQuoteFetchFail,
        onSetCountryList, onSetCountryListFetchFail, onSetTransportManagerList, onSetTransportManagerListFetchFail,
        onSetPriceTypeList, onSetPriceTypeListFetchFail, onSetCommunicationCountryCode, onSetCommunicationCountryCodeFetchFail
    },
)(withTranslation()(QuoteForm)));
