export const accuracyColors = {
    NONE: "primary",
    CORRECT: "success",
    UNCLEAR: "warning",
    WRONG: "danger",
};

export const stateColors = {
    FRESH: "success",
    QUEUED: "warning",
    IN_PROGRESS: "warning",
    OUTDATED: "primary",
    FAILED: "danger",
};

export const quoteStatusColors = {
    SUBMITTED: "marker-green",
    OFFER_MADE: "marker-blue",
    OFFER_OPENED: "marker-yellow",
    OFFER_REJECTED: "marker-red",
    BOOKING_STARTED: "marker-blue",
    WON: "marker-purple",
    ARCHIVED: "grey",
  };