import React from 'react';
import { withRouter } from "react-router-dom";

import LoginCard from "components/Login/LoginCard/LoginCard";


class LoginView extends React.Component {
    render() {
        return (
            <LoginCard />
        );
    }
}

export default withRouter(LoginView);
