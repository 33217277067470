import * as actionType from "redux/actions/actionTypes";

export const quotes = (state = null, action) => {
    switch (action.type) {
        case actionType.QUOTES_RECEIVED:
            return action.quotes;
        case actionType.QUOTE_CREATED:
            if (state) {
                return [...state, action.quote];
            } else {
                return [action.quote];
            }
        case actionType.QUOTES_FETCH_FAIL:
            return null;

        case actionType.SAVE_QUOTE_UPDATE:
        case actionType.UNARCHIVE_QUOTE:
        case actionType.ARCHIVE_QUOTE:
            if (state) {
                return state.map((quote) => (quote.id === action.quote.id ? action.quote : quote));
            } else {
                return state;
            }
        default:
            return state;
    }
};

export const editingQuote = (state = { quote: {} }, action) => {
    switch (action.type) {
        case actionType.VIEW_QUOTE:
            return {
                isOpen: true,
                quote: action.quote,
                readOnly: true
            };
        case actionType.PREFILL_QUOTE_FORM:
        case actionType.EDIT_QUOTE:
            return {
                isOpen: true,
                quote: action.quote,
                readOnly: ["ARCHIVED", "WON"].includes(action.quote.status),
            };
        case actionType.UPDATE_QUOTE_FORM_FIELD:
            return {
                ...state,
                quote: {
                    ...state.quote,
                    [action.key]: action.value,
                },
            };
        case actionType.SAVE_QUOTE_UPDATE:
        case actionType.CLOSE_QUOTE_FORM:
            return {
                isOpen: false,
                quote: {},
            };
        case actionType.QUOTE_FETCH_FAIL:
            return {
                ...state,
                error: action.error,
            };

        default:
            return state;
    }
};

export const viewQuoteHistory = (state = { quote: {} }, action) => {
    switch (action.type) {
        case actionType.VIEW_QUOTE_HISTORY:
            return {
                isOpen: true,
                quote: action.quote,
            };
        case actionType.CLOSE_QUOTE_VIEW:
            return {
                isOpen: false,
                quote: {},
            };
        case actionType.QUOTE_FETCH_FAIL:
            return {
                ...state,
                error: action.error,
            };

        default:
            return state;
    }
};
