// Everything about react router (subroutes and so on... ) https://reacttraining.com/react-router/web/example/route-config
import React from "react";
import { Translation } from "react-i18next";

import urls from "config/urls";
import { isCustomerMode } from "util/Access";
import commonRoutes from "./common";

import RouteWithSubRoutes from "components/Routing/RouteWithSubRoutes";
import CustomerQuotesPage from "components/page/quotes/CustomerQuotesPage";
import QuoteForm from "components/page/quotes/QuoteForm";
import QuoteHistory from "components/page/quotes/QuoteHistory";
import BookingPage from "components/page/bookings/BookingPage";
import BookingForm from "components/page/bookings/BookingForm";
import QuoteFormPage from "components/page/quotes/QuoteFormPage";
import BookingFormPage from "components/page/bookings/BookingFormPage";
import CustomerProfile from "components/page/user/CustomerProfile";

export const customerSpecificRoutes = [
    {
        path: urls.DOMAIN,
        component: RouteWithSubRoutes,
        label: "",
        disabled: () => !isCustomerMode(),
        routes: [
            {
                path: urls.COMPANY_QUOTES,
                label: "",
                component: RouteWithSubRoutes,
                routes: [
                    {
                        path: urls.COMPANY_QUOTES,
                        exact: false,
                        labelComponent: () => <Translation>{t => t("routes.company.quotes", "Quotes")}</Translation>,
                        component: CustomerQuotesPage,
                        showSidebar: true,
                        icon: "assignment",
                        linkLabel: () => <Translation>{t => t("sidebar.customer.quotes", "Quotes")}</Translation>,
                    },
                    {
                        path: urls.COMPANY_QUOTE_HISTORY,
                        exact: true,
                        labelComponent: () => <Translation>{t => t("routes.company.quoteHistory", "Quote history")}</Translation>,
                        component: QuoteHistory
                    },
                    {
                        path: [urls.COMPANY_QUOTE_DETAILS, urls.COMPANY_QUOTE_DETAILS_ALTERNATIVE],
                        exact: true,
                        labelComponent: () => <Translation>{t => t("routes.company.quoteDetails", "Quote details")}</Translation>,
                        component: () => <QuoteForm isModalMode={true} readOnly={true} isOfferMode={false}></QuoteForm>
                    },
                ]
            },
            {
                path: urls.NEW_QUOTE,
                exact: true,
                labelComponent: () => <Translation>{t => t("routes.public.quote", "Quote")}</Translation>,
                component: QuoteFormPage,
                showSidebar: true,
                icon: "newOffer",
                linkLabel: () => <Translation>{t => t("sidebar.customer.newQuote", "New quote")}</Translation>,
            },
            {
                path: urls.NEW_BOOKING,
                exact: true,
                labelComponent: () => <Translation>{t => t("routes.public.booking", "Booking")}</Translation>,
                component: BookingFormPage,
                showSidebar: true,
                icon: "newOffer",
                linkLabel: () => <Translation>{t => t("sidebar.customer.newBooking", "New booking")}</Translation>,
            },
            {
                path: urls.COMPANY_BOOKINGS,
                label: "",
                component: RouteWithSubRoutes,
                routes: [
                    {
                        path: urls.COMPANY_BOOKINGS,
                        exact: false,
                        labelComponent: () => <Translation>{t => t("routes.company.bookings", "Bookings")}</Translation>,
                        component: BookingPage,
                        showSidebar: true,
                        icon: "calendar",
                        linkLabel: () => <Translation>{t => t("sidebar.customer.bookings", "Bookings")}</Translation>,
                    },
                    {
                        path: urls.COMPANY_BOOKING_DETAILS,
                        exact: true,
                        labelComponent: () => <Translation>{t => t("routes.company.bookingDetails", "Booking details")}</Translation>,
                        component: () => <BookingForm isModalMode={true} readOnly={true} />,
                    },
                ]
            },
            {
                path: urls.USER_PROFILE,
                exact: true,
                labelComponent: () => <Translation>{t => t("routes.customer.userProfile", "Profile")}</Translation>,
                component: CustomerProfile,
                showSidebar: true,
                icon: "person",
                linkLabel: () => <Translation>{t => t("sidebar.customer.userProfile", "Profile")}</Translation>,
            },
        ]
    }
];

export default [...commonRoutes, ...customerSpecificRoutes];
