import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import { withTranslation } from "react-i18next";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";
import { UncontrolledPopover, PopoverBody } from "reactstrap";
import { TextField } from "@material-ui/core";
import { dateFormat } from "util/dateTime";
import { languageTypeDateFnLocales } from "util/General";
import { defaultStaticRanges, defaultInputRanges } from "util/reactDateRange/defaultRanges";

class DateRange extends Component {
    static propTypes = {
        onStartDateChange: PropTypes.func.isRequired,
        onEndDateChange: PropTypes.func.isRequired,
        startDate: PropTypes.object,
        endDate: PropTypes.object,
        locale: PropTypes.object,
    };

    formatDateRange = () => {
        const startDate = this.props.startDate;
        const endDate = this.props.endDate;

        let startDateFormatted = dateFormat;
        let endDateFormatted = dateFormat;

        if (!startDate && !endDate) {
            return "";
        }

        if (startDate) {
            startDateFormatted = new moment(startDate).format(dateFormat);
        }
        if (endDate) {
            endDateFormatted = new moment(endDate).format(dateFormat);
        }

        return `${startDateFormatted} - ${endDateFormatted}`;
    }

    isValidDate(date) {
        return date instanceof Date && isFinite(date);
    }

    handleDateRangePickerChange = ranges => {
        let { startDate, endDate } = ranges.selection;
        if (!this.isValidDate(startDate)) { startDate = null; }
        if (!this.isValidDate(endDate)) { endDate = null; }

        this.props.onStartDateChange(startDate);
        this.props.onEndDateChange(endDate);
    }

    setDatesToNull = () => {
        this.props.onStartDateChange(null);
        this.props.onEndDateChange(null);
    }

    render() {
        const { t, startDate, endDate, locale } = this.props;
        const selectionRange = {
            startDate: startDate,
            endDate: endDate,
            key: "selection",
        };

        return (
            <>
                <div
                    style={{
                        position: 'relative',
                        display: 'inline-block'
                    }}
                >
                    <TextField
                        variant="outlined"
                        fullWidth
                        label={t("common.company.pickupDateLabel", "Pickup date")}
                        type="search"
                        id="DateRangeInput"
                        value={this.formatDateRange()}
                        onChange={() => { }}
                        autoComplete="off"
                    />
                </div>
                <UncontrolledPopover
                    trigger="legacy"
                    placement="bottom"
                    target="DateRangeInput"
                    className="form-date-range-popover"
                >
                    <PopoverBody>
                        <DateRangePicker
                            onChange={this.handleDateRangePickerChange}
                            ranges={[selectionRange]}
                            dateDisplayFormat={dateFormat}
                            showDateDisplay={false}
                            locale={locale}
                            staticRanges={defaultStaticRanges}
                            inputRanges={defaultInputRanges}
                        />
                    </PopoverBody>
                </UncontrolledPopover>
            </>
        );
    }
}

function mapStateToProps(state, parentProps) {
    const locale = languageTypeDateFnLocales[state.language];
    return {
        locale: parentProps.locale || locale
    }
}

export default connect(mapStateToProps)(withTranslation()(DateRange));
