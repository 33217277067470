import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import { withTranslation } from "react-i18next"
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";

import ajax from "remotes/ajax";
import { getCustomerDetailsUrl } from "config/urls";
import { getServerErrorMessage } from "util/Errors";

import { withLocalToasts } from "components/hoc";
import Loading from "components/notifications/Loading";
import CreateCustomerModal from "components/user/CreateCustomerModal";
import ConfirmationModal from "components/form/ConfirmationModal";
import { Container, Box, Paper, Grid, Button } from '@material-ui/core';
import Tooltip from "components/form/Tooltip";
import DeleteIcon from '@material-ui/icons/Delete';
import SearchIcon from '@material-ui/icons/Search';

export class CustomerList extends Component {
    static propTypes = {
    };

    constructor(props) {
        super(props);

        this.state = {
            customers: [],
            // misc
            customersLoading: false,
            deleteCustomerLoading: false,
            isConfirmDeleteOpen: false,
            customerToDelete: null
        };
    }

    async componentDidMount() {
        this.getCustomers();
    }

    componentDidUpdate(prevProps, prevState) {
    }

    render() {
        return (
            <Container disableGutters maxWidth={false}>
                <Box m={2}>
                    <Paper elevation={3}>
                        <Box p={4}>
                            <Grid container>
                                <CreateCustomerModal onSuccess={() => this.getCustomers()} />
                            </Grid>

                            {this.renderTable()}
                            {this.renderConfirmDeletionModal()}
                        </Box>
                    </Paper>
                </Box>
            </Container>
        );
    }

    renderTable() {
        const { t } = this.props;
        const { customers, customersLoading } = this.state;

        let NoDataIndication;
        if (customersLoading || !customers) {
            NoDataIndication = <Loading />;
        }
        else if (!customers.length) {
            NoDataIndication = <div className="card-content text-center">{t("customer.customerList.customerListEmpty", "No customers")}</div>;
        }
        else {
            NoDataIndication = undefined;
        }

        const columns = [
            {
                dataField: "id",
                text: t("customer.customerList.table.idHeader", "No"),
                sort: true,
                classes: "pr-0",
                headerClasses: "pr-0",
                hidden: true,
            },
            {
                dataField: "reg_code",
                text: t("customer.customerList.table.regCodeHeader", "Registry code"),
                sort: true,
                classes: "",
                headerClasses: "",
                headerStyle: {
                    width: "150px"
                },
            },
            {
                dataField: "name",
                text: t("customer.customerList.table.nameHeader", "Name"),
                sort: true,
                classes: "",
                headerClasses: "",
                searchable: false,
                headerStyle: {
                    width: "150px"
                },
            },
            {
                dataField: "manager.name",
                text: t("customer.customerList.table.managerHeader", "Manager"),
                sort: true,
                classes: "",
                headerClasses: "",
                headerStyle: {
                    width: "150px"
                },
            },
            {
                dataField: "",
                text: t("customer.customerList.table.actionsHeader", "Action"),
                formatter: this.actionColumnFormatter.bind(this),
                headerClasses: "text-right frozen-last-column",
                classes: "td-actions text-right frozen-last-column",
                searchable: false,
                headerStyle: {
                    width: "70px"
                },
            },
        ];

        const defaultSorted = [{
            dataField: "id",
            order: "desc",
        }];

        const paginationOptions = {
            hidePageListOnlyOnePage: true,
            paginationSize: 50,
            hideSizePerPage: customers.length < 10,
        };

        const rowEvents = {
            onClick: (e, row) => {
                this.onClickView(row);
            },
        };

        return (
            <div className="card-body table-full-width">
                <ToolkitProvider
                    keyField="id"
                    data={customers}
                    columns={columns}
                    bootstrap4={true}
                    search={{
                        // searchFormatted: true,
                    }}
                >
                    {
                        props => (
                            <div>
                                <BootstrapTable
                                    {...props.baseProps}
                                    defaultSorted={defaultSorted}
                                    bordered={false}
                                    wrapperClasses="table-responsive overflow-auto"
                                    classes=""
                                    rowClasses="customer-item pointer"
                                    pagination={paginationFactory(paginationOptions)}
                                    rowEvents={rowEvents}
                                    hover
                                    filter={filterFactory()}
                                    noDataIndication={NoDataIndication}
                                />
                            </div>
                        )
                    }
                </ToolkitProvider>
            </div>
        );
    }

    renderConfirmDeletionModal() {
        const onToggle = () => {
            this.setState(prevState => ({ isConfirmDeleteOpen: !prevState.isConfirmDeleteOpen }));
        }

        const onConfirmFunc = () => {
            const customer = this.state.customerToDelete;
            if (customer) {
                this.deleteCustomer(customer)
                    .then(() => {
                        this.setState({
                            isConfirmDeleteOpen: false,
                            customerToDelete: null,
                        });
                    })
            } else {
                console.log("Deletion customer object missing.");
            }
        }

        return (
            <ConfirmationModal
                isOpen={this.state.isConfirmDeleteOpen}
                onToggle={onToggle}
                onCancel={onToggle}
                onConfirm={onConfirmFunc}
                isDisabled={this.state.deleteCustomerLoading}
                isLoading={this.state.deleteCustomerLoading}
                title={this.props.t("customer.deleteCustomerModalTitle", "Confirm customer deletion")}
            >
                {this.props.t("customer.deleteCustomerModalText", "Are you sure you want to delete this customer?")}
            </ConfirmationModal>
        );
    }

    actionColumnFormatter(cell, row) {
        return (
            <>  <Tooltip title={this.props.t("customer.customerList.table.viewDetailsActionTitle", "Details")}>
                    <Button
                        type="button"
                        className="linkStyleButton"
                        onClick={() => this.onClickView(row)}
                    >
                        <SearchIcon />
                    </Button>
                </Tooltip>
                <Tooltip title={this.props.t("customer.customerList.table.deleteCustomerActionTitle", "Delete customer")}>
                    <Button
                        type="button"
                        className="linkStyleButton"
                        onClick={(e) => this.onClickDelete(e, row)}
                    >
                        <DeleteIcon />
                    </Button>
                </Tooltip>
            </>
        );
    }

    async onClickView(customer) {
        //const message = "Customer ID: " + customer.id;
        //this.props.toastAlerts.createAlert(message, { title: "CustomerList.onClickView()" });
        this.props.history.push(getCustomerDetailsUrl(customer.id));
    }

    onClickDelete(event, customer) {
        event.preventDefault();
        event.stopPropagation();

        this.setState({
            isConfirmDeleteOpen: true,
            customerToDelete: customer
        });
    }

    getCustomers() {
        this.setState({ customersLoading: true });
        ajax.doGetCustomerList()
            .then(data => {
                this.setState({ customers: data });
            })
            .catch(error => {
                const errorMessage = getServerErrorMessage(error);
                this.props.toastAlerts.createAlert(errorMessage, {
                    type: this.props.toastAlerts.ALERT_TYPE.ERROR,
                });
            })
            .finally(() => {
                this.setState({ customersLoading: false });
            })
    }

    deleteCustomer(customer) {
        const id = customer.id;
        this.setState({
            deleteCustomerLoading: true
        });
        return ajax.doDeleteCustomer(id)
            .then(() => {
                this.setState(prevState => {
                    return {
                        customers: prevState.customers.filter(item => item.id !== id)
                    };
                });
                this.props.addLocalToast(
                    this.props.toastAlerts.createAlert(
                        this.props.t("customer.deleteCustomerSuccessMessage", "Customer has been deleted."),
                        {
                            type: this.props.toastAlerts.ALERT_TYPE.INFO
                        }
                    )
                );
            })
            .catch(error => {
                const errorMessage = getServerErrorMessage(error);
                const errorTitle = this.props.t("customer.deleteCustomerErrorTitle", "Failed to delete customer.");
                this.props.toastAlerts.createAlert(errorMessage, {
                    type: this.props.toastAlerts.ALERT_TYPE.ERROR,
                    title: errorTitle
                });
            })
            .finally(() => this.setState({ deleteCustomerLoading: false }));
    }

    onCreateCustomerSuccess(data) {
        this.getCustomers();
    }
}

function mapStateToProps(state) {
    return {
    };
}

export default withRouter(connect(mapStateToProps, {})(withTranslation()(withLocalToasts(CustomerList))));
