import React from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { FormGroup } from "reactstrap";
import classNames from "classnames";
import PlacesAutocomplete, { geocodeByAddress } from "react-places-autocomplete";
import LoadingAnimation from "components/Loader/WrappedPulseLoader";
import ValidationMessage from "components/form/ValidationMessage";
import { TextField, Paper } from '@material-ui/core';
import { LocationOn } from '@material-ui/icons';


class LocationSearchInput extends React.Component {
    static propTypes = {
        address: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
        handleAddress: PropTypes.func.isRequired,
        placeholder: PropTypes.string,
        disabled: PropTypes.bool,
        validationError: PropTypes.string,
        labelText: PropTypes.string.isRequired,
        isRequired: PropTypes.bool,
    }

    constructor(props) {
        super(props);
        this.state = {
            address: this.props.address,
            addressValid: true,
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.address !== this.props.address) {
            this.propAddressUpdate();
        }
    }

    propAddressUpdate() {
        this.setState({
            address: this.props.address,
        });
    }

    handleChange = address => {
        this.setState({ address });
        if (this.props.onChange) {
            this.props.onChange(address);
        }
        // address ? this.setState({ addressValid: true }) : this.setState({ addressValid: false });
    };

    handleSelect = address => {
        this.setState({ address });
        if (!address) {
            return false;
        }
        geocodeByAddress(address)
            .then(results => results[0])
            .then(result => {
                if (result && this.props.handleAddress) {
                    const components = result.address_components;
                    const location = this.state.address;

                    const street_number = this.getComponentValue("street_number", "short_name", components);
                    const route = this.getComponentValue("route", "long_name", components);
                    const address = street_number ? `${route}, ${street_number}` : route;

                    const city = this.getComponentValue("locality", "long_name", components);
                    const county = this.getComponentValue("administrative_area_level_1", "short_name", components);
                    const country = this.getComponentValue("country", "short_name", components);
                    const postal_code = this.getComponentValue("postal_code", "short_name", components);

                    this.props.handleAddress(location, address, city, postal_code, county, country);
                }
            })
            .catch(() => {/* console.error('Error - geocodeByAddress:', error); */ });
    };

    getComponentValue(type, name_format, components = []) {
        const component = components.find(c => c.types.includes(type));
        if (component) {
            return component[name_format];
        }
        return "";
    }

    render() {
        const { t, placeholder } = this.props;
        const searchOptions = {
            //            componentRestrictions: { country: "ee" }

        };
        const placeholderOrDefault = placeholder || t("form.googleAutocomplete.defaultPlaceholder", "Enter location");

        return (
            <FormGroup>
                <PlacesAutocomplete
                    value={this.state.address}
                    onChange={this.handleChange}
                    onSelect={this.handleSelect}
                    searchOptions={searchOptions}
                >
                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <div className="autocomplete-wrapper">
                            <TextField
                                //invalid={!this.state.addressValid}
                                {...getInputProps({
                                    placeholder: placeholderOrDefault,
                                    className: classNames(
                                        { "is-invalid-custom": this.props.validationError }),
                                    disabled: this.props.disabled,
                                })}
                                fullWidth
                                variant="outlined"
                                label={this.props.labelText}
                                InputProps={{
                                    endAdornment: (
                                        <LocationOn />
                                    )
                                }}
                            ></TextField>
                            {this.state.address && suggestions.length > 0 &&
                                <Paper>
                                    {loading && <div className="autocomplete-loading-container"><LoadingAnimation /></div>}
                                    {suggestions.map(suggestion => {
                                        const className = suggestion.active ?
                                            "suggestion-item--active" :
                                            "suggestion-item";
                                        // inline style for demonstration purpose
                                        const style = suggestion.active ?
                                            { backgroundColor: "#fafafa", cursor: "pointer", padding: '1rem' } :
                                            { backgroundColor: "#ffffff", cursor: "pointer", padding: '1rem' };
                                        return (
                                            <div
                                                {...getSuggestionItemProps(suggestion, {
                                                    className,
                                                    style,
                                                })}
                                                key={suggestion.placeId} // didn't work when added to function above
                                            >
                                                <span>{suggestion.description}</span>
                                            </div>
                                        );
                                    })}
                                </Paper>
                            }
                        </div>
                    )}
                </PlacesAutocomplete>
                <ValidationMessage
                    message={this.props.validationError}
                    isVisible={this.props.validationError}
                />
            </FormGroup>
        );
    }
}

export default withTranslation()(LocationSearchInput);
