import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Row, Col } from "reactstrap";

class ErrorMessage extends Component {
    static propTypes = {
        errorMessage: PropTypes.string,
        isError: PropTypes.bool,
        isInvisible: PropTypes.bool,
        className: PropTypes.string,
    };

    render() {
        return (
            <Row
                className={
                    classNames("text-center",
                        this.props.className,
                        {
                            invisible: this.props.isInvisible && !this.props.isError,
                            "d-none": !this.props.isInvisible && !this.props.isError,
                        }
                    )
                }
            >
                <Col>
                    <span className="error-msg is-invalid-custom-message-color">
                        {this.props.errorMessage || this.props.t("common.unknownError", "Unknown error, try again.")}
                    </span>
                </Col>
            </Row>
        );
    }
}

export default withTranslation()(ErrorMessage);
