import { isCustomerMode, isCompanyMode } from "util/Access";

const UUID_REGEX = "([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})?";
const ID_PLACEHOLDER = ":id(\\d+)";
const REFERENCE_PLACEHOLDER = `:reference${UUID_REGEX}`;

const urls = {
    DOMAIN: "/",
    ANY: "/*",

    // public
    LOGIN: "/login",
    FORGOT_PASSWORD: "/forgot-password",
    CHANGE_PASSWORD: `/user/${ID_PLACEHOLDER}/change-password/:token`,
    NEW_CUSTOMER: "/sign-up",
    ACTIVATE_USER: `/user/${ID_PLACEHOLDER}/activate/:token`,

    // common
    PRIVACY_POLICY: "/privacy-policy",
    COOKIE_POLICY: "/cookies",
    QUOTE_THANK_YOU: "/quotes/thankyou",
    OFFER_OVERVIEW: `/offer/${REFERENCE_PLACEHOLDER}`,
    BOOKING_BY_OFFER_REFERENCE: `/offer/${REFERENCE_PLACEHOLDER}/booking`,
    BOOKING_OVERVIEW: `/bookings/${REFERENCE_PLACEHOLDER}/overview`,
    BOOKING_THANK_YOU: "/bookings/thankyou",
    NEW_QUOTE: "/quote",
    NEW_BOOKING: "/booking",

    USER_PROFILE: "/profile",

    // logistics
    COMPANY_QUOTES: "/quotes",
    COMPANY_NEW_OFFER: `/quotes/offer`,
    COMPANY_QUOTE_DETAILS: `/quotes/${ID_PLACEHOLDER}/details`,
    COMPANY_QUOTE_DETAILS_ALTERNATIVE: `/quotes/${ID_PLACEHOLDER}/offer`,
    COMPANY_QUOTE_HISTORY: `/quotes/${ID_PLACEHOLDER}/history`,

    COMPANY_BOOKINGS: "/bookings",
    COMPANY_BOOKING_DETAILS: `/bookings/${ID_PLACEHOLDER}/details`,

    CUSTOMER_LIST: "/customers",
    CUSTOMER_DETAILS: `/customers/${ID_PLACEHOLDER}`,
};

export function getDefaultUrl(user) {
    if (isCompanyMode()) {
        return urls.COMPANY_QUOTES;
    } else if (isCustomerMode()) {
        return urls.COMPANY_QUOTES;
    }

    return urls.NEW_QUOTE; // shows quote form in public
}

export function getBookingUrl(id) {
    return urls.COMPANY_BOOKING_DETAILS.replace(ID_PLACEHOLDER, id);
}

export function getBookingReferenceUrl(offerReference) {
    return urls.BOOKING_BY_OFFER_REFERENCE.replace(REFERENCE_PLACEHOLDER, offerReference);
}

export function getBookingStatusUrl(reference) {
    return urls.BOOKING_OVERVIEW.replace(REFERENCE_PLACEHOLDER, reference);
}

export function getQuoteHistoryUrl(id) {
    return urls.COMPANY_QUOTE_HISTORY.replace(ID_PLACEHOLDER, id);
}

export function getQuoteDetailsrUrl(id) {
    return urls.COMPANY_QUOTE_DETAILS.replace(ID_PLACEHOLDER, id);
}

export function getQuoteOfferUrl(id) {
    return getQuoteDetailsrUrl(id);
}

export function getOfferOverviewUrl(reference) {
    return urls.OFFER_OVERVIEW.replace(REFERENCE_PLACEHOLDER, reference);
}

export function getCustomerDetailsUrl(id) {
    return urls.CUSTOMER_DETAILS.replace(ID_PLACEHOLDER, id);
}

export default urls;
