import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import urls from "config/urls";
import { Container, Row, Col, Card, CardHeader, CardTitle, CardBody } from "reactstrap";

export default function QuoteThankYou() {
    const { t } = useTranslation();
    return (
        <div className="content">
            <Container disableGutters>
                <Row className="justify-content-center">
                    <Col sm="12">
                        <Card className="card-wizard">
                            <CardHeader>
                                <CardTitle className="text-center">
                                    <h4>{t("page.quoteThankYou.heading", "Thank you for the quote request!")}</h4>
                                </CardTitle>
                            </CardHeader>
                            <CardBody>
                                <div className="text-center py-3">
                                    <strong>
                                        {t("page.quoteThankYou.quoteReceivedText", "Your quote request has been received and we will contact you in a few minutes by e-mail.")}
                                    </strong>
                                    <div className="h1"> <i className="fa fa-thumbs-o-up" /> </div>
                                </div>
                                <div className="text-center py-3">
                                    <NavLink
                                        className="btn btn-primary"
                                        to={urls.NEW_QUOTE}
                                    >
                                        {t("page.quoteThankYou.newQuoteButton", "Begin new quote request")}
                                    </NavLink>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
