import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import CreateCustomerForm from './CreateCustomerForm';
import { Grid, Button, Box, Dialog, DialogTitle, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';

const CreateCustomerModal = (props) => {
    const {
        buttonLabel,
        title,
        onSuccess,
    } = props;
    const { t } = useTranslation()

    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    const close = () => setModal(false);

    const _onSuccess = data => {
        setModal(false);
        if (onSuccess) onSuccess(data);
    }


    return (
        <div style={{ width: '100%' }}>
            <Grid container>
                <Button
                    className="linkStyleButton"
                    style={{
                        textTransform: 'none',
                        fontSize: '1rem',
                        padding: '0',
                        marginLeft: 'auto'
                    }}
                    onClick={toggle}>
                    <AddIcon
                        style={{
                            marginBottom: '0.2rem',
                            marginRight: '0.2rem'
                        }}
                    />
                    {buttonLabel || t("customer.createCustomerModal.openButton", "Create new customer")}
                </Button>
            </Grid>
            <Dialog open={modal} toggle={toggle}>
                <Box p={2}>
                    <CloseIcon
                        className="modalClose"
                        onClick={close} />
                    <DialogTitle>
                        <Typography
                            variant="h5">
                            {title || t("customer.createCustomerModal.title", "Create new customer")}
                        </Typography>

                    </DialogTitle>
                    <Box p={2}>
                        <CreateCustomerForm onSuccess={_onSuccess} createAsCompany />
                    </Box>
                </Box>
            </Dialog>
        </div>
    );
}

export default CreateCustomerModal;
