import React from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

export default function CenteredRowLoading() {
    const { t } = useTranslation();

    return (
        <div className="row justify-content-center">
            <div className="col-auto">
                <span>
                    <FontAwesomeIcon icon={faSpinner} className="spin mr-2" />
                    {t("common.loading", "Loading")}...
                    </span>
            </div>
        </div >
    );

}
