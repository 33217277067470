import * as actionType from "redux/actions/actionTypes";
import { combineReducers } from "redux";
// import moment from "moment";

const defaultChosenPickupStart = null; // moment().startOf("day").subtract("14", "day");
const defaultChosenPickupEnd = null; // moment().endOf("day").add("14", "day");

export const chosenClient = (client = "", action) => {
    switch (action.type) {
        case actionType.SET_BOOKING_FILTER_CLIENT:
            return action.client;

        default:
            return client;
    }
};

export const chosenLocationFrom = (location = "", action) => {
    switch (action.type) {
        case actionType.SET_BOOKING_FILTER_LOCATION_FROM:
            return action.location;

        default:
            return location;
    }
};

export const chosenLocationTo = (location = "", action) => {
    switch (action.type) {
        case actionType.SET_BOOKING_FILTER_LOCATION_TO:
            return action.location;

        default:
            return location;
    }
};

export const chosenPickupStart = (date = defaultChosenPickupStart, action) => {
    switch (action.type) {
        case actionType.SET_BOOKING_FILTER_PICKUP_START:
            return action.date;

        default:
            return date;
    }
};

export const chosenPickupEnd = (date = defaultChosenPickupEnd, action) => {
    switch (action.type) {
        case actionType.SET_BOOKING_FILTER_PICKUP_END:
            return action.date;

        default:
            return date;
    }
};

export const chosenGenericSearch = (search = "", action) => {
    switch (action.type) {
        case actionType.SET_BOOKING_FILTER_GENERIC_SEARCH:
            return action.search;

        default:
            return search;
    }
};

const filterReducer = combineReducers({
    chosenClient, chosenLocationFrom, chosenLocationTo, chosenPickupStart, chosenPickupEnd, chosenGenericSearch,
});

const bookingSearch = (state, action) => {
    return filterReducer(state, action);
};

export default bookingSearch;
