let axios = require('axios');

let baseURL = process.env.REACT_APP_API_HOST;
if (!baseURL) {
    console.error("API Base url (REACT_APP_API_HOST) is not set!");
}

let axiosInstance = axios.create({
    baseURL: baseURL,
    headers: {
        "Accept-Language": "et",
    }
});

module.exports = axiosInstance;
