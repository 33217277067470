const green = "#43A047";
const red = "#F4511E";
const blue = "#1E88E5";
const yellow = "#FFB300";
const gray = "#A4A8AD";
const purple = "#5E35B1";
const opacity = "18";

const styles = (theme) => ({
  container: {
    position: "relative",
    marginBottom: "2rem",
  },
  gridContainer: {
    width: "97%",
  },
  upperTextField: {
    marginBottom: "1rem",
  },
  descriptionDropdownDiv: {
    position: "absolute",
    top: "0",
    right: "0",
    "@media (max-width: 500px)": {
      right: "-10px",
    },
  },
  dropdownToggle: {
    width: "3rem",
    color: "#A4A8AD!important",
    border: "none!important",
    background: "inherit!important",
  },
  descriptionHeight: {
    height: "100%",
  },
  checkBoxContainer: {
    border: "1px solid #ced4da",
    borderRadius: "4px",
    height: "9rem", //selle võiks pärast maha võtta
    display: "flex",
    alignItems: "center",
    position: "relative",
    "&:hover": {
      background: "#F6F8FB",
    },
  },
  containerWide: {
    height: "8.5rem",
  },
  containerNarrow: {
    height: "5rem",
  },
  quoteFilterChContainer: {
    padding: "1rem",
  },
  checkBoxContainerChecked: {
    background: "rgba(239, 231, 252, 0.4)",
    border: "2px solid #611AD0",
  },
  markerGreen: {
    borderColor: green,
    backgroundColor: green + opacity,
  },
  markerRed: {
    borderColor: red,
    backgroundColor: red + opacity,
  },
  markerBlue: {
    borderColor: blue,
    backgroundColor: blue + opacity,
  },
  markerYellow: {
    borderColor: yellow,
    backgroundColor: yellow + opacity,
  },
  markerGray: {
    borderColor: gray,
    backgroundColor: gray + opacity,
  },
  markerPurple: {
    borderColor: purple,
    backgroundColor: purple + opacity,
  },
  checkbox: {
    position: "absolute",
    right: "0",
    top: "0",
  },
  checkboxLabel: {
    marginLeft: "0",
    marginBottom: "0",
    padding: "2rem 7rem 2rem 2rem",
  },
  labelText: {
    fontWeight: "800",
  },
  checkBoxHelperText: {
    color: "#0062EC",
    fontWeight: "400",
  },
  checkBoxCheckedHelperText: {
    color: "#611AD0",
    fontWeight: "500",
  },
});

export { styles };
