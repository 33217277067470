import * as actionType from "redux/actions/actionTypes";

export const onSetCountryList = (countryOptions) => {
    return {
        type: actionType.SET_COUNTRY_LIST,
        countryOptions,
    };
};

export const onSetCountryListFetchFail = (error) => {
    return {
        type: actionType.SET_COUNTRY_LIST_FETCH_FAIL,
        error,
    };
};

export const onSetTransportManagerList = (transportManagerOptions) => {
    return {
        type: actionType.SET_TRANSPORT_MANAGER_LIST,
        transportManagerOptions,
    };
};

export const onSetTransportManagerListFetchFail = (error) => {
    return {
        type: actionType.SET_TRANSPORT_MANAGER_LIST_FETCH_FAIL,
        error,
    };
};

export const onSetPriceTypeList = (priceTypeOptions) => {
    return {
        type: actionType.SET_PRICE_TYPE_LIST,
        priceTypeOptions,
    };
};

export const onSetPriceTypeListFetchFail = (error) => {
    return {
        type: actionType.SET_PRICE_TYPE_LIST_FETCH_FAIL,
        error,
    };
};

export const onSetCommunicationCountryCode = (communicationCountryCode) => {
    return {
        type: actionType.SET_COMMUNICATION_COUNTRY_CODE,
        communicationCountryCode,
    };
};

export const onSetCommunicationCountryCodeFetchFail = (error) => {
    return {
        type: actionType.SET_COMMUNICATION_COUNTRY_CODE_FETCH_FAIL,
        error,
    };
};
