import React from "react";
import Search from "components/page/quotes/Search";
import QuotesDatatableList from "components/page/quotes/QuotesDatatableList";
import { isCustomerMode } from "util/Access";
import { Container, Box } from '@material-ui/core';

export default function CustomerQuotesPage() {
    return (
        <div className="content">
            <Container
                disableGutters
                maxWidth={false}
            >
                <Box mt={6} mb={2}>
                    <Search isCustomerMode={isCustomerMode()} />
                </Box>
                <QuotesDatatableList isCustomerMode={isCustomerMode()} />
            </Container >
        </div>
    );
}
