import React from "react";
import { withTranslation } from "react-i18next";
import propTypes from "prop-types";
import { NavLink, UncontrolledTooltip } from "reactstrap";
import { withRouter, matchPath } from "react-router";
import { connect } from "react-redux";
import urls from "config/urls";
import { logout } from "../../util/Auth";
import { isPublicMode } from "util/Access";
import LanguageMode from "components/Navbar/LanguageMode";
import { Box, ButtonGroup, Button } from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';


//import "./Navbar.scss"

class TopNavbar extends React.Component {
    static propTypes = {
        routes: propTypes.any.isRequired
    }

    getRouteComponent = (routes) => {
        const pathname = this.props.location.pathname;
        // eslint-disable-next-line array-callback-return
        for (const route of routes) {
            const routeLabelComponentOrText = route.labelComponent ? <route.labelComponent /> : route.label;
            if (route.routes) {
                // eslint-disable-next-line array-callback-return
                for (const subRoute of route.routes) {
                    if (matchPath(pathname, { path: subRoute.path })) {
                        const subrouteLabelComponentOrText = subRoute.labelComponent ? <subRoute.labelComponent /> : subRoute.label;

                        return (
                            <span>
                                {routeLabelComponentOrText}
                                {(routeLabelComponentOrText && subrouteLabelComponentOrText) ? " · " : ""}
                                {subrouteLabelComponentOrText}
                            </span>
                        );
                    }
                }
            }
            if (matchPath(pathname, { path: route.path, exact: true })) {
                return routeLabelComponentOrText;
            }
        }

        return "";
    };

    render() {
        const { t } = this.props;

        return (
            <Box m={2}>
                <ButtonGroup
                    variant="text"
                    color="primary"
                    size="large"
                    aria-label="text primary button group"
                    style={{ marginLeft: 'auto' }}>
                    <LanguageMode />
                    {!isPublicMode() &&
                        <Button className="logout-button">
                            <NavLink
                                to={urls.DOMAIN}
                                onClick={logout}
                            >
                                <ExitToAppIcon id="navbar-logout-icon" />
                                <UncontrolledTooltip
                                    placement="bottom"
                                    style={{ marginTop: '1rem' }}
                                    target="navbar-logout-icon">
                                    {t("navbar.logout", "Log out")}
                                </UncontrolledTooltip>
                            </NavLink>

                        </Button>
                    }
                </ButtonGroup>
            </Box>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user ? state.auth.user : null,
        headerSuffix: state.navigation.headerSuffix,
    };
};

export default withRouter(
    connect(mapStateToProps)(withTranslation()(TopNavbar))
);
