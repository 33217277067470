import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import { withTranslation } from "react-i18next";
import get from "lodash/get";

import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";


import ajax from "remotes/ajax";
import { getServerErrorMessage } from "util/Errors";
import { getCustomerUserRoleTranslated } from "config/customerUserRoles";

import { withLocalToasts } from "components/hoc";
import Loading from "components/notifications/Loading";
import { CreateCustomerUserForm, EditCustomerUserForm } from "components/user/CustomerUserForm";
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { Paper, Box, Typography, Grid } from '@material-ui/core';

import "./CustomerUserList.scss";

export class CustomerUserList extends Component {
    static propTypes = {
        customerId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    };

    constructor(props) {
        super(props);

        this.state = {
            users: [],
            // misc
            customerUserId: null,
            editModal: false,
            usersLoading: false
        };
    }

    async componentDidMount() {
        this.getUsers();
    }

    componentDidUpdate(prevProps, prevState) {
    }

    render() {
        const { customerId, t } = this.props;
        const { customerUserId, editModal } = this.state;
        return (
            <Paper elevation={3}>
                <Box p={2}>
                    <Grid container>
                        <Typography
                            variant="h4"
                            gutterBottom
                            style={{ marginRight: 'auto' }}
                        >
                            {t("customer.userList.title", "Users")}
                        </Typography>
                        <div >
                            <CreateCustomerUserForm
                                isModalMode
                                customerId={customerId}
                                onSuccess={() => this.getUsers()}
                            />
                        </div>
                    </Grid>
                    {this.renderTable()}

                    <EditCustomerUserForm
                        isModalMode
                        hideModalButton
                        modal={editModal}
                        customerId={customerId}
                        customerUserId={customerUserId}
                        onSuccess={
                            () => {
                                this.getUsers();
                                this.setState({ editModal: false });
                            }
                        }
                        onToggle={
                            () => this.setState(prevState => (
                                { editModal: !prevState.editModal }
                            ))
                        }
                    />
                </Box >
            </Paper >
        );
    }

    renderTable() {
        const { t } = this.props;
        const { users } = this.state;

        let NoDataIndication;
        if (!users) {
            NoDataIndication = <Loading />;
        }
        else if (!users.length) {
            NoDataIndication = <div className="card-content text-center">{t("customer.userList.table.userListEmpty", "No users")}</div>;
        }
        else {
            NoDataIndication = undefined;
        }

        const columns = [
            {
                dataField: "id",
                text: "",
                sort: true,
                classes: "pr-0",
                headerClasses: "pr-0",
                hidden: true,
            },
            {
                dataField: "user.email",
                text: t("customer.userList.table.emailHeader", "Email"),
                sort: true,
                classes: "",
                headerClasses: "",
                searchable: true,
                headerStyle: {
                    width: "200px"
                },
            },
            {
                dataField: "user.first_name",
                text: t("customer.userList.table.firstNameHeader", "First name"),
                sort: true,
                classes: "",
                headerClasses: "",
                searchable: true,
                headerStyle: {
                    width: "100px"
                },
            },
            {
                dataField: "user.last_name",
                text: t("customer.userList.table.lastNameHeader", "Last name"),
                sort: true,
                classes: "",
                headerClasses: "",
                searchable: true,
                headerStyle: {
                    width: "100px"
                },
            },
            {
                dataField: "role",
                text: t("customer.userList.table.roleHeader", "Role"),
                sort: true,
                classes: "",
                headerClasses: "",
                headerStyle: {
                    width: "100px"
                },
                formatter: cell => {

                    return getCustomerUserRoleTranslated(cell);
                }
            },
            {
                dataField: "is_active",
                text: t("customer.userList.table.isActiveHeader", "Active"),
                classes: "text-center",
                headerClasses: "is-active-header text-center",
                searchable: false,
                headerStyle: {
                    width: "80px"
                },
                formatter: (cell, row) => {
                    if (cell) {
                        return <CheckBoxIcon />
                    } else {
                        return <CheckBoxOutlineBlankIcon />
                    }
                }
            },
        ];

        const defaultSorted = [{
            dataField: "id",
            order: "desc",
        }];

        const paginationOptions = {
            hidePageListOnlyOnePage: true,
            paginationSize: 50,
            hideSizePerPage: users.length < 10,
        };

        const rowEvents = {
            onClick: (e, row) => {
                this.onEditRow(row);
            },
        };

        return (
            <div className="card-body table-full-width">
                <ToolkitProvider
                    keyField="id"
                    data={users}
                    columns={columns}
                    bootstrap4={true}
                    search={{
                        // searchFormatted: true,
                    }}
                >
                    {
                        props => (
                            <div>
                                <BootstrapTable
                                    {...props.baseProps}
                                    defaultSorted={defaultSorted}
                                    bordered={false}
                                    wrapperClasses="table-responsive overflow-auto"
                                    classes="table table-with-actions"
                                    rowClasses="customer-user-item pointer"
                                    pagination={paginationFactory(paginationOptions)}
                                    rowEvents={rowEvents}
                                    hover
                                    filter={filterFactory()}
                                    noDataIndication={NoDataIndication}
                                />
                            </div>
                        )
                    }
                </ToolkitProvider>
            </div>
        );
    }

    onEditRow(row) {
        this.setState({
            customerUserId: row.id,
            editModal: true
        })
    }

    getUsers() {
        this.setState({ usersLoading: true });
        ajax.doGetCustomerDetails(this.props.customerId)
            .then(data => {
                this.setState({ users: get(data, "users", []) });
            })
            .catch(error => {
                const errorMessage = getServerErrorMessage(error);
                this.props.toastAlerts.createAlert(errorMessage, { title: "CustomerList.onEditRow()", type: "error" });
            })
            .finally(() => {
                this.setState({ usersLoading: false });
            })
    }
}

function mapStateToProps(state) {
    return {
    };
}

export default withRouter(connect(mapStateToProps, {})(withTranslation()(withLocalToasts(CustomerUserList))));
