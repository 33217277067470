import $ from "jquery";
import store from "redux/store";
import get from "lodash/get";
import { toast } from 'react-toastify';
import defaultsDeep from "lodash/defaultsDeep";

import { logOut } from "redux/actions/auth";

import axios from "../axios";


export default {
    // User

    doForgotPassword(email) {
        const url = "forgot-password/";
        return doPost(url, { email }).then(resp => resp.data)
    },

    doResetPassword(id, token) {
        const url = `reset-password/${id}/${token}/`;
        return doGet(url).then(resp => resp.data)
    },

    doChangePassword(key, new_password1, new_password2) {
        const url = "rest-auth/password/change/";
        const data = { new_password1, new_password2 };
        const options = {
            headers: {
                Authorization: `Token ${key}`
            }
        }
        return doPost(url, data, options).then(resp => resp.data)
    },

    doCreateCustomer(customer) {
        // customer { email, password, password2, company_name, company_reg_code, first_name, last_name }
        const url = "register/";
        return doPost(url, customer).then(resp => resp.data)
    },

    doCreateCustomerAsCompany(customer) {
        // data { name, reg_code, users: [{ user: {email, first_name, last_name} }] }
        const url = "customer/";
        return doPost(url, customer).then(resp => resp.data)
    },

    doActivateUser(id, token) {
        const url = `activate-user/${id}/`;
        const data = { token };
        return doPost(url, data).then(resp => resp.data)
    },

    doChangeExistingPassword(newPassword, newPassword2) {
        const url = "/rest-auth/password/change/";
        const data = {
            new_password1: newPassword,
            new_password2: newPassword2
        };
        return doPost(url, data);
    },

    doGetUserDetails() {
        const url = "rest-auth/user/";
        return doGet(url).then(resp => resp.data);
    },

    doUpdateUserDetails({ first_name, last_name }) {
        const url = "rest-auth/user/";
        return doPut(url, { first_name, last_name }).then(resp => resp.data);
    },

    // customers

    doGetCustomerList() {
        const url = "customer/";
        return doGet(url).then(resp => resp.data);
    },

    doGetCustomerDetails(id) {
        const url = `customer/${id}/`;
        return doGet(url).then(resp => resp.data);
    },

    doPatchCustomerDetails(id, partialData) {
        const url = `customer/${id}/`;
        return doPatch(url, partialData).then(resp => resp.data);
    },

    doDeleteCustomer(id) {
        const url = `customer/${id}/`;
        return doDelete(url).then(resp => resp.data);
    },

    doGetCustomerManagerOptionList() {
        const url = "customer-manager-list/";
        return doGet(url).then(resp => resp.data);
    },

    // customer - user

    doCreateCustomerUser(data) {
        // data = { company, is_active, user: { email, first_name, last_name } }
        const url = `customer-user/`;
        return doPost(url, data).then(resp => resp.data);
    },

    doGetCustomerUser(id) {
        const url = `customer-user/${id}/`;
        return doGet(url).then(resp => resp.data);
    },

    doPartialUpdateCustomerUser(id, payload) {
        const url = `customer-user/${id}/`;
        return doPatch(url, payload).then(resp => resp.data);
    },

    // Quotes

    doGetQuotes() {
        const url = "quote/";
        return doGet(url).then(resp => resp.data)
    },

    doMakeQuote(quote) {
        const url = "quote/";
        return doPost(url, quote).then(resp => resp.data);
    },

    doGetQuote(id) {
        const url = `quote/${id}/`;
        return doGet(url).then(resp => resp.data);
    },

    doMakeOffer(quote) {
        const url = `quote/${quote.id}/make-offer/`;
        return doPut(url, quote).then(resp => resp.data);
    },

    doMakeOfferDirectly(quote) {
        const url = "quote/make-offer/";
        return doPost(url, quote).then(resp => resp.data);
    },

    doArchiveQuote(id) {
        const url = `quote/${id}/archive/`;
        return doDelete(url).then(resp => resp.data);
    },

    doUnarchiveQuote(id) {
        const url = `quote/${id}/unarchive/`;
        return doPatch(url).then(resp => resp.data);
    },

    // Offers

    doGetOffer(reference) {
        const url = `/offer/${reference}/`;
        return doGet(url).then(resp => resp.data);
    },

    doPostRejectOffer(reference, feedback) {
        const url = `offer/${reference}/reject/`;
        return doPost(url, feedback).then(resp => resp.data);
    },

    doGetBookingPrefillByOfferReference(offerReference) {
        const url = `offer/${offerReference}/booking/`;
        return doGet(url).then(resp => resp.data);
    },

    // Bookings

    doGetBookings() {
        const url = "booking/";
        return doGet(url).then(resp => resp.data);
    },

    doMakeBooking(booking) {
        const url = "booking/";
        return doPost(url, booking).then(resp => resp.data);
    },

    doMakeBookingWithOfferReference(booking, offerReference) {
        const url = `offer/${offerReference}/booking/`;
        return doPost(url, booking).then(resp => resp.data);
    },

    doGetBooking(id) {
        const url = `booking/${id}/`;
        return doGet(url).then(resp => resp.data);
    },

    doGetBookingStatusByReference(offerReference) {
        const url = `booking/status/${offerReference}/`;
        return doGet(url).then(resp => resp.data);
    },

    doSaveBooking(booking) {
        const url = `booking/${booking.id}/`;
        return doPut(url, booking).then(resp => resp.data);
    },

    // Misc

    doGetCountryCodes() {
        const url = "country-codes/";
        return doGet(url).then(resp => resp.data).catch(error => {
            toast.error("Failed to load country options.")
            throw error;
        });
    },

    doGetQuoteCountryList() {
        const url = "country-list/";
        return doGet(url).then(resp => resp.data).catch(error => {
            toast.error("Failed to load country options.")
            throw error;
        });;
    },

    doGetTransportManagerList() {
        const url = "logistic-user-list/";
        return doGet(url).then(resp => resp.data);
    },

    doGetPriceTypeList() {
        const url = "price-type-list/";
        return doGet(url).then(resp => resp.data);
    },

    doGetCommunicationCountryCode() {
        const url = "communication-country-code/";
        return doGet(url).then(resp => resp.data);
    }
};

/*
    Firefox can't handle 200 with no response body.

    $.ajax needs either 204 as response or anything as response body.
    https://stackoverflow.com/a/27129567
 */

function doGet(url, data, options) {
    return doAjax(url, "GET", data, options);
}

function doPost(url, data, options) {
    return doAjax(url, "POST", data, options);
}

function doPut(url, data, options) {
    return doAjax(url, "PUT", data, options);
}

function doPatch(url, data, options) {
    return doAjax(url, "PATCH", data, options);
}

function doDelete(url, data, options) {
    return doAjax(url, "DELETE", data, options);
}

function doAjax(url, method, data, options = {}) {
    //const token = store.getState().auth.token;
    const config = defaultsDeep(options, {
        headers: {
            "X-CSRFToken": getToken(),
        }
    });

    return axios({
        method,
        url,
        data,
        ...config
    })
        .catch(error => {
            if (error.status === 403) {
                store.dispatch(logOut());
            }

            throw error;
        });
}

// https://docs.djangoproject.com/en/2.1/ref/csrf/
function getToken() {
    const name = "csrftoken";
    let cookieValue = null;

    if (document.cookie && document.cookie !== "") {
        const cookies = document.cookie.split(";");
        for (let i = 0; i < cookies.length; i++) {
            const cookie = $.trim(cookies[i]);
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === name + "=") {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}
