import React from "react";
import PropTypes from "prop-types";
import ValidationMessage from "components/form/ValidationMessage";
import { UncontrolledPopover, PopoverBody } from "reactstrap";
import classNames from "classnames";
import { TextField, TextareaAutosize } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { styles } from '../../assets/stylesMUI/shipmentRowStyles';

const stylesz = styles;

class TextAreaPopup extends React.Component {
    static propTypes = {
        value: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        validationMessage: PropTypes.string,
        inputProps: PropTypes.shape({
            className: PropTypes.string,
            labelText: PropTypes.string,
            placeholder: PropTypes.string,
            required: PropTypes.bool,
            id: PropTypes.string.isRequired,
            disableValidationBorder: PropTypes.bool,
            validationMessageEnabled: PropTypes.bool,
            extraProps: PropTypes.object,
        }),
        disableTextArea: PropTypes.bool,
        textAreaProps: PropTypes.shape({
            className: PropTypes.string,
            labelText: PropTypes.string,
            placeholder: PropTypes.string,
            required: PropTypes.bool,
            disableValidationBorder: PropTypes.bool,
            disableValidationMessage: PropTypes.bool,
            test: PropTypes.string,
            extraProps: PropTypes.object,
        }),
        classes: PropTypes.object.isRequired,
    }

    static defaultProps = {
        value: "",
        disabled: false,
        validationMessage: "",
        inputProps: {},
        disableTextArea: true,
        textAreaProps: {},
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.descriptionHeight}>
                <TextField
                inputProps={{ style: { height: "6.2rem" }}}
                    variant="outlined"
                    required
                    fullWidth
                    multiline
                    id={this.props.inputProps.id}
                    {...this.props.inputProps.extraProps}
                    label={this.props.inputProps.labelText}
                    onChange={this.props.onChange}
                    placeholder={this.props.inputProps.placeholder}
                    type="text"
                    value={this.props.value}
                    disabled={this.props.disabled}
                    error={true && this.props.validationMessage.length > 0}
                    helperText={this.props.validationMessage}
                />

                <ValidationMessage
                    message={this.props.validationMessage}
                    isVisible={!this.props.inputProps.disableValidationMessage && this.props.validationMessage}
                />
                {!this.props.disableTextArea &&
                    <UncontrolledPopover
                        container="body"
                        trigger="legacy"
                        placement="bottom"
                        target={this.props.inputProps.id}
                    >
                        <PopoverBody
                            className={classes.popoverBody}
                        >
                            <TextareaAutosize
                                value={this.props.value}
                                onChange={this.props.onChange}
                                placeholder={this.props.textAreaProps.placeholder}
                                disabled={this.props.disabled}
                                className={
                                    classNames(classes.textareaAutosize,
                                        {
                                            "is-invalid-custom": !this.props.textAreaProps.disableValidationBorder &&
                                                this.props.validationMessage,
                                        }
                                    )
                                }

                                {...this.props.textAreaProps.extraProps}
                            />
                            <ValidationMessage
                                message={this.props.validationMessage}
                                isVisible={!this.props.textAreaProps.disableValidationMessage && this.props.validationMessage}
                            />
                        </PopoverBody>
                    </UncontrolledPopover>
                }
            </div>
        );
    }
}

export default withStyles(stylesz)(TextAreaPopup);
