import React from "react";
import { withTranslation } from "react-i18next";
import { Container, Paper, Box, Button, FormControl } from '@material-ui/core';
import Alerts from "components/Alerts/Alerts";
import {
    getAuthUser,
    login,
    LOGIN_ALERT_GROUP
} from "util/Auth";
import {
    clearLocalAlerts
} from "util/Alerts";

import EmailForm from "components/Login/EmailForm/EmailForm";
import LoadingAnimation from "components/Loader/WrappedPulseLoader";
import TypographyLargest from "components/form/TypographyLargest";

class LoginCard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            email: "",
            password: "",
            isAuthenticating: false,
        };
    }

    handleChange = async (event) => {
        const { target } = event;
        const { name, value } = target;
        await this.setState({ [name]: value });
    };

    setIsAuthenticating = (value) => {
        this.setState({
            isAuthenticating: value,
        });
    };

    authenticateUser = (event) => {
        event.preventDefault();
        clearLocalAlerts();

        this.logInCredentials(this.state.email, this.state.password);
    };

    logInCredentials = (email, password) => {
        this.setIsAuthenticating(true);
        login(email, password).then((isSuccess) => {
            if (isSuccess) {
                getAuthUser();
            }

            this.setIsAuthenticating(false);
        });
    };

    render() {
        const { t } = this.props;
        return (
            <Container disableGutters>
                <Box mt={6}>
                    <Paper
                        elevation={3}
                        style={{ maxWidth: '70rem', margin: 'auto' }} >
                        <Box p={8}>
                            <TypographyLargest>
                                {t("form.booking.formFields.heading.login", "Log in")}
                            </TypographyLargest>
                            <FormControl
                                fullWidth={true}
                            >
                                <Box mb={2}>
                                    <EmailForm
                                        handleChanged={this.handleChange}
                                    />
                                    <Alerts
                                        alerts={this.props.alertsList}
                                        hideGlobal={true}
                                        filterGroups={[LOGIN_ALERT_GROUP]}
                                    />
                                </Box>
                                <Box mt={2} mb={2}>
                                    <Button
                                        variant="contained"
                                        size="large"
                                        className="submitButton"
                                        onClick={this.authenticateUser}
                                        disabled={this.state.isAuthenticating}
                                    >
                                        {this.state.isAuthenticating ? <LoadingAnimation /> : this.props.t("buttons.login", "Login")}
                                    </Button>
                                </Box>
                            </FormControl>
                        </Box>
                    </Paper>
                </Box >
            </Container >
        );
    }
}

export default withTranslation()(LoginCard);
