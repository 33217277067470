import React from "react";
import { withRouter, Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Col, Row, Card, CardBody, Button, Form } from "reactstrap";

import ajax from "remotes/ajax";
import urls from "config/urls";

import BrandLogoText from "components/Misc/BrandLogoText";
import CustomInput from "components/form/Input";
import Alerts from "components/Alerts/Alerts";
import UtilAlert from "util/toastAlerts";

//import "./ChangePassword.scss";
import { getServerErrorMessage } from "util/Errors";

const CHANGE_PASSWORD_ALERT_GROUP = "changePasswordGroup";

class ChangePasswordView extends React.Component {
    state = {
        passwordOne: "",
        passwordTwo: "",
        key: null,
        alerts: [],
        passwordChangeSuccess: false,
        fetchKeyFailed: false,
        localAlertIds: []
    };

    componentDidMount() {
        const id = this.props.match.params.id;
        const token = this.props.match.params.token;

        this.getKey(id, token);
    }

    componentWillUnmount() {
        UtilAlert.dismissAlerts(this.state.localAlertIds);
    }

    render() {
        return (
            <Card className="change-password-card small-card">
                <CardBody>
                    <div>
                        <BrandLogoText />
                        <p className="change-password-heading text-center">
                            {this.props.t("authentication.changePassword.title", "Change password")}
                        </p>
                    </div>
                    {this.renderContent()}
                    <Row className="text-center">
                        <Col>
                            <Link to={urls.LOGIN}>{this.props.t("buttons.login", "Login")}</Link>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        );
    }

    renderContent() {
        if (this.state.fetchKeyFailed || this.state.passwordChangeSuccess) {
            return this.renderAlerts();
        }

        return this.renderForm();
    }

    renderAlerts() {
        return (
            <Alerts
                manualAlerts={this.state.alerts}
                hideGlobal={true}
                filterGroups={[CHANGE_PASSWORD_ALERT_GROUP]}
                className={`text-left ${this.state.alerts.length ? "mb-2" : "mb-0"
                    }`}
            />
        );
    }

    renderForm() {
        return (
            <Form
                onSubmit={this.onSubmit}
                id="change-password-form"
                className="text-left"
            >
                <Row className="mb-2">
                    <Col>
                        <CustomInput
                            value={this.state.passwordOne}
                            onChange={(value) =>
                                this.setState({ passwordOne: value })
                            }
                            type="password"
                            labelText={this.props.t("authentication.changePassword.passwordLabel", "New password")}
                            placeholder={this.props.t("authentication.changePassword.passwordPlaceholder", "New password")}
                            extraProps={{
                                required: true,
                            }}
                        />
                    </Col>
                </Row>
                <Row className="mb-4">
                    <Col>
                        <CustomInput
                            value={this.state.passwordTwo}
                            onChange={(value) =>
                                this.setState({ passwordTwo: value })
                            }
                            type="password"
                            labelText={this.props.t("authentication.changePassword.repeatPasswordLabel", "Repeat password")}
                            placeholder={this.props.t("authentication.changePassword.repeatPasswordPlaceholder", "Repeat password")}
                            extraProps={{
                                required: true,
                            }}
                        />
                    </Col>
                </Row>
                {this.renderAlerts()}
                <Row className="mb-4">
                    <Col>
                        <Button
                            block
                            color="primary"
                            form="change-password-form"
                        >
                            {this.props.t("authentication.changePassword.submitButton", "Change")}
                        </Button>
                    </Col>
                </Row>
            </Form>
        );
    }

    addAlertId = id => {
        this.setState((prevState) => {
            return {
                localAlertIds: [...prevState.localAlertIds, id],
            };
        });
    }

    onSubmit = (e) => {
        e.preventDefault();
        UtilAlert.dismissAlerts(this.state.localAlertIds);

        if (this.state.passwordOne !== this.state.passwordTwo) {
            const alertId = UtilAlert.createValidationAlert(this.props.t("authentication.changePassword.passwordWarningTitle", "Passwords do not match."));
            this.addAlertId(alertId);
            return;
        }

        this.changePassword(
            this.state.key,
            this.state.passwordOne,
            this.state.passwordTwo
        );
    };

    getKey = (id, token) => {
        this.setState({ loading: true });
        ajax.doResetPassword(id, token)
            .then((data) => {
                this.setState({
                    key: data.key,
                });
            })
            .catch(() => {
                this.setState({
                    fetchKeyFailed: true,
                });
                const message = this.props.t("authentication.changePassword.getKeyErrorMessage", "Current link is not active anymore.");
                const alertId = UtilAlert.createAlert(message, {
                    type: UtilAlert.ALERT_TYPE.ERROR,
                    autoClose: false
                });
                this.addAlertId(alertId);

            })
            .finally(() => this.setState({ loading: false }));
    };

    changePassword = (key, password1, password2) => {
        ajax.doChangePassword(key, password1, password2)
            .then(() => {
                this.setState({ passwordChangeSuccess: true });
                const successMessage = this.props.t("authentication.changePassword.successMessage", "Password succesfully changed. You can log in now.");
                UtilAlert.createAlert(successMessage, {
                    type: UtilAlert.ALERT_TYPE.SUCCESS,
                    delay: false
                });
                this.props.history.push(urls.LOGIN);
            })
            .catch((error) => {
                const errorMessage = getServerErrorMessage(error);
                if (error.response && error.response.data) {
                    const data = error.response.data;
                    if (data.detail) {
                        const alertId = UtilAlert.createAlert(data.detail, {
                            type: UtilAlert.ALERT_TYPE.ERROR
                        });
                        this.addAlertId(alertId);
                    }
                    if (data.new_password1) {

                        const alertId = UtilAlert.createAlert(data.new_password1.join(" "), {
                            title: this.props.t("authentication.changePassword.passwordWarningTitle", "Problem with given password"),
                            type: UtilAlert.ALERT_TYPE.WARNING
                        });
                        this.addAlertId(alertId);
                    }
                    if (data.new_password2) {
                        const alertId = UtilAlert.createAlert(data.new_password2.join(" "), {
                            title: this.props.t("authentication.changePassword.passwordWarningTitle", "Problem with given password"),
                            type: UtilAlert.ALERT_TYPE.WARNING
                        });
                        this.addAlertId(alertId);
                    }
                    if (
                        !data.detail &&
                        !data.new_password1 &&
                        !data.new_password2
                    ) {
                        const alertId = UtilAlert.createAlert(errorMessage, {
                            title: this.props.t("authentication.changePassword.unknownErrorTitle", "Unexpected problem"),
                            type: UtilAlert.ALERT_TYPE.ERROR
                        });
                        this.addAlertId(alertId);
                    }
                } else {
                    const alertId = UtilAlert.createAlert(errorMessage, {
                        title: this.props.t("authentication.changePassword.unknownErrorTitle", "Unexpected problem"),
                        type: UtilAlert.ALERT_TYPE.ERROR
                    });
                    this.addAlertId(alertId);
                }
            })
            .finally(() => this.setState({ loading: false }));
    };
}

export default withRouter(withTranslation()(ChangePasswordView));
