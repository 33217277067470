import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  root: {
    fontWeight: "500",
  },
}));

export default function TypographyLarge(props) {
  const classes = useStyles();
  return (
    <Typography gutterBottom variant="h5" className={classes.root}>
      {props.children}
    </Typography>
  );
}
