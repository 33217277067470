import React from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

export default function (props) {
    const { className } = props;
    const { t } = useTranslation();
    return (
        <div className={className || ""}>
            <FontAwesomeIcon icon={faSpinner} className="spin mr-2" />
            {t("common.loading", "Loading")}...
        </div>
    );

}
