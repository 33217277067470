import React, { Component, Fragment } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import ValidationMessage from "components/form/ValidationMessage";
import { dateFormat, isDateAfterYesterday, isDateAfterYesterdayAndCustomDate } from "util/dateTime";
import moment from "moment";
import Datetime from "react-datetime";
import TransportType from "components/form/TransportType";
import { transportType } from "maps/classifiers";
import Shipments from "components/form/shipment/Shipments";
import QuotePrices from "components/page/quotes/QuotePrices";
import QuoteDirection from "components/page/quotes/QuoteDirection";
import Incoterms from "components/form/Incoterms";
import AddititionalInfoBlockInputs from "components/form/AddititionalInfoBlockInputs";
import AdditionalInfoBlockCheckboxes from "components/form/AdditionalInfoBlockCheckboxes";
import InputWithValidation from "components/form/InputWithValidation";
import classNames from "classnames";
import LoadType from "../../form/LoadType";
import OfferFeedbackSection from "./OfferFeedbackSection";
import CalendarToday from "@material-ui/icons/CalendarToday";
import { Container, Grid, Paper, Box, TextField, FormGroup, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import TypographyLarge from "components/form/TypographyLarge";

class QuoteFormFields extends Component {
    static propTypes = {
        quote: PropTypes.object.isRequired,
        isOfferMode: PropTypes.bool,
        countryOptions: PropTypes.array.isRequired,
        transportManagerOptions: PropTypes.array.isRequired,
        priceTypeOptions: PropTypes.array.isRequired,
        errors: PropTypes.object.isRequired,
        submitDone: PropTypes.bool.isRequired,
        updateQuoteFormField: PropTypes.func.isRequired,
        communicationCountryCode: PropTypes.string.isRequired,
    };


    constructor(props) {
        super(props);
        this.state = {
            selectedTransport: transportType.ROAD,
            selectedLoadType: ''
        };
    }

    render() {
        const { t } = this.props;
        return (
            <Fragment>
                <Container disableGutters maxWidth={false}>
                    <Box m={2}>
                        <Paper elevation={3} >
                            <Box p={4}>
                                <div>
                                    <TypographyLarge>
                                        {t("form.booking.formFields.heading.transport", "Transport")}
                                    </TypographyLarge>
                                </div>
                                {this.renderTransportType()}
                            </Box>
                        </Paper>
                    </Box>
                </Container>
                <Container disableGutters maxWidth={false}>
                    <Box m={2}>
                        <Paper elevation={3} >
                            <Box p={4}>
                                <div>
                                    <TypographyLarge>
                                        {t("form.booking.formFields.heading.direction", "Direction")}
                                    </TypographyLarge>
                                </div>
                                <Box mt={2}>
                                    <QuoteDirection
                                        quote={this.props.quote}
                                        countryOptions={this.props.countryOptions}
                                        errors={this.props.errors}
                                        submitDone={this.props.submitDone}
                                        updateQuoteFormField={this.props.updateQuoteFormField}
                                        isReadOnly={this.props.readOnly}
                                    />
                                </Box>
                            </Box>
                        </Paper>
                    </Box>
                </Container>
                <Container disableGutters maxWidth={false}>
                    <Box m={2}>
                        <Paper elevation={3} >
                            <Box p={4}>
                                {this.renderShipmentDetails()}
                            </Box>
                        </Paper>
                    </Box>
                </Container>
                <Container disableGutters maxWidth={false}>
                    <Box m={2}>
                        <Paper elevation={3} >
                            <Box p={4}>
                                {this.renderAdditionalInfoBlock()}
                            </Box>
                        </Paper>
                    </Box>
                </Container>
                <Container disableGutters maxWidth={false}>
                    <Box m={2}>
                        <Paper elevation={3} >
                            <Box p={4}>
                                <div>
                                    <TypographyLarge>
                                        {t("form.booking.formFields.heading.contacts", "Contacts")}
                                    </TypographyLarge>
                                </div>
                                {this.renderCustomerContacts()}
                            </Box>
                        </Paper>
                    </Box>
                </Container>
                {this.renderOfferDetails()}

                <OfferFeedbackSection data={this.props.quote} />
            </Fragment>
        );
    }

    renderTransportType() {
        return (
            <>
                <TransportType
                    onChange={(event) => this.onCheckboxCheckedTransport(event)}
                    value={this.props.quote.transport_type}
                    readOnly={this.props.readOnly}
                    selectedValue={this.state.selectedTransport}
                />
                <ValidationMessage
                    message={this.props.errors.transport_type}
                    isVisible={this.props.submitDone && this.props.errors.transport_type}
                />
                <LoadType
                    value={this.props.quote.load_type}
                    transportType={this.props.quote.transport_type}
                    onChange={(value) => this.onCheckboxCheckedLoad(value)}
                    readOnly={this.props.readOnly}
                    checkboxHelperText={this.checkboxHelperText}
                    selectedValue={this.state.selectedLoadType}
                >
                    <ValidationMessage
                        message={this.props.errors.load_type}
                        isVisible={this.props.submitDone && this.props.errors.load_type}
                    />
                </LoadType>
            </>
        );
    }

    renderShipmentDetails() {
        return (
            <Fragment>
                <Shipments
                    shipments={this.props.quote.rows}
                    loadType={this.props.quote.load_type}
                    onChange={(shipments) => this.props.updateQuoteFormField("rows", shipments)}
                    validationErrors={this.props.errors}
                    readOnly={this.props.readOnly}
                />
            </Fragment>
        );
    }

    renderAdditionalInfoBlock() {
        const { t } = this.props;
        const pickupPlaceholder = this.props.isOfferMode ?
            t("form.quote.formFields.offerPickupDateLabel", "Planned pickup date")
            : t("form.quote.formFields.pickupDateLabel", "Ready for Collection");
        const deliveryPlaceholder = this.props.isOfferMode ?
            t("form.quote.formFields.offerDeliveryDateLabel", "Planned delivery date")
            : t("form.quote.formFields.deliveryDateLabel", "Desired delivery date");
        return (
            <Fragment>
                <div className="">
                    <TypographyLarge>
                        {t("form.booking.formFields.heading.additionalInfo", "Additional Info")}
                    </TypographyLarge>
                </div>
                <Box pt={2} >
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={6} sm={6}>
                                    <AdditionalInfoBlockCheckboxes
                                        data={this.props.quote.dangerous}
                                        onChange={this.props.updateQuoteFormField}
                                        quoteFormField="dangerous"
                                        readOnly={this.props.readOnly}
                                        label={t("form.dangerousGoods.dangerousGoodsLabel", "Dangerous goods")}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                    <AdditionalInfoBlockCheckboxes
                                        data={this.props.quote.temperature_sensitive}
                                        onChange={this.props.updateQuoteFormField}
                                        quoteFormField="temperature_sensitive"
                                        readOnly={this.props.readOnly}
                                        label={t("form.temperatureSensitive.temperatureSensitiveLabel", "Temperature sensitive")}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item sm={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6} className="calendar-icon-container">
                                    <CalendarToday />
                                    <Datetime
                                        className="datepicker-up"
                                        value={this.displayDate(this.props.quote.pickup_date)}
                                        timeFormat={false}
                                        dateFormat={dateFormat}
                                        inputProps={{
                                            placeholder: pickupPlaceholder,
                                            disabled: this.props.readOnly,
                                            className: classNames("form-control maskedAsMUIinput", { "is-invalid-custom": this.props.errors.pickup_date }),
                                        }}
                                        defaultValue={""}
                                        closeOnSelect={true}
                                        onChange={(date) => this.handleDateChange("pickup_date", date)}
                                        isValidDate={isDateAfterYesterday}
                                    />
                                    <ValidationMessage
                                        message={this.props.errors.pickup_date}
                                        isVisible={this.props.submitDone && this.props.errors.pickup_date}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} className="calendar-icon-container">
                                    <CalendarToday />
                                    <Datetime
                                        className="datepicker-up"
                                        value={this.displayDate(this.props.quote.delivery_date)}
                                        timeFormat={false}
                                        dateFormat={dateFormat}
                                        inputProps={{
                                            placeholder: deliveryPlaceholder,
                                            disabled: this.props.readOnly,
                                            className: classNames("form-control maskedAsMUIinput", { "is-invalid-custom": this.props.errors.delivery_date }),
                                        }}
                                        defaultValue={""}
                                        closeOnSelect={true}
                                        onChange={(date) => this.handleDateChange("delivery_date", date)}
                                        isValidDate={(currentDate) =>
                                            isDateAfterYesterdayAndCustomDate(currentDate, this.props.quote.delivery_date)
                                        }
                                    />
                                    <ValidationMessage
                                        message={this.props.errors.delivery_date}
                                        isVisible={this.props.submitDone && this.props.errors.pdelivery_date}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12}>
                                    <Incoterms
                                        data={this.props.quote}
                                        errors={this.props.errors}
                                        submitDone={this.props.submitDone}
                                        updateField={this.props.updateQuoteFormField}
                                        communicationCountryCode={this.props.communicationCountryCode}
                                        isRequired={false}
                                        isReadOnly={this.props.readOnly}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <AddititionalInfoBlockInputs
                        data={this.props.quote}
                        onChange={this.props.updateQuoteFormField}
                        readOnly={this.props.readOnly}
                    />
                </Box>
            </Fragment>
        );
    }
    renderCustomerContacts() {
        const { t, quote, errors, readOnly } = this.props;
        return (
            <Fragment>
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={6}>
                        <InputWithValidation
                            value={quote.company_name}
                            onChange={(value) => this.props.updateQuoteFormField("company_name", value)}
                            error={errors.company_name}
                            name="company_name"
                            type="text"
                            labelText={t("form.booking.formFields.customerCompanyNameLabel", "Company name")}
                            placeholder={t("form.booking.formFields.customerCompanyNamePlaceholder", "Company name")}
                            disabled={readOnly}
                            isRequired={true}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                        <InputWithValidation
                            value={quote.contact_person}
                            onChange={(value) => this.props.updateQuoteFormField("contact_person", value)}
                            error={errors.contact_person}
                            name="contact_person"
                            type="text"
                            labelText={t("form.booking.formFields.customerContactPersonLabel", "Contact person")}
                            placeholder={t("form.booking.formFields.customerContactPersonPlaceholder", "Contact person")}
                            disabled={readOnly}
                            isRequired={true}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={6}>
                        <InputWithValidation
                            value={quote.contact_email}
                            onChange={(value) => this.props.updateQuoteFormField("contact_email", value)}
                            error={errors.contact_email}
                            name="contact_email"
                            type="text"
                            labelText={t("form.booking.formFields.customerContactEmailLabel", "Contact e-mail")}
                            placeholder={t("form.booking.formFields.customerContactEmailPlaceholder", "Contact e-mail")}
                            disabled={readOnly}
                            isRequired={true}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                        <InputWithValidation
                            value={quote.contact_phone}
                            onChange={(value) => this.props.updateQuoteFormField("contact_phone", value)}
                            error={errors.contact_phone}
                            name="contact_phone"
                            type="text"
                            labelText={t("form.booking.formFields.customerContactPhoneLabel", "Contact phone")}
                            placeholder={t("form.booking.formFields.customerContactPhonePlaceholder", "Contact phone")}
                            disabled={readOnly}
                            isRequired={false}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginTop: '1rem' }}>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            multiline={true}
                            rows={6}
                            aria-label="customer comments"
                            placeholder={t("form.booking.formFields.customerCommentsLabel", "Customer comments")}
                            onChange={(event) => this.props.updateQuoteFormField("customer_comments", event.target.value)}
                            value={quote.customer_comments || ""}
                            disabled={readOnly}
                        />
                    </Grid>
                </Grid>
            </Fragment >
        );
    }

    renderOfferDetails() {
        const { t } = this.props;
        if (!this.props.isOfferMode || !this.props.quote.prices) {
            return null;
        }

        return (
            <Fragment>
                <Container disableGutters maxWidth={false}>
                    <Box m={2}>
                        <Paper elevation={3} >
                            <Box p={4}>
                                <div>
                                    <TypographyLarge>
                                        {t("form.offer.formFields.heading.offer", "Offer")}
                                    </TypographyLarge>
                                </div>
                                <Box mt={2}>
                                    <QuotePrices
                                        onChange={(prices) => this.props.updateQuoteFormField("prices", prices)}
                                        prices={this.props.quote.prices}
                                        priceTypeOptions={this.props.priceTypeOptions}
                                        validationErrors={this.props.errors.prices}
                                        readOnly={this.props.readOnly}
                                    />

                                    <Grid container spacing={2}>
                                        <Grid item sm={6}>
                                            <FormControl variant="outlined" fullWidth>
                                                <InputLabel id="freightForwarder">
                                                    {t("form.offer.formFields.freightForwarderLabel", "Freight forwarder")}
                                                </InputLabel>
                                                <Select
                                                    labelId="freightForwarder"
                                                    label={t("form.offer.formFields.freightForwarderLabel", "Freight forwarder")}
                                                    variant="outlined"
                                                    onChange={(option) => this.props.updateQuoteFormField("transport_manager", option.target.value)}
                                                    value={this.props.quote.transport_manager || ""}
                                                    placeholder={t("form.offer.formFields.freightForwarderPlaceholder", "- Select freight forwarder -")}
                                                    className={classNames({ "is-invalid-custom": this.props.errors.transport_manager })}
                                                    disabled={this.props.readOnly}
                                                >
                                                    {this.renderTransportManagerOptions()}
                                                </Select>
                                                <ValidationMessage
                                                    message={this.props.errors.transport_manager}
                                                    isVisible={this.props.submitDone && this.props.errors.transport_manager}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item sm={6}>
                                            <FormGroup
                                                variant="outlined"
                                                style={{ marginTop: '-2rem' }}
                                            >
                                                <label>{t("form.offer.formFields.dueDateLabel", "Due date")}</label>
                                                <Datetime
                                                    className="datepicker-up"
                                                    value={this.displayDate(this.props.quote.due_date)}
                                                    timeFormat={false}
                                                    dateFormat={dateFormat}
                                                    inputProps={{
                                                        placeholder: t("common.genericDatePlaceholder", "Enter date"),
                                                        disabled: this.props.readOnly,
                                                        className: classNames("form-control maskedAsMUIinput", { "is-invalid-custom": this.props.errors.due_date }),
                                                    }}
                                                    defaultValue={""}
                                                    closeOnSelect={true}
                                                    onChange={(date) => this.handleDateChange("due_date", date)}
                                                    isValidDate={isDateAfterYesterday}
                                                />
                                                <ValidationMessage
                                                    message={this.props.errors.due_date}
                                                    isVisible={this.props.errors.due_date}
                                                />

                                            </FormGroup>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item sm={6}>
                                            <FormGroup>
                                                <TextField
                                                    multiline={true}
                                                    variant="outlined"
                                                    rows={6}
                                                    aria-label="Comments to customer"
                                                    placeholder={t("form.offer.formFields.commentsToCustomerPlaceholder", "Comments to customer")}
                                                    onChange={(event) => this.props.updateQuoteFormField("offer_text", event.target.value)}
                                                    value={this.props.quote.offer_text || ""}
                                                    disabled={this.props.readOnly}
                                                />
                                                <ValidationMessage
                                                    message={this.props.errors.offer_text}
                                                    isVisible={this.props.submitDone && this.props.errors.offer_text}
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item sm={6}>
                                            <FormGroup>
                                                <TextField
                                                    multiline={true}
                                                    variant="outlined"
                                                    rows={6}
                                                    aria-label="Internal comments"
                                                    placeholder={t("form.offer.formFields.internalCommentsPlaceholder", "Internal comments")}
                                                    onChange={(event) => this.props.updateQuoteFormField("internal_comments", event.target.value)}
                                                    value={this.props.quote.internal_comments || ""}
                                                    disabled={this.props.readOnly}
                                                />
                                            </FormGroup>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        </Paper>
                    </Box>
                </Container>
            </Fragment>
        );
    }

    renderTransportManagerOptions = () => {
        const { t } = this.props;
        if (this.props.transportManagerOptions.length) {
            return this.props.transportManagerOptions.map((options, index) => {
                return (
                    <MenuItem
                        label={options.label}
                        value={options.value}
                        key={index}
                        name={options.label}
                    >{options.label}
                    </MenuItem>
                );
            })
        } else {
            return (
                <MenuItem>
                    {t("form.quote.formFields.noOptions", "No options")}
                </MenuItem>
            )
        }

    }

    handleDateChange = (field, date) => {
        if (moment(date).isValid() === false) {
            date = null;
        }
        this.props.updateQuoteFormField(field, date);
    }

    displayDate(date) {
        if (date && this.isDateValid(date)) {
            return moment(date).format(dateFormat);
        }
        return date;
    }


    isDateValid(date) {
        return !date || (moment(date).isValid && moment(date).isValid());
    }

    checkboxHelperText() {

    }

    onCheckboxCheckedTransport(event) {
        this.props.updateQuoteFormField("transport_type", event.target.value)
        this.setState({ selectedTransport: event.target.value })
    }

    onCheckboxCheckedLoad(event) {
        if (event && event.target && event.target.value) {
            this.props.updateQuoteFormField("load_type", event.target.value)
            this.setState({ selectedLoadType: event.target.value })
        }
    }
}

export default withTranslation()(QuoteFormFields);
