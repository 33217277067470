import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { languageTypes } from "util/General";
import { languageUpdate } from "redux/actions/language";
import { Select, MenuItem } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import _ from "lodash";

const styles = (theme) => ({
    select: {
        fontWeight: '500',
        fontSize: '1.2rem',
        color: '#1F0242'
    },
    menuItem: {
        width: '8rem',
        fontSize: '1.2rem',
        fontWeight: '500',
        padding: '1rem 1.2rem'
    }
});

class LanguageMode extends React.Component {
    static propTypes = {
        userId: PropTypes.number,
        languageUpdate: PropTypes.func.isRequired,
        classes: PropTypes.object.isRequired
    };

    updateLanguage = (languageCode) => {
        // change frontend language
        this.props.languageUpdate(languageCode);
    }

    render() {
        const activeLanguage = this.props.language;
        const { classes } = this.props;
        return (
            <>
                <Select
                    className={classes.select}
                    disableUnderline
                    value={activeLanguage}
                    onChange={(event) => this.updateLanguage(event.target.value)}
                >
                    <MenuItem
                        className={classes.menuItem}
                        value={languageTypes.ET}>Est
                    </MenuItem>
                    <MenuItem
                        className={classes.menuItem}
                        value={languageTypes.EN}>Eng
                    </MenuItem>
                </Select>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userId: _.get(state, "auth.user.id", null),
        language: state.language,
    };
};

const mapActionsToProps = {
    languageUpdate,
}

export default connect(mapStateToProps, mapActionsToProps)(withTranslation()(withStyles(styles)(LanguageMode)));
