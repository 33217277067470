export const RESET_STORE = "RESET_STORE";

// AUTHENTICATION
export const REGISTER_START = "REGISTER_START";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const LOG_OUT = "LOG_OUT";

// USER
export const GET_AUTH_USER = "GET_AUTH_USER";
export const FETCH_USERS_START = "FETCH_USERS_START";
export const FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS";
export const FETCH_USERS_FAIL = "FETCH_USERS_FAIL";
export const UPDATE_USER_START = "UPDATE_USER_START";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";

// GENERAL
export const LANGUAGE_UPDATE = "LANGUAGE_UPDATE";

export const SET_COMMUNICATION_COUNTRY_CODE = "SET_COMMUNICATION_COUNTRY_CODE";
export const SET_COMMUNICATION_COUNTRY_CODE_FETCH_FAIL = "SET_COMMUNICATION_COUNTRY_CODE_FETCH_FAIL";

export const SET_COUNTRY_LIST = "SET_COUNTRY_LIST";
export const SET_COUNTRY_LIST_FETCH_FAIL = "SET_COUNTRY_LIST_FETCH_FAIL";

export const SET_PRICE_TYPE_LIST = "SET_PRICE_TYPE_LIST";
export const SET_PRICE_TYPE_LIST_FETCH_FAIL = "SET_PRICE_TYPE_LIST_FETCH_FAIL";

// NAVIGATION
export const NAVIGATION_UPDATE_HEADER_SUFFIX =
    "NAVIGATION_UPDATE_HEADER_SUFFIX";

// ALERTS
export const ALERTS_ADD_GLOBAL = "ALERTS_ADD_GLOBAL";
export const ALERTS_ADD_LOCAL = "ALERTS_ADD_LOCAL";
export const ALERTS_CLEAR_ALL = "ALERTS_CLEAR_ALL";
export const ALERTS_CLEAR_GLOBAL = "ALERTS_CLEAR_GLOBAL";
export const ALERTS_CLEAR_LOCAL = "ALERTS_CLEAR_LOCAL";
export const ALERTS_CLEAR_INCLUDED_GROUPS = "ALERTS_CLEAR_INCLUDED_GROUPS";
export const ALERTS_CLEAR_UNINCLUDED_GROUPS = "ALERTS_CLEAR_UNINCLUDED_GROUPS";
export const ALERTS_UPDATE_ACTIVE_GROUPS = "ALERTS_UPDATE_ACTIVE_GROUPS";

// Quotes filters
export const SET_FILTER_STATUS = "SET_FILTER_STATUS";
export const SET_FILTER_CLIENT = "SET_FILTER_CLIENT";
export const SET_FILTER_LOCATION_FROM = "SET_FILTER_LOCATION_FROM";
export const SET_FILTER_LOCATION_TO = "SET_FILTER_LOCATION_TO";
export const SET_FILTER_PICKUP_START = "SET_FILTER_PICKUP_START";
export const SET_FILTER_PICKUP_END = "SET_FILTER_PICKUP_END";
export const SET_FILTER_GENERIC_SEARCH = "SET_FILTER_GENERIC_SEARCH";

// Quotes
export const QUOTES_RECEIVED = "QUOTES_RECEIVED";
export const QUOTES_FETCH_FAIL = "QUOTES_FETCH_FAIL";
export const QUOTE_CREATED = "QUOTE_CREATED";
export const PREFILL_QUOTE_FORM = "PREFILL_QUOTE_FORM";
export const VIEW_QUOTE = "VIEW_QUOTE";
export const CLOSE_QUOTE_VIEW = "CLOSE_QUOTE_VIEW";
export const EDIT_QUOTE = "EDIT_QUOTE";
export const VIEW_QUOTE_HISTORY = "VIEW_QUOTE_HISTORY";
export const SAVE_QUOTE_UPDATE = "SAVE_QUOTE_UPDATE";
export const CLOSE_QUOTE_FORM = "CLOSE_QUOTE_FORM";
export const QUOTE_FETCH_FAIL = "QUOTE_FETCH_FAIL";
export const ARCHIVE_QUOTE = "ARCHIVE_QUOTE";
export const UNARCHIVE_QUOTE = "UNARCHIVE_QUOTE";
export const UPDATE_QUOTE_FORM_FIELD = "UPDATE_QUOTE_FORM_FIELD";
export const SET_TRANSPORT_MANAGER_LIST = "SET_TRANSPORT_MANAGER_LIST";
export const SET_TRANSPORT_MANAGER_LIST_FETCH_FAIL = "SET_TRANSPORT_MANAGER_LIST_FETCH_FAIL";

// Bookings
export const BOOKINGS_RECEIVED = "BOOKINGS_RECEIVED";
export const BOOKINGS_FETCH_FAIL = "BOOKINGS_FETCH_FAIL";
export const PREFILL_BOOKING_FORM = "PREFILL_BOOKING_FORM";
export const EDIT_BOOKING = "EDIT_BOOKING";
export const VIEW_BOOKING = "VIEW_BOOKING";
export const SAVE_BOOKING_UPDATE = "SAVE_BOOKING_UPDATE";
export const CLOSE_BOOKING_FORM = "CLOSE_BOOKING_FORM";
export const UPDATE_BOOKING_FORM_FIELD = "UPDATE_BOOKING_FORM_FIELD";
export const BOOKING_FETCH_FAIL = "BOOKING_FETCH_FAIL";

// Bookings filters
export const SET_BOOKING_FILTER_CLIENT = "SET_BOOKING_FILTER_CLIENT";
export const SET_BOOKING_FILTER_LOCATION_FROM = "SET_BOOKING_FILTER_LOCATION_FROM";
export const SET_BOOKING_FILTER_LOCATION_TO = "SET_BOOKING_FILTER_LOCATION_TO";
export const SET_BOOKING_FILTER_PICKUP_START = "SET_BOOKING_FILTER_PICKUP_START";
export const SET_BOOKING_FILTER_PICKUP_END = "SET_BOOKING_FILTER_PICKUP_END";
export const SET_BOOKING_FILTER_GENERIC_SEARCH = "SET_BOOKING_FILTER_GENERIC_SEARCH";
