import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Container from '@material-ui/core/Container';

import { FiveStars } from "components/page/offer/RejectOfferModal";

class OfferFeedbackSection extends Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
    };

    render() {
        const { data, t } = this.props;
        const { latest_feedback, status } = data;

        if (!latest_feedback || status !== "OFFER_REJECTED") {
            return null;
        }

        return (
            <Container disableGutters>
                <h5> <i className="fa fa-comment mr-2" />{t("form.offerFeedback.heading", "Offer Feedback")}</h5>
                <FiveStars stars={latest_feedback.stars} disabled />asdgadsg
            </Container>
        );
    }
}

export default withTranslation()(OfferFeedbackSection);
