import React from 'react';
import PropTypes from "prop-types";
import { Alert as MuiAlert } from '@material-ui/lab';
import { withStyles } from '@material-ui/styles';

const styles = (theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
});


class Alert extends React.Component {
    static propTypes = {
        className: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        content: PropTypes.any,
        classes: PropTypes.object.isRequired
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                {/* eslint-disable-next-line react/jsx-no-duplicate-props */}
                <MuiAlert severity={this.severityType()}>{this.props.content}</MuiAlert>
            </div>
        );
    }

    severityType() {
        if (this.props.type.indexOf('success') !== -1) {
            return "success"
        } else if (this.props.type.indexOf('error') !== -1) {
            return "error"
        } else {
            return "info"
        }
    }
}

export default withStyles(styles)(Alert);