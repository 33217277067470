import React from "react";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import get from "lodash/get";

import ajax from "remotes/ajax";
import { getServerErrorMessage } from "util/Errors";

import CustomInput from "components/form/InputWithValidation";
import { withLocalToasts } from "components/hoc";
import { Grid, FormControl, Box, Button } from '@material-ui/core';



const getInitialFormState = () => ({
    email: "",
    first_name: "",
    last_name: "",
    company_name: "",
    company_reg_code: "",
    password: "",
    password2: "",
})

class CreateCustomerForm extends React.Component {
    static propTypes = {
        onSuccess: PropTypes.func,
        createAsCompany: PropTypes.bool
    }

    state = {
        // new customer data
        ...getInitialFormState(),
        // other
        validationErrors: {}
    };

    FORM_ID = "create-customer-form";

    componentDidMount() {
    }

    componentWillUnmount() {
        this.props.dismissLocalToasts();
    }

    render() {
        return (
            <div className="create-customer__wrapper">
                {this.renderContent()}
            </div>
        );
    }

    renderContent() {
        return this.renderForm();
    }

    renderForm() {
        const { createAsCompany } = this.props;
        const { validationErrors } = this.state;
        return (
            <FormControl
                id={this.FORM_ID}
                fullWidth={true}
            >
                <Box mb={2}>
                    <CustomInput
                        value={this.state.email}
                        error={validationErrors.email || get(validationErrors, "users[0].user.email", null)}
                        onChange={(value) =>
                            this.setState({ email: value })
                        }
                        type="email"
                        labelText={this.props.t("user.createCustomer.emailLabel", "Email")}
                        placeholder={this.props.t("user.createCustomer.emailPlaceholder", "Email")}
                        isRequired={true}
                    />
                </Box>
                <Box mb={2}>
                    <Grid container spacing={3}>
                        <Grid item xs={6} sm={6}>
                            <CustomInput
                                value={this.state.first_name}
                                error={validationErrors.first_name || get(validationErrors, "users[0].user.first_name", null)}
                                onChange={(value) =>
                                    this.setState({ first_name: value })
                                }
                                type="text"
                                labelText={this.props.t("user.createCustomer.firstNameLabel", "First name")}
                                placeholder={this.props.t("user.createCustomer.firstNamePlaceholder", "First name")}
                                isRequired={true}
                            />
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            <CustomInput
                                value={this.state.last_name}
                                error={validationErrors.last_name || get(validationErrors, "users[0].user.last_name", null)}
                                onChange={(value) =>
                                    this.setState({ last_name: value })
                                }
                                type="text"
                                labelText={this.props.t("user.createCustomer.lastNameLabel", "Last name")}
                                placeholder={this.props.t("user.createCustomer.lastNamePlaceholder", "Last name")}
                                isRequired={true}
                            />
                        </Grid>
                    </Grid>
                </Box>
                <Box mb={2}>
                    <CustomInput
                        value={this.state.company_name}
                        error={validationErrors.company_name || validationErrors.name}
                        onChange={(value) =>
                            this.setState({ company_name: value })
                        }
                        type="text"
                        labelText={this.props.t("user.createCustomer.companyNameLabel", "Company name")}
                        placeholder={this.props.t("user.createCustomer.companyNamePlaceholder", "Company name")}
                        isRequired={true}
                    />
                </Box>
                <Box mb={2}>
                    <CustomInput
                        value={this.state.company_reg_code}
                        error={validationErrors.company_reg_code || validationErrors.reg_code}
                        onChange={(value) =>
                            this.setState({ company_reg_code: value })
                        }
                        type="text"
                        labelText={this.props.t("user.createCustomer.companyRegCodeLabel", "Company registration code")}
                        placeholder={this.props.t("user.createCustomer.companyRegCodePlaceholder", "Company registration code")}
                        isRequired={true}
                    />
                </Box>
                {
                    !createAsCompany &&
                    <Box mb={2}>
                        <CustomInput
                            value={this.state.password}
                            error={validationErrors.password}
                            onChange={(value) =>
                                this.setState({ password: value })
                            }
                            type="password"
                            labelText={this.props.t("user.createCustomer.passwordLabel", "Password")}
                            placeholder={this.props.t("user.createCustomer.passwordPlaceholder", "Password")}
                            isRequired={true}
                        />
                    </Box>
                }
                {
                    !createAsCompany &&
                    <Box mb={2}>
                        <CustomInput
                            value={this.state.password2}
                            error={validationErrors.password2}
                            onChange={(value) =>
                                this.setState({ password2: value })
                            }
                            type="password"
                            labelText={this.props.t("user.createCustomer.repeatPasswordLabel", "Repeat password")}
                            placeholder={this.props.t("user.createCustomer.repeatPasswordPlaceholder", "Repeat password")}
                            isRequired={true}
                        />
                    </Box>
                }
                <Box mt={2} mb={2}>
                    <Button
                        variant="contained"
                        size="large"
                        form={this.FORM_ID}
                        onClick={this.onSubmit}
                        className="submitButton"
                    >
                        {this.props.t("user.createCustomer.submitButton", "Create account")}
                    </Button>
                </Box>
            </FormControl >
        );
    }

    onValidate = () => {
        const { createAsCompany, t } = this.props;
        const errors = {};
        let requiredFields = ["email", "first_name", "last_name", "company_name", "company_reg_code"];
        if (!createAsCompany) {
            requiredFields = [...requiredFields, "password", "password2"];
        }

        for (const fieldName of requiredFields) {
            const value = this.state[fieldName];
            if (value.trim() === "") {
                errors[fieldName] = t("validations.isRequired", "This field may not be blank");
            }
        }

        this.setState({
            validationErrors: errors
        });

        return Object.keys(errors).length === 0;
    }

    onSubmit = (e) => {
        e.preventDefault();
        this.props.dismissLocalToasts();
        if (this.onValidate()) {
            this.createCustomer();
        }

    };

    createCustomer = () => {
        const { createAsCompany } = this.props;
        if (createAsCompany) {
            this.createCustomerAsCompany();
        } else {
            this.createCustomerAsPublic();
        }
    }

    createCustomerAsPublic() {
        const { onSuccess } = this.props;
        const { email, company_name, company_reg_code, first_name, last_name, password, password2 } = this.state;
        const data = { email, company_name, company_reg_code, first_name, last_name, password, password2 };
        const endpoint = ajax.doCreateCustomer;

        this.setState({ loading: true });
        endpoint(data)
            .then(() => {
                this.setState({
                    ...getInitialFormState()
                });
                const successTitle = this.props.t("user.createCustomer.successTitle", "Account created");
                const successMessage = this.props.t("user.createCustomer.successMessage", "Activation link has been sent to your e-mail.");
                const alertId = this.props.toastAlerts.createAlert(successMessage, {
                    type: this.props.toastAlerts.ALERT_TYPE.SUCCESS,
                    title: successTitle,
                    autoClose: false
                });
                this.props.addLocalToast(alertId);
                if (onSuccess) {
                    onSuccess(data);
                }
            })
            .catch(error => {
                const data = get(error, "response.data", null);
                if (typeof data === "object" && data !== null) {
                    this.setState({ validationErrors: data });
                } else {
                    const errorMessage = getServerErrorMessage(error);
                    const errorTitle = this.props.t("user.createCustomer.errorTitle", "Error occured during customer creation.");
                    const alertId = this.props.toastAlerts.createAlert(errorMessage, {
                        type: this.props.toastAlerts.ALERT_TYPE.ERROR,
                        title: errorTitle,
                        autoClose: false
                    });
                    this.props.addLocalToast(alertId);
                }
            })
            .finally(() => this.setState({ loading: false }))
    }

    createCustomerAsCompany = () => {
        const { onSuccess } = this.props;
        const { email, company_name, company_reg_code, first_name, last_name } = this.state;
        const data = {
            name: company_name,
            reg_code: company_reg_code,
            manager: null,
            users: [
                {
                    user: {
                        email,
                        first_name,
                        last_name
                    }
                }
            ]
        };
        const endpoint = ajax.doCreateCustomerAsCompany;
        this.setState({ loading: true });
        endpoint(data)
            .then(() => {
                this.setState({
                    ...getInitialFormState()
                });
                const successTitle = this.props.t("user.createCustomer.createAsCompanySuccessTitle", "New customer created");
                const successMessage = this.props.t("user.createCustomer.createAsCompanySuccessMessage", "Change password link has been sent to the customer.");
                this.props.toastAlerts.createAlert(successMessage, {
                    type: this.props.toastAlerts.ALERT_TYPE.SUCCESS,
                    title: successTitle,
                    autoClose: true
                });
                if (onSuccess) {
                    onSuccess(data);
                }
            })
            .catch(error => {
                const data = get(error, "response.data", null);
                if (typeof data === "object" && data !== null) {
                    this.setState({
                        validationErrors: data
                    });
                    console.log("validationErrors:", data)
                } else {
                    const errorMessage = getServerErrorMessage(error);
                    const errorTitle = this.props.t("user.createCustomer.errorTitle", "Error occured during customer creation.");
                    const alertId = this.props.toastAlerts.createAlert(errorMessage, {
                        type: this.props.toastAlerts.ALERT_TYPE.ERROR,
                        title: errorTitle,
                        autoClose: false
                    });
                    this.props.addLocalToast(alertId);
                }
            })
            .finally(() => this.setState({ loading: false }))
    }
}

export default withRouter(withTranslation()(withLocalToasts(CreateCustomerForm)));
