import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import moment from "moment";
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import Tooltip from '@material-ui/core/Tooltip';
import "./Quotes.css";

class QuoteStatusNotification extends Component {
    static propTypes = {
        quote: PropTypes.object,
    };

    timeSinceText = (preText, timestamp) => {
        const eventMoment = new moment(timestamp);
        return `${preText} ${eventMoment.fromNow()}`;
    }

    isOverdue = (timestamp) => {
        const eventMoment = new moment(timestamp);
        const minutesSinceEvent = moment().diff(eventMoment, "minutes");
        return minutesSinceEvent >= 15;
    }

    isExpired = (dueDate) => {
        dueDate = moment(dueDate);
        if (!dueDate.isValid()) {
            return false;
        }
        return dueDate.endOf("day") < moment();
    }

    findLastEventOfType = (events, type) => {
        if (!events) {
            return null;
        }
        for (let i = events.length - 1; i >= 0; i--) {
            const event = events[i];
            if (event.type === type) {
                return event;
            }
        }
        return null;
    }

    render() {
        const { quote, t } = this.props;
        if (!quote || ["OFFER_MADE", "OFFER_OPENED", "BOOKING_STARTED"].includes(quote.status) === false) {
            return false;
        }

        const dueDate = quote.due_date;
        if (dueDate && this.isExpired(dueDate)) {
            return (
                <Tooltip title={this.timeSinceText(t("components.quoteStatusNotification.expired", "Expired"), dueDate)} >
                    <NotificationsActiveIcon fontSize="small" style={{ marginRight: '-1.5rem', color: '#F4511E' }} />
                </Tooltip>
            );
        }

        if (!quote.events || quote.events.length === 0 || quote.status !== "OFFER_OPENED") {
            return false;
        }

        const lastEvent = this.findLastEventOfType(quote.events, "OFFER_OPENED");
        if (!lastEvent || !this.isOverdue(lastEvent.timestamp)) {
            return false;
        }

        return (
            <i className="fa fa-bell text-danger" title={this.timeSinceText(t("components.quoteStatusNotification.opened", "Opened"), lastEvent.timestamp)} />
        );
    }
}

export default withTranslation()(QuoteStatusNotification);
