import store from "redux/store";
import _ from "lodash";

import { companyUserRoles, customerUserRoles, getIsSalesRole } from "config/userRoles"

const _getToken = () => {
    const state = store.getState();
    return _.get(state, "auth.token", null)
}

export function isAuthenticatedWithUser() {
    const state = store.getState();

    const token = _getToken();
    const user = getUser(state);

    return Boolean(token && user);
}

export function isAuthenticatedWithoutUser() {
    const state = store.getState();

    const token = _getToken();
    const user = getUser(state);

    if (token && !user) {
        return true;
    } else {
        return false;
    }
}

export function getUser(state = null) {
    state = state || store.getState();
    if (!state || !state.auth || !state.auth.user || !state.auth.user) {
        return null;
    }
    const userId = _.get(state, "auth.user.id", null)
    return userId ? state.auth.user : null;
}

export function getUserRole(state = null) {
    state = state || store.getState();
    const user = getUser(state);
    return _.get(user, "role", null);
}

export function isCustomerMode() {
    const state = store.getState();
    const userRole = getUserRole(state);
    return customerUserRoles.includes(userRole);
}

export function isCompanyMode() {
    const state = store.getState();

    const user = getUser(state);
    if (!user) {
        return false;
    }

    return companyUserRoles.includes(user.role);
}

export function isCompanySalesMode() {
    const state = store.getState();
    return getIsSalesRole(getUserRole(state));
}

export function isPublicMode() {
    return !isCompanyMode() && !isCustomerMode();
}

export function hasAdminMode() {
    const state = store.getState();

    const user = getUser(state);
    if (!user) {
        return false;
    }

    return false; // TODO: Add check or remove func
}
