import React, { Component } from "react";
import { withRouter, NavLink } from "react-router-dom";
import { withTranslation, useTranslation } from "react-i18next";
import moment from "moment";
import { Container, Row, Col, Card, CardHeader, CardBody, CardTitle } from "reactstrap";

import ajax from "remotes/ajax";
import { getBookingReferenceUrl, getBookingStatusUrl } from "config/urls";
import formatRoute from "util/formatRoute";
import { dateFormat } from "util/dateTime";
import { getShipmentsTotalsText } from "util/shipments";

import { PricesReadOnly } from "components/form/price/PricesReadOnly";
import RejectOfferModal from "components/page/offer/RejectOfferModal";
import Loading from "components/notifications/Loading";

class Offerpage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            offer: null,
            offerError: false,
            isLoading: true,
        };
    }

    async componentDidMount() {
        this.fetchOfferByReference();
    }

    async fetchOfferByReference() {
        const reference = this.props.match.params.reference;
        if (reference) {
            try {
                this.setState({
                    isLoading: true,
                    offerError: false,
                });
                const offer = await ajax.doGetOffer(reference);
                this.setState({
                    offer,
                    isLoading: false,
                });
            }
            catch (error) {
                this.setState({
                    isLoading: false,
                    offerError: true,
                });
            }
        }
    }

    render() {
        const { t } = this.props;

        let content;
        if (this.isShowError()) {
            content = <strong>{t("offerPage.offerExpiredText", "The offer you are looking for has expired.")}</strong>;
        }
        else if (this.isShowLoading()) {
            content = <Loading />;
        }
        else {
            content = this.renderContent();
        }
        return (
            <div className="content">
                <Container disableGutters>
                    <Row className="card-row">
                        <Col sm="12">
                            <Card className="card-wizard">
                                <CardHeader>
                                    {this.renderHeader()}
                                </CardHeader>
                                <CardBody className="text-center py-4">
                                    {content}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }

    renderHeader() {
        return (
            <>
                <CardHeader className="text-center">
                    {this.renderTitle()}
                    {this.renderHeaderContent()}
                </CardHeader>
            </>
        );
    }

    renderTitle() {
        const { t } = this.props;
        let title = t("offerPage.defaultTitle", "Offer overview");
        if (this.isShowExpired()) {
            title = t("offerPage.expiredTitle", "The offer you are looking for has expired");
        }
        return (
            <>
                <CardTitle tag="h3" className="text-center pt-2">{title}</CardTitle>
            </>
        );
    }

    renderHeaderContent() {
        const { t } = this.props;
        if (this.isShowBooked()) {
            return <strong>{t("offerPage.offerApprovedText", "Offer is approved!")}</strong>;
        }

        const dueDate = this.state.offer ? this.state.offer.due_date : null;
        if (dueDate && (this.isShowStartBooking() || this.isShowExpired())) {
            return <div>{t("offerPage.validUntilLabel", "Valid until")} <strong>{this.momentDate(dueDate)}</strong></div>;
        }

        return null;
    }

    renderContent() {
        return (
            <>
                {this.renderContactsSection()}
                <hr />
                {this.renderShipmentsSection()}
                <hr />
                {this.renderOfferSection()}
                {this.renderCardFooter()}
            </>
        );
    }

    renderContactsSection() {
        const { t } = this.props;
        return (
            <>
                <h4 className="mt-0"><i className="fa fa-envelope-o mr-2" />{t("offerPage.contactsHeader", "Contacts")}</h4>
                <Row>
                    <Col md="6">
                        <Row>
                            <Col md="6" className="text-right">
                                {t("common.companyNameLabel", "Company name")}
                            </Col>
                            <Col md="6" className="text-left">
                                <strong>{this.state.offer.company_name}</strong>
                            </Col>
                        </Row>

                        <Row>
                            <Col md="6" className="text-right">
                                {t("common.contactPersonLabel", "Contact person")}
                            </Col>
                            <Col md="6" className="text-left">
                                <strong>{this.state.offer.contact_person}</strong>
                            </Col>
                        </Row>

                    </Col>
                    <Col md="6">
                        <Row>
                            <Col md="6" className="text-right">
                                {t("common.contactEmailLabel", "Contact e-mail")}
                            </Col>
                            <Col md="6" className="text-left">
                                <strong>{this.state.offer.contact_email}</strong>
                            </Col>
                        </Row>

                        <Row>
                            <Col md="6" className="text-right">
                                {t("common.contactPhoneLabel", "Contact phone")}
                            </Col>
                            <Col md="6" className="text-left">
                                <strong>{this.state.offer.contact_phone}</strong>
                            </Col>
                        </Row>

                    </Col>
                </Row>
            </>
        );
    }

    renderShipmentsSection() {
        const { t } = this.props;
        return (
            <>
                <h4 className="mt-0"><i className="fa fa-truck mr-2" />{t("offerPage.shipmentsHeader", "Shipment")}</h4>
                <Row>
                    <Col md="6">
                        <Row>
                            <Col md="6" className="text-right">
                                {t("common.routeLabel", "Route")}
                            </Col>
                            <Col md="6" className="text-left">
                                <strong>{formatRoute(this.state.offer)}</strong>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6" className="text-right">
                                {t("common.totalLabel", "Total")}
                            </Col>
                            <Col md="6" className="text-left">
                                <strong>{getShipmentsTotalsText(this.state.offer.rows, "")}</strong>
                            </Col>
                        </Row>
                    </Col>
                    <Col md="6">
                        <Row>
                            <Col md="6" className="text-right">
                                {t("common.pickupDateLabel", "Planned collection date")}
                            </Col>
                            <Col md="6" className="text-left">
                                <strong>{this.momentDate(this.state.offer.pickup_date)}</strong>
                            </Col>
                        </Row>

                        <Row>
                            <Col md="6" className="text-right">
                                {t("common.deliveryDateLabel", "Planned delivery date")}
                            </Col>
                            <Col md="6" className="text-left">
                                <strong>{this.momentDate(this.state.offer.delivery_date)}</strong>
                            </Col>
                        </Row>
                    </Col>
                    <RenderDangerousAndTemperatureSensitiveGoods
                        dangerous={this.state.offer.dangerous}
                        temperatureSensitive={this.state.offer.temperature_sensitive}
                    />
                </Row>
                <Row>
                    <Col md="3" className="text-right">
                        {t("common.commentLabel", "Comment")}
                    </Col>
                    <Col md="9" className="text-left">
                        <strong>{this.state.offer.customer_comments}</strong>
                    </Col>
                </Row>
            </>
        );
    }

    renderOfferSection() {
        const { t } = this.props;
        return (
            <>
                <h4 className="mt-0"><i className="fa fa-file-text-o mr-2" />{t("offerPage.offerHeader", "Offer")}</h4>
                <Row className="justify-content-center">
                    <Col md="12">
                        <PricesReadOnly
                            price={this.state.offer.price}
                            prices={this.state.offer.prices}
                        />
                        {this.state.offer.offer_text &&
                            <div><strong>{this.state.offer.offer_text}</strong></div>
                        }
                    </Col>
                </Row>
            </>
        );
    }

    renderCardFooter() {
        const { t } = this.props;
        let content;
        if (this.isShowBooked()) {
            content = (
                <>
                    {this.renderBookingLinks()}
                </>
            );
        }
        else if (this.isShowRejected()) {
            content = <strong>{t("offerPage.offerRejectedText", "The offer has been rejected.")}</strong>;
        }
        else if (this.isShowBookingStarted()) {
            content = (
                <NavLink
                    to={getBookingReferenceUrl(this.state.offer.reference)}
                    className="btn btn-lg btn-success"
                >
                    {t("offerPage.returnToBookingOverviewButton", "RETURN TO BOOKING")}
                </NavLink>
            );
        }
        else if (this.isShowRejectOffer() || this.isShowStartBooking()) {
            content = (
                <Row className="justify-content-center">
                    {
                        this.isShowRejectOffer() &&
                        (<Col md="auto">
                            <RejectOfferModal buttonClassName="btn btn-lg btn-block" reference={this.state.offer.reference} onReject={this.onReject} />
                        </Col>)
                    }
                    {
                        this.isShowStartBooking() && (
                            <Col md="auto">
                                <NavLink
                                    to={getBookingReferenceUrl(this.state.offer.reference)}
                                    className="btn btn-lg btn-success btn-block"
                                >
                                    {t("offerPage.acceptAndStartBookingButton", "ACCEPT AND START BOOKING")}
                                </NavLink>
                            </Col>
                        )
                    }
                </Row>
            );
        }

        return (
            <div className="text-center py-3">
                {content}
            </div>
        );
    }

    renderBookingLinks() {
        const { t } = this.props;
        const bookingLinks = this.state.offer.booking_references.map((bookingLink, index) => {
            return (
                <Row key={index} className="justify-content-center">
                    <NavLink
                        to={getBookingStatusUrl(bookingLink)}
                        className="btn btn-primary"
                    >
                        {t("offerPage.linkToBookingOverviewButton", "Link to booking")}
                    </NavLink>
                </Row>
            );
        });

        return (
            <Col>
                {bookingLinks}
            </Col>
        );
    }

    onReject = () => {
        this.setState({
            offer: {
                ...this.state.offer,
                status: "OFFER_REJECTED",
            },
        });
    }

    isOverdue = () => {
        const due_date = this.state.offer && this.state.offer.due_date;
        const dueDate = moment(due_date);
        if (dueDate.isValid() && moment().isAfter(dueDate, "day")) {
            return true;
        }

        return false;
    }

    momentDate = date => {
        const dateMoment = moment(date);

        if (!dateMoment.isValid()) {
            return "";
        }

        return moment(date).format(dateFormat);
    }

    isShowError = () => {
        return this.state.offerError === true;
    }

    isShowLoading = () => {
        return !this.isShowError() && !this.state.offer;
    }

    isShowExpired = () => {
        if (this.isShowError() || this.isShowLoading()) {
            return false;
        }
        const status = this.state.offer.status;
        const isCorrectStatus = ["OFFER_MADE", "OFFER_OPENED", "ARCHIVED"].includes(status);
        return status === "ARCHIVED" ||
            (isCorrectStatus && this.isOverdue());
    }

    isShowBookingStarted = () => {
        if (this.isShowError() || this.isShowLoading()) {
            return false;
        }
        return this.state.offer.status === "BOOKING_STARTED";
    }

    isShowBooked = () => {
        if (this.isShowError() || this.isShowLoading()) {
            return false;
        }
        return this.state.offer.status === "WON";
    }

    isShowStartBooking = () => {
        if (this.isShowError() || this.isShowLoading()) {
            return false;
        }
        return ["OFFER_MADE", "OFFER_OPENED"].includes(this.state.offer.status) &&
            !this.isOverdue();
    }

    isShowRejectOffer = () => {
        if (this.isShowError() || this.isShowLoading()) {
            return false;
        }
        return ["OFFER_MADE", "OFFER_OPENED"].includes(this.state.offer.status);
    }

    isShowRejected = () => {
        if (this.isShowError() || this.isShowLoading()) {
            return false;
        }

        return this.state.offer.status === "OFFER_REJECTED";
    }
}



export function RenderDangerousAndTemperatureSensitiveGoods(props) {
    const { dangerous, temperatureSensitive } = props;
    const { t } = useTranslation();

    if (!dangerous && !temperatureSensitive) {
        return null;
    }

    let temperature_sensitive_value = "";
    if (temperatureSensitive) {
        const temperatureSensitiveText = <strong>{t("offerPage.isTemperateSensitiveValue", "Temperature sensitive")}</strong>;
        if (dangerous) {
            temperature_sensitive_value = <><br />{temperatureSensitiveText}</>;
        } else {
            temperature_sensitive_value = temperatureSensitiveText;
        }
    }

    return (
        <Col md="12">
            <Row>
                <Col md="3" className="text-right">
                    {t("offerPage.otherLabel", "Other")}
                </Col>
                <Col md="9" className="text-left">
                    {dangerous && <strong>  {t("common.labels.dangerousGoods", "Dangerous goods")}</strong>}
                    {temperature_sensitive_value}
                </Col>
            </Row>
        </Col>
    );
}

export default withRouter(withTranslation()(Offerpage));
