import React from "react";
import { withRouter, Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import ajax from "remotes/ajax";
import urls from "config/urls";
import UtilAlert from "util/toastAlerts";
import CustomInput from "components/form/Input";
import Alerts from "components/Alerts/Alerts";
import { createAlertObject, alertContext, alertType } from "util/Alerts";
import { Container, Paper, Box, Button, FormControl } from '@material-ui/core';
import TypographyLargest from "components/form/TypographyLargest";

//import "./ForgotPassword.scss";
import { getServerErrorMessage } from "util/Errors";

const RESET_PASSWORD_ALERT_GROUP = "resetPasswordGroup";

class ForgotPasswordView extends React.Component {
    state = {
        email: "",
        passwordResetSuccess: false,
        alerts: [],
    };

    render() {
        const { t } = this.props;
        return (
            <Container>
                <Box mt={6}>
                    <Paper
                        elevation={3}
                        style={{ maxWidth: '70rem', margin: 'auto' }} >
                        <Box p={8}>
                            <TypographyLargest>
                                {t("form.booking.formFields.heading.restorePassword", "Restore password")}
                            </TypographyLargest>
                            <Box mt={4}>
                                {this.renderContent()}
                            </Box>
                            <Box p={2}>
                                <Link
                                    to={urls.LOGIN}
                                    style={{ display: 'block', margin: 'auto', textAlign: 'center' }}
                                >{this.props.t("authentication.forgotPassword.loginLink", "Login")}</Link>
                            </Box>
                        </Box>
                    </Paper>
                </Box>
            </Container >
        );
    }

    renderContent() {
        if (this.state.passwordResetSuccess) {
            return this.renderAlerts();
        }

        return this.renderForm();
    }

    renderAlerts() {
        return (
            <Alerts
                manualAlerts={this.state.alerts}
                hideGlobal={true}
                filterGroups={[RESET_PASSWORD_ALERT_GROUP]}
                className={`text-left ${this.state.alerts.length ? "mb-2" : "mb-0"
                    }`}
            />
        );
    }

    renderForm() {
        return (
            <FormControl
                fullWidth={true}
                id="forgot-password-form"
            >
                <Box mb={2}>
                    <CustomInput
                        value={this.state.email}
                        onChange={(value) =>
                            this.setState({ email: value })
                        }
                        type="email"
                        labelText={this.props.t("authentication.forgotPassword.emailLabel", "Email")}
                    />
                </Box>
                {this.renderAlerts()}
                <Box mt={2}>
                    <Button
                        variant="contained"
                        size="large"
                        className="submitButton"
                        onClick={this.onSubmit}
                    >
                        {this.props.t("authentication.forgotPassword.submitButton", "Send link to email")}
                    </Button>
                </Box>
            </FormControl>
        );
    }

    resetAlerts = () => {
        this.setState({ alerts: [] });
    };

    addAlert = (alert) => {
        this.setState((prevState) => {
            return {
                alerts: [...prevState.alerts, alert],
            };
        });
    };

    onSubmit = (e) => {
        e.preventDefault();
        this.resetAlerts();

        this.resetPassword(this.state.email);
    };

    resetPassword = (email) => {
        ajax.doForgotPassword(email)
            .then(() => {
                this.setState({ loading: false, passwordResetSuccess: true });
                const message = (
                    <span>
                        {this.props.t("authentication.forgotPassword.submitSuccessMessage",
                            "E-mail with link to change the password has been sent to the given e-mail.")}
                    </span>
                );
                this.addAlert(
                    createAlertObject(
                        alertContext.LOCAL,
                        alertType.SUCCESS,
                        RESET_PASSWORD_ALERT_GROUP,
                        message
                    )
                );
            })
            .catch((error) => {
                const errorMessage = getServerErrorMessage(error);
                UtilAlert.createAlert(errorMessage, {
                    type: UtilAlert.ALERT_TYPE.ERROR,
                    title: this.props.t("authentication.forgotPassword.submitErrorMessage", "Failed to reset password!")
                });
            });
    };
}

export default withRouter(withTranslation()(ForgotPasswordView));
