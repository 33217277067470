import React from "react";
import { Trans } from "react-i18next";
import _ from "lodash";
import cloneDeep from "lodash/cloneDeep";
import omit from "lodash/omit";
import { getNewShipment, getShipmentsTotalsText } from "util/shipments";
import formatRoute from "util/formatRoute";
import { dateFormat } from "util/dateTime";
import omitDeep from "util/omitDeep";
import moment from "moment";

const getOfferFieldsInitialData = (isQuoteMode) => {
    return {
        internal_comments: null,
        offer_text: null,
        price: 0,
        prices: isQuoteMode ? [] : [getNewPrice()],
        due_date: isQuoteMode ? null : getDefaultDueDate(),
        latest_feedback: {}
    };
}

export function getNewQuote() {
    return {
        is_new: true,
        transport_type: "ROAD",
        load_type: null,
        rows: [getNewShipment()],
        events: [],
        from_location: "",
        from_address: "",
        from_city: "",
        from_county: "",
        from_country_code: "",
        from_region_code: "",
        from_company: "",
        to_location: "",
        to_address: "",
        to_city: "",
        to_county: "",
        to_country_code: "",
        to_region_code: "",
        to_company: "",
        dangerous: false,
        dangerous_un_nr: "",
        dangerous_package_group: "",
        dangerous_class: "",
        dangerous_neq: "",
        dangerous_technical_name: "",
        temperature_sensitive: false,
        temperature_min: null,
        temperature_max: null,
        pickup_date: null,
        delivery_date: null,
        incoterms: "DAP",
        company_name: "",
        contact_person: "",
        contact_email: "",
        contact_phone: "",
        customer_comments: "",
        transport_insurance: false,
        customs_clearance: false,
        total_weight: null,
        total_ldm: null,
    };
}

export function getNewOffer() {
    return {
        ...getNewQuote(),
        ...getOfferFieldsInitialData()
    }
}

export function cleanPrefillQuote(data = {}, isOfferMode = false) {
    data = cloneDeep(data);
    data = omit(data, ["status", "booking_references", "booking_ids", "created_on", "reference"]);
    data = omitDeep(data, ["id"]);
    if (!isOfferMode) {
        const offerFields = Object.keys(getOfferFieldsInitialData());
        data = omit(data, offerFields);
    }
    return data;
}

export function getNewPrefillQuoteObject(prefillData = {}, options = {}) {
    const defaultOptions = { isOfferMode: false };
    const conf = { ...defaultOptions, ...options };
    prefillData = cleanPrefillQuote(prefillData, conf.isOfferMode);
    if (conf.isOfferMode) {
        return {
            ...getNewOffer(),
            ...prefillData
        };
    } else {
        return {
            ...getNewQuote(),
            ...prefillData
        }
    }
}

export function getNewPrice() {
    return {
        is_new: true,
        temp_id: _.uniqueId("new_"),
        price_type: "ALL_IN_PRICE",
        currency: "EUR",
        description: "All in price"
    };
}

export function getDefaultDueDate() {
    const nowInTwoWeeks = moment().add(14, "days");
    const dateInTwoWeeks = moment(nowInTwoWeeks.format(dateFormat), dateFormat);
    return dateInTwoWeeks;
}

export function findImportantChanges(old, current) {
    const changes = [];
    if (!current || !current.id) {
        return changes;
    }

    const oldRoute = formatRoute(old);
    const currentRoute = formatRoute(current);
    if (oldRoute !== currentRoute) {
        const label = <Trans i18nKey="form.importantChanges.routeLabel">Route</Trans>;
        const description = (
            <span>
                <Trans i18nKey="form.importantChanges.routeChangedTo">
                    Route changed to <code>{{ ROUTE: currentRoute }}</code>
                </Trans>
            </span>
        );
        const change = createChangeObject(label, oldRoute, currentRoute, description);
        changes.push(change);
    }

    const oldShipmentTotalsText = getShipmentsTotalsText(old.rows);
    const currentShipmentTotalsText = getShipmentsTotalsText(current.rows);
    if (oldShipmentTotalsText !== currentShipmentTotalsText) {
        const label = <Trans i18nKey="form.importantChanges.shipmentsLabel">Shipments</Trans>;
        const description = (
            <span>
                <Trans i18nKey="form.importantChanges.shipmentTotals">
                    Shipment totals changed to <code>{{ SHIPMENT_TOTALS: currentShipmentTotalsText }}</code>
                </Trans>
            </span>
        );
        const change = createChangeObject(label, oldShipmentTotalsText, currentShipmentTotalsText, description);
        changes.push(change);
    }

    if (old.dangerous !== current.dangerous) {
        const label = <Trans i18nKey="form.importantChanges.dangerousLabel">Dangerous goods</Trans>;
        let description = null;
        if (current.temperature_sensitive) {
            description = (
                <span>
                    <Trans i18nKey="form.importantChanges.isDangerousDescription">
                        Goods changed to <code>dangerous</code>
                    </Trans>
                </span>
            );
        } else {
            description = (
                <span>
                    <Trans i18nKey="form.importantChanges.isNotDangerousDescription">
                        Goods changed to <code>not dangerous</code>
                    </Trans>
                </span>
            );
        }
        const change = createChangeObject(label, _.toString(old.dangerous), _.toString(current.dangerous), description);
        changes.push(change);
    }

    if (old.temperature_sensitive !== current.temperature_sensitive) {
        const label = <Trans i18nKey="form.importantChanges.temperatureSensitiveLabel">Temperature sensitive goods</Trans>;
        let description = null;
        if (current.temperature_sensitive) {
            description = (
                <span>
                    <Trans i18nKey="form.importantChanges.isTemperateSensitiveDescription">
                        Goods changed to <code>not temperature sensitive</code>
                    </Trans>
                </span>
            );
        } else {
            description = (
                <span>
                    <Trans i18nKey="form.importantChanges.isNotTemperatureSensitiveDescription">
                        Goods changed to <code>temperature sensitive</code>
                    </Trans>
                </span>
            );
        }
        const change = createChangeObject(
            label,
            _.toString(old.temperature_sensitive),
            _.toString(current.temperature_sensitive),
            description,
        );
        changes.push(change);
    }

    const oldPickupDate = moment(old.pickup_date).format(dateFormat);
    const currentPickupDate = moment(current.pickup_date).format(dateFormat);
    if (oldPickupDate !== currentPickupDate) {
        const label = <Trans i18nKey="form.importantChanges.pickupDateLabel">Planned pickup date</Trans>;
        const description = (
            <span>
                <Trans i18nKey="form.importantChanges.pickupDateDescription">
                    Planned pickup date changed to <code>{{ PICKUP_DATE: currentPickupDate }}</code>
                </Trans>
            </span>
        );
        const change = createChangeObject(label, oldPickupDate, currentPickupDate, description);
        changes.push(change);
    }

    const oldDeliveryDate = moment(old.delivery_date).format(dateFormat);
    const currentDeliveryDate = moment(current.delivery_date).format(dateFormat);
    if (oldDeliveryDate !== currentDeliveryDate) {
        const label = <Trans i18nKey="form.importantChanges.deliveryDateLabel">Planned delivery date</Trans>;
        const description = (
            <span>
                <Trans i18nKey="form.importantChanges.deliveryDateDescription">
                    Planned delivery date changed to <code>{{ DELIVERY_DATE: currentDeliveryDate }}</code>
                </Trans>
            </span>
        );
        const change = createChangeObject(label, oldDeliveryDate, currentDeliveryDate, description);
        changes.push(change);
    }

    // TODO: feature/QFPP-72 add prices array based total price important change check

    return changes;
}

function createChangeObject(label, oldVal, newVal, description) {
    return {
        label,
        oldVal,
        newVal,
        description,
    };
}
