function flattenRecursiveListOfObjects(arr, listKey) {
    if (!Array.isArray(arr)) {
        return [];
    }
    return arr.reduce((flattenedList, initialValue) => {
        return [...flattenedList, initialValue, ...flattenRecursiveListOfObjects(initialValue[listKey], listKey)];
    }, []);
}

export default flattenRecursiveListOfObjects;
