import React from "react";
import { withRouter } from "react-router-dom";
import { Container, Box } from '@material-ui/core';

import CustomerUserList from "components/user/CustomerUserList";
import CustomerManagerForm from "components/user/CustomerManagerForm";

class CustomerDetailsPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            customerId: props.match.params.id,
        };
    }

    componentDidMount() {
    }

    render() {
        const { customerId } = this.state;
        return (
            <div className="customer-details-page__wrapper">
                <Container disableGutters maxWidth={false}>
                    <Box pt={4}>
                        <CustomerUserList customerId={customerId} />
                        <CustomerManagerForm customerId={customerId} />
                    </Box>
                </Container>
            </div>
        );
    }
}

export default withRouter(CustomerDetailsPage);
