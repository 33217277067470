import { matchPath } from "react-router-dom";
import defaultsDeep from "lodash/defaultsDeep";

export function handleDynamicAsBoolean(source) {
    switch (typeof source) {
        case "function": return !!source();
        case "object": {
            if (Array.isArray(source)) {
                return source.map(s => handleDynamicAsBoolean(source)).every(item => !!item);
            } else {
                return !!source;
            }
        }
        default: return !!source;
    }
}

export default function filterEnabledRoutes(routes, options = {}) {
    if (!Array.isArray(routes)) {
        return [];
    }

    options = defaultsDeep(options, {
        depth: 0,
        skipDepth: -1,
        skipMatchPathProps: {
            path: ["/"],
            exact: true
        }
    });
    const { depth, skipDepth, skipMatchPathProps } = options;

    return routes.reduce((filteredList, currentRoute) => {
        if (handleDynamicAsBoolean(currentRoute.disabled)) {
            return filteredList;
        } else if (skipDepth >= depth && matchPath(currentRoute.path, skipMatchPathProps) !== null) {
            return [...filteredList, ...filterEnabledRoutes(currentRoute.routes, { ...options, depth: depth + 1 })]
        } else {
            return [...filteredList, {
                ...currentRoute,
                routes: filterEnabledRoutes(currentRoute.routes, { ...options, depth: depth + 1 })
            }];
        }
    }, []);
}
