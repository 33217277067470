import store from "redux/store";
import ajax from "remotes/ajax";
import { onQuotesFetchFail, onQuotesReceived } from "redux/actions/quotes";
import { onBookingsFetchFail, onBookingsReceived } from "redux/actions/bookings";

export async function fetchQuotes() {
    return ajax.doGetQuotes()
        .then(data => {
            store.dispatch(onQuotesReceived(data))
            return data;
        }
        )
        .catch(error => {
            store.dispatch(onQuotesFetchFail(error.statusText));
            throw error;
        });
}

export async function fetchBookings() {
    return ajax.doGetBookings()
        .then(data => {
            store.dispatch(onBookingsReceived(data));
            return data;
        })
        .catch(error => {
            store.dispatch(onBookingsFetchFail(error.statusText));
            throw error;
        });
}
