import React from "react";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

import { withLocalToasts } from "components/hoc";
import ChangeExistingPasswordForm from "components/user/ChangeExistingPasswordForm";
import UserDetailsForm from "components/user/UserDetailsForm";
import { Paper, Box } from '@material-ui/core';

class EmployeeProfile extends React.Component {

    componentDidMount() {
    }

    render() {
        return (
            <div className="employee-profile__wrapper">
                <Box mt={4}>
                    <Paper
                        elevation={3}
                        style={{ maxWidth: '50rem', margin: 'auto' }} >
                        <Box p={4}>
                            <UserDetailsForm />
                            <ChangeExistingPasswordForm />
                        </Box>
                    </Paper>
                </Box>
            </div>
        );
    }
}

export default withRouter(withTranslation()(withLocalToasts(EmployeeProfile)));
