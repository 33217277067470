import { combineReducers } from "redux";
import * as actionTypes from "redux/actions/actionTypes";
import auth from "./auth";
import { navigation } from "./navigation";
import alerts from "./alerts";
import { general } from "./general";
import { language } from "./language";

// project specific
import { editingQuote, viewQuoteHistory, quotes } from "redux/reducers/quotes";
import quoteSearch from "redux/reducers/quoteSearch";
import bookingSearch from "redux/reducers/bookingSearch";
import { editingBooking, bookings } from "redux/reducers/bookings";

const countryOptions = (state = [], action) => {
    switch (action.type) {
        case actionTypes.SET_COUNTRY_LIST:
            return action.countryOptions;

        default:
            return state;
    }
};

const transportManagerOptions = (state = [], action) => {
    switch (action.type) {
        case actionTypes.SET_TRANSPORT_MANAGER_LIST:
            return action.transportManagerOptions;

        default:
            return state;
    }
};

const priceTypeOptions = (state = [], action) => {
    switch (action.type) {
        case actionTypes.SET_PRICE_TYPE_LIST:
            return action.priceTypeOptions;

        default:
            return state;
    }
};

const communicationCountryCode = (state = "", action) => {
    switch (action.type) {
        case actionTypes.SET_COMMUNICATION_COUNTRY_CODE:
            return action.communicationCountryCode;

        default:
            return state;
    }
};

const appReducer = combineReducers({
    // from epn
    auth,
    general,
    language,
    navigation,
    alerts,

    // quote and book
    countryOptions,
    transportManagerOptions,
    priceTypeOptions,
    editingQuote,
    viewQuoteHistory,
    quotes,
    quoteSearch,
    bookingSearch,
    editingBooking,
    bookings,
    communicationCountryCode,

});

const rootReducer = (state, action) => {
    if (
        action.type === actionTypes.LOG_OUT ||
        action.type === actionTypes.RESET_STORE
    ) {
        state = undefined;
    }
    return appReducer(state, action);
};

export default rootReducer;
