import React from "react";
import { withRouter } from "react-router-dom";
import { withTranslation, useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import get from "lodash/get";
import defaultsDeep from "lodash/defaultsDeep";
import { Form } from "reactstrap";

import ajax from "remotes/ajax";
import userRoles from "config/userRoles";
import customerUserRoles from "config/customerUserRoles";

import CustomInput from "components/form/InputWithValidation";
import LoadingButton from "components/form/LoadingButton";
import { withLocalToasts } from "components/hoc";
import { Box, Grid, Dialog, Button, Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';


const CustomerUserModal = (props) => {
    const {
        children,
        modal,
        toggle,
        close,
        hideModalButton,
        title
    } = props;
    const { t } = useTranslation();

    const titleOrDefault = title || t("customer.userForm.createModalTitle", "Add user to customer");
    return (
        <div>
            {!hideModalButton &&
                <Button
                    className="linkStyleButton"
                    size="large"
                    onClick={toggle}
                >{t("customer.customerUserModal.openButton", "Add user")}
                </Button>}
            <Dialog open={modal}>
                <Box p={2}>
                    <CloseIcon
                        className="modalClose"
                        onClick={close} />
                    <Box p={2}>
                        <Typography variant="h5">
                            {titleOrDefault}
                        </Typography>
                        {children}
                    </Box>
                </Box>
            </Dialog>
        </div>
    );
}

class _CreateCustomerUserForm extends React.Component {
    static propTypes = {
        customerId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        isModalMode: PropTypes.bool,
        onSuccess: PropTypes.func,
    }

    createCustomerUser = (payload) => {
        const { onSuccess, t, toastAlerts, addLocalToast } = this.props;
        delete payload.id;

        return ajax.doCreateCustomerUser(payload)
            .then(data => {
                const successTitle = t("customer.userForm.createCustomerUserUserSuccessTitle", "User created");
                const successMessage = t("customer.userForm.createCustomerUserSuccessMessage", "Set password e-mail has been sent to the user email.");
                const alertId = toastAlerts.createAlert(successMessage, {
                    type: toastAlerts.ALERT_TYPE.SUCCESS,
                    title: successTitle,
                    autoClose: false
                });
                addLocalToast(alertId);
                if (onSuccess) {
                    onSuccess(data);
                }
                return { modal: false };
            })
            .catch(error => {
                const data = get(error, "response.data", null);
                if (typeof data === "object" && data !== null) {
                    return { validationErrors: data };
                } else {
                    const errorTitle = t("customer.userForm.createCustomerUserErrorMessage", "Error occured during user creation.");
                    const alertId = toastAlerts.createErrorAlert(error, {
                        title: errorTitle
                    });
                    addLocalToast(alertId);
                }
                return {};
            })
    }

    render() {
        const { t, customerId, isModalMode } = this.props;
        return (
            <CustomerUserForm
                customerId={customerId}
                onSubmit={this.createCustomerUser}
                submitButton={t("customer.userForm.submitButton", "Add user")}
                isModalMode={isModalMode}
            />
        );
    }
}

class _EditCustomerUserForm extends React.Component {
    static propTypes = {
        customerId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        customerUserId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        onSuccess: PropTypes.func,
        isModalMode: PropTypes.bool,
        modal: PropTypes.bool,
        hideModalButton: PropTypes.bool,
        onToggle: PropTypes.func,

    }

    editCustomerUser = (customerUser) => {
        const { onSuccess, t, toastAlerts, addLocalToast, customerUserId } = this.props;
        const payload = customerUser;

        return ajax.doPartialUpdateCustomerUser(customerUserId, payload)
            .then(data => {
                const successMessage = t("customer.userForm.editCustomerUserSuccessMessage", "User info has been updated.");
                const alertId = toastAlerts.createAlert(successMessage, {
                    type: toastAlerts.ALERT_TYPE.SUCCESS,
                    autoClose: true
                });
                addLocalToast(alertId);
                if (onSuccess) {
                    onSuccess(data);
                }
                return { modal: false };
            })
            .catch(error => {
                const data = get(error, "response.data", null);
                if (typeof data === "object" && data !== null) {
                    return { validationErrors: data };
                } else {
                    const errorTitle = t("customer.userForm.editCustomerUserErrorMessage", "Error occured during user update.");
                    const alertId = toastAlerts.createErrorAlert(error, {
                        title: errorTitle
                    });
                    addLocalToast(alertId);
                }
                return {};
            })
    }

    render() {
        const { t, customerId, isModalMode, customerUserId, modal, hideModalButton, onToggle } = this.props;
        return (
            <CustomerUserForm
                customerId={customerId}
                customerUserId={customerUserId}
                onSubmit={this.editCustomerUser}
                submitButton={t("customer.userForm.editSubmitButton", "Save")}
                isModalMode={isModalMode}
                modal={modal}
                hideModalButton={hideModalButton}
                onToggle={onToggle}
                modalTitle={t("customer.customerUserModal.editTitle", "Edit user")}
            />
        );
    }
}


class _CustomerUserForm extends React.Component {
    static propTypes = {
        customerId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        customerUserId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        onSuccess: PropTypes.func,
        onSubmit: PropTypes.func.isRequired,
        submitButton: PropTypes.string.isRequired,
        disabled: PropTypes.bool,
        isModalMode: PropTypes.bool,
        modal: PropTypes.bool,
        hideModalButton: PropTypes.bool,
        onToggle: PropTypes.func,
        modalTitle: PropTypes.node
    }

    constructor(props) {
        super(props);

        this.state = {
            // new customer data
            customerUser: this.getInitialFormState(props),
            // other
            loading: false,
            disabled: !!props.disabled,
            validationErrors: {},
            modal: !!props.modal,
        };
    }

    FORM_ID = "customer-user-form";
    WRAPPER_CLASS = "customer-user-form__wrapper";

    componentDidMount() {
        if (this.props.customerUserId) {
            this.getCustomerUserDetails();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { customerUserId } = this.props;
        if (prevProps.modal !== this.props.modal) {
            this.setState({ modal: !!this.props.modal });
        }

        if (this.state.modal !== prevState.modal) {
            if (this.state.modal) {
                if (customerUserId) {
                    this.getCustomerUserDetails();
                }
            } else {
                this.setState({
                    customerUser: this.getInitialFormState(this.state.props),
                    validationErrors: {}
                });
            }
        }
    }

    componentWillUnmount() {
    }


    render() {
        return (
            <div className={this.WRAPPER_CLASS}>
                {this.renderContent()}
            </div>
        );
    }

    renderModalMode() {
        const { onToggle, hideModalButton, modalTitle } = this.props;
        const { modal } = this.state;
        const toggle = () => {
            this.setState(prevState => ({ modal: !prevState.modal }));
            if (onToggle) {
                onToggle();
            }
        };
        const close = () => {
            this.setState({ modal: false })
        }

        return (
            <CustomerUserModal
                modal={modal}
                toggle={toggle}
                close={close}
                hideModalButton={hideModalButton}
                title={modalTitle}
            >
                {this.renderForm()}
            </CustomerUserModal>
        )
    }

    renderContent() {
        const { isModalMode } = this.props;
        if (isModalMode) {
            return this.renderModalMode();
        }
        return this.renderForm();
    }

    renderForm() {
        const { customerUserId, submitButton, t } = this.props;
        const { validationErrors, loading, disabled, customerUser } = this.state;
        return (
            <Form
                onSubmit={this.onSubmit}
                id={this.FORM_ID}
                className="create-customer__form text-left"
            >
                <Box p={2}>
                    <Grid container>
                        <Grid item sm={12}>
                            <CustomInput
                                value={customerUser.user.email}
                                error={get(validationErrors, "user.email", undefined)}
                                onChange={(value) =>
                                    this.updateStateCustomerUser({ user: { email: value } })
                                }
                                type="email"
                                labelText={t("fields.emailLabel", "Email")}
                                placeholder={t("fields.emailPlaceholder", "Email")}
                                isRequired={true}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <CustomInput
                                value={customerUser.user.first_name}
                                error={get(validationErrors, "user.first_name", undefined)}
                                onChange={(value) =>
                                    this.updateStateCustomerUser({ user: { first_name: value } })
                                }
                                type="text"
                                labelText={t("fields.firstNameLabel", "First name")}
                                placeholder={t("fields.firstNamePlaceholder", "First name")}
                                isRequired={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <CustomInput
                                value={customerUser.user.last_name}
                                error={get(validationErrors, "user.last_name", undefined)}
                                onChange={(value) =>
                                    this.updateStateCustomerUser({ user: { last_name: value } })
                                }
                                type="text"
                                labelText={t("fields.lastNameLabel", "Last name")}
                                placeholder={t("fields.lastNamePlaceholder", "Last name")}
                                isRequired={true}
                            />
                        </Grid>
                    </Grid>
                    {customerUserId && (
                        <FormControlLabel
                            control={<Checkbox
                                checked={customerUser.is_active}
                                onChange={(event) => this.updateStateCustomerUser({ is_active: event.target.checked })} />}
                            label={t("fields.isActiveLabel", "Active")}
                        />
                    )

                    }
                    <Box pt={4}>
                        <LoadingButton
                            loading={loading}
                            disabled={disabled}
                            color="primary"
                            form={this.FORM_ID}
                        >
                            {submitButton}
                        </LoadingButton>
                    </Box>
                </Box>
            </Form>
        );
    }

    getInitialFormState() {
        const { customerId, customerUserId } = this.props;
        return {
            id: customerUserId,
            company: customerId,
            is_active: true,
            role: customerUserRoles.ADMIN,
            user: {
                id: undefined,
                role: userRoles.CUSTOMER,
                email: "",
                first_name: "",
                last_name: ""
            }
        };
    }

    updateStateCustomerUser(changes) {
        this.setState(prevState => ({
            customerUser: defaultsDeep(changes, prevState.customerUser)
        }));
    }

    onValidate = () => {
        const { t } = this.props;
        const errors = {};
        let requiredFields = ["user.email", "user.first_name", "user.last_name"];

        for (const fieldName of requiredFields) {
            const value = get(this.state.customerUser, fieldName, "");
            if (value.trim() === "") {
                errors[fieldName] = t("validations.isRequired", "This field may not be blank");
            }
        }

        this.setState({
            validationErrors: errors
        });

        return Object.keys(errors).length === 0;
    }

    onSubmit = (e) => {
        e.preventDefault();
        this.props.dismissLocalToasts();
        if (this.onValidate()) {
            this.setState({ loading: true });
            this.props.onSubmit(this.state.customerUser)
                .then(stateChanges => this.setState({ ...stateChanges }))
                .finally(() => this.setState({ loading: false }));
        }
    };

    getCustomerUserDetails() {
        const { customerUserId, t, toastAlerts, addLocalToast } = this.props;
        this.setState({ loading: true });
        ajax.doGetCustomerUser(customerUserId)
            .then(data => {
                this.setState({
                    customerUser: data
                });
            })
            .catch(error => {
                this.setState({ disabled: true });
                const errorTitle = t("customer.userForm.getCustomerUserErrorMessage", "Error occured when fetching user details");
                const alertId = toastAlerts.createAlert(error, {
                    type: toastAlerts.ALERT_TYPE.ERROR,
                    title: errorTitle,
                    autoClose: false
                });
                addLocalToast(alertId);

            })
            .finally(() => this.setState({ loading: false }));
    }


}

const CustomerUserForm = withRouter(withTranslation()(withLocalToasts(_CustomerUserForm)));
export const CreateCustomerUserForm = withTranslation()(withLocalToasts(_CreateCustomerUserForm));
export const EditCustomerUserForm = withTranslation()(withLocalToasts(_EditCustomerUserForm));
export default CustomerUserForm;
