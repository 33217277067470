// This one is directly used in src/axios.js because importing threw errors.
//export const API_BASE_URL = process.env.REACT_APP_API_HOST;
export const ENABLE_REDUX_DEVTOOLS =
    process.env.REACT_APP_ENABLE_REDUX_DEVTOOLS === "true";

export const BASE_PATH = process.env.REACT_APP_BASE_PATH || "/";

export const COMPANY_NAME = process.env.REACT_APP_COMPANY_NAME || "Quote & Book";
export const COMPANY_LOGO_SRC = process.env.REACT_APP_COMPANY_LOGO_SRC || "/images/logo.png";
export const COMPANY_LOGO_CIRCLE = process.env.REACT_APP_COMPANY_LOGO_CIRCLE !== "false";
export const COMPANY_LOGO_MAX_WIDTH = process.env.REACT_APP_COMPANY_LOGO_MAX_WIDTH || 56;
export const COMPANY_LOGO_MAX_HEIGHT = process.env.REACT_APP_COMPANY_LOGO_MAX_HEIGHT || 56;
export const PRIMARY_COLOR = process.env.REACT_APP_PRIMARY_COLOR || "blue";
