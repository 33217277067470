import omit from "lodash/omit";
import forIn from "lodash/forIn";

export default function omitDeep(object, paths = []) {
    if (typeof object === "object") {
        if (object === null) {
            return object;
        } else if (Array.isArray(object)) {
            return object.map(item => omitDeep(item, paths));
        } else {
            const updatedObject = omit(object, paths);
            forIn(updatedObject, (value, key) => {
                updatedObject[key] = omitDeep(value, paths);
            })
            return updatedObject;
        }
    } else {
        return object;
    }
}
