import React from "react";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { withLocalToasts } from "components/hoc";
import ChangeExistingPasswordForm from "components/user/ChangeExistingPasswordForm";
import UserDetailsForm from "components/user/UserDetailsForm";
import { Container, Paper, Box } from '@material-ui/core';

class CustomerProfile extends React.Component {

    componentDidMount() {
    }

    componentWillUnmount() {
        this.props.dismissLocalToasts();
    }

    render() {
        return (
            <div className="customer-profile__wrapper">
                <Container>
                    <Box mt={6}>
                        <Paper
                            elevation={3}
                            style={{ maxWidth: '70rem', margin: 'auto' }} >
                            <Box p={8}>
                                <UserDetailsForm />
                                <ChangeExistingPasswordForm />
                            </Box>
                        </Paper>
                    </Box>
                </Container>
            </div >
        );
    }

    renderContent() {
        return this.renderForm();
    }

    renderForm() {
        return (
            <>

            </>
        );
    }
}

export default withRouter(withTranslation()(withLocalToasts(CustomerProfile)));
