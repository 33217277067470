import React from "react";
import { Button } from "@material-ui/core";

import LoadingAnimation from "components/Loader/WrappedPulseLoader";

export default function LoadingButton(props) {
    const { disabled, loading, children, ...otherProps } = props;

    return (
        <Button
            variant="contained"
            className="submitButton"
            type="submit"
            disabled={disabled || loading}
            {...otherProps}
        >
            {loading ? <LoadingAnimation /> : children}
        </Button>
    );
}
