export const defaultStatusFilter = {
    Fresh: true,
    Queued: true,
    Outdated: false,
    Failed: true,
};

export const defaultQuoteStatusFilter = {
    SUBMITTED: true,
    OFFER_MADE: true,
    OFFER_OPENED: true,
    OFFER_REJECTED: true,
    BOOKING_STARTED: true,
    WON: true,
    ARCHIVED: false,
};

export const statisticsPeriodFilter = {
    current_week: "Current week",
    last_week: "Last week",
    current_month: "Current month",
    last_month: "Last month",
    current_year: "Current year",
    last_year: "Last year",
};

export const defaultStatisticsPeriod = "current_month";
