import React, { Component, Fragment } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { withStyles } from '@material-ui/styles';
import { styles } from '../../assets/stylesMUI/shipmentRowStyles';
import { FormControlLabel, Checkbox } from "@material-ui/core";
import clsx from 'clsx';

const stylesz = styles;

class AdditionalInfoBlockCheckboxes extends Component {
    static propTypes = {
        data: PropTypes.bool.isRequired,
        readOnly: PropTypes.bool,
        onChange: PropTypes.func.isRequired,
        className: PropTypes.string,
        classes: PropTypes.object.isRequired,
        quoteFormField: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
    };

    render() {
        const { classes } = this.props;
        return (
            <Fragment >
                <div>
                    <div className={clsx(classes.checkBoxContainer, classes.containerWide,
                        this.isCheckboxChecked(this.props.data) &&
                        classes.checkBoxContainerChecked)}
                    >
                        <div>
                            <FormControlLabel
                                className={classes.checkboxLabel}
                                control={<Checkbox
                                    color="default"
                                    className={classes.checkbox}
                                    checked={this.isCheckboxChecked(this.props.data)}
                                />}
                                label={this.getHelperText()}
                                onChange={(event) => this.props.onChange(this.props.quoteFormField, event.target.checked)}
                                disabled={this.props.readOnly}
                            />
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }

    isCheckboxChecked = (value) => {
        return value === null || value === undefined ? false : value;
    }

    getHelperText = () => {
        const { t, classes, data, label } = this.props;
        if (this.isCheckboxChecked(data)) {
            return (
                <>
                <span className={classes.labelText}>{label}</span>
                <p className={classes.checkBoxCheckedHelperText}>
                    {t("form.checkboxHelperText.checked", "Checked")}
                </p>
                </>
            )
        } else return (
            <>
            <span className={classes.labelText}>{label}</span>
            <p className={classes.checkBoxHelperText}>
                {t("form.checkboxHelperText.choose", "Choose")}
            </p>
            </>
        );
    }
}

export default withTranslation()(withStyles(stylesz)(AdditionalInfoBlockCheckboxes));