/* eslint-disable react/no-did-mount-set-state */
import React, { Component } from "react";
import { withRouter, NavLink } from "react-router-dom";
import { withTranslation } from "react-i18next";
import _ from "lodash";
import styled from "styled-components";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { toast } from "react-toastify";

import ajax from "remotes/ajax";
import Loading from "components/notifications/Loading";
import {
    onSetCountryList,
    onSetCountryListFetchFail,
    onSetCommunicationCountryCode,
    onSetCommunicationCountryCodeFetchFail,
} from "redux/actions";
import {
    onPrefillBookingForm,
    onViewBooking,
    onBookingFetchFail,
    onCloseBookingForm,
    onSaveBookingUpdate,
    updateBookingFormField
} from "redux/actions/bookings";
import CloseButton from "components/modal/CloseButton";
import BookingFormFields from "components/page/bookings/BookingFormFields";
import urls, { getQuoteDetailsrUrl, getOfferOverviewUrl, getBookingStatusUrl } from "config/urls";
import ErrorMessage from "components/form/ErrorMessage";
import AdditionalOptionsSection from "../../form/AdditionalOptionsSection";
import { getNewQuote, findImportantChanges, getNewPrefillQuoteObject } from "util/quotes";
import { isCustomerMode, isPublicMode } from "util/Access";
import { validateBooking } from "util/validations";
import ConfirmChangesModal from "components/form/ConfirmChangesModal";
import BookingTermsModal from "components/page/bookings/BookingTermsModal";
import { Button, Container, Box, Grid, Dialog } from '@material-ui/core';
import { ArrowForward } from "@material-ui/icons";
import TypographyLargest from "components/form/TypographyLargest";

const Footer = styled.div`
    margin-top: 30px;
`;

class BookingForm extends Component {
    static propTypes = {
        isModalMode: PropTypes.bool,
        isCreateMode: PropTypes.bool,
        isStartExpanded: PropTypes.bool,
        isPublicMode: PropTypes.bool,
        isCustomerMode: PropTypes.bool,
        prefillId: PropTypes.number,
        // redux state
        booking: PropTypes.object.isRequired,
        readOnly: PropTypes.bool.isRequired,
        bookingError: PropTypes.string,
        countryOptions: PropTypes.array.isRequired,
        communicationCountryCode: PropTypes.string.isRequired,
        // redux actions
        onSaveBookingUpdate: PropTypes.func.isRequired,
        onPrefillBookingForm: PropTypes.func.isRequired,
        onViewBooking: PropTypes.func.isRequired,
        onBookingFetchFail: PropTypes.func.isRequired,
        updateBookingFormField: PropTypes.func.isRequired,
        onCloseBookingForm: PropTypes.func.isRequired,
        onSetCountryList: PropTypes.func.isRequired,
        onSetCountryListFetchFail: PropTypes.func.isRequired,
        onSetCommunicationCountryCode: PropTypes.func.isRequired,
        onSetCommunicationCountryCodeFetchFail: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            errors: {},
            submitDone: false,
            isLoading: false,
            hasError: false,
            confirmationModal: false,
            initialBooking: {},
            importantChanges: [],
            termsModal: false,
            referenceMode: false,
        };
    }

    async componentDidMount() {
        const { t } = this.props;
        const id = this.props.match.params.id;
        const offerReference = this.props.match.params.reference;
        const isCreateMode = this.props.isCreateMode;

        let booking;
        const prefillId = this.props.prefillId;

        if (prefillId && !this.props.isPublicMode) {
            this.setState({
                isLoading: true,
            });
            ajax.doGetBooking(prefillId)
                .then(data => {
                    const booking = getNewPrefillQuoteObject(data);
                    if (isCreateMode) {
                        this.props.onPrefillBookingForm(booking);
                    } else {
                        this.props.onViewBooking(booking);
                    }
                })
                .catch(error => {
                    this.props.onBookingFetchFail(error.statusText);
                    toast.error(t("form.booking.errorFetchPrefillDataMessage", "Failed to load data for prefilling."))
                })
                .finally(() => this.setState({ isLoading: false }))
        } if (id) {
            try {
                this.setState({
                    isLoading: true,
                    hasError: false,
                });
                booking = await ajax.doGetBooking(id);
                if (isCreateMode) {
                    this.props.onPrefillBookingForm(booking);
                } else {
                    this.props.onViewBooking(booking);
                }
            } catch (error) {
                this.props.onBookingFetchFail(error.statusText);
                this.setState({
                    hasError: true,
                });
            } finally {
                this.setState({
                    isLoading: false
                });
            }
        } else if (offerReference) {
            try {
                this.setState({
                    referenceMode: true,
                    isLoading: true,
                    hasError: false,
                });
                booking = await ajax.doGetBookingPrefillByOfferReference(offerReference);
                if (this.redirectBookedQuotesToBookingStatusPage(booking)) {
                    return;
                } else {
                    if (isCreateMode) {
                        this.props.onPrefillBookingForm(booking);
                    } else {
                        this.props.onViewBooking(booking);
                    }
                }
                this.setState({
                    initialBooking: booking
                });
            } catch (error) {
                this.props.onBookingFetchFail(error.statusText);
                this.setState({
                    hasError: true,
                });
                this.props.history.push(getOfferOverviewUrl(offerReference));
            } finally {
                this.setState({
                    isLoading: false
                });
            }
        } else {
            booking = getNewQuote();
            if (isCreateMode) {
                this.props.onPrefillBookingForm(booking);
            } else {
                this.props.onViewBooking(booking);
            }
        }

        this.setState({
            isLoading: false,
            initialBooking: booking
        });

        if (!this.props.countryOptions.length) {
            try {
                this.setState({
                    isLoading: true,
                    hasError: false,
                });
                const countries = await ajax.doGetQuoteCountryList();
                this.props.onSetCountryList(_.sortBy(countries));
                this.setState({
                    isLoading: false,
                });
            }
            catch (error) {
                this.props.onSetCountryListFetchFail(error.statusText);
                this.setState({
                    isLoading: false,
                    hasError: true,
                });
            }
        }

        if (!this.props.communicationCountryCode) {
            try {
                this.setState({
                    isLoading: true,
                    hasError: false,
                });

                const communicationCountryCode = await ajax.doGetCommunicationCountryCode();
                this.props.onSetCommunicationCountryCode(communicationCountryCode);

                this.setState({
                    isLoading: false,
                });
            }
            catch (error) {
                this.props.onSetCommunicationCountryCodeFetchFail(error.statusText);
                this.setState({
                    isLoading: false,
                    hasError: true,
                });
            }
        }
    }

    componentWillUnmount() {
        this.props.onCloseBookingForm();
    }

    render() {
        let bookingFormBody;
        if (this.props.bookingError) {
            bookingFormBody = this.renderBookingError();
        }
        else if (_.isEmpty(this.props.booking)) {
            bookingFormBody = this.renderNoBooking();
        }
        else {
            bookingFormBody = this.renderForm();
        }

        const isModalMode = this.props.isModalMode;

        return (
            <>
                {isModalMode && <Dialog
                    onClose={this.onClose}
                    open={isModalMode}
                    maxWidth="lg"
                >
                    <Box p={2}>
                        {bookingFormBody}
                    </Box>
                </Dialog>}
                {
                    !isModalMode &&
                    <div>
                        {bookingFormBody}
                    </div>
                }
            </>
        );
    }

    renderNoBooking() {
        return (
            <div className="row justify-content-center">
                <Loading />
            </div>
        );
    }

    renderBookingError() {
        const errorMessage = this.props.t("form.booking.errorFetchingBookingMessage", "Error while fetching booking.");
        return <ErrorMessage isError={true} errorMessage={errorMessage} />;
    }

    renderTerms() {
        if (!this.props.isCreateMode) {
            return null;
        }
        return (
            <>
                <BookingTermsModal
                    isModalOpen={this.state.termsModal}
                    onToggle={this.toggleTermsModal}
                />
            </>
        );
    }

    renderActionButton() {
        const { t } = this.props;
        if (this.props.readOnly) {
            if (!this.props.booking || this.props.booking.quote_id == null) {
                return (
                    <Button
                        type="button"
                        size="large"
                        className="linkStyleButton"
                        onClick={this.onClose}
                        style={{
                            textTransform: 'none',
                            fontSize: '1rem',
                            display: 'block',
                            margin: 'auto'
                        }}
                    >
                        {t("form.booking.buttons.close", "CLOSE")}
                    </Button>
                );
            }

            return (
                <NavLink className="btn btn-primary btn-wd" to={getQuoteDetailsrUrl(this.props.booking.quote_id)}>
                    {t("form.booking.buttons.goToOffer", "GO TO OFFER")}
                </NavLink>
            );
        }

        let isDisabled = this.isDisabled();
        let buttonText;
        if (this.state.confirmationModal || this.state.loading) {
            isDisabled = true;
            buttonText = <Loading />;
        } else if (this.props.isCreateMode) {
            buttonText = t("form.booking.buttons.submit", "SUBMIT");
        } else {
            buttonText = t("form.booking.buttons.save", "SAVE");
        }

        return (
            <Container disableGutters maxWidth={false}>
                <Box m={2}>
                    <Grid container>
                        <div style={{ marginRight: 'auto' }}>
                            <AdditionalOptionsSection
                                isBookingView={true}
                                booking={this.props.booking}
                                data={this.props.booking}
                                onChange={this.props.updateBookingFormField}
                                readOnly={this.props.readOnly}
                                toggleTermsModal={this.toggleTermsModal}
                            />
                        </div>
                        <div>
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                size="large"
                                disabled={isDisabled}
                                className="submitButton"
                            >
                                {buttonText}
                                <ArrowForward style={{ margin: '0 0 2px 2px' }} />
                            </Button>
                        </div>
                    </Grid>
                </Box>
            </Container>
        );
    }

    renderPrefillNewBooking() {
        const { t, isCustomerMode } = this.props;
        const { referenceMode } = this.state;
        const prefillId = this.props.booking.id;
        if (referenceMode || !isCustomerMode || !prefillId) {
            return null;
        }
        const to = {
            pathname: urls.NEW_BOOKING,
            state: {
                prefillId: prefillId
            }
        }

        return (
            <div>
                <Button
                    variant="contained"
                    type="submit"
                    size="large"
                    className="submitButton"
                    style={{ float: 'right', marginBottom: '2rem', marginRight: '2rem' }}
                >
                    <NavLink
                        to={to}
                    >
                        {t("form.booking.buttons.newPrefilledBooking", "NEW BOOKING")}
                        <ArrowForward style={{ margin: '0 0 2px 2px' }} />
                    </NavLink>
                </Button>
            </div>
        );
    }

    renderForm() {
        const { t, isModalMode } = this.props;
        return (
            <> <Box>
                {isModalMode &&
                    <CloseButton onClick={this.onClose} />}
                <TypographyLargest>
                    {t("form.booking.heading", "Booking")}
                </TypographyLargest>
            </Box>
                <ConfirmChangesModal
                    buttonLabel="Danger"
                    headerTitle={t("form.booking.confirmChanges.heading", "Confirm important changes")}
                    isOpen={this.state.confirmationModal}
                    isError={this.state.error}
                    isLoading={this.state.loading}
                    onCancel={this.cancelImportantChanges}
                    onConfirm={this.confirmImportantChanges}
                    onToggle={this.toggleConfirmationModal}
                    changesMade={this.state.importantChanges}
                />
                <form method="#" action="#" className="mr-2" onSubmit={this.preSubmitBookingForm.bind(this)}>
                    <BookingFormFields
                        booking={this.props.booking}
                        isStartExpanded={this.props.isStartExpanded}
                        countryOptions={this.props.countryOptions}
                        errors={this.state.errors}
                        submitDone={this.state.submitDone}
                        updateBookingFormField={this.props.updateBookingFormField}
                        readOnly={this.props.readOnly}
                        communicationCountryCode={this.props.communicationCountryCode}
                        toggleTermsModal={this.toggleTermsModal}
                    />

                    {this.renderTerms()}
                    <Footer>
                        <div className="text center">
                            <ErrorMessage
                                isInvisible={true}
                                isError={this.state.error && !this.state.confirmationModal}
                            />
                            <ErrorMessage
                                isError={this.isAnyValidationErrors()}
                                errorMessage={t("form.booking.validationErrorsMessage", "Some fields have validation errors.")}
                            />
                        </div>
                        <div>
                            {this.renderPrefillNewBooking()}
                            {this.renderActionButton()}
                        </div>
                    </Footer>
                </form>
            </>
        );
    }

    toggleConfirmationModal = () => {
        this.setState({
            confirmationModal: !this.state.confirmationModal,
        });
    }

    toggleTermsModal = () => {
        this.setState({
            termsModal: !this.state.termsModal
        })
    }

    closeModal = () => {
        this.setState({
            termsModal: !this.state.termsModal
        }, () => {
            document.removeEventListener('click', this.closeModal);
        });
    }

    confirmImportantChanges = () => {
        this.submitBookingForm();
    }

    cancelImportantChanges = () => {
        this.toggleConfirmationModal();
        this.setState({
            error: null,
        });
    }

    onClose = () => {
        this.props.onCloseBookingForm();
        this.props.history.push(urls.COMPANY_BOOKINGS);
    };

    isDisabled() {
        if (this.state.loading) {
            return true;
        }
        return false;
    }

    preSubmitBookingForm = (event) => {
        event.preventDefault();
        event.stopPropagation();

        this.setState({
            submitDone: true,
        });

        if (this.validateForm()) {
            const importantChanges = findImportantChanges(this.state.initialBooking, this.props.booking);
            this.setState({
                importantChanges,
            });
            if (importantChanges && importantChanges.length) {
                this.toggleConfirmationModal();
            }
            else {
                this.submitBookingForm();
            }
        }
    }

    async submitBookingForm() {
        this.setState({
            submitDone: true,
        });
        if (this.validateForm()) {
            this.setState({
                loading: true,
                error: false,
            });
            let redirectTo = urls.BOOKINGS;
            try {
                let returnedData;
                if (this.props.isCreateMode) {
                    redirectTo = urls.BOOKING_THANK_YOU;

                    const offerReference = this.props.match.params.reference;
                    if (offerReference) {
                        returnedData = await ajax.doMakeBookingWithOfferReference(this.props.booking, offerReference);
                    } else {
                        returnedData = await ajax.doMakeBooking(this.props.booking);
                    }
                } else {
                    returnedData = await ajax.doSaveBooking(this.props.booking);
                }
                this.props.onSaveBookingUpdate(returnedData);
                this.props.history.push(redirectTo);
            } catch (error) {
                this.setState({
                    error: true,
                });
            } finally {
                this.setState({
                    loading: false,
                });
            }
        }

    }

    validateForm() {
        const errors = validateBooking(this.props.booking);
        this.setState({
            errors,
        });
        return _.isEmpty(errors);
    }

    isAnyValidationErrors = (errors) => {
        return !_.isEmpty(this.state.errors);
    }

    redirectBookedQuotesToBookingStatusPage(booking) {
        if (booking && booking.status === "WON") {
            const bookingReference = booking.booking_references[0];
            this.props.history.push(getBookingStatusUrl(bookingReference));
            return true;
        }
        return false;
    }
}

function mapStateToProps(state, parentProps) {
    return {
        booking: state.editingBooking.booking,
        readOnly: state.editingBooking.readOnly || !!parentProps.readOnly,
        countryOptions: state.countryOptions,
        communicationCountryCode: state.communicationCountryCode,
        bookingError: state.editingBooking.error,
        isPublicMode: isPublicMode() || parentProps.isPublicMode,
        isCustomerMode: isCustomerMode() || parentProps.isCustomerMode,
        prefillId: parentProps.prefillId || _.get(parentProps.location.state, "prefillId", null),
    };
}

export default withRouter(
    connect(
        mapStateToProps,
        {
            onPrefillBookingForm,
            onViewBooking,
            onBookingFetchFail,
            onSaveBookingUpdate,
            updateBookingFormField,
            onCloseBookingForm,
            onSetCountryList,
            onSetCountryListFetchFail,
            onSetCommunicationCountryCode,
            onSetCommunicationCountryCodeFetchFail,
        },
    )(withTranslation()(BookingForm)),
);
