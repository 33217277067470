import React, { Component, Fragment } from "react";
import { withTranslation, Trans } from "react-i18next";
import PropTypes from "prop-types";
import { FormGroup } from "reactstrap";
import { COMPANY_NAME } from "config/envVariables";
import { FormControlLabel, Checkbox, Box } from '@material-ui/core';

class AdditionalOptionsSection extends Component {
    static propTypes = {
        booking: PropTypes.object,
        data: PropTypes.object.isRequired,
        readOnly: PropTypes.bool,
        onChange: PropTypes.func.isRequired,
        isBookingView: PropTypes.bool,
        toggleTermsModal: PropTypes.func,
    };

    render() {
        return (
            <Fragment>
                <Box pl={2}>
                    <FormGroup row style={{ margin: 0 }}>
                        <FormControlLabel
                            disabled={this.props.readOnly}
                            control={<Checkbox checked={this.props.data.transport_insurance}
                                onChange={(event) => this.props.onChange("transport_insurance", event.target.checked)}
                            />}
                            label={<Trans i18nKey="form.additionalOptions.transportInsuranceLabel">Request for transport insurance through {{ COMPANY_NAME }}</Trans>}
                        />
                    </FormGroup>
                    < FormGroup row style={{ margin: 0 }}>
                        <FormControlLabel
                            disabled={this.props.readOnly}
                            control={<Checkbox checked={this.props.data.customs_clearance}
                                onChange={(event) => this.props.onChange("customs_clearance", event.target.checked)}
                            />}
                            label={<Trans i18nKey="form.additionalOptions.customsClearanceLabel">Request for customs clearance through {{ COMPANY_NAME }}</Trans>}
                        />
                    </FormGroup >
                    {this.props.isBookingView &&
                        <FormGroup row style={{ margin: 0 }}>
                            <FormControlLabel
                                required
                                disabled={this.props.readOnly}
                                control={<Checkbox required checked={this.props.booking.is_terms_checked || false}
                                    onChange={(event) => this.props.onChange("is_terms_checked", event.target.checked)}
                                />}
                                label={<Trans i18nKey="form.booking.complex.termsAndConditionsLabel">
                                    I agree with  <span
                                        className="btn-link"
                                        onClick={(event) => {
                                            event.preventDefault();
                                            event.stopPropagation();
                                            this.props.toggleTermsModal();
                                        }}
                                    >
                                        Terms and Conditions
                                    </span>
                                    .
                                </Trans>}
                            />
                            {/* <ValidationMessage
                        message={this.state.errors.is_terms_checked}
                        isVisible={this.state.errors.is_terms_checked}
                        className="ml-2 pl-1"
                    /> */}
                        </FormGroup>
                    }
                </Box>
            </Fragment >
        );
    }
}

export default withTranslation()(AdditionalOptionsSection);
